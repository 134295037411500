import { useState, useMemo, useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { PaletteMode } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";
import Layout from "./components/Layout";
import { getDesignTokens, ColorModeContext } from "./theme";
import ProductConfigurator from "./pages/admin/product.page";
import PriceConfigurator from "./pages/admin/price.page";
import PromptConfigurator from "./pages/admin/prompt.page";
import Dashboard from "./pages/admin/dashboard.page";
import AdminDashboard from "./pages/admin/dashboard_new.page";
import GeniePage from "./pages/genie.page";
import LandingPage from "./pages/landing.page";
import AnycodeLandingPage from "./pages/Anycode_landing";
import SignupPage from "./pages/auth/signup.page";
import TermsPage from "./pages/auth/terms.page";
import SigninPage from "./pages/auth/signin.page";
import ForgotPage from "./pages/auth/forgot.page";
import VerifyPage from "./pages/auth/verify.page";
import RequireUser from "./components/requireUser";
import UnauthorizePage from "./pages/unauthorized.page";
import PricingPage from "./pages/pricing.page";
import PrivacyPage from "./pages/auth/privacy.page";
import FeedbackPage from "./pages/feedback.page";
import PaymentPage from "./pages/payment/payment.page";
import SuccessPage from "./pages/payment/success.page";
import { Sitemap } from "./Sitemap";
import Aboutus from "./pages/auth/Aboutus.page";
import Contactus from "./pages/auth/contactus.page"
import { useParams } from "react-router-dom";
import './assets/css/bootstrap.min.css';
import ContactusPage from "./pages/contactus.page";
import Usersetting from "./pages/Usersetting";
import ResetPassword from "./pages/auth/ResetPassword.page";
import ResetPasswordAnother from "./pages/auth/ResetPasswordAnother.page";
import CommonHeader from "./components/CentriLandingPage/CommonpageHeader";
import VerificationPage from "./pages/auth/VerificationPage.page"
import InnerPageResumeBuilder from "./pages/InnerPageResumeBuilder.page";
import SecondLandingPage from "./pages/SecondLanding.page";
import DashboardPage from "./pages/Dashboard.page";
import DashboardSection from "./components/DashboardSection";


function App(props: any) {

  const [mode, setMode] = useState<PaletteMode>("dark");

  const userRole = localStorage.getItem('userRole');
  console.log('role of the user1=>' + userRole);


  const colorMode = useMemo(
    () => ({

      toggleColorMode: () => {
        debugger
        {
          userRole === 'admin' ?
            setMode((prevMode: PaletteMode) =>
              prevMode === "light" ? "light" : "light"
            ) : setMode((prevMode: PaletteMode) =>
              prevMode === "light" ? "dark" : "dark"
            )
        }
      },
    }),
    []
  );


  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  /* const loggedin_or_not = localStorage.getItem('logged_in'); */

  return (
    <>
      {console.log("Router Page", localStorage.getItem("logged_in"))}
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <CssBaseline />
          <Routes>
            {/* <Route path="/" element={<SecondLandingPage />} /> */}
          </Routes>
          {
            localStorage.getItem("logged_in") != "true" ? <Routes>
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/signin" element={<SigninPage />} />
              <Route path="/verify" element={<VerifyPage page="signin" setPassCallback={() => { return true }} />} />
              <Route path="/forgot" element={<ForgotPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/" element={<SecondLandingPage />} />
              {/* <Route path="/resetpassword" element={<ResetPassword />} />  */}
              <Route path="*" element={<Navigate replace to="/signin" />} />
            </Routes> : <Routes>
              <Route path="/" element={<SecondLandingPage />} />
              <Route path="/secondlanding" element={<LandingPage />} />
              <Route path="/Anycode_landing" element={<AnycodeLandingPage />} />
              <Route path="sitemap" element={<Sitemap />} />
              <Route path="/aboutus" element={<Aboutus />} />
              <Route path="/aboutuspage" element={<Aboutus />} />
              <Route path="/contactus" element={<ContactusPage />} />
              <Route path="/contactuspage" element={<Contactus />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/codegenie/:module" element={<GeniePage />} />
              {/* <Route element={<RequireUser allowedRoles={["admin", "user"]} />}>
                <Route path="/" element={<Layout type="user" page="" />}>
                  
                </Route>
              </Route> */}
              {/* <Route path="/" element={<Layout type="user" page="pricing" />}>
                <Route path="pricing" element={<PricingPage />} />
              </Route> */}

              <Route element={<RequireUser allowedRoles={["admin"]} />}>
                <Route path="/admin" element={<Layout type="admin" page="" />}>
                  <Route path="dashboard" element={<DashboardSection />} /> 
                  <Route path="config">
                    <Route path="products" element={<ProductConfigurator />} />
                    <Route path="prompts" element={<PromptConfigurator />} />
                    <Route path="prices" element={<PriceConfigurator />} />
                  </Route>
                  <Route path="content">
                    {/* <Route path="generate" element={<ContentGenerator / >} />  */}
                  </Route>
                </Route>
              </Route>
              {/* <Route path="/signup" element={<SignupPage />} />
               <Route path="/signin" element={<SigninPage />} />  */}
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route path="/resetpasswordanother" element={<ResetPasswordAnother />} />
              <Route path="/verificationpage" element={<VerificationPage />} />
              <Route path="/commonheader" element={<CommonHeader />} />
              <Route path="/innerpageresumebuilder" element={<InnerPageResumeBuilder />} />
              {/* <Route path="/verify" element={<VerifyPage page="signin" setPassCallback={() => { return true }} />} />  */}
              <Route path="/forgot" element={<ForgotPage />} />
              <Route path="/feedback" element={<FeedbackPage />} />
              <Route path="/checkout" element={<PaymentPage />} />
              <Route path="/codegenie/settings" element={<Usersetting />} />
              {/* <Route path="/" element={<Layout type="user" page="pricing" />}>
                <Route path="success" element={<SuccessPage />} />
              </Route> */}
              <Route path="/unauthorized" element={<UnauthorizePage />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          }
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

export default App;