import { Box, Typography, Container, Divider, SvgIcon } from "@mui/material";
import { Link } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import img54 from "../../assets/newimg/logo.png";
import { useState , useEffect } from "react";

import { object, string, TypeOf } from "zod";
import { useSubscribeNewsMutation } from "../../redux/api/authApi";
import { toast } from "react-toastify";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";


const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>
const AboutusPage = () => {
  const navigate = useNavigate();

  const handleLearnMore = () =>{
    navigate('/codegenie/smart_contracts');
  }
const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();

  const methods = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .then((response) => {
        if ('data' in response) {
          const status = response.data.status;
          console.log(status);
          if (status === 'success') {
            toast.success(response.data.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
        } else {
          // Error case
          console.error('Error sending query:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error sending query:', error);

      });
  }
  return (
    <>
      
      <Container maxWidth="lg">
      <nav className="navbar navbar-expand-md">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              {/* <img src="./img/logo.png" alt="" /> */}
              <img src={img54} className="img-fluid" alt="" />
              {/* <img src={logo} alt="" /> */}
  
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="">
                <i className="bi bi-list text-white" />
              </span>
            </button>



            
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                <li className="nav-item">
                  <a
                    className="nav-link nav_active"
                    style={{ color: "#4ba5eb", fontWeight: 800 }}
                    href="/"
                  >
                    Home
                  </a>
                </li>




                <li className="nav-item">
                  <a className="nav-link nav_simple" href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873">
                    Affiliate
                  </a>
                </li> 


              </ul>


              
              <div className="d-flex gap-4" role="search">
              <button className="button_leniar px-3" type="submit"  onClick={() => {
                navigate("/signup");
              }}>
                  Try Now
                </button>
                <button className="btn btn-outline-primary px-3"
                  onClick={() => {
                    navigate("/signin");
                  }}>Sign  in</button>
              </div>
            </div>
          </div>
        </nav>
          <Box py={5}>
            <Typography
              variant="h2"
              color="text.secondary"
              fontWeight={600}
              textAlign="justify"
              mb={10}
              
            >
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               About Us - Code Genie</div>
          </Typography>
    
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
          Who We Are    
          </Typography>
          <Typography>
          Code Genie, a product of OceSha, Inc., is a cutting-edge software tool designed to empower developers, engineering managers, 
          QA teams, and other tech professionals. Born from the desire to simplify and enhance the coding process, Code Genie is a testament to innovation, efficiency, and technological excellence.
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
          Our Mission
          </Typography>
          <Typography>
          Our mission at Code Genie is to revolutionize the way developers work by providing a 
          versatile and intelligent tool that can analyze, fix, rewrite, and assist with code in almost any programming language. We strive to increase productivity, reduce manual effort, and enable tech professionals to focus on what truly matters - creating outstanding software products.

          </Typography>

          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
          Versatile Tool
          </Typography>

          <Typography>
          Code Genie is language-agnostic and can work with practically any programming languages, including but not limited to PHP, Python, Node.js, Solidity, Angular, and more. Whether it's analyzing, fixing, debugging, refactoring, or rewriting code, Code Genie does it all within seconds.

          </Typography>

          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
          Bug-Free Development
          </Typography>
          <Typography>
          We understand the frustration of bug fixing and the challenges of code documentation. Code Genie is designed to optimize development processes, increase efficiency, and deliver high-quality, bug-free software products in a timely manner.<br></br><br></br>
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
          Specialized Solutions 
          </Typography>
          <Typography>
          From auditing and refactoring blockchain smart contracts to converting monolithic code into scalable microservices, Code Genie offers specialized solutions tailored to meet the unique needs of modern development landscapes.      
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
          Non-Techie Code Summary
          </Typography>

              <Typography>
              Code Genie bridges the gap between technical and non-technical teams by providing clear and concise code summaries. This fosters better communication and understanding across cross-functional teams.
              </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
          Our Values
          </Typography>

          <Box component="li">
          Innovation: We believe in continuous innovation to stay ahead of the technological curve.

              </Box>

              <Box component="li">
              Quality: We are committed to delivering a product that meets the highest standards of quality and excellence.

              </Box>

              <Box component="li">
              Integrity: We operate with transparency, honesty, and ethical principles.
              </Box>

              <Box component="li">
              Collaboration: We value collaboration and strive to create a product that enhances teamwork and communication.
              </Box>

              <Box component="li">
              Customer-Centric: We prioritize our customers' needs and work diligently to provide exceptional support and service.
              </Box>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
          Meet the Team
          </Typography>
          <Typography>
          Code Genie was created by a team of passionate developers, engineers, and tech enthusiasts who understand the daily challenges faced by the tech community. Our team's combined expertise and dedication have shaped Code Genie into a tool that is truly a developer's friend.
          
           
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
          Join Us
          </Typography>
          <Typography>
          Are you excited about transforming the world of coding? We're always looking for talented individuals who share our passion for technology and innovation.

          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
          Contact Us
          </Typography>
          <Typography>
          We'd love to hear from you! Whether you have questions, feedback, or just want to say hello, feel free to contact us. <br></br>  <br></br>
          Code Genie by OceSha, Inc. - Empowering Developers, Transforming Code.

          </Typography>
          

          <Typography>
          If you have any questions about these Terms, please contact Code Genie at <Link
                          to=""
                          onClick={(e) => {
                            window.location.href = `mailto:support@code-genie.ai`;
                            e.preventDefault();
                          }}
                          style={{
                            color: "#0168B5",
                          }}
                        >
                          support@code-genie.ai 
                        </Link>
          </Typography>
          </Box>
         </Container>

          <div className="py-5 mx-sm-0 mx-2 backgroundbg">
      <div className="container">
        <div className="row px-sm-0 px-2">
          <div className="col-lg-3 text-white text-lg-start text-center">
            <div>
              <div>
                {/* <img src={logo} alt="" /> */}
                <img src={img54} className="img-fluid" alt="" />

              </div>
              <p className="fw_samibold">
                OceSha/Code Genie Los Angeles, Ca 310-748-8901
              </p>
            </div>
          </div>
          <div className="col-lg-3 text-white d-lg-none d-block  text-lg-start text-center">
            <div>
              <h6 className="f_heading mb-4">
                Get news in your mailbox right away!
              </h6>
              <p className="fw_samibold">
                In addition, stay in loop for our exclusive launches and limited
                time deals.
              </p>
            </div>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Search" />
              <button className="btn btn-primary" type="submit">
                Subscribe
              </button>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Products</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a href="#">AI Course</a>
                  </li>
                  <li>
                    <a href="#">AI Consulting</a>
                  </li>
                  <li>
                    <a href="#">AI Book</a>
                  </li>
                  <li>
                    <a href="#">Blockchain Book</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Information</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a href="/aboutuspage">About Us</a>
                  </li>
                  <li>
                    <a href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873">Affiliate</a>
                  </li>
                  <li>
                    <a href="#">Pricing</a>
                  </li>
                  <li>
                    <a href="/contactuspage">Contact us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4  text-lg-start text-sm-end">
            <div>
              <div className="text-white">
                <h6 className="fw_samibold">Support</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a href="/terms">Terms and Conditions</a>
                  </li>
                  <li>
                    <a href="privacy">Privacy &amp; Cookie policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 text-white d-lg-block d-none">
            <div>
              <h5 className="mb-4 f_heading">
                Get news in your mailbox right away!
              </h5>
              <p className="fw_samibold">
                In addition, stay in loop for our exclusive launches and limited
                time deals.
              </p>
            </div>
            {/* News Subscribe */}
         <div className="input-group mb-3" onSubmit={handleSubmit(onSubmitHandler)}>
                    <input className={`form-control ${errors["email"] ? "error" : ""}`}
                      {...register("email")}
                      type="email"
                      placeholder="Enter your mail"
                      // error={!!errors["email"]}
                      // helperText={errors["email"]?.message}
                      onChange={(e) => setSubscribeVal({ ...subscribeVal, email: e.target.value })} />
                    <button className="btn btn-primary" type="submit" onClick={handleSubscribeNews}>
                      Subscribe
                    </button>
                    {errors["email"] && (
                      <span className="error-message">{errors["email"]?.message}</span>
                    )}
                  </div>
                  {/*  */}
          </div>
        </div>
      </div>
    </div>
                          
      
    </>
  );
};

export default AboutusPage;