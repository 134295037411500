import { useEffect, useState } from "react";
import {
    Grid,
    Box,
    Typography,
    Container,
    Stack,
    SvgIcon,
    Divider,
    FormControl,
    TextField,
    Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    LoginSocialTwitter,
    LoginSocialGithub,
    LoginSocialLinkedin,
    IResolveParams,
} from "reactjs-social-login";
import { toast } from "react-toastify";
import { object, string, TypeOf } from "zod";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import { ReactComponent as GoogleIcon } from "../../assets/ico_google.svg";
import { ReactComponent as FacebookIcon } from "../../assets/ico_facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/ico_twtter.svg";
import { ReactComponent as GithubIcon } from "../../assets/ico_github.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/ico_linkedin.svg";

import BackSignup from "../../assets/back_signup.png";
//import Logo from "../../assets/logo_white.png";
import Logo from "../../logo.svg";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import {
    useSignupUserMutation,
    useSocialAuthMutation,
} from "../../redux/api/authApi";
import { useAppDispatch } from "../../redux/store";
import { setModule } from "../../redux/features/genieSlice";

/* CentriLife */
import '../../components/Centri_CSS/style.css';

import signinImg from '../../assets/image/logo signin.png';
import SideImg from '../../assets/image/Side Img.jpeg';
import { error } from "console";
import Header from "../../components/headers/Header";
import LandingHeader from "../../components/headers/LandingHeader";
import CommonHeader from "../../components/CentriLandingPage/CommonpageHeader";
import LandingPageFooter from "../../components/CentriLandingPage/LandingpageFooter";
/*  */

const signupSchema = object({
    name: string().min(1, "Full name is required"),
    email: string()
        .min(1, "Email address is required")
        .email("Email address is invalid"),
    password: string()
        .min(1, "Password is required")
        .min(8, "Password must be more than 8 characters"),
    passwordConfirm: string().min(1, "Please confirm your password"),
    initial_product: string().optional(),
}).refine((data) => data.password === data.passwordConfirm, {
    path: ["passwordConfirm"],
    message: "Passwords do not match",
});

export type SignupInput = TypeOf<typeof signupSchema>;

const VerificationPage = () => {

    const [termsCheck, setTermsCheck] = useState(false);
    const [isTyping, setIsTyping] = useState(false);

    const [authSocial, socialState] = useSocialAuthMutation();
    const [signupUser, signupState] = useSignupUserMutation();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const methods = useForm<SignupInput>({
        resolver: zodResolver(signupSchema),
        mode: 'onChange',
    });

    const REDIRECT_URI = window.location.href;



    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = methods;


    /* Password strength analyzing */
    const password = watch('password', '');

    const validatePasswordStrength = (value: string) => {
        if (value.length >= 8) {
            return { strength: 'Excellent', color: '#087446' };
        } else {
            return { strength: 'Poor', color: 'red' };
        }
    };
    /*  */

    useEffect(() => {
        if (socialState.isSuccess) {
            toast.success("Social signup success");
            localStorage.setItem("logged_in", "true");
            if (socialState.data.role === "user") {
                if (localStorage.getItem("module") != "Any Code") {
                    localStorage.setItem("module", "SMART CONTRACTS");
                    dispatch(setModule("SMART CONTRACTS"));
                    navigate("/codegenie/smart_contracts");
                }
                else {
                    navigate("/codegenie/any_code");
                }

            } else {
                navigate("/admin/dashboard");
            }
        }
        if (socialState.isError) {
            if (Array.isArray((socialState.error as any).data.detail)) {
                (socialState.error as any).data.detail.map((el: any) =>
                    toast.error(`${el.loc[1]} ${el.msg}`)
                );
            } else toast.error((socialState.error as any).data.detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socialState]);

    useEffect(() => {
        if (signupState.isSuccess) {
            toast.success("Account created successfully");
            navigate("/signin");
        }
        if (signupState.isError) {
            if (Array.isArray((signupState.error as any).data.detail)) {
                (signupState.error as any).data.detail.map((el: any) =>
                    toast.error(`${el.loc[1]} ${el.msg}`)
                );
            } else toast.error((signupState.error as any).data.detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signupState]);

    const onSubmitHandler: SubmitHandler<SignupInput> = (values) => {
        signupUser(values);
    };

    return (
        <>
            <CommonHeader />
            <div className="container-fluid resetPass verify">
                <div className="row">
                    <div className="col-12 col-md-6 left-img">

                        <img src={SideImg} alt="" width="100%" height="100%" />

                    </div>

                    <div className="col-12 col-md-6 card">
                        <div className="text-center">
                            <Link to="/" target="_self" rel="noreferrer">
                                <img src={signinImg} alt="" className="logo" />
                            </Link>
                        </div>
                        <div className="box">
                            <form action="">
                                <h1 className="mb-3">Verification Code</h1>
                                <input name="" value="" type="text" placeholder="Verify Code" />
                                <button className="btn1" type="button">Send</button>
                            </form>


                        </div>

                    </div>
                </div>

            </div>
            <LandingPageFooter />
        </>
    );
};

export default VerificationPage;