import {
  Container,
  Box,
  Typography,
  Button,
  SvgIcon,
  Stack,
  Avatar,
} from "@mui/material";
import { ArrowForward, Margin } from "@mui/icons-material";
import { Link, useHref, useNavigate } from "react-router-dom";

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';



import img1 from "../assets/newimg/img-1.png";
// import img2 from "../assets/newimg/body-img.png"; 
import img2 from "../assets/newimg/body-img.png";
import text2 from "../assets/newimg/text-2.png";
import img3 from "../assets/newimg/icon1.png";
import img4 from "../assets/newimg/icon2.png";
import img5 from "../assets/newimg/icon3.png";
import img6 from "../assets/newimg/icon4.png";
import img7 from "../assets/newimg/icon5.png";
import img8 from "../assets/newimg/icon6.png";
import img9 from "../assets/newimg/icon7.png";
import img10 from "../assets/newimg/icon8.png";
import img11 from "../assets/newimg/icon9.png";
import img12 from "../assets/newimg/icon10.png";
import img13 from "../assets/newimg/icon11.png";
import img14 from "../assets/newimg/icon12.png";
import img15 from "../assets/newimg/icon13.png";
import img16 from "../assets/newimg/icon14.png";
import img17 from "../assets/newimg/icon15.png";
import img18 from "../assets/newimg//lock.png";
import img19 from "../assets/newimg/icon-1-1.png";
import img20 from "../assets/newimg/icon-1-2.png";
import img21 from "../assets/newimg/icon-1-3.png";
import img22 from "../assets/newimg/line.png";
import img23 from "../assets/newimg//lineicon1.png";
import img24 from "../assets/newimg/line.png";
import img25 from "../assets/newimg/lineicon2.png";
import img55 from "../assets/newimg/lineicon3.png";
import img26 from "../assets/newimg/line.png";
import img27 from "../assets/newimg/lineicon3.png";
import img28 from "../assets/newimg/mask_group-img.png";
import img29 from "../assets/newimg/bt_icon1.png";
import img30 from "../assets/newimg/bt_icon2.png";
import img31 from "../assets/newimg/bt_icon3.png";
import img32 from "../assets/newimg/bt_icon4.png";
import img33 from "../assets/newimg/bt_icon5.png";
import img34 from "../assets/newimg/bt_icon6.png";
import img35 from "../assets/newimg/bt_icon7.png";
import img36 from "../assets/newimg/bt_icon8.png";
import img37 from "../assets/newimg/bt_icon9.png";
import img38 from "../assets/newimg/icon-1-1.png";
import img39 from "../assets/newimg/icon-1-2.png";
import img40 from "../assets/newimg//icon-1-3.png";
import img41 from "../assets/newimg/icon-1-3.png";
import img42 from "../assets/newimg/icon-1-3.png";
import img43 from "../assets/newimg/gridicon1.png";
import img44 from "../assets/newimg/gridicon1.png";
import img45 from "../assets/newimg/gridicon1.png";
import img46 from "../assets/newimg/gridicon1.png";
import img47 from "../assets/newimg/img-1.png";
import img48 from "../assets/newimg/img-1.png";
import img49 from "../assets/newimg/img-1.png";
import img50 from "../assets/newimg/img-1.png";
import img51 from "../assets/newimg/card-img-1.png";
import img52 from "../assets/newimg/card-img-2.png";
import img53 from "../assets/newimg/card-img-3.png";
import img54 from "../assets/newimg/logo.png";
import video1 from "../assets/newimg/video.mp4";
import text1 from "../assets/newimg/text-1.png";
import body2 from "../assets/newimg/text-2.png";
import lock from "../assets/img/lock.png";
import python from "../assets/img/pythonicon.png";
import react from "../assets/img/react.png";
import vue from "../assets/img/vuejs.png";
import angular from "../assets/img/angularjsbig.png";
import nodejs from "../assets/img/nodejs.png";
import java from "../assets/img/java.png";
import cprogram from "../assets/img/c#.png";
import c1 from "../assets/img/c++.png";
import c2 from "../assets/img/c.png";
import php from "../assets/img/php.png";
import ruby from "../assets/img/ruby.png";
import go from "../assets/img/go.png";
import typescript from "../assets/img/typescript.png";
import swift from "../assets/img/swift.png";
import kotlin from "../assets/img/kotlin.png";
import rust from "../assets/img/rust.png";
import scala from "../assets/img/scala.png";
import r from "../assets/img/r.png";
import matlab from "../assets/img/matlab.png";
import perl from "../assets/img/perl.png";
import javascript1 from "../assets/img/javascript1.png";
import html5 from "../assets/img/html5.png";
import css from "../assets/img/css.png";
import sql from "../assets/img/sql.png";
import dart from "../assets/img/dart.png";
import flutter from "../assets/img/flutter.png";
import icon1 from "../assets/img/icon-1-1.png";
import iconbg from "../assets/img/icon-bg.png";
import gridicon2 from "../assets/newimg/gridicon2.png";
import gridicon3 from "../assets/newimg/gridicon3.png";
import gridicon4 from "../assets/newimg/gridicon4.png";
import logo from "../assets/newimg/logo.png";

import blog1 from "../assets/img/card-img-1.png";
import blog2 from "../assets/img/card-img-2.png";
//import blog3 from "../assets/img/blogs3.png";
import blog4 from "../assets/img/card-img-3.png";

import testimonial1 from "../assets/img/ellipse-34-2.png";
import testimonial2 from "../assets/img/ellipse-34-1.png";
import testimonial3 from "../assets/img/ellipse-34.svg";

// import "./newstyle.css";

/* imports for centriLife */
import '../Centri_CSS/style.css';

import centriLogo from '../../assets/image/logo-centri-life-white-color.png';
import homeImg from '../../assets/image/home.jpeg';
import pinterestImg from '../../assets/image/pinterest.jpeg';
import dribbelImg from '../../assets/image/dribbel.jpeg';
import spotifyImg from '../../assets/image/spotify.jpeg';
import laptopImg from '../../assets/image/Laptop.jpeg';
import workImg from '../../assets/image/work.jpeg';
import sparkleImg from '../../assets/image/Sparkle.jpeg';
import fileImg from '../../assets/image/file.jpeg';
import brushImg from '../../assets/image/brush.jpeg';


/*  */

// import ".assests/Anycode.css";
import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import { useState } from "react";
import { IGetBlogs } from "../../redux/api/types";
// import { useGetBlogDetailsQuery } from "../../redux/api/promptApi";

import { object, string, TypeOf } from "zod";
import { useSubscribeNewsMutation } from "../../redux/api/authApi";
import { toast } from "react-toastify";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { logout } from '../../redux/features/userSlice';
import { useAppDispatch } from "../../redux/store";




const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>











const LandingPageHeader = () => {
  //const genieSelector = useAppSelector((state) => state.genieState);
  let module_name = "SMART CONTRACTS";//genieSelector.module;
  // localStorage.setItem("module", module_name);

  //blogs listing and displaying
  const [blogs, setBlogs] = useState<IGetBlogs[]>([]);

  //const getBlogs = useGetBlogDetailsQuery({ limitval: 3, skipval: 0 });
  const [showAllBlogs, setShowAllBlogs] = useState(false);

  useEffect(() => {
    // Scroll to the top of the landing page
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (getBlogs && getBlogs.data) {
  //     setBlogs([...getBlogs.data]);
  //     console.log(blogs);
  //   }
  // }, [getBlogs])



  //

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 100;
  const slidestoshow = (isMobile) ? 1 : 3;
  const audittoshow = (isMobile) ? 1 : 3;
  const testimonialtoshow = (isMobile) ? 1 : 3;


  var slidersettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidestoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,
  };

  var auditsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,


  };
  var costsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,


  };

  var benefitssettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,


  };



  var testimonialsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: testimonialtoshow,
    slidesToScroll: 1,
    arrows: true,
    // nextArrow: true,
    // prevArrow: ,


  };

  const module = localStorage.getItem('module');

  const filteredBlogs = blogs.filter((blog) => {
    if (module === 'SMART CONTRACTS' && blog.cat === 'cat1') {
      return true;
    }
    return false;
  });

  const slicedBlogs = filteredBlogs.slice(0, 3);


const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();

  const methods = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .then((response) => {
        if ('data' in response) {
          const status = response.data.status;
          console.log(status);
          if (status === 'success') {
            toast.success(response.data.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
        } else {
          // Error case
          console.error('Error sending query:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error sending query:', error);

      });
  }

  const handleLogout = () => {
    localStorage.setItem("logged_in", "false");
    //console.log("loggedin:" + localStorage.getItem("logged_in"));
    dispatch(logout());
    // var module = localStorage.getItem("module");
    /* if (module == "Any Code")
      navigate("/Anycode_landing");
    else */
    localStorage.removeItem("payment");
    localStorage.removeItem("msg");
    localStorage.removeItem("complexity");
    localStorage.removeItem("module");
    localStorage.removeItem("subscription_level");
    localStorage.removeItem("free_plan");
    localStorage.removeItem("userRole");
    localStorage.removeItem("user_id");
    localStorage.removeItem('Document_id');
    localStorage.removeItem('doc_id');
    localStorage.removeItem('logged_in');
    localStorage.removeItem('user_email');
    localStorage.removeItem('remaining_wish_count');
    localStorage.removeItem('property');
    //navigate("/");
    window.location.href = '/';
    toast.success("Logout success");
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-body-tertiary fixed-top">
    <div className="container-fluid">
      <a className="navbar-brand" href="#">
        <img
          src={centriLogo}
          width="100px"
          alt="brand"
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          {/* <li className="nav-item">
            <a className="nav-link" aria-current="page" href="#">Money</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Health</a>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link active" href="#">Tech Skills/Jobs</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Aging Place</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">My Home</a>
          </li> */}
        </ul>

        <div className="ml-auto">
          <ul className="navbar-nav action-btn">
          {!(localStorage.getItem('logged_in')) ?
          <>
            <li className="nav-item">
              <a className="nav-link" href="/signin">Log In</a>
            </li>
            <li className="nav-item">
              <a className="nav-link signup" href="/signup">Sign Up</a>
            </li>
          </>
          :
          <>
            {
              localStorage.getItem('userRole') == 'admin' ? (
                <li className="nav-item">
                  <a className="nav-link signup" href="/admin/dashboard">Dashboard</a>
                </li>
              ) : (
                <li className="nav-item">
                  <a className="nav-link signup" href="/codegenie/resume">Back</a>
                </li>
              )
            }
            <li className="nav-item">
              <a className="nav-link signup" style={{cursor:'pointer'}} /* href="#" */ onClick={handleLogout}>Log Out</a>
            </li>
          </>
          } 
          </ul>
        </div>
      </div>
    </div>
  </nav>
  );
};

export default LandingPageHeader;