import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Container,
  Stack,
  Button,
  SvgIcon,
  TextField,
  Autocomplete
} from "@mui/material";

import { ReactComponent as PlustWhiteIcon } from "../../assets/ico_plus_white.svg";
import Prompt from "../../components/Prompt";
import { IPromptAcceptSchema, IProductHeadings, IPlanDetail } from "../../redux/api/types";
import { useGetProductsNamesQuery, useLazySearchProductQuery, useLazyGetProductQuery } from "../../redux/api/productApi";
import { useGetPromptsQuery } from "../../redux/api/promptApi";
import { zodResolver } from "@hookform/resolvers/zod";
import { object, string, TypeOf, array, number } from "zod";
import { useForm } from "react-hook-form";



const saveSchema = object({
  product_name: string().min(1, "Product name is required"),
  product_module: string().optional(),
  module_description: string().optional(),
  plan_details: array(
    object({
      user: number(),
      plan_name: string(),
      total_wishes: number().optional(),
      price: string(),
      period: string(),
      product_id: string(),
      price_id: string(),
      subscription_level: string(),
      type: string(),
      button_text: string(),
    })
  ),
});
export type PriceSettingSaveInput = TypeOf<typeof saveSchema>;

const PromptConfigurator = () => {
  const [prompts, setPrompts] = useState<IPromptAcceptSchema[]>([]);
  const [products, setProducts] = useState<string[]>([]);
  const [options, setOptions] = useState<IProductHeadings[]>([]);
  const [filter, setFilter] = useState<IProductHeadings | null>(null);
  const [selectedModule, setSelectedModule] = useState<string | null>(null);

  const [originalPrompts, setOriginalPrompts] = useState<IPromptAcceptSchema[]>([]);


  const [plans, setPlans] = useState<IPlanDetail[]>([
    {
      user: 1,
      plan_name: "",
      total_wishes: 0,
      price: "",
      period: "",
      product_id: "",
      price_id: "",
      subscription_level: "",
      feature: [],
      contracts: 0,
      type: "",
      button_text: "",
    },
  ]);

  const methods = useForm<PriceSettingSaveInput>({
    resolver: zodResolver(saveSchema),
    defaultValues: {},
  });
  const { setValue } = methods;


  const getNamesState = useGetProductsNamesQuery();
  console.log(getNamesState);
  const getPromptsState = useGetPromptsQuery();
  const [getProduct, getState] = useLazyGetProductQuery();
  const [searchProduct, searchState] = useLazySearchProductQuery();

  useEffect(() => {
    searchProduct("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getNamesState.isSuccess) setProducts(getNamesState.data);
  }, [getNamesState]);

  /* useEffect(() => {
    if (getPromptsState.isSuccess) {
      setPrompts([...getPromptsState.data]);
    }
  }, [getPromptsState]); */

  useEffect(() => {
    if (getPromptsState.isSuccess) {
      const receivedPrompts = getPromptsState.data;
      setPrompts(receivedPrompts);
      setOriginalPrompts(receivedPrompts);
    }
  }, [getPromptsState]);

  const handleModuleChange = (event: React.ChangeEvent<{}>, newValue: IProductHeadings | null) => {
    setSelectedModule(newValue ? newValue.product_module : null);
    // Reset to original prompts when the module changes
    setPrompts(originalPrompts);
  };

  useEffect(() => {
    if (searchState.data) {
      setOptions(searchState.data);
    }
  }, [searchState]);

  useEffect(() => {
    const { data } = getState;
    setValue("product_name", data?.product_name as string);
    setValue("product_module", data?.product_module as string);
    setValue("module_description", data?.module_description as string);
    if (data?.plan_details) setPlans([...data.plan_details]);
    else
      setPlans([
        {
          user: 1,
          plan_name: "",
          total_wishes: 0,
          price: "",
          period: "",
          product_id: "",
          price_id: "",
          subscription_level: "",
          feature: [],
          contracts: 0,
          type: "",
          button_text: "",
        },
      ]);
  }, [getState, setValue]);

  useEffect(() => {
    console.log(prompts);
  }, [prompts])



  return (
    <>
      <div className="body-17-1" >
        <Box paddingY={5}>
          <Typography
            textAlign="center"
            variant="h4"
            sx={{
              background:
                "-webkit-linear-gradient(139deg, #036AB7 0%, #49A4EA 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              letterSpacing: "-0.8px",
              fontWeight: "bold",
            }}
          >
            Prompt Configurator
          </Typography>
        </Box>
        <Divider />
        <Container>
          <Stack marginTop={5} spacing={2}>
            <Stack alignItems='end'>
              <Button
                variant="contained"
                sx={{ textTransform: "none", paddingY: 1, paddingX: 2, color: "white" }}
                startIcon={
                  <SvgIcon>
                    <PlustWhiteIcon />
                  </SvgIcon>
                }
                onClick={() => {
                  setPrompts([
                    ...prompts,
                    {
                      _id: "",
                      product_name: "",
                      plan: "",
                      product_module: "",
                      prompt_name: "",
                      order: 0,
                      prompt: "",
                    },
                  ]);
                }}
              >
                Add prompt configuration settings
              </Button>

            </Stack>
            <Stack>
              <Autocomplete
                options={options}
                noOptionsText="No Products"
                sx={{ width: 216, alignItems: 'right' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ border: '1px solid #CBCBCB' }}
                    placeholder="Search Products"
                    // label="Search Products"
                    fullWidth
                    size="small"

                  />
                )}
                getOptionLabel={(option) =>
                  `${option.product_name} : ${option.product_module}`
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      {option.product_name} : {option.product_module}
                    </li>
                  );
                }}
                value={filter}
                onChange={handleModuleChange}
              />
            </Stack>

            {prompts.map((prompt, index) => {
              if (!selectedModule || prompt.product_module === selectedModule) {
                return (
                  <Prompt
                    key={`prompt_box_${index}`}
                    id={prompt._id}
                    products={products}
                    product={prompt.product_name}
                    plan={prompt.plan}
                    module={prompt.product_module}
                    prompt_name={prompt.prompt_name}
                    order={prompt.order}
                    prompt={prompt.prompt}
                  />
                );
              }
              return null;
            })}

          </Stack>
        </Container>
      </div>
    </>
  );
};

export default PromptConfigurator;
