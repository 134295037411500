import { useCookies } from "react-cookie";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { userApi } from "../redux/api/userApi";
import FullScreenLoader from "./FullScreenLoader";

const RequireUser = ({ allowedRoles }: { allowedRoles: string[] }) => {
  // const [cookies] = useCookies(["logged_in"]);
  const location = useLocation();

  // const { isLoading, isFetching } = userApi.endpoints.getMe.useQuery(null, {
  //   skip: false,
  //   refetchOnMountOrArgChange: true,
  // });

  // const loading = isLoading || isFetching;

  // const userData = userApi.endpoints.getMe.useQueryState(null);
  // console.log("Cookie =>", useCookies(), userData)
  if (localStorage.getItem("logged_in") != "true") {
    return <FullScreenLoader />;
  }

  return (localStorage.getItem("logged_in") == "true") &&
    allowedRoles.includes(localStorage.getItem("userRole") as string) ? (
    <Outlet />
  ) : localStorage.getItem("logged_in") == "true" ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
};

export default RequireUser;