import React from 'react'
import "../../assets/css/bootstrap.min.css"
import logo from "../../assets/image/logo.jpeg"
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from '../../redux/store';
import { logout } from '../../redux/features/userSlice';

function ResumeBuilderHeader() {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleLogout = () => {
        localStorage.setItem("logged_in", "false");
        //console.log("loggedin:" + localStorage.getItem("logged_in"));
        dispatch(logout());
        // var module = localStorage.getItem("module");
        /* if (module == "Any Code")
          navigate("/Anycode_landing");
        else */
        localStorage.removeItem("payment");
        localStorage.removeItem("msg");
        localStorage.removeItem("complexity");
        localStorage.removeItem("module");
        localStorage.removeItem("subscription_level");
        localStorage.removeItem("free_plan");
        localStorage.removeItem("userRole");
        localStorage.removeItem("user_id");
        localStorage.removeItem('Document_id');
        localStorage.removeItem('doc_id');
        localStorage.removeItem('logged_in');
        localStorage.removeItem('user_email');
        localStorage.removeItem('property');
        localStorage.removeItem('remaining_wish_count');
        localStorage.removeItem('localStorage_msg');
        localStorage.removeItem('resume_name_');
        localStorage.removeItem('localStorage_msg');
        localStorage.removeItem('resumeCompleted');
        localStorage.removeItem('CreatingResume');
        localStorage.removeItem('resumeSave');
        localStorage.removeItem('View Saved Resume');
        localStorage.removeItem('activePane');
        //navigate("/");
        window.location.href = '/';

        toast.success("Log Out success");
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-body-tertiary fixed-top">
                <div className="container-fluid nav">
                    <a className="navbar-brand" href="/">
                        <img src={logo} width="100px" alt="brand" />
                    </a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02"
                        aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            {/* <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Money</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Health</a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Tech Skills/Jobs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Aging Place</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">My Home</a>
                            </li> */}
                        </ul>

                        <div className="ml-auto">
                            <ul className="navbar-nav action-btn">
                                {!(localStorage.getItem('logged_in')) ? 
                                <>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Login</a>
                                </li>
                                <li className="nav-item"> 
                                    <a className="nav-link signup" href="#">Signup</a>
                                </li>
                                </>
                                :
                                <>
                                <li className="nav-item">
                                    <a className="nav-link signup" style={{cursor:'pointer'}}  href="/codegenie/settings">Settings</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link signup" style={{cursor:'pointer'}}  onClick={handleLogout}>Log Out</a>
                                </li>
                                </>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default ResumeBuilderHeader
