import { Box, Typography, Container, Divider, SvgIcon } from "@mui/material";
import { Link } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import img54 from "../../assets/newimg/logo.png";
import { useState , useEffect } from "react";
import logo from "../../assets/image/logo.jpeg";
import { object, string, TypeOf } from "zod";
import { useSubscribeNewsMutation } from "../../redux/api/authApi";
import { toast } from "react-toastify";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";


const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>
const PrivacyPage = () => {
  const navigate = useNavigate();

  const handleLearnMore = () =>{
    navigate('/codegenie/smart_contracts');
  }
const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();

  const methods = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .then((response) => {
        if ('data' in response) {
          const status = response.data.status;
          console.log(status);
          if (status === 'success') {
            toast.success(response.data.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
        } else {
          // Error case
          console.error('Error sending query:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error sending query:', error);

      });
  }
  return (
    <>
      <Container maxWidth="lg">
      <nav className="navbar navbar-expand-md">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              {/* <img src="./img/logo.png" alt="" /> */}
              <img src={logo} style={{width: '100px'}} className="img-fluid" alt="" />
              {/* <img src={logo} alt="" /> */}
  
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="">
                <i className="bi bi-list text-white" />
              </span>
            </button>



            
            <div className="collapse navbar-collapse justify-content-end" id="navbarScroll">
             
              
              <div className="d-flex gap-4" role="search">
              <button className="button_leniar px-3" type="submit"  onClick={() => {
                navigate("/signup");
              }}>
                  Try Now
                </button>
                <button className="btn btn-outline-primary px-3"
                  onClick={() => {
                    navigate("/signin");
                  }}>Sign  in</button>
              </div>
            </div>
          </div>
        </nav> 
          <Box py={5}>
            <Typography
              variant="h2"
               color={"black"}
              fontWeight={600}
              textAlign="justify"
              mb={10}
              
            >
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               Artificial Intelligence Policy </div>
          </Typography>
          <Typography color={"black"}>

           Welcome! Centri Life is a web-based platform that provides users with resources and tools to provide information and actionable recommendations on topics such as finance, health and career opportunities. Centri Life is owned and managed by Centri Tech LLC (“Centri Tech,” “us,” or “we”). We are delighted to have you as part of our community. The following Artificial Intelligence Policy applies to our resume and cover letter support service (the “Service”) available via Centri Life. Please note that the Centri Tech Terms of Use and Privacy Policy also apply to the Service.
          </Typography>
          <br />
          <Typography  color={"black"}>
          Centri Tech provides the Service to assist you in generating professional documentation such as resumes and cover letters (“Documents”) that you may use to apply for employment or otherwise build your professional profile. We offer the Service through the use of Artificial Intelligence. “Artificial Intelligence” as used in this policy refers to automated computer-generated written materials: when you provide information through the Service to create a resume or cover letter, Artificial Intelligence is used to generate the resulting Documents.  
          </Typography>
          <Typography variant="h4"  color={"black"} mt={5} mb={2}>
          By accessing or using the Service, you agree to be bound by this Artificial Intelligence Policy (“Policy”). Please read this document carefully. If you do not agree with any part of this Policy, you may not use the Service.
          </Typography>
          <Typography color={"black"}>
          We are happy to provide you with the Service. However, we want you to be aware of your responsibilities when it comes to the Documents:
          </Typography>
          <br />
          <Typography color={"black"}>
            <Box component="ul">
              <Box component="li">
              You have sole responsibility for the personal data and/or private information that you provide, create, edit, incorporate, and/or store through the Service. You are the only one responsible for guaranteeing the information is truthful, updated, and complete.
              </Box>
              <br />
              <Box component="li">
              While Artificial Intelligence is a helpful tool in generating Documents, you are responsible for thoroughly reviewing the generated Documents as the owner of the underlying information. You should always review the Documents and check them for accuracy and completeness. As with any automated service, the Service could contain errors, lack clarity, or otherwise need revisions from you.
              </Box>

            </Box>
          </Typography>

          <Typography color={"black"}>
          Please note that Centri Tech does not review, control or monitor the Documents created by you through the Service. Centri Tech will not be responsible for the updating, accuracy, truth or legality of the information within any Documents created by you.
          <br />
          <br />
          Centri Tech will not be held responsible for the quality, accuracy, reliability, sincerity, integrity or availability of the Documents created by you and sent to third parties (for example, if you send the Documents to prospective employers), as it is beyond our reasonable control. We urge you to keep your username and password confidential to avoid unauthorized access to your Documents. Note that while we provide reasonable security measures, no Internet website or service is 100% safe and we cannot guarantee the security of the data that is provided to the Service.
            
          <br />
          <br />
            
          We do not guarantee the quality or appropriateness of the uploaded, generated or stored files that will, in any case, be your responsibility.
          <br />
          <br /> 
            
          Likewise, please be aware that Centri Life will not be held responsible if:
          <br />
          <br /> 
            <Box component="ul">
              <Box component="li">
              If you provide us with a third party’s personal data without their prior express consent, such as when creating a professional document for a third person.
              </Box>
              <Box component="li">
              If you provide us with the personal data of a minor and you are not his/her parent and/or legal guardian and/or that you have not obtained the authorization of his/her parents or legal guardians to communicate the minor’s personal data with us.
              </Box>
              <Box component="li">
              If you are a minor and you have included and/or provided your personal data on the Service or have communicated it to a third party’s and/or employer’s website(s) without the prior express written consent of your parents or legal guardians.
              </Box>

              <Box component="li">
              Centri Tech shall not be liable for any use of the Service that is contrary to applicable law. We reserve the right to take any measures to prevent the adoption of such conduct, including but not limited to the suspension or termination of your use of the Service.
              </Box>

            </Box>
          </Typography>
        
          <Typography color={"black"}>
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL CENTRI TECH, ITS AFFILIATES, OR ITS LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
            
          <br />
          <br />   
            
THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

            
           
          </Typography>
         
          <Link
            to="/signin"
            style={{
              display: "flex",
              marginTop: 40,
              textDecoration: "none",
              color: "Black",
            }}
          >
            <SvgIcon sx={{ mr: 1 }}>
              <KeyboardBackspace />
            </SvgIcon>
            Back to previous page
          </Link>
        </Box>
      </Container>
      
    </>
  );
};

export default PrivacyPage;
