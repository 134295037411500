import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";



export const genieApi = createApi({
  reducerPath: "",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    uploadImage: builder.mutation<any, FormData>({
      query(data) {
        return {
          url: "aiResume/upload_file",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
    }),
    sendUrl: builder.mutation<{ content: string }, { url: string }>({
      query(data) {
        return {
          url: "genie/url",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
    }),
    /* exportFile: builder.mutation<{ path: string },{ doc_type: string;  advice: any }>
    ({
      query({ doc_type, advice }) {
        return {
          url: `genie/export/${doc_type}`,
          method: "POST",
          credentials: "include",
          body: { advice },
        };
      },
    }), */
    /* centriLife */
    /* exportFile: builder.mutation<{ path: string },Iexportresumes>
    ({
      query(Iexportresumes) {
        return {
          url: `aiResume/DataExport`,
          method: "POST",
          credentials: "include",
          body: Iexportresumes
        };
      },
    }), */
    exportFile: builder.mutation<any, any>({
      query(data) {
          return {
              url: `aiResume/DataExport`,
              method: "POST",
              body: data
          };
      },
    }),

    exportCoverFile: builder.mutation<any, any>({
      query(data) {
          return {
              url: `aiResume/export_cover_letter`,
              method: "POST",
              body: data
          };
      },
    }),

    downloadFile: builder.mutation<any, any>({
      query({ doc_id }) {
          return {
              url: `aiResume/download_resume/${doc_id}`,
              method: "GET",
          };
      },
    }),
  /*  */

    remainingWishCount: builder.query<any, { email: String }>({
      query(arg) {
        const { email } = arg;
        return {
          url: "genie/remaining_wish_count",
          params: { email },
          method: "POST",
        };
      },
    }),
    getUserDetails: builder.query<any, { email: String }>({
      query(arg) {
        const { email } = arg;
        return {
          url: "auth/getUserDetails",
          params: { email },
          method: "POST",
        };
      },
    }),

  }),
});

export const {
  useUploadImageMutation,
  useSendUrlMutation,
  useExportFileMutation,
  useExportCoverFileMutation,
  useRemainingWishCountQuery,
  useLazyRemainingWishCountQuery,
  useGetUserDetailsQuery,
  useDownloadFileMutation
} = genieApi;
