import {
  Container,
  Box,
  Typography,
  Button,
  SvgIcon,
  Stack,
  Avatar,
} from "@mui/material";
import { ArrowForward, Margin } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';



import img1 from "../assets/newimg/img-1.png";
// import img2 from "../assets/newimg/body-img.png"; 
import img2 from "../assets/newimg/body-img.png";
import text2 from "../assets/newimg/text-2.png";
import img3 from "../assets/newimg/icon1.png";
import img4 from "../assets/newimg/icon2.png";
import img5 from "../assets/newimg/icon3.png";
import img6 from "../assets/newimg/icon4.png";
import img7 from "../assets/newimg/icon5.png";
import img8 from "../assets/newimg/icon6.png";
import img9 from "../assets/newimg/icon7.png";
import img10 from "../assets/newimg/icon8.png";
import img11 from "../assets/newimg/icon9.png";
import img12 from "../assets/newimg/icon10.png";
import img13 from "../assets/newimg/icon11.png";
import img14 from "../assets/newimg/icon12.png";
import img15 from "../assets/newimg/icon13.png";
import img16 from "../assets/newimg/icon14.png";
import img17 from "../assets/newimg/icon15.png";
import img18 from "../assets/newimg//lock.png";
import img19 from "../assets/newimg/icon-1-1.png";
import img20 from "../assets/newimg/icon-1-2.png";
import img21 from "../assets/newimg/icon-1-3.png";
import img22 from "../assets/newimg/line.png";
import img23 from "../assets/newimg//lineicon1.png";
import img24 from "../assets/newimg/line.png";
import img25 from "../assets/newimg/lineicon2.png";
import img55 from "../assets/newimg/lineicon3.png";
import img26 from "../assets/newimg/line.png";
import img27 from "../assets/newimg/lineicon3.png";
import img28 from "../assets/newimg/mask_group-img.png";
import img29 from "../assets/newimg/bt_icon1.png";
import img30 from "../assets/newimg/bt_icon2.png";
import img31 from "../assets/newimg/bt_icon3.png";
import img32 from "../assets/newimg/bt_icon4.png";
import img33 from "../assets/newimg/bt_icon5.png";
import img34 from "../assets/newimg/bt_icon6.png";
import img35 from "../assets/newimg/bt_icon7.png";
import img36 from "../assets/newimg/bt_icon8.png";
import img37 from "../assets/newimg/bt_icon9.png";
import img38 from "../assets/newimg/icon-1-1.png";
import img39 from "../assets/newimg/icon-1-2.png";
import img40 from "../assets/newimg//icon-1-3.png";
import img41 from "../assets/newimg/icon-1-3.png";
import img42 from "../assets/newimg/icon-1-3.png";
import img43 from "../assets/newimg/gridicon1.png";
import img44 from "../assets/newimg/gridicon1.png";
import img45 from "../assets/newimg/gridicon1.png";
import img46 from "../assets/newimg/gridicon1.png";
import img47 from "../assets/newimg/img-1.png";
import img48 from "../assets/newimg/img-1.png";
import img49 from "../assets/newimg/img-1.png";
import img50 from "../assets/newimg/img-1.png";
import img51 from "../assets/newimg/card-img-1.png";
import img52 from "../assets/newimg/card-img-2.png";
import img53 from "../assets/newimg/card-img-3.png";
import img54 from "../assets/newimg/logo.png";
import video1 from "../assets/newimg/video.mp4";
import text1 from "../assets/newimg/text-1.png";
import body2 from "../assets/newimg/text-2.png";
import lock from "../assets/img/lock.png";
import python from "../assets/img/pythonicon.png";
import react from "../assets/img/react.png";
import vue from "../assets/img/vuejs.png";
import angular from "../assets/img/angularjsbig.png";
import nodejs from "../assets/img/nodejs.png";
import java from "../assets/img/java.png";
import cprogram from "../assets/img/c#.png";
import c1 from "../assets/img/c++.png";
import c2 from "../assets/img/c.png";
import php from "../assets/img/php.png";
import ruby from "../assets/img/ruby.png";
import go from "../assets/img/go.png";
import typescript from "../assets/img/typescript.png";
import swift from "../assets/img/swift.png";
import kotlin from "../assets/img/kotlin.png";
import rust from "../assets/img/rust.png";
import scala from "../assets/img/scala.png";
import r from "../assets/img/r.png";
import matlab from "../assets/img/matlab.png";
import perl from "../assets/img/perl.png";
import javascript1 from "../assets/img/javascript1.png";
import html5 from "../assets/img/html5.png";
import css from "../assets/img/css.png";
import sql from "../assets/img/sql.png";
import dart from "../assets/img/dart.png";
import flutter from "../assets/img/flutter.png";
import icon1 from "../assets/img/icon-1-1.png";
import iconbg from "../assets/img/icon-bg.png";
import gridicon2 from "../assets/newimg/gridicon2.png";
import gridicon3 from "../assets/newimg/gridicon3.png";
import gridicon4 from "../assets/newimg/gridicon4.png";
import logo from "../assets/newimg/logo.png";

import blog1 from "../assets/img/card-img-1.png";
import blog2 from "../assets/img/card-img-2.png";
//import blog3 from "../assets/img/blogs3.png";
import blog4 from "../assets/img/card-img-3.png";

import testimonial1 from "../assets/img/ellipse-34-2.png";
import testimonial2 from "../assets/img/ellipse-34-1.png";
import testimonial3 from "../assets/img/ellipse-34.svg";

// import "./newstyle.css";

/* imports for centriLife */
import '../Centri_CSS/style.css';

import centriLogo from '../../assets/image/logo-centri-life-white-color.png';
import homeImg from '../../assets/image/home.jpeg';
import pinterestImg from '../../assets/image/pinterest.jpeg';
import dribbelImg from '../../assets/image/dribbel.jpeg';
import spotifyImg from '../../assets/image/spotify.jpeg';
import laptopImg from '../../assets/image/Laptop.jpeg';
import workImg from '../../assets/image/work.jpeg';
import sparkleImg from '../../assets/image/Sparkle.jpeg';
import fileImg from '../../assets/image/file.jpeg';
import brushImg from '../../assets/image/brush.jpeg';


/*  */

// import ".assests/Anycode.css";
import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import { useState } from "react";
import { IGetBlogs } from "../../redux/api/types";
// import { useGetBlogDetailsQuery } from "../../redux/api/promptApi";

import { object, string, TypeOf } from "zod";
import { useSubscribeNewsMutation } from "../../redux/api/authApi";
import { toast } from "react-toastify";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";




const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>











const LandingPageFooter = () => {
  //const genieSelector = useAppSelector((state) => state.genieState);
  let module_name = "SMART CONTRACTS";//genieSelector.module;
  // localStorage.setItem("module", module_name);

  //blogs listing and displaying
  const [blogs, setBlogs] = useState<IGetBlogs[]>([]);

  //const getBlogs = useGetBlogDetailsQuery({ limitval: 3, skipval: 0 });
  const [showAllBlogs, setShowAllBlogs] = useState(false);

  useEffect(() => {
    // Scroll to the top of the landing page
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (getBlogs && getBlogs.data) {
  //     setBlogs([...getBlogs.data]);
  //     console.log(blogs);
  //   }
  // }, [getBlogs])



  //


  const navigate = useNavigate();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 100;
  const slidestoshow = (isMobile) ? 1 : 3;
  const audittoshow = (isMobile) ? 1 : 3;
  const testimonialtoshow = (isMobile) ? 1 : 3;


  var slidersettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidestoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,
  };

  var auditsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,


  };
  var costsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,


  };

  var benefitssettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,


  };



  var testimonialsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: testimonialtoshow,
    slidesToScroll: 1,
    arrows: true,
    // nextArrow: true,
    // prevArrow: ,


  };

  const module = localStorage.getItem('module');

  const filteredBlogs = blogs.filter((blog) => {
    if (module === 'SMART CONTRACTS' && blog.cat === 'cat1') {
      return true;
    }
    return false;
  });

  const slicedBlogs = filteredBlogs.slice(0, 3);


  const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();

  const methods = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .then((response) => {
        if ('data' in response) {
          const status = response.data.status;
          console.log(status);
          if (status === 'success') {
            toast.success(response.data.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
        } else {
          // Error case
          console.error('Error sending query:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error sending query:', error);

      });
  }
  return (
    <footer className="container-fluid footer">
      <div className="row">
        <div className="d-flex justify-content-center">
          <Link to='#' onClick={() => window.scrollTo(0, 0)}>
            <img width="100%" src={centriLogo} className="footer-img" />
          </Link>
        </div>
        {/* <div className="footer-brand col-md-3 col-lg-2 pt-3">
          <Link to='/' onClick={() => window.scrollTo(0, 0)}>
            <img width="50%" src={centriLogo} className="footer-img" />
          </Link>
        </div>
        <div className="col-md-3 col-lg-2 pt-3">
          <h6 className="footer-head">MONEY</h6>
          <div className="footer-para">
            <p>Banking Basics</p>
            <p>Tax Filing and Tax Returns</p>
            <p>Credit Cards</p>
            <p>Insurance</p>
            <p>Retirement &amp; Investing</p>
          </div>
        </div>
        <div className="col-md-3 col-lg-2 pt-3">
          <h6 className="footer-head">HEALTH</h6>
          <div className="footer-para">
            <p>Find a Doctor</p>
            <p>Health Insurance</p>
            <p>Diet &amp; Exercise</p>
            <p>Diseases and Conditions</p>
          </div>
        </div>
        <div className="col-md-3 col-lg-2 pt-3">
          <h6 className="footer-head">TECH SKILLS/JOBS</h6>
          <div className="footer-para">
            <p>Find a Job</p>
            <p>Unemployment</p>
            <p>Start Your Own Business</p>
          </div>
        </div>
        <div className="col-md-3 col-lg-2 pt-3" style={{width: '152px'}}>
          <h6 className="footer-head">AGING IN PLACE</h6>
          <div className="footer-para">
            <p>Medicare</p>
            <p>Staying in Touch</p>
            <p>Getting Around</p>
          </div>
        </div>
        <div className="col-md-3 col-lg-2 pt-3" style={{ display: 'flex' }}>
          <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '20px' }} >
            <path d="M16.5 21.9209C19.2614 21.9209 21.5 19.6823 21.5 16.9209C21.5 14.1595 19.2614 11.9209 16.5 11.9209C13.7386 11.9209 11.5 14.1595 11.5 16.9209C11.5 19.6823 13.7386 21.9209 16.5 21.9209Z" stroke="#F7F8F9" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M22 5.4209H11C7.68629 5.4209 5 8.10719 5 11.4209V22.4209C5 25.7346 7.68629 28.4209 11 28.4209H22C25.3137 28.4209 28 25.7346 28 22.4209V11.4209C28 8.10719 25.3137 5.4209 22 5.4209Z" stroke="#F7F8F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23 11.9209C23.8284 11.9209 24.5 11.2493 24.5 10.4209C24.5 9.59247 23.8284 8.9209 23 8.9209C22.1716 8.9209 21.5 9.59247 21.5 10.4209C21.5 11.2493 22.1716 11.9209 23 11.9209Z" fill="#F7F8F9" />
          </svg>
          <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '20px' }} >
            <path d="M27 5.4209H6C5.44772 5.4209 5 5.86861 5 6.4209V27.4209C5 27.9732 5.44772 28.4209 6 28.4209H27C27.5523 28.4209 28 27.9732 28 27.4209V6.4209C28 5.86861 27.5523 5.4209 27 5.4209Z" stroke="#F7F8F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.5 14.9209V22.9209" stroke="#F7F8F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.5 14.9209V22.9209" stroke="#F7F8F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.5 18.4209C15.5 17.4926 15.8687 16.6024 16.5251 15.946C17.1815 15.2896 18.0717 14.9209 19 14.9209C19.9283 14.9209 20.8185 15.2896 21.4749 15.946C22.1313 16.6024 22.5 17.4926 22.5 18.4209V22.9209" stroke="#F7F8F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.5 12.4209C12.3284 12.4209 13 11.7493 13 10.9209C13 10.0925 12.3284 9.4209 11.5 9.4209C10.6716 9.4209 10 10.0925 10 10.9209C10 11.7493 10.6716 12.4209 11.5 12.4209Z" fill="#F7F8F9" />
          </svg>
          <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '20px' }} >
            <path d="M20.5 16.9209L14.5 12.9209V20.9209L20.5 16.9209Z" stroke="#F7F8F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.5 16.9208C3.5 20.6458 3.8875 22.8208 4.175 23.9458C4.25427 24.251 4.40418 24.5334 4.61262 24.77C4.82106 25.0067 5.08221 25.1911 5.375 25.3083C9.5625 26.9083 16.5 26.8708 16.5 26.8708C16.5 26.8708 23.4375 26.9083 27.625 25.3083C27.9178 25.1911 28.1789 25.0067 28.3874 24.77C28.5958 24.5334 28.7457 24.251 28.825 23.9458C29.1125 22.8208 29.5 20.6458 29.5 16.9208C29.5 13.1958 29.1125 11.0208 28.825 9.89578C28.7457 9.59053 28.5958 9.30819 28.3874 9.07152C28.1789 8.83485 27.9178 8.65048 27.625 8.53328C23.4375 6.93328 16.5 6.97078 16.5 6.97078C16.5 6.97078 9.5625 6.93328 5.375 8.53328C5.08221 8.65048 4.82106 8.83485 4.61262 9.07152C4.40418 9.30819 4.25427 9.59053 4.175 9.89578C3.8875 11.0208 3.5 13.1958 3.5 16.9208Z" stroke="#F7F8F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '20px' }} >
            <path d="M16.5002 11.9209C16.5002 9.17086 18.8127 6.88336 21.5627 6.92086C22.5257 6.93198 23.4651 7.22101 24.2678 7.75321C25.0706 8.28541 25.7025 9.03812 26.0877 9.92086H30.5002L26.4627 13.9584C26.2021 18.0141 24.4068 21.8183 21.4417 24.5977C18.4766 27.3771 14.5643 28.9229 10.5002 28.9209C6.50019 28.9209 5.50019 27.4209 5.50019 27.4209C5.50019 27.4209 9.50019 25.9209 11.5002 22.9209C11.5002 22.9209 3.50019 18.9209 5.50019 7.92086C5.50019 7.92086 10.5002 12.9209 16.5002 13.9209V11.9209Z" stroke="#F7F8F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div> */}
      </div>
    </footer>
  );
};

export default LandingPageFooter;