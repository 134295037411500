import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";

import {
  IBlogSaveDraft,
  IBlogSavePublish,
  IGenericResponse,
  IGetBlogs,
  IGetBlogsDet,
  IPromptAcceptSchema,
  IPromptRunSchema,
  IGenerateContent,
  IGetBlogsAdmin
} from "./types";
import { IPromptSchema } from "../../components/Prompt";
import { userApi } from "./userApi";
import { setComplexity } from "../features/genieSlice";
import { IBlogsUpdate, IGetBlogId, IDeleteBlog } from "./types";
import { BlogUpdate } from "../../pages/admin/blogedit.page";
import { BlogDraft } from "../../pages/admin/blogedit.page";
import { BlogPublish } from "../../pages/admin/blogedit.page";
import { GenerateCont } from "../../pages/admin/blogedit.page";

export const promptApi = createApi({
  reducerPath: "promptApi",
  baseQuery: customFetchBase,
  tagTypes: ["Prompt"],
  endpoints: (builder) => ({
    addPrompt: builder.mutation<IGenericResponse, IPromptSchema>({
      query(data) {
        return {
          url: "prompts",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Prompt", id: "LIST" }],
    }),
    getPrompts: builder.query<IPromptAcceptSchema[], void>({
      query() {
        return {
          url: "prompts",
          method: "GET",
        };
      },
      transformResponse: (results: { data: IPromptAcceptSchema[] }) =>
        results.data,
      providesTags: [{ type: "Prompt", id: "LIST" }],
    }),
    updatePrompt: builder.mutation<
      IGenericResponse,
      { id: string; info: IPromptSchema }
    >({
      query({ id, info }) {
        return {
          url: `prompts/${id}`,
          method: "PATCH",
          body: info,
        };
      },
      invalidatesTags: [{ type: "Prompt", id: "LIST" }],
    }),
    getPromptNames: builder.query<string[], { product_name: string; product_module: string }>({
      query({ product_name, product_module }) {
        return {
          url: `prompts/names?product_name=${product_name}&product_module=${product_module}`,
        };
      },
      transformResponse: (results: { data: { prompt_name: string }[] }) =>
        results.data.map((item) => item.prompt_name),
    }),
    runPrompt: builder.mutation<Array<any>, IPromptRunSchema>({
      query(data) {
        return {
          url: "prompts/run",
          method: "POST",
          body: data,
        };
      },
      transformResponse: (results: { msg: Array<any> }) => results.msg,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          await dispatch(userApi.endpoints.getMe.initiate(null));
          console.log(data);
          //dispatch(setComplexity(data));
        } catch (error) { }
      },
    }),
    getBlogDetails: builder.query<IGetBlogs[], { limitval: number; skipval: number }>({
    
      query({ limitval, skipval }) {
        return {
          url: `prompts/listBlogsadmin?limitval=${limitval}&skipval=${skipval}`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IGetBlogs[] }) => results.data,
      providesTags: [{ type: "Prompt", id: "LIST" }],
    }),
    getBlogDetailsAdmin: builder.query<IGetBlogsAdmin[], { limitval: number; skipval: number, query: string }>({
    
      query({ limitval, skipval, query }) {
        console.log("herere...1");
        return {
          url: `prompts/listBlogsadmin?limitval=${limitval}&skipval=${skipval}&query=${query}`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IGetBlogsAdmin[] }) => results.data,
      providesTags: [{ type: "Prompt", id: "LIST" }],
    }),
    getBlogDet: builder.query<IGetBlogsDet[], { url: string }>({
      query({ url }) {
        return {
          url: `prompts/getBlogDetbyURL?url=${url}`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IGetBlogsDet[] }) => results.data,
      providesTags: [{ type: "Prompt", id: "LIST" }],

    }),
    getBlogid: builder.query<IGetBlogId[], { blog_id: string }>({
      query({ blog_id }) {
        return {
          url: `prompts/getBlogDet?blog_id=${blog_id}`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IGetBlogId[] }) => results.data,
      providesTags: [{ type: "Prompt", id: "LIST" }],

    }),

    deleteBlog: builder.mutation<IDeleteBlog, { blog_id: string }>({
      query({blog_id}) {
        return {
          url: `prompts/deleteBlog?blog_id=${blog_id}`,
          method: "GET",
        };
      },
    }),

    BlogDetailsUpdate: builder.mutation<IBlogsUpdate[], BlogUpdate>({
      query(data) {
        return {
          url: "prompts/updateblog",
          method: "POST",
          body: data,
        };
      },
    }),

    BlogDetailsDraft: builder.mutation<IBlogSaveDraft, BlogDraft>({
      query(data) {
        return {
          url: "prompts/savecontentasdraft",
          method: "POST",
          body: data,
        };
      },
    }),

    BlogDetailsPublish: builder.mutation<IBlogSavePublish, BlogPublish>({
      query(data) {
        return {
          url: "prompts/publishcontent",
          method: "POST",
          body: data,
        };
      },
    }),

    GenerateBlogs: builder.mutation<IGenerateContent, {cat: string, title: string, prompt: string}>({
      query({cat, title, prompt}) {
        return {
          url: `prompts/generatecontent?cat=${cat}&title=${title}&prompt=${prompt}`,
          method: "POST",
          /* body: data, */
        };
      },
    }),

    BlogsCount: builder.query<any, void>({
      query(){
        return{
          url: "prompts/listBlogsCount",
          method: "GET"
        };
      },
      transformResponse: (results: { data: any }) => results.data,
    providesTags: [{ type: 'Prompt', id: 'LIST' }],
    }),
  }),
});

export const {
  useAddPromptMutation,
  useGetPromptsQuery,
  useUpdatePromptMutation,
  useGetPromptNamesQuery,
  useRunPromptMutation,
  useGetBlogDetailsQuery,
  useGetBlogDetailsAdminQuery,
  useGetBlogDetQuery,
  useBlogDetailsUpdateMutation,
  useBlogDetailsDraftMutation,
  useBlogDetailsPublishMutation,
  useGenerateBlogsMutation,
  useBlogsCountQuery,
  useGetBlogidQuery,
  useDeleteBlogMutation
} = promptApi;
