import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import {
  Box,
  Stack,
  Typography,
  SvgIcon,
  IconButton,
  Button,
  Divider,
  Tabs,
  Tab,
  Menu,
  Modal,
  MenuItem,
  TextareaAutosize,
  Popper,
  CircularProgress
} from "@mui/material";

import { ReactComponent as CopyIcon } from "../../assets/ico_copy.svg";
import { ReactComponent as ExportIcon } from "../../assets/ico_export.svg";

import { useGetPromptNamesQuery } from "../../redux/api/promptApi";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setPromptName } from "../../redux/features/genieSlice";
import { useExportFileMutation, useExportCoverFileMutation, useDownloadFileMutation } from "../../redux/api/genieApi";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import PayNowButton from "../../pages/payment/payNowButton";
import { object, string, TypeOf, number, any } from "zod";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import {
  useLazyRemainingWishCountQuery,
  useRemainingWishCountQuery,
  useSendUrlMutation,
  useUploadImageMutation,
} from "../../redux/api/genieApi";
import { GifBoxRounded, Padding } from "@mui/icons-material";
import { useDeactiveUserMutation, useFeedBackMutation } from '../../redux/api/authApi';
import { LoadingButton } from '@mui/lab';
import { ReactComponent as FeedBackIcon } from '../../assets/ico_feedback.svg';
import { useSaveResumeCoverContentMutation } from '../../redux/api/manualApi';
import logo from '../../assets/image/logo-centri-life-white-color.png';
import ResumeTempImg1 from '../../assets/image/ResumeTemplateOne.jpg';
import ResumeTempImg2 from '../../assets/image/ResumeTemplateTwo.jpg';
import ResumeTempImg3 from '../../assets/image/ResumeTemplateThree.jpg';
import { Dialog } from '@mui/material';
import { generateDocument } from '../docxtemplater/templater1';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import '../../components/Centri_CSS/loader.css';
import { ht } from 'date-fns/locale';


//from "../r ../redux/api/genieApi";
interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
}



const feedbackform = object({
  email: string(),
  rating: number(),
  feedback: string(),
  productname: string(),
  date: string()
});

export type FeedBackForm = TypeOf<typeof feedbackform>

const StoreResumeCover = object({
  user_id: string(),
  is_type: string(),
  input_method: string(),
  content: any(),
  original_data: any(),
  formatted_data: any()
  // created_on: string()
})

export type SaveResCov = TypeOf<typeof StoreResumeCover>

interface ResumeData {
  _id: string;
  user_id?: string;
  document_id?: string;
  is_type?: string;
  input_method?: string | null;
  content?: {
    "PERSONAL DETAILS"?: {
      Name?: string;
      Address?: string;
      City?: string;
      State?: string;
      Country?: string;
      Phone?: string;
      Email?: string;
    };
    "PROFESSIONAL SUMMARY"?: string;
    "EMPLOYMENT HISTORY"?: Array<Record<string, string | string[]>>;
    "EDUCATION"?: Array<Record<string, string>>;
    "SKILLS"?: string;
  };
  original_data?: string | null;
  formatted_data?: string | null;
  created_on?: string | null;
  updated_on?: string | null;
}

const finetuneResume = (formattedMsg: string) => {
  formattedMsg = formattedMsg.replace("***** PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("{PROFESSIONAL SUMMARY}", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("***** END PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("***** END OF PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("{SKILLS}", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("SKILLS:", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("***** SKILLS *****", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("***** SKILLS *****", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("{EDUCATION}", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("***** EDUCATION *****", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("***** RESUME *****", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("{RESUME}", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("***** SKILLS SECTION *****", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("***** JOB DESCRIPTION *****", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("*", '');
  formattedMsg = formattedMsg.replace("RESUME", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("END OF", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("END", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace("{}", '').replace(/\r?\n\r?\n/g, '\r\n');
  formattedMsg = formattedMsg.replace(/^##/gm, "\r\n##");
  formattedMsg = formattedMsg.replace(/ ## /gm, "\r\n##");
  formattedMsg = formattedMsg.replace(" OF ", '');
  formattedMsg = formattedMsg.replace(/^## /g, '\n');
  formattedMsg = formattedMsg.replace(/^##/g, '\n');
  formattedMsg = formattedMsg.replace(/^(\*)+(.)+(\*)+$/g, '');
  formattedMsg = formattedMsg.replace(/\*\*\*\*\*.\*\*\*\*\*/g, '');
  return formattedMsg;
}

const convertJsonToString = (key: string, value: any) => {
  try {
    if (typeof value === 'string' && value.trim() !== '') {
      // const parsedValue = JSON.parse(value);
      // const msg = parsedValue.msg || parsedValue || value;
      // const formattedMsg = msg.trim();
      let formattedMsg = value.trim();
      formattedMsg = formattedMsg.replace("***** PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
      formattedMsg = formattedMsg.replace("{PROFESSIONAL SUMMARY}", '').replace(/\r?\n\r?\n/g, '\r\n');
      formattedMsg = formattedMsg.replace("***** END PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
      formattedMsg = formattedMsg.replace("***** END OF PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
      formattedMsg = formattedMsg.replace("{SKILLS}", '').replace(/\r?\n\r?\n/g, '\r\n');
      formattedMsg = formattedMsg.replace("SKILLS:", '').replace(/\r?\n\r?\n/g, '\r\n');
      formattedMsg = formattedMsg.replace("***** SKILLS *****", '').replace(/\r?\n\r?\n/g, '\r\n');
      formattedMsg = formattedMsg.replace("***** SKILLS *****", '').replace(/\r?\n\r?\n/g, '\r\n');
      formattedMsg = formattedMsg.replace("{EDUCATION}", '').replace(/\r?\n\r?\n/g, '\r\n');
      formattedMsg = formattedMsg.replace("***** EDUCATION *****", '').replace(/\r?\n\r?\n/g, '\r\n');
      formattedMsg = formattedMsg.replace("***** RESUME *****", '').replace(/\r?\n\r?\n/g, '\r\n');
      formattedMsg = formattedMsg.replace(/^(\*)+(.)+(\*)+$/g, '');
      formattedMsg = formattedMsg.replace(/\*\*\*\*\*.\*\*\*\*\*/g, '');
      return `\n${formattedMsg}\n\n`;
    } else if (typeof value === 'object' && value !== null && 'msg' in value) {
      return `\n${(value as { msg?: string }).msg}\n\n`;
    } else {
      return `\n${JSON.stringify(value)}\n\n`;
    }
  } catch (error) {
    console.error(`Error parsing JSON for key '${key}' and value ${value}:`, error);
    return "";
  }
}

const TotalPanel = ({
  export_check,
  export_text,
  export_pdf,
  export_word,
  personalDetData,
  professionalSummaryData,
  employmentHistoryData,
  educationData,
  skillsData,
  coverletterData,
  loadData,
  loadingCreateResume,
  code,
  jobDescp,
  addDataSaved,
  isClear,
  viewSavedRes,
  showTemplates,
  setShowTemplates,
  download,
  showCoverLetterTab,
  activePane,
  setActivePane,
  settabBButtonbgColor,
  settabBButtonColor
}: {
  export_check: string[];
  export_text: string;
  export_pdf: string;
  export_word: string;
  personalDetData: string,
  professionalSummaryData: string,
  employmentHistoryData: string,
  educationData: string,
  skillsData: string,
  coverletterData: string,
  loadData: boolean,
  loadingCreateResume: boolean,
  code: string,
  jobDescp: string,
  addDataSaved: any,
  isClear: boolean,
  viewSavedRes: boolean,
  showTemplates: boolean,
  setShowTemplates: (value: boolean) => void,
  download: string,
  showCoverLetterTab: boolean,
  activePane: any,
  setActivePane: (value: any) => void,
  settabBButtonbgColor: (value: any) => void,
  settabBButtonColor: (value: any) => void
}) => {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
  const initialState = {
    copied: false
  }
  const [clipboard, clipboardState] = useState(initialState);
  // const [showCoverLetter, setShowCoverLetter] = useState(false);
  // const [activePane, setActivePane] = useState("pane-AAA");

  const genieSelector = useAppSelector((state) => state.genieState);
  // console.log(genieSelector.prompt_name);
  const userSelector = useAppSelector((state) => state.userState);
  const dispatch = useAppDispatch();

  const [exportFile, exportState] = useExportFileMutation();
  const [exportCoverFile, exportCoverState] = useExportCoverFileMutation();
  const [downloadFileFunc, downloadFileState] = useDownloadFileMutation();

  const [openPaidModal, setOpenPaidModal] = useState(false);
  const [openPaidModal1, setOpenPaidModal1] = useState(false);
  const [openPaidModalAnyCode, setOpenPaidModalAnyCode] = useState(false);
  const navigate = useNavigate();

  const isPaid = localStorage.getItem("payment");
  const [resumeContent, setResumeContent] = useState('');
  const [htmlResumeContent, sethtmlResumeContent] = useState('');
  const [coverContent, setCoverContent] = useState('');
  const [lines, setLines] = useState<string[]>([]);
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);

  // const [tabBbuttonColor, settabBButtonColor] = useState('#ccc');
  // const [tabBbuttongbColor, settabBButtonbgColor] = useState('#fff');

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     if (lines && currentLetterIndex < lines[currentLineIndex]?.length) {
  //       setResumeContent(prevContent => prevContent + lines[currentLineIndex][currentLetterIndex]);
  //       setCurrentLetterIndex(prevIndex => prevIndex + 1);
  //     } else if (currentLineIndex < lines.length - 1) {
  //       setCurrentLineIndex(prevIndex => prevIndex + 1);
  //       setCurrentLetterIndex(0);
  //       setResumeContent(prevContent => prevContent + '\n');
  //     } else {
  //       clearInterval(timer);
  //     }
  //   }, 5);

  //   return () => clearInterval(timer);
  // }, [currentLetterIndex, currentLineIndex, lines]);
  useEffect(() => {
    //this commented to set realtime streaming data instead of mimicing realtime streaming
    // const timer = setInterval(() => {
    //   if (lines && currentLineIndex < lines.length) {
    //     const currentLine = lines[currentLineIndex];
    //     if (currentLetterIndex < currentLine.length) {
    //       setResumeContent(prevContent => prevContent + currentLine[currentLetterIndex]);
    //       setCurrentLetterIndex(prevIndex => prevIndex + 1);
    //     } else if (currentLineIndex < lines.length - 1) {
    //       setCurrentLineIndex(prevIndex => prevIndex + 1);
    //       setCurrentLetterIndex(0);
    //       setResumeContent(prevContent => prevContent + '\n');
    //     } else {
    //       clearInterval(timer);
    //     }
    //   } else {
    //     clearInterval(timer);
    //   }
    // }, 5);

    // return () => clearInterval(timer);
  }, [currentLetterIndex, currentLineIndex, lines]);

  let localStorage_msg = {
    /* 'SUMMARY': summaryData,
    'FIX BUGS': bugFixesData,
    'COMPLEXITY': complexityData,
    'FUNCTIONALITY': functionalityData, */
    'PERSONAL DETAILS': personalDetData,
    'PROFESSIONAL SUMMARY': professionalSummaryData,
    'EMPLOYMENT HISTORY': employmentHistoryData,
    'EDUCATION': educationData,
    'SKILLS': skillsData,
    'COVER LETTER': coverletterData,
    // 'COMPLETE AUDIT': completeAuditData,
    // 'RESUME': resumeData
  }

  const htmlTemplate = `<table width='100%' border='0'>
    <!--- Personal details starts-->
    <tr>
      <td width='100%' style="margin-right: 5px !important;">
        <table width='100%' style="line-height: 26px;">
          <tr>
            
            <td width='30%' align='left' style="padding-right: 5px;padding-bottom: 0;padding-top: 0;">{{address}}</td>
            <td width='70%' align='right' style="text-align: right;padding-right: 5px;font-size: 18px;padding-bottom: 0;padding-top: 0;font-weight: bold;"><strong>{{name}}</strong></td>
            
          </tr>
          <tr>
            <td width='30%' align='left' style="padding-right: 5px;padding-bottom: 0;padding-top: 0;">{{email}}</td>
            <td width='70%' align='right' style="padding-right: 5px;padding-bottom: 0;padding-top: 0;">{{designation}}</td>
            
          </tr>
          <tr>
            <td width='30%' align='left' style="padding-right: 5px;padding-bottom: 0;padding-top: 0;">{{phone}}</td>
          </tr>
        </table>
      </td>
      

    </tr>
   
  
   <!--- Personal details Ends-->
  

  <tr><td colspan='3' align='left' style='font-size: 14px; font-weight: bold;'>PROFESSIONAL SUMMARY</td></tr>
  <tr style="padding: 5px 0px;"><td colspan='3' align='left' style="text-align: justify; font-weight: lighter;padding-right:5px;">{{professional_summary}}</td></tr>
  
  
    <tr><td colspan='3' style='height: 10px;'>&nbsp;</td></tr>
    <tr><td colspan='3' align='left' style="font-size: 14px; font-weight: bold;">EMPLOYMENT HISTORY </td></tr>
    <tr><td colspan='3' align='left' style="font-size: 12px; font-weight: bold;">{{employment_history}} </td>
    </tr>


    <tr><td colspan='3' style='height: 10px;'>&nbsp;</td></tr>
    <tr><td colspan='3' align='left' style='font-size: 14px; font-weight: bold;'><br>{{educational_title}} </td></tr>
    {{spacingtd}}
    <tr><td colspan='3' align='left' style="font-size: 12px; font-weight: 400px; margin-top: 20px;">{{educational_history}}</td></tr>

  
    <tr style="padding: 0px 0px;"><td colspan='3' align='left' tyle="font-size: 14px; font-weight: 400px; margin-top: 10px;"><br><span style='font-size: 14px; font-weight: bold;'>SKILLS</span><br><br><ul>{{skills}}</ul></td></tr>
  </table>`;


  const htmlTemplate2 = `<table width='100%' style="line-height: 26px;">
  <tr style="font-size: 14px; width: 100% !important;">
    <td align="right" width="33.3%">{{name}} &nbsp; &nbsp; . &nbsp; &nbsp; </td>
    
    <td align="center" width="33%">{{email}} &nbsp; &nbsp; . &nbsp; &nbsp; </td>
    
    <td align="left" width="33.1%">{{phone}}</td>
  </tr>
 
  <tr style="height: 10px;">&nbsp;</tr>
  <tr align="center">
    <td width="33.3%">&nbsp;</td>
  
    <td align="center" width="33%" style="letter-spacing: -0.02em;
    line-height: 24px;
    font-weight: bolder; font-size: 16px;">{{name}}</td>
  
    <td width="33.1%">&nbsp;</td>
  </tr>
  <tr align="center">
    <td width="33.3%">&nbsp;</td>

    <td align="center" width="33%" style="letter-spacing: -0.02em;
    line-height: 24px;
    font-weight: lighter; font-size: 14px;">{{designation}}</td>
    
    <td width="33.1%">&nbsp;</td>
  </tr>
  <tr align="center">
    <td width="33.3%">&nbsp;</td>
    
    <td align="center" width="33%" style="letter-spacing: -0.02em;
    line-height: 24px;
    font-weight: bolder; font-size: 16px;">* * *</td>
    
    <td width="33.1%">&nbsp;</td>
  </tr>
</table>

<!--- Personal details Ends-->

<table>
<!-- <tr><td colspan='3' align='left' style='font-size: 14px; font-weight: bold;'>PROFESSIONAL SUMMARY</td></tr> -->
<tr style="padding: 5px 5px;"><td colspan='3' align='left' style="text-align: justify; font-weight: lighter;padding: 5px 5px;">{{professional_summary}}</td></tr>


<tr><td colspan='3' align='left' width="100%"  style="padding: 5px 5px;" padding="5px;"><hr /></td></tr>
<tr><td colspan='3' align='left' style="font-size: 14px; font-family: system-ui, -apple-system, 'Segoe UI', Roboto,
'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji'; font-weight: bold;">EMPLOYMENT HISTORY </td></tr>
<tr><td colspan='3' align='left' width="100%"  style="padding: 5px 5px;"  padding="5px;"><hr /></td></tr>

<tr><td colspan='3' align='left' style="font-size: 14px; font-family: system-ui, -apple-system, 'Segoe UI', Roboto,
'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji'; font-weight: 400px;">{{employment_history}} </td>
</tr>

<tr><td colspan='3' align='left' style="padding: 5px 5px;" padding="5px;"  width="100%"><hr /></td></tr>
<tr><td colspan='3' align='left' style='font-size: 14px; font-weight: bold;'>{{educational_title}} </td></tr>
<tr><td colspan='3' align='left' style="padding: 5px 5px;" padding="5px;"  width="100%"><hr /></td></tr>
<tr><td colspan='3' align='left' style="font-size: 14px; font-family: system-ui, -apple-system, 'Segoe UI', Roboto,
'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji'; font-weight: 400px;">{{educational_history}}</td></tr>

<tr><td colspan='3' style='height: 10px;'>&nbsp;</td></tr>
<tr><td colspan='3' align='left' style="padding: 5px 5px;" padding="5px;" width="100%"><hr /></td></tr>
<tr><td colspan='3' align='left' style='font-size: 14px; font-weight: bold;'>SKILLS</td></tr>
<tr><td colspan='3' align='left' style="padding: 5px 5px;" padding="5px;" width="100%"><hr /></td></tr>
<tr style="padding: 0px 0px;"><td colspan='3' align='left' tyle="font-size: 14px; font-family: system-ui, -apple-system, 'Segoe UI', Roboto,
'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji'; font-weight: 400px; margin-top: 20px;">{{skills}}</td></tr>
</table>`;


  const htmlTemplate3 = `<table width='100%'>
<!--- Personal details starts-->
<tr>
  <td width="100%" align="left" style="font-size: 16px; font-weight: 500; line-height: 1.2;padding: 5px 5px;">{{name}}</td>
  </tr>
<tr>
  <td width="100%" align="left" style="text-transform: uppercase!important;
  font-size: 14px;
  margin-bottom: 8px !important;
  font-weight: 500;
  line-height: 1.2;">{{designation}}</td>
</tr>
<tr>
  <td width="100%" align="left" style="letter-spacing: -0.02em;
  line-height: 24px;
  font-weight: 500;" >{{name}} / {{phone}} / {{email}}</td>
</tr>

<!--- Personal details Ends-->

<tr><td  align='left' width='100%' style="border-bottom: 1px;"><hr /></td></tr>
</table>
<table width="100%">
<tr><td width="25%" align='left' style='font-size: 14px; font-weight: bold;vertical-align: top;'>PROFESSIONAL SUMMARY</td>
<td width="75%" align='left' style="text-align: justify; font-weight: lighter;padding: 5px 5px;">{{professional_summary}}</td></tr>
<tr><td colspan='2' align='left'><hr /></td></tr>

<tr><td width="100%" colspan='2'  align='left' style="font-size: 14px;  font-weight: bold;">EMPLOYMENT HISTORY</td>
</tr>
{{employment_history}}

  <!-- <tr><td colspan='3' style='height: 10px;'>&nbsp;</td></tr> -->

<tr><td colspan='2' align='left' width='100%'>{{hr}}</td></tr>


<tr><td width="25%"   align='left' style='font-size: 14px; font-weight: bold;vertical-align: top;'>{{educational_title}} </td>
  <td width="75%" colspan='2' align='left' style='font-weight: 400px;vertical-align: top;'>{{educational_history}}</td></tr>

<tr><td colspan='2' align='left' width='100%'><hr /></td></tr>
<tr><td colspan='3' style='height: 10px;'>&nbsp;</td></tr>

<tr><td width="25%" align='left' style='font-size: 14px; font-weight: bold;vertical-align: top;'>SKILLS</td>
<td width="75%" colspan='2' align='left' style='font-weight: lighter;'> {{skills}}</td></tr>
</table>`;

  // Define the type for the contact object
  interface PERSONAL_DETAILS {
    Name: string;
    Position: string;
    Phone: string;
    Email: string;
    City: string;
    State: string;
    Country: string;
  }

  useEffect(() => {
    let personal = '';
    let summary = '';
    let employeement = '';
    let education = '';
    let skills = '';
    let personal1 = '';
    let summary1 = '';
    let employeement1 = '';
    let education1 = '';
    let skills1 = '';


    if (localStorage_msg['PERSONAL DETAILS'].trim() !== '') {
      const heading1 = `'PERSONAL DETAILS'\n............................\n`;
      const strData1 = convertJsonToString('PERSONAL DETAILS', finetuneResume(localStorage_msg['PERSONAL DETAILS'].trim()))
      localStorage_msg['PERSONAL DETAILS'] = finetuneResume(localStorage_msg['PERSONAL DETAILS'].trim());
      personal = heading1 + strData1
      personal1 = strData1
    }
    if (localStorage_msg['PROFESSIONAL SUMMARY'].trim() !== '') {
      const heading2 = `'PROFESSIONAL SUMMARY'\n............................\n`;
      const strData2 = convertJsonToString('PROFESSIONAL SUMMARY', finetuneResume(localStorage_msg['PROFESSIONAL SUMMARY'].trim()))
      if (personal) {
        summary = heading2 + strData2
        summary1 = strData2
        localStorage_msg['PROFESSIONAL SUMMARY'] = finetuneResume(localStorage_msg['PROFESSIONAL SUMMARY'].trim());
      }
    }
    if (localStorage_msg['EMPLOYMENT HISTORY'].trim() !== '') {
      const heading3 = `'EMPLOYMENT HISTORY'\n............................\n`;
      const strData3 = convertJsonToString('EMPLOYMENT HISTORY', finetuneResume(localStorage_msg['EMPLOYMENT HISTORY'].trim()))
      if (personal && summary) {
        employeement = heading3 + strData3
        employeement1 = strData3
        localStorage_msg['EMPLOYMENT HISTORY'] = finetuneResume(localStorage_msg['EMPLOYMENT HISTORY'].trim())
      }
    }
    if (localStorage_msg['EDUCATION'].trim() !== '') {
      const heading4 = `'EDUCATION'\n............................\n`;
      const strData4 = convertJsonToString('EDUCATION', finetuneResume(localStorage_msg['EDUCATION'].trim()))
      if (personal && summary && employeement) {
        education = heading4 + strData4
        education1 = strData4
        localStorage_msg['EDUCATION'] = finetuneResume(localStorage_msg['EDUCATION'].trim())
      }


    }
    if (localStorage_msg['SKILLS'].trim() !== '') {
      const heading5 = `'SKILLS'\n............................\n`;
      const strData5 = convertJsonToString('SKILLS', finetuneResume(localStorage_msg['SKILLS'].trim()))
      if (personal && summary && employeement && education) {
        skills = heading5 + strData5
        skills1 = strData5
        localStorage_msg['SKILLS'] = finetuneResume(localStorage_msg['SKILLS'].trim())
      }


    }
    if (localStorage_msg['COVER LETTER'].trim() !== '') {
      const heading6 = `'COVER LETTER'\n............................\n`;

      const strData6 = localStorage_msg['COVER LETTER'].trim()
      localStorage_msg['COVER LETTER'] = localStorage_msg['COVER LETTER'].trim()
      setCoverContent(heading6 + strData6);

    }
    let fullres = personal + '\n' + summary + '\n' + finetuneResume(employeement) + '\n' + finetuneResume(education) + '\n' + skills
    fullres = fullres.replace(/##/g, "");



    //console.log("prview call............1");

    if (personal1) {
      setResumeContent(fullres);
      handleResumePreview("template1", "");
    }






    //setLines([personal+summary+employeement+education+skills]);
  }, [JSON.stringify(localStorage_msg)]);



  useEffect(() => {
    console.log("clearing from total panel");
    setCurrentLetterIndex(0);
    setCurrentLineIndex(0);
    setLines([]);
    setResumeContent('');
    sethtmlResumeContent('');
    setCoverContent('');

  }, [isClear]);


  if (isPaid) {
    localStorage_msg = JSON.parse(String(localStorage.getItem("localStorage_msg")));
  }

  //feedback popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const ratings = [1, 2, 3, 4, 5];
  const user = useAppSelector((state) => state.userState.user);
  const userEmail = localStorage.getItem('user_email');
  const [productName, setProductName] = useState<string | null>(localStorage.getItem('module'));
  const currentDate = new Date().toISOString();

  const [feedbackForm, setFeedbackForm] = useState<FeedBackForm>({ email: userEmail || "", rating: 0, feedback: '', productname: productName || '', date: currentDate })
  const [feedbackMutation] = useFeedBackMutation();
  const remainingWishCountQuery = useRemainingWishCountQuery({ "email": user?.email || "" })
  const [remainingWishCount, remWishState] = useLazyRemainingWishCountQuery();
  const [wishRemCount, setWishRemCount] = useState(0);

  const [isEditing, setIsEditing] = useState(false);
  const [editableContent, setEditableContent] = useState<string>(genieSelector.msg[
    genieSelector.prompt_name ? genieSelector.prompt_name : ""
  ] || '');
  const contentRef = useRef<HTMLDivElement>(null);
  const resumeBoxRef = useRef<HTMLDivElement>(null);

  const [saveResumeCoverMutation] = useSaveResumeCoverContentMutation();
  const [resumeTab, setResumeTab] = useState(true);
  const [coverTab, setCoverTab] = useState(false);
  const [selectedResume, setSelectedResume] = useState<any>("");
  const [selectedCoverLetter, setSelectedCoverLetter] = useState<any>("");

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [DownloadLoading, setDownloadLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  // const [showTemplates, setShowTemplates] = useState(false);
  const [showSavedResumes, setShowSavedResumes] = useState(false);
  const [savedResumesData, setSavedResumesData] = useState<ResumeData[]>([]);
  const [savedCoverLettersData, setSavedCoverLettersData] = useState<ResumeData[]>([]);
  const [savedResumesFirstName, setSavedResumesFirstName] = useState<string[]>([]);

  // console.log('showTemp val=>', storedValue);

  /* useEffect(() => {
    console.log('useEffect hook in TotalPanel');
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'View Saved Resume' && event.newValue === 'true') {
        setShowTemplates(true);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); */
  /*  useEffect(() => {
     const handleStorageChange = () => {
       const buttonId = 'view-saved-resumes-button';
       const localStorageKey = 'ViewSavedResume';
       const buttonClicked = localStorage.getItem(localStorageKey);
       if (buttonClicked && buttonClicked === 'true') {
         const button = document.getElementById(buttonId);
         if (button) {
           setShowTemplates(viewSavedRes || false);
           localStorage.removeItem(localStorageKey); 
         }
       }
     };
 
     handleStorageChange(); // Check initial value
     window.addEventListener('storage', handleStorageChange);
 
     return () => {
       window.removeEventListener('storage', handleStorageChange);
     };
   }, []); */

  const baseUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/api/`;

  const strToObj = (str: string): Record<string, string> => {
    const details = str.split('##').filter(Boolean);
    const object: Record<string, string> = {};

    details.forEach((detail) => {
      const [key, value] = detail.split(':').map((item) => item.trim());
      object[key] = value;
    });
    return object;
  };

  const strToArr = (str: string): Record<string, string | string[]>[] => {
    const detailsArr = str.split('##').filter(Boolean);
    const array: Record<string, string | string[]>[] = [];

    for (const details_item of detailsArr) {
      let details: string[] = details_item.split('\n').filter(Boolean);
      const object: Record<string, string | string[]> = {};

      details.forEach((detail, index) => {
        const [key, ...values] = detail.split(':').map((item: string) => item.trim());
        const value = values.join(':').trim();

        if (key === "Responsibilities") {
          // Handle responsibilities as an array
          const responsibilities: string[] = [];
          for (let i = index + 1; i < details.length && details[i].startsWith('-'); i++) {
            responsibilities.push(details[i].replace(/^-/, '').trim());
          }
          if (responsibilities.length > 0 && key) {
            object[key] = responsibilities;
          }
        } else if (!detail.startsWith('-') && key) {
          // Exclude lines starting with hyphen from the top-level array
          object[key] = value;
        }
      });

      if (Object.keys(object).length > 0) {
        array.push(object);
      }
    }

    return array;
  };

  const handleResumePreview = (restemplate: string, mode: string) => {
    //let use html template and show the resume with html tempalte for beautification
    //console.log("restemplate.........");
    //console.log(restemplate);
    let htmlContent = htmlTemplate;
    if (restemplate == "template1")
      htmlContent = htmlTemplate;
    else if (restemplate == "template2")
      htmlContent = htmlTemplate2;
    else if (restemplate == "template3")
      htmlContent = htmlTemplate3;

    let pd: PERSONAL_DETAILS = {
      Name: "",
      Position: "",
      Phone: "",
      Email: "",
      City: "",
      State: "",
      Country: ""
    };
    //let personal_details: PERSONAL_DETAILS = localStorage_msg["PERSONAL DETAILS"] !== "" ? { ...strToObj(localStorage_msg["PERSONAL DETAILS"]), ...pd } : pd;


    let personal_details: any;
    if (mode == "saved")
      personal_details = localStorage_msg["PERSONAL DETAILS"];
    else
      if (typeof localStorage_msg["PERSONAL DETAILS"] === 'object')
        personal_details = localStorage_msg["PERSONAL DETAILS"];
      else
        personal_details = strToObj(localStorage_msg["PERSONAL DETAILS"]);

    if (personal_details.Name == undefined || personal_details.Name == "") {
      if (personal_details["First Name"] || personal_details["Last Name"])
        personal_details.Name = personal_details["First Name"] + " " + personal_details["Last Name"];
      else
        personal_details.Name = "";
    }
    let strhtmlTemplate = htmlContent.replaceAll("{{name}}", personal_details.Name.trim());
    if (personal_details.Position == undefined || !personal_details.Position) personal_details.Position = ""
    strhtmlTemplate = strhtmlTemplate.replace("{{designation}}", personal_details.Position.trim())
    if (personal_details.Email == undefined || !personal_details.Email) personal_details.Email = ""
    strhtmlTemplate = strhtmlTemplate.replace("{{email}}", personal_details.Email.trim())
    if (personal_details.Phone == undefined || !personal_details.Phone) personal_details.Phone = ""
    strhtmlTemplate = strhtmlTemplate.replace("{{phone}}", personal_details.Phone.trim())
    if (personal_details.Address == undefined || !personal_details.Address) personal_details.Address = ""
    let address = '';
    if (personal_details.Address == "" && personal_details.Address != "[Not Provided]") {

      if (personal_details.City && personal_details.City != "[Not Provided]") {
        address = personal_details.City
      }

      if (personal_details.State && personal_details.State != "[Not Provided]") {
        if (address.length > 0)
          address = address + ", " + personal_details.State
        else
          address = personal_details.State
      }

      if (personal_details.Country && personal_details.Country != "[Not Provided]") {
        if (address.length > 0)
          address = address + ", " + personal_details.Country
        else
          address = personal_details.Country
      }


    }
    else {
      address = personal_details.Address
    }

    strhtmlTemplate = strhtmlTemplate.replace("{{address}}", address.trim())

    let photo = localStorage.getItem("photo");
    //const base64String = btoa(photo?"data:image/jpeg;base64,/9j/4AAQSk"+photo:"");

    strhtmlTemplate = strhtmlTemplate.replace("{{octet_stream_data_photo}}", photo ? photo : '');

    //set professional summary
    strhtmlTemplate = strhtmlTemplate.replace("{{professional_summary}}", "<br>" + localStorage_msg['PROFESSIONAL SUMMARY']);

    let template = "template1";
    //set employment history
    let emphistory: any;
    if (mode == "saved")
      emphistory = localStorage_msg["EMPLOYMENT HISTORY"];
    else
      if (typeof localStorage_msg["EMPLOYMENT HISTORY"] === 'object')
        emphistory = localStorage_msg["EMPLOYMENT HISTORY"];
      else
        emphistory = strToArr(localStorage_msg["EMPLOYMENT HISTORY"]);

    let emprecord = ""
    for (const item of emphistory) {

      let job_title = item["Job Title"];
      let company = item["Company"];
      let period_of_work: any = item["Period of work"];

      let location = item["Location"];

      if ((job_title || company || item["Period of Work"] || location) && template == "template3") {

        emprecord += "<tr><td colspan='3' align='left' style='font-weight: bold;'>&nbsp;</td></tr><tr><td width='25%'  align='left' style='font-size: 11px; font-family: system-ui, -apple-system, Roboto, Arial, sans-serif; font-weight: 400px; text-align: justify;'>" + item["Period of Work"] + "<br>" + job_title + "</td>";
        emprecord += "<td width='75%' colspan='2'  align='left' style='font-size: 11px; font-family: system-ui, -apple-system, Roboto, Arial, sans-serif;font-weight: 400px; text-align: justify;vertical-align: top;'>" + company + ", " + location + "</td></tr>";
      }
      else {
        emprecord += "<tr><td colspan='3' align='left' style='font-weight: bold;'>";
        if (job_title)
          emprecord += "<br>" + job_title + ", "
        if (company)
          emprecord += company + ", "

        if (item["Period of Work"])
          emprecord += item["Period of Work"] + " "
        if (location)
          emprecord += location
        emprecord += "</td></tr>"
      }


      if (item["Responsibilities"] && template == "template3") {
        let responsibilities_list: any = item["Responsibilities"]
        emprecord += "<tr><td width='25%'  align='left' style='font-weight: 400px; text-align: justify;vertical-align: top;padding-right:5px;'>&nbsp;</td><td colspan='2' align='left' style='font-weight: 400px; text-align: justify;vertical-align: top;padding-right:5px;'>"
        for (const respitem of responsibilities_list) {
          //Split the string into an array using newline character
          let array_list: any = respitem.split('\n');
          //Traverse through the array using a for loop
          let resp = "<ul>";
          for (let respitem1 of array_list) {
            respitem1 = respitem1.replace(/\*/gi, '');
            resp += "<li>" + respitem1 + "</li>"

          }
          resp += "</ul>";
          emprecord += resp;

        }
        emprecord += "</td></tr>";
      }
      else if (item["Responsibilities"]) {

        let responsibilities_list: any = item["Responsibilities"]
        emprecord += "<tr><td colspan='3' align='left' style='font-weight: 400px; text-align: justify;vertical-align: top;padding-right:5px;'>";
        for (const respitem of responsibilities_list) {
          //Split the string into an array using newline character
          let array_list: any = respitem.split('\n');
          //Traverse through the array using a for loop
          let resp = "<ul>"
          for (let respitem1 of array_list) {
            respitem1 = respitem1.replace(/\*/gi, '');
            resp += "<li>" + respitem1 + "</li>"

          }
          resp += "</ul>"
          emprecord += resp;

        }
        emprecord += "</td></tr>"

      }


    }
    strhtmlTemplate = strhtmlTemplate.replace("{{employment_history}}", emprecord);

    //set educational history
    let eduhistory: any;
    if (mode == "saved")
      eduhistory = localStorage_msg["EDUCATION"];
    else
      if (typeof localStorage_msg["EDUCATION"] === 'object')
        eduhistory = localStorage_msg["EDUCATION"];
      else
        eduhistory = strToArr(localStorage_msg["EDUCATION"]);

    let edurecord = "";
    let institution_name = "";
    let degree = "";


    for (const record of eduhistory) {

      if (!record["Degree"] && !record["Institution Name"] && !record["Period of Study"])
        continue;

      let period_of_study: any = record["Period of Study"]

      if (template == "template3") {
        if (record["Degree"] && record["Institution Name"]) {
          degree = record["Degree"];
          institution_name = record["Institution Name"];
          edurecord += "<strong>" + degree + "</strong>,<br>";
          edurecord += institution_name + "<br>";
          degree = "";
          institution_name = "";
        }
        else if (record["Degree"]) {
          degree = record["Degree"];
          edurecord += "<strong>" + degree + "</strong>,<br>";
          edurecord += institution_name + "<br><br> ";
          degree = "";
          institution_name = "";
        }
        else if (record["Institution Name"])
          institution_name = record["Institution Name"]
      }
      else if (template != "template3") {
        if (record["Degree"] && record["Institution Name"]) {
          degree = record["Degree"];
          institution_name = record["Institution Name"];
          edurecord += "<tr><td colspan='3' align='left' style='padding-bottom:10px;'>";
          institution_name = record["Institution Name"];
          edurecord += "<strong>" + degree + "</strong>,<br>";
          edurecord += institution_name + "<br>";
          edurecord += "</td></tr>";
          degree = "";
          institution_name = "";
        }
        else if (record["Degree"]) {
          degree = record["Degree"];
          edurecord += "<strong>" + degree + "</strong>,<br>";
          edurecord += institution_name + "<br>";
          edurecord += "</td></tr>";
          degree = "";
          institution_name = "";
        }
        else if (record["Institution Name"]) {
          edurecord += "<tr><td colspan='3' align='left'  style='padding-bottom:10px;'>";
          institution_name = record["Institution Name"];
        }


      }

      if (record["summary"])
        edurecord += "<tr><td colspan='3' align='left'>" + record["summary"] + "</td></tr>"

    }

    if (edurecord) {

      strhtmlTemplate = strhtmlTemplate.replace("{{educational_title}}", "EDUCATIONAL HISTORY");
      strhtmlTemplate = strhtmlTemplate.replace("{{spacingtd}}", "<tr><td colspan='3' style='height: 10px;'>&nbsp;</td></tr>");
      strhtmlTemplate = strhtmlTemplate.replace("{{educational_history}}", edurecord);
      strhtmlTemplate = strhtmlTemplate.replace("{{hr}}", " <hr />");
    }
    else {
      strhtmlTemplate = strhtmlTemplate.replace("{{educational_title}}", "");
      strhtmlTemplate = strhtmlTemplate.replace("{{spacingtd}}", "");
      strhtmlTemplate = strhtmlTemplate.replace("{{educational_history}}", "");
      strhtmlTemplate = strhtmlTemplate.replace("{{hr}}", "");
    }

    //update skillset
    let skilsets: any = localStorage_msg["SKILLS"];
    // Split the string into an array of skill sets using newline character '\n'
    // Use map to iterate over each skill and remove leading/trailing whitespace
    // Filter out any empty strings from the resulting array
    let skills_list: string[] | null = null;
    if (typeof skilsets === 'string') {
      skills_list = skilsets.split('\n').map((skill: string) => skill.trim()).filter((skill: string) => skill !== '');
    }

    let skillset = ""
    if (skills_list) {
      for (let record of skills_list) {
        const pattern: RegExp = /\bJOB DESCRIPTION\b/i;
        record = record.replace(/\*/gi, '');
        let rskill = record.replace("-", "").trim();
        if (skillset === "" && !pattern.test(rskill) && rskill.trim().length > 6) {
          skillset = "<li>" + rskill + "</li>"
        }
        else if (!pattern.test(rskill) && rskill.trim().length > 6) {
          skillset += "<li>" + rskill + "</li>"
        }


      }
    }

    skillset = skillset.replace("\n", "<br>")

    skillset = skillset
      .replace(/Skills?:/gi, '')  // Remove 'Skills:' or 'Skill:' (case-insensitive)
      .replace(/Skills?/gi, '')   // Remove 'Skills' or 'Skill' (case-insensitive)
      .replace(/\*/gi, '');       // Remove asterisks

    strhtmlTemplate = strhtmlTemplate.replace("{{skills}}", skillset);


    if (personal_details.Name)
      sethtmlResumeContent(strhtmlTemplate);

    setShowModal(false);
  }



  const handleCoverOptionSelect = async (download: string) => {
  
    console.log('start handleCoverOptionSelect fnc--->');
    console.log('cover_doc_id' + localStorage.getItem("cover_doc_id"));

    if (localStorage.getItem("cover_doc_id")) {
      console.log('1--->' + download);
      console.log('2 --- selectedOption--->' + selectedOption);
      setSelectedOption(download);

      if (download === 'googledocs') {
        console.log('3 --- selectedOption--->' + selectedOption);
        try {
          setDownloadLoading(true)
          const { data }: any = await exportCoverFile({ user_id: localStorage.getItem("user_id"), document_id: localStorage.getItem("cover_doc_id"), template_id: selectedImage, document_type: download })
          console.log('document type -> ' + selectedOption);
          localStorage.setItem("cover_doc_id", data["document_id"])
          const res = await axios.get(baseUrl + `aiResume/download_signed_resume/${data["document_id"]}/${localStorage.getItem('resume_name_')}`);
          const result = res.data;
          //console.log(result);
          let file_url = result?.url;
          const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(file_url)}`;
          console.log(googleDocsViewerUrl);
          window.open(googleDocsViewerUrl, '_blank');
          setShowModal(false)
          setDownloadLoading(false)
          setSelectedOption("");
        } catch {
          setDownloadLoading(false)
        }
      } else {
        try {
          setDownloadLoading(true)
          const { data }: any = await exportCoverFile({ user_id: localStorage.getItem("user_id"), document_id: localStorage.getItem("cover_doc_id"), template_id: selectedImage, document_type: download })
          localStorage.setItem("cover_doc_id", data["document_id"])
          const file: any = await downloadFileFunc({ doc_id: localStorage.getItem("cover_doc_id") });
          const base64String: string = file["data"]["binary_data"]
          downloadBase64AsPDF(base64String, localStorage.getItem('resume_name_') + `_CoverLetter_${cleanedTimeStamp}.${download === "pdf" ? "pdf" : "docx"}`, "word")
          toast.success(`Downloaded: ${localStorage.getItem('resume_name_')}_CoverLetter_${cleanedTimeStamp}.${download === "pdf" ? "pdf" : "docx"}`);
          // toast.success(`Downloaded: ${localStorage.getItem("doc_id")}.${selectedOption === "pdf" ? "pdf" : "docx"}`);
          setShowModal(false)
          setDownloadLoading(false)
          setSelectedOption("");
        } catch {
          toast.error("Error")
          setDownloadLoading(false)
        }
      }
    } else {
      toast.error("Save First Then Export")
    }

  };



  const handleCloseModal = () => {
    setShowModal(false);
    if (showTemplates) {
      setShowTemplates(false);
    }
    setShowSavedResumes(false);
    localStorage.setItem('View Saved Resume', 'false');
  };
  const handleCheckboxChange = (image: string) => {
    setSelectedImage((prevSelectedImage) => (prevSelectedImage === image ? null : image));
    //console.log("prview call............2");
    handleResumePreview(image, "");
    //handleDownload();
  };





  /* Current timing and date with removed special characters and spaces inbetween */
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  const second = String(now.getSeconds()).padStart(2, '0');

  const timestamp = `${year}-${month}-${day} | ${hour}:${minute}`;
  const cleanedTimeStamp = timestamp.replace(/[^0-9a-zA-Z]/g, '');
  /*  */

  useEffect(() => {
    const updatedFirstNames = savedResumesData.map((data) => {
      const personalDetails = data?.content?.["PERSONAL DETAILS"];
      const name = personalDetails?.["Name"] ?? '';
      localStorage.setItem(`resume_name_`, name);
      return name;
    });
    setSavedResumesFirstName(updatedFirstNames);
  }, [savedResumesData]);

  useEffect(() => {
    //console.log("prview call............3");
    handleResumePreview(localStorage.getItem('changeTemplate') ?? "template1", "");
  }, [localStorage.getItem('changeTemplate')]);

  const handleDownload = async (download: string) => {

   

    let template = localStorage.getItem("changeTemplate");
    //console.log("template....");
    //console.log(template);
    if (!template) template = "template1";

    if (!selectedImage) setSelectedImage(template);

    console.log("localStorage.getItem('downloadmode')");
    console.log(localStorage.getItem('downloadmode'));

    if (download === 'pdf') {
      try {
        setDownloadLoading(true)
        console.log("exportFile");
        console.log(localStorage.getItem('doc_id'));

        const { data }: any = await exportFile({ user_id: localStorage.getItem("user_id"), document_id: localStorage.getItem("doc_id"), template_id: template, document_type: download })
        localStorage.setItem("doc_id", data["document_id"])
        const file: any = await downloadFileFunc({ doc_id: localStorage.getItem("doc_id") });
        console.log('file details=>', file);
        const base64String: string = file["data"]["binary_data"]
        downloadBase64AsPDF(base64String, localStorage.getItem('resume_name_') + `_Resume_${cleanedTimeStamp}.${download == "pdf" ? "pdf" : "word"}`, "pdf")
        toast.success(`Downloaded: ${localStorage.getItem('resume_name_')}_Resume_${cleanedTimeStamp}.pdf`);

        if (localStorage.getItem('downloadmode') != 'saved')
          handleCoverOptionSelect('pdf');

        setShowModal(false)
        setDownloadLoading(false)
        localStorage.setItem("setDownloadingResume","block");
        setTimeout(() => {
          localStorage.setItem("setDownloadingResume","none");
          
          // Your logic here
        }, 2000); // 2000 milliseconds = 2 seconds
        

      } catch (error) {
        if (error instanceof Error) {
          // Handle the error if it's an instance of Error
          console.error("An error occurred:", error.message);
          toast.error("An error occurred: " + error.message);
        } else {
          // Handle other types of errors
          console.error("An unknown error occurred:", error);
          toast.error("An unknown error occurred");
        }
        localStorage.setItem("setDownloadingResume","none");
        setDownloadLoading(false)
      }
    } else if (download === 'word') {
      // try {
      //   setDownloadLoading(true)
      //   let payload = {
      //     'resume': JSON.stringify(content)
      //   };
      //   const response = await axios.post(`${baseUrl}prompts/resume_to_json`, payload);
      //   const result = response.data;
      //   generateDocument(result);
      //   setShowModal(false)
      //   setDownloadLoading(false)
      //   setSelectedOption("");
      // } catch (error) {
      //   console.log(error)
      //   setDownloadLoading(false)
      // }
      try {
        setDownloadLoading(true)
        const { data }: any = await exportFile({ user_id: localStorage.getItem("user_id"), document_id: localStorage.getItem("doc_id"), template_id: template, document_type: download })
        localStorage.setItem("doc_id", data["document_id"])
        const file: any = await downloadFileFunc({ doc_id: localStorage.getItem("doc_id") });
        const base64String: string = file["data"]["binary_data"]
        downloadBase64AsPDF(base64String, localStorage.getItem('resume_name_') + `_Resume_${cleanedTimeStamp}.${download === "word" ? "docx" : "pdf"}`, "word")
        toast.success(`Downloaded: ${localStorage.getItem('resume_name_')}_Resume_${cleanedTimeStamp}.docx`);
        localStorage.setItem("setDownloadingResume","none");
        if (localStorage.getItem('downloadmode') != 'saved')
          handleCoverOptionSelect('word');

        setShowModal(false)
        setDownloadLoading(false)

      } catch {
        toast.error("Error")
        setDownloadLoading(false)
        localStorage.setItem("setDownloadingResume","none");
      }
    } else if (download === 'googledocs') {
      try {
        setDownloadLoading(true)
        const { data }: any = await exportFile({ user_id: localStorage.getItem("user_id"), document_id: localStorage.getItem("doc_id"), template_id: template, document_type: download })
        localStorage.setItem("doc_id", data["document_id"])
        const res = await axios.get(baseUrl + `aiResume/download_signed_resume/${data["document_id"]}/${localStorage.getItem('resume_name_')}`);
        const result = res.data;
        //console.log(result);
        let file_url = result?.url;
        console.log('file url', file_url);

        const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(file_url)}`;
        console.log(googleDocsViewerUrl);
        window.open(googleDocsViewerUrl, '_blank');

        if (localStorage.getItem('downloadmode') != 'saved')
          handleCoverOptionSelect('googledocs');

        setShowModal(false)
        localStorage.setItem("setDownloadingResume","none");
        setDownloadLoading(false)

      } catch {
        localStorage.setItem("setDownloadingResume","none");
        setDownloadLoading(false)
      }
    }

  };

  //console.log(download);
  let handleOptionSelectCalled = false;
  const handleOptionSelect = (/* option: string */download: string) => {
    console.log('hi, there handleoptionselect!!', download);
    if (!handleOptionSelectCalled) {
      handleOptionSelectCalled = true;
      if (download !== null) {
        if (localStorage.getItem("doc_id")) {
          // setSelectedOption(option);
          setSelectedOption(download);

          //setShowModal(true);
          // handleDownload(option);
          handleDownload(download);

          // toggleColor();

        } else {
          toast.error("Save First Then Export")
        }
      }
    }
  };


  useEffect(() => {
    if (download) {
      handleOptionSelect(download);
    } else {
      console.error("Download prop is not provided");
    }
  }, [download]);

  function downloadBase64AsPDF(base64String: string, fileName: string, filetype: string) {

    const binaryString = atob(base64String);
    const byteNumbers = new Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteNumbers[i] = binaryString.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    //console.log("byteArray.........")
    //console.log(byteArray);

    let blob = new Blob([byteArray], { type: 'application/octet-stream' });
    if (filetype == 'pdf')
      blob = new Blob([byteArray], { type: 'application/pdf' });
    else if (filetype == 'word')
      blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  }


  const user_id = localStorage.getItem('user_id');
  const handleResumeTab = () => {

    setResumeTab(true);
    setCoverTab(false);
    // axios.post(baseUrl + `aiResume/start_resume_timer/${user_id}`).then(response => {
    //   console.log('Resume timer started successfully!!');
    // }).catch(error => {
    //   console.log('Oops!!Error on starting resume timer');

    // })
  }



  const handleCoverTab = () => {
    console.log(activePane);

    setCoverTab(true);
    setResumeTab(false);
    localStorage.removeItem('Tab');
    //setActivePane((prevPane: any) => (prevPane === "pane-AAA" ? "pane-BBB" : "pane-AAA"));
    // setActivePane(activePane === "pane-BBB" ? "pane-AAA" : "pane-BBB");
    
    setIsEditing(false);
    //settabBButtonColor('#fff');
   // settabBButtonbgColor('#0076ad');
    axios.post(baseUrl + `aiResume/start_cover_timer/${user_id}`).then(response => {
      console.log('Cover timer started successfully!!');
    }).catch(error => {
      console.log('Oops!!Error on starting cover timer');
    })
    //localStorage.setItem('activePane', activePane);
  }
  
  
  // useEffect(()=>{
  //   if(showCoverLetterTab){
  //     //handleCoverTab();

  //   }else{
  //     console.log('Error on changing cover tab');
      
  //   }
  // }, [showCoverLetterTab])

  useEffect(()=>{
    setTimeout(() => {

      let stat = localStorage.getItem("showresume");
      if(stat=="cover")
        handleCoverTab();
      else
        handleResumeTab();
      // Your logic here
    }, 500); 
    
      

  },[localStorage.getItem("showresume")])

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.innerHTML = genieSelector.msg[
        genieSelector.prompt_name ? genieSelector.prompt_name : ""
      ] || '';
    }
    if (resumeBoxRef.current) {
      resumeBoxRef.current.innerHTML = genieSelector.msg[
        genieSelector.prompt_name ? genieSelector.prompt_name : ""
      ] || '';
    }
  }, []);

  const fetchData = async () => {
    try {
      let user_id = localStorage.getItem("user_id");

      const responseResumes = await axios.get(`${baseUrl}aiResume/getDocument?user_id=${user_id}`);
      const resultResumes = responseResumes.data?.data;

      if (Array.isArray(resultResumes)) {
        setSavedResumesData(resultResumes as ResumeData[]);
      } else {
        setSavedResumesData([]);
      }

      const responseCoverLetters = await axios.get(`${baseUrl}aiResume/getDocument?user_id=${user_id}&is_type=cover`);
      const resultCoverLetters = responseCoverLetters.data?.data;

      if (Array.isArray(resultCoverLetters)) {
        setSavedCoverLettersData(resultCoverLetters as ResumeData[]);

      } else {
        setSavedCoverLettersData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  }

  useEffect(() => {
    if (remainingWishCountQuery.isSuccess)
      setWishRemCount(remainingWishCountQuery.data?.remaining_wish_count);
  }, [remainingWishCountQuery]);

  useEffect(() => {
    if (remWishState.isSuccess) {
      //console.log(remWishState.data?.remaining_wish_count);
      setWishRemCount(remWishState.data?.remaining_wish_count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remWishState]);

  const handleFormSubmit = () => {
    // Call the feedbackMutation function with the form data as payload
    feedbackMutation(feedbackForm)
      .then((response) => {
        // console.log('Feedback sent successfully:', response);
        toast.success('FeedBack Sent Successfully!!');
        setIsPopupOpen(false);
        setFeedbackForm({
          email: userEmail || "",
          rating: 0,
          feedback: '',
          productname: productName || '',
          date: currentDate
        });

      })
      .catch((error) => {
        console.error('Error sending feedback:', error);

      });
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Update feedbackForm with the textarea value
    setFeedbackForm((prevForm) => ({
      ...prevForm,
      feedback: event.target.value,
    }));

  };

  const handleRating = (event: number) => {
    // Update feedbackForm with the textarea value
    setFeedbackForm((prevForm) => ({
      ...prevForm,
      rating: event
    }));

  };

  useEffect(() => {

    //console.log(userSelector.user?.subscription_level);

    var plan = -1;
    plan = Number(userSelector.user?.subscription_level?.indexOf("Start"));

  }, []);

  const promptsInfo = useGetPromptNamesQuery({
    product_name: "CodeGenie",
    product_module: genieSelector.module ? genieSelector.module : ""/* "RESUME" */,
  });

  const messages = Object.keys(localStorage_msg)
    .slice(0, 2).map(key => `\n${key} : \n${(localStorage_msg as any)[key]}`)
    .concat(
      Object.keys(localStorage_msg)
        .slice(3, 15)
        .map(key => `\n${key} : \n${(localStorage_msg as any)[key]}`)
    )
    .join('\n');



  useEffect(() => {
    if (clipboard.copied) {
      toast.success("Copied to clipboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipboard]);

  useEffect(() => {
    if (promptsInfo.isSuccess) {
      dispatch(setPromptName(promptsInfo.data[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptsInfo]);



  let combinedAuditShown = false;
  const updatedPromptsInfoData = promptsInfo.data?.map((panel, index) => {
    //console.log(panel+"-----****")
    if (genieSelector.module === 'SMART CONTRACTS') {
      if (index === 2) {
        return "COMPLETE AUDIT";
      } else if (index >= 3 && index <= 14) {
        return "";
      } else {
        return panel;
      }
    }
    else if (genieSelector.module === 'Any Code') {
      if (index === 3) {
        return "COMPLETE AUDIT";
      }
      else if (index > 0 && index < 2) {
        return "";
      }
      else if (index >= 4 && index <= 14) {
        return "";
      } else {
        return panel;
      }
    }

    if (index === 0) {
      return panel;
    } else if (!combinedAuditShown) {
      combinedAuditShown = true;
      return "COMPLETE AUDIT";
    } else {
      return "";
    }
  });
  //const [completeAudit, setCompleteAudit] = useState('');
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    //console.log("============");
    //console.log(wishRemCount);
    var valid = true;
    //if(isPaid == "Success" || genieSelector.module != "SMART CONTRACTS" ){
    if ((isPaid == "Success" && genieSelector.module == "SMART CONTRACTS") || (wishRemCount > 0 && genieSelector.module == "Any Code")) {
      valid = true;
      //console.log("module....................................");
      //console.log(genieSelector.module);
      if (genieSelector.module == "Any Code") {
        //debugger
        //console.log("Free plan");
        //console.log(localStorage.getItem("free_plan"));
        //console.log("subscription_level--=" + localStorage.getItem("subscription_level"));
        //console.log("promptsInfo-------------------------------");
        //console.log(promptsInfo);
        //console.log("updatedPromptsInfoData-------------------------------");
        //console.log(updatedPromptsInfoData);
        //const newvalue = updatedPromptsInfoData ? updatedPromptsInfoData[newValue] : "";
        const newvalue = promptsInfo.data ? promptsInfo.data[newValue] : "";
        if (newvalue === "COMPLETE AUDIT") {
          //debugger
          //console.log(genieSelector.msg);
          const messages = Object.keys(localStorage_msg)
            .slice(1, 2).map(key => `\n${key} : \n${(localStorage_msg as any)[key]}`)
            .concat(
              Object.keys(localStorage_msg)
                .slice(3, 15)
                .map(key => `\n${key} : \n${(localStorage_msg as any)[key]}`)
            )
            .join('\n');

          //console.log(messages);
          //setCompleteAudit(messages); //need to review
          //localStorage.setItem('Messages', messages); //need to review

        }
        //console.log(newvalue);
        if (localStorage.getItem("free_plan") != "1") {
          if ((newvalue != "SUMMARY" && newvalue != "FIX BUGS") && (localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")) {
            const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
            // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
            console.log("storedAllSet -- 1", storedAllSet);
            if (storedAllSet)
              setOpenPaidModalAnyCode(true);
            else
              toast.success('Pls. wait, we are working in background.');
            valid = false;
          }
        }

      }
      else if (genieSelector.module == "SMART CONTRACTS") {
        // Get all keys from localStorage
        const keys = Object.keys(localStorage);

        // Iterate over the keys and display the values
        keys.forEach((key) => {
          const value = localStorage.getItem(key);
        });
      }

    } else {
      if (updatedPromptsInfoData) {
        if (genieSelector.module == "SMART CONTRACTS") {
          const newvalue = updatedPromptsInfoData ? updatedPromptsInfoData[newValue] : "";
          if (newvalue === "COMPLETE AUDIT") {
            //debugger
            const messages = Object.keys(genieSelector.msg)
              .slice(2, 15)
              .map(key => `\n${key} : \n${genieSelector.msg[key]}`)
              .join('\n');

            //console.log(messages);
            //setCompleteAudit(messages); //need to review
            //localStorage.setItem('Messages',messages); //need to review
          }
          if (newvalue != "FUNCTIONALITY" && newvalue != "COMPLEXITY") {
            const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
            // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
            console.log("storedAllSet -- 2", storedAllSet);
            if (storedAllSet)
              setOpenPaidModal(true);
            else
              toast.success('Pls. wait, we are working in background.');
            valid = false;
          }
        }
        else if (genieSelector.module == "Any Code") {
          //console.log("Free plan");
          //console.log(localStorage.getItem("free_plan"));
          //console.log("subscription_level=" + localStorage.getItem("subscription_level"))
          const newvalue = updatedPromptsInfoData ? updatedPromptsInfoData[newValue] : "";


          if (newvalue != "SUMMARY" &&
            (localStorage.getItem("free_plan") != "1" || ((localStorage.getItem("subscription_level") != "level_1"
              || localStorage.getItem("subscription_level") != "level_3") && localStorage.getItem("subscription_level") != ""))) {
            const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
            // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
            console.log("storedAllSet -- 3", storedAllSet);
            if (storedAllSet)
              setOpenPaidModalAnyCode(true);
            else
              toast.success('Pls. wait, we are working in background.');
            valid = false;
          }
        }

        else {
          const newvalue = updatedPromptsInfoData ? updatedPromptsInfoData[newValue] : "";
          if (newvalue === "COMPLETE AUDIT") {
            //debugger
            const messages = Object.keys(genieSelector.msg)
              .slice(1, 10)
              .map(key => `\n${key} : \n${genieSelector.msg[key]}`)
              .join('\n');
          }
        }

      }
    }

    if (valid) {
      setValue(newValue);
      if (updatedPromptsInfoData) {
        const newVal = updatedPromptsInfoData[newValue];
        if (newVal) {
          dispatch(setPromptName(newVal));
          // setCompleteAudit(genieSelector.msg);
        }
      }
    }

  };

  interface EmploymentHistory {
    "Job Title": string;
    "Company": string;
    "Period Of Work": string;
    "Responsibilities": string[];
  }

  interface EducationHistory {
    "Institution Name": string;
    "Degree": string;
    // "Period of Study": string;
  }

  interface PersonalDetails {
    "Name": string;
    "Position": string;
    "Email": string;
    "Phone": string;
    "City": string;
    "State": string;
    "Country": string;
    "Postal Code": string;
    "Address": string;
  }

  interface ConvertedData {
    employmentHistory: EmploymentHistory[];
    educationHistory: EducationHistory[];
    personalDetails: PersonalDetails;
    personalSummary: string;
    skills: string[];
  }
  const [EmploymentHistoryJSON, setEmploymentHistoryJSON] = useState<any>([]);

  const handleSave = async (user_id: string) => {
    try {
      // debugger

      /* Generated Content */
      const strToArr = (str: string): Record<string, string | string[]>[] => {
        const detailsArr = str.split('##').filter(Boolean);
        const array: Record<string, string | string[]>[] = [];

        for (const details_item of detailsArr) {
          let details: string[] = details_item.split('\n').filter(Boolean);
          const object: Record<string, string | string[]> = {};
          details.forEach((detail, index) => {
            const [key, ...values] = detail.split(':').map((item: string) => item.trim());
            const value = values.join(':').trim(); // Rejoin values in case the value itself contains ':'

            if (key === "Responsibilities") {
              // Handle responsibilities as an array
              const responsibilities: string[] = [];
              for (let i = index + 1; i < details.length && details[i].startsWith('-'); i++) {
                responsibilities.push(details[i].replace(/^-/, '').trim());
              }
              if (responsibilities.length > 0) {
                object[key] = responsibilities;
              }
            } else if (!detail.startsWith('-')) {
              // Exclude lines starting with hyphen from the top-level array
              object[key] = value;
            }
          });

          if (Object.keys(object).length > 0) {
            array.push(object);
          }
        }

        return array;
      };

      const strToObj = (str: string): Record<string, string> => {
        const details = str.split('##').filter(Boolean);
        const object: Record<string, string> = {};

        details.forEach((detail) => {
          const [key, value] = detail.split(':').map((item) => item.trim());
          object[key] = value;
        });
        return object;
      };


      const obj: Record<string, unknown> = {

        "PERSONAL DETAILS": localStorage_msg["PERSONAL DETAILS"] !== "" ? strToObj(localStorage_msg["PERSONAL DETAILS"]) : "",
        "PROFESSIONAL SUMMARY": localStorage_msg["PROFESSIONAL SUMMARY"] !== "" ? localStorage_msg["PROFESSIONAL SUMMARY"] : "",
        "EMPLOYMENT HISTORY": localStorage_msg["EMPLOYMENT HISTORY"] !== "" ? strToArr(localStorage_msg["EMPLOYMENT HISTORY"]) : "",
        "EDUCATION": localStorage_msg["EDUCATION"] !== "" ? strToArr(localStorage_msg["EDUCATION"]) : "",
        "SKILLS": localStorage_msg["SKILLS"] !== "" ? localStorage_msg["SKILLS"] : "",
      };

      // const obj: Record<string, unknown> = {
      //   // "PERSONAL DETAILS": localStorage_msg["PERSONAL DETAILS"] !== "" ? strToObj(JSON.parse(localStorage_msg["PERSONAL DETAILS"])["msg"]) : "",
      //   // "PROFESSIONAL SUMMARY": localStorage_msg["PROFESSIONAL SUMMARY"] !== "" ? JSON.parse(localStorage_msg["PROFESSIONAL SUMMARY"])["msg"] : "",
      //   // "EMPLOYMENT HISTORY": localStorage_msg["EMPLOYMENT HISTORY"] !== "" ? strToArr(JSON.parse(localStorage_msg["EMPLOYMENT HISTORY"])["msg"]) : "",
      //   // "EDUCATION": localStorage_msg["EDUCATION"] !== "" ? strToArr(JSON.parse(localStorage_msg["EDUCATION"])["msg"]) : "",
      //   // "SKILLS": localStorage_msg["SKILLS"] !== "" ? JSON.parse(localStorage_msg["SKILLS"])["msg"] : "",
      // };

      const obj1: Record<string, unknown> = {
        "COVER LETTER": localStorage_msg["COVER LETTER"] !== "" ? localStorage_msg["COVER LETTER"] : ""
      };


      // const obj1: Record<string, unknown> = {
      //   //"COVER LETTER": localStorage_msg["COVER LETTER"] !== "" ? JSON.parse(localStorage_msg["COVER LETTER"])["msg"] : ""
      // };

      // if (resumeContent !== "" || coverContent !== "") {
      //   const savedresumecover = await saveResumeCoverMutation({
      //     user_id: user_id,
      //     is_type: resumeTab ? "resume" : "cover",
      //     input_method: localStorage.getItem("input_method") ?? "text",
      //     content: isEditing ? (coverTab ? editedCover : content) : (resumeTab ? obj : obj1),
      //     formatted_data: resumeTab ? resumeContent : coverContent,
      //     original_data: resumeTab ? code : jobDescp
      //   });

      //   if ('error' in savedresumecover) {
      //     console.error('Error saving content:', savedresumecover.error);
      //   } else if (savedresumecover.data && savedresumecover.data.status === "success") {
      //     toast.success(savedresumecover.data.message);
      //     if (resumeTab) {
      //       localStorage.setItem('doc_id', savedresumecover.data.document_id);
      //       handleResumeExportClick();

      //     } else {
      //       localStorage.setItem('cover_doc_id', savedresumecover.data.document_id);
      //       handleCoverExportClick();
      //     }
      //     fetchData();
      //   } else {
      //     console.error('Something went wrong:', savedresumecover.data?.message);
      //   }
      //   console.log('savedresumecover=>', savedresumecover);
      // } else {
      //   toast.error("First Create Resume");
      // }

      if (resumeContent !== "") {
        const savedresumecover = await saveResumeCoverMutation({
          user_id: user_id,
          is_type: "resume",
          input_method: localStorage.getItem("input_method") ?? "text",
          content: isEditing ? (coverTab ? editedCover : content) : (resumeTab ? obj : obj1),
          formatted_data: resumeContent,
          original_data: code
        });

        if ('error' in savedresumecover) {
          console.error('Error saving content:', savedresumecover.error);
        } else if (savedresumecover.data && savedresumecover.data.status === "success") {
          toast.success(savedresumecover.data.message);
          localStorage.setItem('doc_id', savedresumecover.data.document_id);

          fetchData();
        } else {
          console.error('Something went wrong:', savedresumecover.data?.message);
        }
        console.log('savedresumecover=>', savedresumecover);
      } else {
        toast.error("First Create Resume");
      }


      if (coverContent !== "") {
        const savedresumecover = await saveResumeCoverMutation({
          user_id: user_id,
          is_type: "cover",
          input_method: localStorage.getItem("input_method") ?? "text",
          content: isEditing ? (coverTab ? editedCover : content) : (resumeTab ? obj : obj1),
          formatted_data: coverContent,
          original_data: jobDescp
        });

        if ('error' in savedresumecover) {
          console.error('Error saving content:', savedresumecover.error);
        } else if (savedresumecover.data && savedresumecover.data.status === "success") {
          toast.success(savedresumecover.data.message);

          localStorage.setItem('cover_doc_id', savedresumecover.data.document_id);

          fetchData();
        } else {
          console.error('Something went wrong:', savedresumecover.data?.message);
        }
        console.log('savedresumecover=>', savedresumecover);
      } else {
        toast.error("First Create Resume");
      }


    } catch (error) {
      console.error('Error saving content:', error);
    }
  }


  //combined all the prompts key and value excluded cover letter for ResumeTab
  const displayRanges = (start: number, end: number): string => {
    // console.log(localStorage_msg)
    return Object.entries(localStorage_msg)
      .filter(([key]) => key !== 'COVER LETTER') // Exclude cover letter 
      .slice(start, end)
      .map(([key, value], index, array) => {
        try {
          if (typeof value === 'string' && value.trim() !== '') {
            // If the value is a string, have to parse it as JSON
            const parsedValue = JSON.parse(value);
            // console.log('parse value=>', parsedValue);
            // Check if the parsed value has a msg property
            const msg = parsedValue.msg || parsedValue || value;
            // Replaced ## as the end of each line
            const formattedMsg = msg/*.replace(/##$/gm, '')*/;
            const separator = index < array.length - 1 ? '\n------\n' : '\n';
            return `\n${key} : \n${formattedMsg}${separator}`;
          } else if (typeof value === 'object' && value !== null && 'msg' in value) {
            // If the value is an object with msg property, extract msg
            const separator = index < array.length - 1 ? '\n------\n' : '\n';
            return `\n${key} : \n${(value as { msg?: string }).msg}${separator}`;
          } else {
            //other cases as needed
            const separator = index < array.length - 1 ? '\n------\n' : '\n';
            return `\n${key} : \n${JSON.stringify(value)}${separator}`;
          }
        } catch (error) {
          console.error(`Error parsing JSON for key '${key}' and value ${value}:`, error);
          return "";
        }
      })
      .join('\n');
  };

  // console.log('display ranges=>', displayRanges);

  // 

  //separated a cover letter prompt for coverTab
  const displayCoverLetterContent = (): string => {
    const coverLetterEntry = Object.entries(localStorage_msg)
      .find(([key]) => key === 'COVER LETTER');

    if (coverLetterEntry) {
      const [, value] = coverLetterEntry;

      try {
        if (typeof value === 'string' && value.trim() !== '') {
          const parsedValue = JSON.parse(value);
          const msg = parsedValue.msg || parsedValue || value;
          const formattedMsg = msg.replace(/##$/gm, '');
          return `\nCOVER LETTER : \n${formattedMsg}`;
        } else if (typeof value === 'object' && value !== null && 'msg' in value) {
          return `\nCOVER LETTER : \n${(value as { msg?: string }).msg}`;
        } else {
          return `\nCOVER LETTER : \n${JSON.stringify(value)}`;
        }
      } catch (error) {
        console.error(`Error parsing JSON for key 'COVER LETTER':`, error);
        return ``;
      }
    } else {
      return '';
    }
  };
  // 

  //conditionally rendering a result based on Tabs
  // const displayedContent = resumeTab ? displayRanges(0, 6) : displayCoverLetterContent();

  useEffect(() => {
    if (resumeBoxRef.current) {
      //resumeBoxRef.current.scrollTop = resumeBoxRef.current.scrollHeight;
    }
    console.log("CreatingResume..........................1");
    console.log(localStorage.getItem("CreatingResume"));
    console.log(localStorage.getItem('resumeCompleted'));
    console.log(localStorage.getItem('resumeSave'));
    if (localStorage.getItem("CreatingResume") == 'true' &&
      localStorage.getItem("resumeCompleted") == 'true' && localStorage.getItem("resumeSave") == 'false') {
      localStorage.setItem('resumeSave', 'true');
    }
    if (localStorage.getItem("CreatingResume") == 'true' &&
      localStorage.getItem("resumeCompleted") == 'true' && localStorage.getItem("resumeSave") == 'true') {

      let userId = localStorage.getItem('user_id');

      if (userId !== null) {
        handleSave(userId);
        settabBButtonColor('#0076ad');
        settabBButtonbgColor('#fff');
        const buttonElement = document.querySelector('button.dropdown-toggle.btn');
        if (buttonElement) {
          // Change style properties dynamically
          buttonElement.classList.remove('btn-primary');
          buttonElement.classList.add('btn-primary-1');

        }
      }
      localStorage.setItem('CreatingResume', 'false');
      localStorage.setItem('resumeCompleted', 'false');
      localStorage.setItem('resumeSave', 'false');
      localStorage.setItem('resumeProcessed', 'true');
    }

  }, [localStorage.getItem("resumeCompleted"), localStorage.getItem("resumeSave")])

  const [content, setContent] = useState<Record<string, string | string[]>[]>([]);
  const [editedCover, setEditedCover] = useState<Record<string, unknown>>({});
  const [copyEditedContent, setCopyEditedContent] = useState<string>();

  const toggleColor = () => {
    const buttonElement = document.querySelector('button.dropdown-toggle.btn');
    if (buttonElement && localStorage.getItem("resumeProcessed") == 'true') {
      // Change style properties dynamically
      settabBButtonColor('#0076ad');
      settabBButtonbgColor('#fff');
      buttonElement.classList.remove('btn-primary');
      buttonElement.classList.add('btn-primary-1');

    }
  }

  const handleContentChange = (e: React.FormEvent<HTMLPreElement>) => {
    const currentTarget = e.currentTarget as unknown;
    const updatedContent = (currentTarget as HTMLDivElement)?.innerHTML;

    setCopyEditedContent(updatedContent);
    // setContent(updatedContent);
    // console.log(content);

    // Split the updated content using '------'

    const strToArr = (str: string): Record<string, string | string[]>[] => {
      const detailsArr = str.split('##').filter(Boolean);
      const array: Record<string, string | string[]>[] = [];

      for (const details_item of detailsArr) {
        let details: string[] = details_item.split('\n').filter(Boolean);
        const object: Record<string, string | string[]> = {};

        details.forEach((detail, index) => {
          const [key, ...values] = detail.split(':').map((item: string) => item.trim());
          const value = values.join(':').trim();

          if (key === "Responsibilities") {
            // Handle responsibilities as an array
            const responsibilities: string[] = [];
            for (let i = index + 1; i < details.length && details[i].startsWith('-'); i++) {
              responsibilities.push(details[i].replace(/^-/, '').trim());
            }
            if (responsibilities.length > 0 && key) {
              object[key] = responsibilities;
            }
          } else if (!detail.startsWith('-') && key) {
            // Exclude lines starting with hyphen from the top-level array
            object[key] = value;
          }
        });

        if (Object.keys(object).length > 0) {
          array.push(object);
        }
      }

      return array;
    };

    const strToObj = (str: string): Record<string, string> => {
      const details = str.split('##').filter(Boolean);
      const object: Record<string, string> = {};

      details.forEach((detail) => {
        const [key, value] = detail.split(':').map((item) => item.trim());
        object[key] = value;
      });
      return object;
    };

    let personal_details: string | undefined = updatedContent.split('PERSONAL DETAILS :').pop()?.split('------').shift();
    let professional_summary: string | undefined = updatedContent.split('PROFESSIONAL SUMMARY :').pop()?.split('------').shift();
    let employment_history: string | undefined = updatedContent.split('EMPLOYMENT HISTORY :').pop()?.split('------').shift();
    let education_: string | undefined = updatedContent.split('EDUCATION :').pop()?.split('------').shift();
    let skills_: string | undefined = updatedContent.split('SKILLS :').pop()?.split('------').shift();
    let cover_letter: string | undefined = updatedContent.split('COVER LETTER :').pop();

    const updatedContentArray: Record<string, unknown> = {
      "PERSONAL DETAILS": personal_details ? strToObj(personal_details) : "",
      "PROFESSIONAL SUMMARY": professional_summary ? professional_summary : "",
      "EMPLOYMENT HISTORY": employment_history ? strToArr(employment_history) : "",
      "EDUCATION": education_ ? strToArr(education_) : "",
      "SKILLS": skills_ ? skills_ : "",
    };

    const updatedContentCover: Record<string, unknown> = {
      "COVER LETTER": cover_letter ? cover_letter : ""
    };

    setContent(prevState => ({
      ...prevState,
      ...updatedContentArray,
    }));

    setEditedCover({ ...updatedContentCover });
    /* setContent((prevState: Record<string, string | string[]>[]) =>
      coverTab
        ? { ...prevState, ...updatedContentCover }
        : { ...prevState, ...updatedContentArray }
    ); */

    /* setEditedCover(prevState => ({
      ...prevState,
      ...updatedcovercontent,
    })); */

    // console.log(updatedContentArray);
  };

  const handleDeleteCoverLetter = async (data: string) => {
    //console.log(data);
    try {
      let user_id = localStorage.getItem("user_id")
      const res = (await axios.delete(`${baseUrl}aiResume/resume/delete/${data}?user_id=${user_id}`))
      if (res?.data?.status === "success") {
        const response = await axios.get(`${baseUrl}aiResume/getDocument?user_id=${user_id}&is_type=cover`);
        const result = response.data?.data;
        //console.log(result);
        if (Array.isArray(result)) {
          setSavedCoverLettersData((result as ResumeData[]));
        } else {
          setSavedCoverLettersData([]);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteResume = async (data: string) => {
    alert('Sure, You want to delete this resume?');
    try {
      let user_id = localStorage.getItem("user_id")
      const res = (await axios.delete(`${baseUrl}aiResume/resume/delete/${data}?user_id=${user_id}`))
      if (res?.data?.status === "success") {
        const response = await axios.get(`${baseUrl}aiResume/getDocument?user_id=${user_id}`);
        const result = response.data?.data;
        //console.log(result);
        if (Array.isArray(result)) {
          setSavedResumesData((result as ResumeData[]));
        } else {
          setSavedResumesData([]);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }


  const handleResumeExportClick = () => {
    axios.post(baseUrl + `aiResume/end_resume_timer/${user_id}`).then(response => {
      //console.log(response);
      console.log('Ended resume timer successfull!!');
    }).catch(error => {
      console.log('Oops!!Error on ending resume timer...');
    })
  }

  const handleCoverExportClick = () => {
    axios.post(baseUrl + `aiResume/end_cover_timer/${user_id}`).then(response => {
      console.log('Ended cover timer successfully!!');
    }).catch(error => {
      console.log('Oops!!Error on ending cover timer...');
    })
  }


  useEffect(() => {
    if (localStorage.getItem('Tab') === 'Resume') {
      // axios.post(baseUrl + `aiResume/start_resume_timer/${user_id}`).then(response => {
      //   console.log('Resume timer started successfully!!');
      // }).catch(error => {
      //   console.log('Oops!!Error on starting resume timer...');
      // })
    } else {

    }
  })

  /* const [viewSavedreslocal, setViewSavedresLocal] = useState<any>(localStorage.getItem('View Saved Resume') === 'true'); 
  useEffect(() => {
    if (viewSavedreslocal){
      setShowTemplates(true);
    }
  }, [viewSavedreslocal]); */
  /* useEffect(() => {
    window.addEventListener('storage', (event) => {
      if (event.key === 'View Saved Resume') {
         setShowTemplates(true);
         console.log('show temp val =>', showTemplates);
      }
  });
  }); */




  return (
    <>
      {/* <!-- Column 2 --> */}
      <div className="col-lg right">
        {/* <!-- Tabs --> */}
        {/* <ul id="tabs" className="row nav mb-2 nav-tabs left-tabs" role="tablist">
          <li className="nav-item col-md-6 bg" onClick={() => handleResumeTab()}>
            <a id="tab-A" href="#pane-AAA" className="nav-link active" data-toggle="tab" role="tab">RESUME
            </a>
          </li>
          <li className="nav-item col-md bg" onClick={() => handleCoverTab()}>
            <a id="tab-B" href="#pane-BBB" className="nav-link" data-toggle="tab" role="tab">COVER LETTER</a>
          </li>
        </ul> */}
        {/* <ul id="tabs" className="row nav nav-tabs right-tabs" role="tablist">
          <li className="nav-item col-md-6 bg" style={{ cursor: 'pointer' }} onClick={() => setShowTemplates(true)}>
            <a id="tab-A" className="nav-link">View Saved Resumes </a>
          </li>
          <li className="nav-item col-md bg m" style={{ cursor: 'pointer' }} onClick={() => setShowSavedResumes(true)}>
            <a id="tab-B" className="nav-link">View Saved CoverLetters</a>
          </li>
        </ul> */}
        {/* <div className="row nav nav-tabs left-tabs" style={{ textAlign: 'center', alignItems:'baseline', padding: '1% 0px' }}> */}
        {/* <div className='col-md-3 col-xxl-3' style={{textAlign: 'center', alignItems: 'center'}}>
          <a id="tab-A" href={`#${activePane}`} className={!coverContent ? "" : "nav-link"} data-toggle="tab" role="tab" style={{ cursor: !coverContent ? 'not-allowed' : 'pointer', display: 'inline-block',  color: 'blue', textDecoration: 'underLine' }} >
          <Button id="tab-B-Button" variant="outlined"  onClick={!coverContent ? undefined : () => handleCoverTab()} style={{cursor: !coverContent ? 'not-allowed' : 'pointer', minWidth: '60px', margin: '1.4% 0%', borderRadius: '4px', padding: '17px 10px', boxShadow: 'none', lineHeight: 'inherit', color: tabBbuttonColor , background: tabBbuttongbColor, border:"none" }}>{activePane === "pane-BBB" ? <>Step 5 <br></br> Show Resume</> : <>Step 5 <br></br> Show Cover Letter</>}</Button>
          </a>
          </div> */}

        {/* <div className='col-md-3 col-xxl-3' style={{textAlign: 'center', alignItems: 'center', marginRight: '25%'}}>
          <a id="tab-A" className="nav-link bg" style={{ textAlign:'center', alignContent:'center', cursor: 'not-allowed', display: 'inline-block', color: 'blue', textDecoration: 'underLine', background:"#fff" }}>
            
            <Dropdown drop="up" className="custom-dropdown-menu" onBlur={()=>{toggleColor()}} style={{ justifyContent: "center", alignItems: "center", textDecoration: 'none', color: tabBbuttonColor, border: 'none', background: tabBbuttongbColor, fontWeight: 500, fontSize: '14px', height: '100%',cursor: 'not-allowed'  }}>
            
              <Dropdown.Toggle   onMouseOut={() =>{toggleColor()}} onBlur={() =>{toggleColor()}}  style={{ width: '100%', height: '100%' }}>
              <span>Step 6<br></br></span>Download
                <Dropdown.Menu style={{ zIndex: 999,  bottom: '100%', transform:  'translate3d(0px, 32.2px, 0px) !important'}}> 
                  <Dropdown.Item onClick={() => {handleOptionSelect('pdf');}} style={{ fontSize: "14px", fontWeight: "400" }}>PDF</Dropdown.Item>
                  <Dropdown.Item onClick={() => {handleOptionSelect('word'); }} style={{ fontSize: "14px", fontWeight: "400" }}>MS Word</Dropdown.Item>
                  <Dropdown.Item onClick={() => {handleOptionSelect('googledocs'); }} style={{ fontSize: "14px", fontWeight: "400" }}>Google Docs</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown.Toggle>

            </Dropdown>
          </a>
          </div> */}

        {/*  <div className='col-md-3 col-xxl-3' style={{textAlign: 'center', alignItems: 'center'}}>
          <a id="tab-A" className="nav-link bg" style={{ background:"#fff", display: 'inline-block',   textDecoration: 'underLine',  margin:'-1% 0%', cursor:'not-allowed' }} onClick={() => setShowTemplates(true)} >
           <Button variant="outlined" style={{ backgroundColor:'#fff !important', minWidth: '60px', margin: '1% 0%', borderRadius: '4px', padding: '0px 10px', boxShadow: 'none', paddingTop: '10%', paddingBottom: '10%' , border:'none'}}>View Saved Resumes</Button>
          </a>
          </div> */}

        {/* <a id="tab-B" className="nav-link bg" style={{ cursor: 'pointer', display: 'inline-block', width: '33%', color: 'blue', textDecoration: 'underLine' }} onClick={() => setShowSavedResumes(true)}>
            View Saved CoverLetters
          </a> */}
        {/* <a id="tab-B" href={`#${activePane}`} "#pane-BBB" className="nav-link" data-toggle="tab" role="tab" onClick={() => handleCoverTab()} style={{ cursor: 'pointer', display: 'inline-block', width: '33%', color: 'blue', textDecoration: 'underLine' }}>
          
          </a> */}
        {/* </div> */}

        <div id="content" className="tab-content mt-3" role="tablist">
          {/* {!showCoverLetter ? ( */}
          <div id="pane-AAA" className={`card tab-pane fade ${activePane === "pane-AAA" ? "show active" : ""}`} /* className="card tab-pane fade show active" */ role="tabpanel" aria-labelledby="tab-A">
            <div className="card-header" role="tab" id="heading-A">
              <h5 className="mb-0">
                {/* <!-- Note: `data-parent` removed from here --> */}
                <a data-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
                  Accordion 01
                </a>
              </h5>
            </div>

            {/* <!-- Note: New place of `data-parent` --> */}
            <div className="demo-resume p-2">
              <div className="d-flex justify-content-between">
                {/* <button className="btn" style={{ color: "#0076ad" }} onClick={handleEditClick}>Edit</button> */}
                <Box sx={{ display: 'flex', alignContent: 'center' }}>
                  {loadingCreateResume &&
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                      {/* <CircularProgress size={22} style={{ marginTop: '12px', marginLeft: '7px' }} /> */}
                      <div className='loader-spiner' style={{ marginTop: '20px', marginLeft: '15px' }}></div>
                      <span style={{ marginLeft: '22px', marginTop: '12px', color: '#7CB9E8' }}>Processing...</span>
                    </div>
                  }
                </Box>
                <div className="d-flex justify-content-end">
                  {/* <button className="btn btn-outline-info" style={{ marginRight: '12px' }} onClick={() => {
                    const userId = localStorage.getItem('user_id');
                    if (userId !== null) {
                      handleSave(userId);
                    } else {
                      console.error('User ID is null');
                    }
                  }}>Save</button> */}
                  {/*  <Dropdown className="btn btn-outline-info" style={{ padding: "10px 5px", color: "inherit" }} onClick={() => setShowModal(true)}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5 14H3.5C3.36739 14 3.24021 13.9473 3.14645 13.8536C3.05268 13.7598 3 13.6326 3 13.5V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H9.5L13 5.5V13.5C13 13.6326 12.9473 13.7598 12.8536 13.8536C12.7598 13.9473 12.6326 14 12.5 14Z"
                        stroke="#292C33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.5 2V5.5H13" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                      <path d="M6.25 9.25L8 7.5L9.75 9.25" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                      <path d="M8 11.5V7.5" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                    </svg>
                    &nbsp; Change Design
                  </Dropdown> */}
                  {/* </Dropdown.Toggle> */}

                  {/*  <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleOptionSelect('pdf')} style={{ fontSize: "14px", fontWeight: "400" }}>{export_pdf}</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleOptionSelect('word')} style={{ fontSize: "14px", fontWeight: "400" }}>{export_word}</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleOptionSelect('googledocs')} style={{ fontSize: "14px", fontWeight: "400" }}>Google Docs</Dropdown.Item>
                    </Dropdown.Menu> */}


                  {/* <button style={{ marginLeft: '10px' }} className="btn btn-outline-info"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      if (isPaid == null && genieSelector.module == "SMART CONTRACTS") {
                        setOpenPaidModal1(true);
                      }
                      else
                        setAnchorEl(anchorEl ? null : event.currentTarget);
                    }}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5 14H3.5C3.36739 14 3.24021 13.9473 3.14645 13.8536C3.05268 13.7598 3 13.6326 3 13.5V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H9.5L13 5.5V13.5C13 13.6326 12.9473 13.7598 12.8536 13.8536C12.7598 13.9473 12.6326 14 12.5 14Z"
                        stroke="#292C33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.5 2V5.5H13" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                      <path d="M6.25 9.25L8 7.5L9.75 9.25" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                      <path d="M8 11.5V7.5" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                    </svg>
                    Export
                  </button>
                  <Popper
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    style={{
                      backgroundColor: '#E6F1F7', borderRadius: '4px',
                      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
                      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))'
                    }}
                  >
                    <MenuItem style={{ color: '#0076AD' }}
                      onClick={() => handleOptionSelect('pdf')}>
                      {export_pdf}
                    </MenuItem>
                    <MenuItem style={{ color: '#0076AD' }} onClick={() => handleOptionSelect('word')}>
                      {export_word}
                    </MenuItem>
                    <MenuItem style={{ color: '#0076AD' }} onClick={() => handleOptionSelect('GoogleDocs')}>
                      Google Docs
                    </MenuItem>
                  </Popper> */}
                </div>
              </div>
              {/* <hr /> */}

              <Box
                component="pre"
                sx={(theme) => ({
                  whiteSpace: "pre-wrap",
                  overflow: "auto",
                  height: "600px",
                  "&.MuiBox-root::-webkit-scrollbar": {
                    width: 4,
                  },
                  "&.MuiBox-root::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.divider,
                    borderRadius: 4,
                  },
                  "&.MuiBox-root::-webkit-scrollbar-track": {
                    backgroundColor:
                      theme.palette.mode === "dark" ? "#2D2D2D" : "#fff",
                  },
                })}
                ref={resumeBoxRef}
                contentEditable={isEditing}
                onInput={(e) => handleContentChange(e)}
                dangerouslySetInnerHTML={{ __html: htmlResumeContent }}
              // dangerouslySetInnerHTML={{ __html: resumeContent }}
              />
            </div>
          </div>
          {/* ) : ( */}
          <div id="pane-BBB" className={`card tab-pane fade ${activePane === "pane-BBB" ? "show active" : ""}`} /* className="card tab-pane fade" */ role="tabpanel" aria-labelledby="tab-B">
            <div className="card-header" role="tab" id="heading-B">
              <h5 className="mb-0">
                <a className="collapsed" data-toggle="collapse" href="#collapse-B" aria-expanded="false"
                  aria-controls="collapse-B">
                  Accordion 02
                </a>
              </h5>
            </div>

            <div className="demo-resume p-2">
              <div className="d-flex justify-content-between" >
                {/* <button className="btn" style={{ color: "#0076ad" }} onClick={handleEditClick}>Edit</button> */}
                <Box sx={{ display: 'flex', alignContent: 'center' }}>
                  {loadingCreateResume &&
                    <span style={{ display: 'flex', alignContent: 'center' }}>
                      {/* <CircularProgress size={22} style={{ marginTop: '12px', marginLeft: '7px' }} /> */}
                      <div className='loader-spiner' style={{ marginTop: '20px', marginLeft: '15px' }}></div>
                      <span style={{ marginLeft: '22px', marginTop: '12px', color: '#7CB9E8' }}>Processing...</span>
                    </span>
                  }
                </Box>
                <div className="d-flex justify-content-end">
                  {/* <button className="btn btn-outline-info"
                    style={{ marginRight: '12px' }}
                    onClick={() => {
                      const userId = localStorage.getItem('user_id');
                      if (userId !== null) {
                        handleSave(userId);
                      } else {
                        console.error('User ID is null');
                      }
                    }}>Save</button> */}
                  {/* <button className="btn btn-outline-info mx-2"
                    onClick={() => {
                      const textToCopy = isEditing ? copyEditedContent : displayedContent;
                      let sanitizedText: string;
                      if (typeof textToCopy === 'string') {
                        sanitizedText = textToCopy.replace(/<\/?[^>]+(>|$)/g, '');
                        navigator.clipboard.writeText(sanitizedText)
                          .then(() => {
                            clipboardState({ copied: true });
                          })
                          .catch((error) => {
                            console.error('Error copying to clipboard:', error);
                          });
                      }
                    }}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.5 11.5V2.5H4.5" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                      <path d="M11.5 4.5H2.5V13.5H11.5V4.5Z" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                    </svg>
                    &nbsp; Copy
                  </button> */}
                  {/* <Dropdown >
                    <Dropdown.Toggle variant="outline-info" className="btn btn-outline-info" style={{ padding: "10px 5px", color: "inherit" }} >
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.5 14H3.5C3.36739 14 3.24021 13.9473 3.14645 13.8536C3.05268 13.7598 3 13.6326 3 13.5V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H9.5L13 5.5V13.5C13 13.6326 12.9473 13.7598 12.8536 13.8536C12.7598 13.9473 12.6326 14 12.5 14Z"
                          stroke="#292C33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.5 2V5.5H13" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round" />
                        <path d="M6.25 9.25L8 7.5L9.75 9.25" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round" />
                        <path d="M8 11.5V7.5" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round" />
                      </svg>
                      &nbsp; Export
                    </Dropdown.Toggle> */}

                  {/* <Dropdown.Menu> */}
                  {/*<Dropdown.Item onClick={() => handleCoverOptionSelect('pdf')} style={{ fontSize: "14px", fontWeight: "400" }}>{export_pdf}</Dropdown.Item>*/}
                  {/* <Dropdown.Item onClick={() => handleCoverOptionSelect('word')} style={{ fontSize: "14px", fontWeight: "400" }}>{export_word}</Dropdown.Item> */}
                  {/* <Dropdown.Item onClick={() => handleCoverOptionSelect('googledocs')} style={{ fontSize: "14px", fontWeight: "400" }}>Google Docs</Dropdown.Item> */}
                  {/* </Dropdown.Menu> */}
                  {/* </Dropdown> */}
                  {/* <button className="btn btn-outline-info"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      if (isPaid == null && genieSelector.module == "SMART CONTRACTS") {
                        setOpenPaidModal1(true);
                      }
                      else
                        setAnchorE2(anchorE2 ? null : event.currentTarget);
                    }}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5 14H3.5C3.36739 14 3.24021 13.9473 3.14645 13.8536C3.05268 13.7598 3 13.6326 3 13.5V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H9.5L13 5.5V13.5C13 13.6326 12.9473 13.7598 12.8536 13.8536C12.7598 13.9473 12.6326 14 12.5 14Z"
                        stroke="#292C33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.5 2V5.5H13" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                      <path d="M6.25 9.25L8 7.5L9.75 9.25" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                      <path d="M8 11.5V7.5" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                    </svg>
                    &nbsp; Export
                  </button>
                  <Popper
                    anchorEl={anchorE2}
                    open={Boolean(anchorE2)}
                    style={{
                      backgroundColor: '#E6F1F7', borderRadius: '4px',
                      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
                      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))'
                    }}
                  >
                    <MenuItem style={{ color: '#0076AD' }}
                      onClick={() => handleCoverOptionSelect('pdf')}>
                      {export_pdf}
                    </MenuItem>
                    <MenuItem style={{ color: '#0076AD' }} onClick={() => handleCoverOptionSelect('word')}>
                      {export_word}
                    </MenuItem>
                    <MenuItem style={{ color: '#0076AD' }} onClick={() => handleCoverOptionSelect('GoogleDocs')}>
                      Google Docs
                    </MenuItem>
                  </Popper> */}
                </div>
              </div>
              {/* <hr /> */}

              <Box
                component="pre"
                sx={(theme) => ({
                  whiteSpace: "pre-wrap",
                  overflow: "auto",
                  height: "600px",
                  "&.MuiBox-root::-webkit-scrollbar": {
                    width: 4,
                  },
                  "&.MuiBox-root::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.divider,
                    borderRadius: 4,
                  },
                  "&.MuiBox-root::-webkit-scrollbar-track": {
                    backgroundColor:
                      theme.palette.mode === "dark" ? "#2D2D2D" : "#fff",
                  },
                })}
                ref={contentRef}
                contentEditable={isEditing}
                onInput={(e) => handleContentChange(e)}
                dangerouslySetInnerHTML={{ __html: coverContent }}
              />
            </div>
          </div>
          {/* )} */}
        </div>
      </div>


      {/* {isMobile ? (
        <TabPanel value={value} >
          <Stack gap="16px" height="100%" sx={{
            padding: 0,
            "& .MuiBox-root": {
              padding: 0,  
            },
          }}>
            <Stack flexDirection="row" sx={{
              padding: 0,
              "& .MuiBox-root": {
                padding: 0,  
              },
            }}>
              <Typography
                variant="h5"
                color="text.secondary"
                fontWeight="500"
                flexGrow={1}
              >
                Results
              </Typography>
              <Stack gap="16px" flexDirection="row" sx={{
                padding: 0,
                "& .MuiBox-root": {
                  padding: 0,  
                },
              }}>
                
                

                <CopyToClipboard
                  text={ 
                    
                    localStorage_msg && (localStorage_msg as any)[
                    genieSelector.prompt_name ? genieSelector.prompt_name : ""
                    ]
                  }
                  onCopy={() => clipboardState({ copied: true })}>
                  <IconButton>
                    <SvgIcon>
                      <CopyIcon />
                    </SvgIcon>
                  </IconButton>
                </CopyToClipboard>

                <Button
                  startIcon={
                    <SvgIcon>
                      <ExportIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="inherit"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    //console.log(genieSelector.module + "--" + isPaid)
                    if (isPaid == null && genieSelector.module == "SMART CONTRACTS") {
                      setOpenPaidModal1(true);
                    }
                    else
                      setAnchorEl(anchorEl ? null : event.currentTarget);
                  }}
                  sx={(theme) => ({
                    borderColor: theme.palette.divider,
                  })}
                >
                  Export
                </Button>
                <Popper
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  style={{
                    backgroundColor: '#373C47', borderRadius: '4px',
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
                    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))'
                  }}
                // onClose={() => setAnchorEl(null)}
                >
                  {export_check.includes("export_pdf") && (
                    <MenuItem
                      onClick={() => {
                        const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
                        if(storedAllSet)
                          exportFile({
                            doc_type: "pdf",
                            module: genieSelector?.module || "",
                            advice: localStorage_msg,
                          });
                        else
                          toast.success('Pls. wait, we are working in background.');
                      }}
                    >
                      {export_pdf}
                    </MenuItem>
                  )}
                  {export_check.includes("export_word") && (
                    <MenuItem
                      onClick={() => {
                        const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
                        if(storedAllSet)
                          exportFile({
                            doc_type: "docx",
                            module: genieSelector?.module || "",
                            advice: localStorage_msg,
                          });
                        else
                          toast.success('Pls. wait, we are working in background.');
                      }}
                    >
                      {export_word}
                    </MenuItem>
                  )}
                  {export_check.includes("export_text") && (
                    <MenuItem
                      onClick={() => {
                        const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
                        if(storedAllSet)
                          exportFile({
                            doc_type: "txt",
                            module: genieSelector?.module || "",
                            advice: localStorage_msg,
                          });
                        else
                          toast.success('Pls. wait, we are working in background.');
                      }}
                    >
                      {export_text}
                    </MenuItem>
                  )}
                </Popper>
              </Stack>
            </Stack>
            <Divider />
            <Box
              component="pre"
              sx={(theme) => ({
                whiteSpace: "pre-wrap",
                overflow: "auto",
                padding: 0,
                "& .MuiBox-root": {
                  padding: 0,  // Remove padding
                },
                width: "100%",
                height: "calc(100vh - 400px)",
                "&.MuiBox-root::-webkit-scrollbar": {
                  width: 4,
                },
                "&.MuiBox-root::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.divider,
                  borderRadius: 4,
                },
                "&.MuiBox-root::-webkit-scrollbar-track": {
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#2D2D2D" : "#fff",
                },
              })}
            >
              {
                localStorage.getItem('CompleteAudit') || localStorage_msg && (localStorage_msg as any)[
                genieSelector.prompt_name ? genieSelector.prompt_name : ""
                ]
              }
            </Box>
          </Stack>
        </TabPanel>
      ) : null} */}

      {/*<Button
          onClick={() => {
            setIsPopupOpen(true);
          }}
          variant="contained"
          sx={{ mr: 2, margin: '1%', float: 'right' }}
        >
          FeedBack
        </Button> */}
      {/* <LoadingButton
        startIcon={
          <SvgIcon>
            <FeedBackIcon />
          </SvgIcon>
        }
        sx={{
          background:
            "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
          marginLeft: 5,
          color: "white",
          paddingX: "20px",
          paddingY: "12px",
          borderRadius: "4px",
          width: "fit-content",
          float: 'right',
          margin: '2% 0%'
        }}
        onClick={() => {
          setIsPopupOpen(true);
        }}>
        Submit FeedBack
      </LoadingButton> */}
      {/* <Dropdown className="btn btn-outline-info" style={{
        background:
          "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
        marginLeft: 5,
        color: "white",
        padding: "10px 5px",
        borderRadius: "4px",
        width: "fit-content",
        float: 'right',
        margin: '2% 3% 2% 0%',
      }} onClick={() => setShowModal(true)}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.5 14H3.5C3.36739 14 3.24021 13.9473 3.14645 13.8536C3.05268 13.7598 3 13.6326 3 13.5V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H9.5L13 5.5V13.5C13 13.6326 12.9473 13.7598 12.8536 13.8536C12.7598 13.9473 12.6326 14 12.5 14Z"
            stroke="#292C33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9.5 2V5.5H13" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
          <path d="M6.25 9.25L8 7.5L9.75 9.25" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
          <path d="M8 11.5V7.5" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
        </svg>
        &nbsp; Change Design
      </Dropdown> */}
      <Modal open={isPopupOpen} ><Box sx={{
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transform: "translate(-50%, -50%)",
        width: isMobile ? "95%" : 600,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      }}><div style={{ padding: '3%' }}>{genieSelector.module == 'Any Code' ? "How satisfied are you with our Audit Code?" : (genieSelector.module == 'SMART CONTRACTS' ? "How satisfied are you with our Audit Contract?" : "How satisfied are you with Code Convert?")}</div>

        <div style={{ padding: '3%' }}>{ratings.map((rating, index) => (
          <Button key={index} style={{ backgroundColor: rating <= feedbackForm.rating ? "#187BC6" : '#FFC107', color: '#FFFF', margin: '5px', minWidth: '50px' }} onClick={() => handleRating(rating)}>{rating}</Button>

        ))}</div>

        <TextareaAutosize
          aria-label="Comments"
          minRows={3}
          placeholder="Enter your comments(Optional)"
          onChange={handleTextAreaChange}
          style={{ width: '100%', marginBottom: '10px' }}
        />
        <div style={{ paddingTop: '3%', width: '100%' }}>
          <Button
            onClick={() => {
              setIsPopupOpen(false);
            }}
            variant="contained"
            sx={{ mr: 2, /* backgroundColor: '#DC3545', */ float: 'right' }}
          >
            Close
          </Button>
          <Button variant="contained" sx={{ mr: 2,/*  backgroundColor: '#198754', */ float: 'right' }}
            onClick={handleFormSubmit}

          >
            Submit
          </Button></div></Box></Modal>

      <Modal
        open={openPaidModal}
        onClose={() => setOpenPaidModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <p id="child-modal-description">Sorry! you can see this only from a paid plan.</p>
          <Stack flexDirection="row">
            <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <PayNowButton></PayNowButton>
            </Box>

            <Button
              onClick={() => {
                // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
                setOpenPaidModal(false);
              }}
              variant="contained"
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>


      <Modal
        open={openPaidModalAnyCode}
        onClose={() => setOpenPaidModalAnyCode(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "80%" : 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <p id="child-modal-description">Sorry! you can see this after plan upgrade. Click on Pay Now/Upgrade Now button on top.</p>
          <Stack flexDirection="row">
            <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <PayNowButton></PayNowButton>
            </Box>

            <Button
              onClick={() => {
                // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
                setOpenPaidModalAnyCode(false);
              }}
              variant="contained"
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>


      <Modal
        open={openPaidModal1}
        onClose={() => setOpenPaidModal1(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <p id="child-modal-description">Sorry! you can do this only from a paid plan.</p>
          <Stack flexDirection="row">
            <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <PayNowButton></PayNowButton>
            </Box>

            <Button
              onClick={() => {
                // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
                setOpenPaidModal1(false);
              }}
              variant="contained"
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Dialog maxWidth="lg" open={showModal} onClose={handleCloseModal} style={{ width: "100%" }}>
        <Box sx={{ backgroundColor: "white", padding: "20px" }}>
          <div className="row">
            <h3 style={{ color: "#222", fontWeight: "bold", marginBottom: "20px" }}>Choose one template</h3>

            <div className="col-md-4">
              <label>
                <input
                  type="checkbox"
                  style={{ display: "none" }}
                  checked={selectedImage === 'template1'}
                  onChange={() => handleCheckboxChange('template1')} />
                <img src={ResumeTempImg1} alt="Image 1" style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template1' ? "2px solid #1B75D0" : "" }} />
              </label>
            </div>
            <div className="col-md-4">
              <label>
                <input style={{ display: "none" }} type="checkbox" onChange={() => handleCheckboxChange('template2')} />
                <img src={ResumeTempImg2} alt="Image 2" style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template2' ? "2px solid #1B75D0" : "" }} />
              </label>
            </div>
            <div className="col-md-4">
              <label>
                <input type="checkbox" style={{ display: "none" }} onChange={() => handleCheckboxChange('template3')} />
                <img src={ResumeTempImg3} alt="Image 3" style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template3' ? "2px solid #1B75D0" : "" }} />
              </label>
            </div>

            {/* <div className="col-md-4">
              <label>
                <input
                  type="radio"
                  name="exportOption"
                  value="pdf"
                  onChange={() => handleOptionSelect('pdf')}
                />
                Export as PDF
              </label>
            </div>
            <div className="col-md-4">
              <label>
                <input
                  type="radio"
                  name="exportOption"
                  value="word"
                  onChange={() => handleOptionSelect('word')}
                />
                Export as Word
              </label>
            </div>
            <div className="col-md-4">
              <label>
                <input
                  type="radio"
                  name="exportOption"
                  value="googledocs"
                  onChange={() => handleOptionSelect('googledocs')}
                />
                Export to Google Docs
              </label>
            </div> */}
          </div>
          <div style={{ textAlign: "end", margin: "10px 10px" }}>
            {/* <LoadingButton loading={DownloadLoading} style={{ backgroundColor: "#0076AD", color: "#FFF", padding: DownloadLoading ? "18px 50px" : "" }} variant="contained" sx={{ mr: "20px" }} onClick={handleDownload} disableElevation>
              {DownloadLoading ? "" : "Download"}
            </LoadingButton> */}
            <Button
              onClick={() => {
                setShowModal(false);
              }}
              variant="outlined"
              disableElevation
            >
              close
            </Button>
            <br></br>
          </div>
        </Box>
      </Dialog>


      <Dialog maxWidth="lg" open={showTemplates} className='MuiDialog-paperWidthLg' onClose={handleCloseModal} style={{ width: '100%' }}>

        <Box sx={{ backgroundColor: "white", padding: "20px" }}>
          <div >
            <div className="row justify-content-center">
              <h3 style={{ color: "#222", fontWeight: "bold", marginBottom: "20px", position: 'absolute', top: 0, left: 0, width: '100%', padding: '10px' }}>
                My Resume</h3>
            </div>
            <div style={{ textAlign: "end", margin: "10px 10px" }}>


              <Button variant="outlined" onClick={() => {
                if (!selectedResume) {
                  toast.error('Please select one resume');
                }
                else {
                  setShowModal(false);
                  addDataSaved(selectedResume?.original_data);
                  setResumeContent(selectedResume?.formatted_data);
                  sethtmlResumeContent(selectedResume?.formatted_data);
                  localStorage.setItem('doc_id', selectedResume?.document_id);
                  console.log("selectedResume?.document_id");
                  console.log(selectedResume?.document_id);
                  console.log(localStorage.getItem('doc_id'));
                  localStorage.setItem('downloadmode', 'saved');
                  try {
                    localStorage_msg['PERSONAL DETAILS'] = selectedResume?.content['PERSONAL DETAILS']
                    const name = selectedResume?.content['PERSONAL DETAILS']?.["Name"] ?? '';
                    localStorage.setItem(`resume_name_`, name);
                    localStorage_msg['PROFESSIONAL SUMMARY'] = selectedResume?.content['PROFESSIONAL SUMMARY']
                    localStorage_msg['EMPLOYMENT HISTORY'] = selectedResume?.content['EMPLOYMENT HISTORY']
                    localStorage_msg['EDUCATION'] = selectedResume?.content['EDUCATION']
                    localStorage_msg['SKILLS'] = selectedResume?.content['SKILLS']
                    localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg));
                    //console.log("prview call............4");
                    handleResumePreview("template1", "saved");

                    //setActivePane("pane-BBB");
                    //handleCoverTab();

                  } catch (err) {
                    console.log(err)
                  }
                  handleCloseModal();
                }
              }}
              >
                Select
              </Button>
              &nbsp; &nbsp;
              <Button variant="outlined" onClick={() => {

                handleCloseModal();
              }}
              >
                Close
              </Button>
            </div>
            <div className="row" style={{ marginTop: '50px', marginBottom: '30px', maxHeight: '80%', padding: '10' }}>
              <h6>Please select any one of the file.</h6>
              {savedResumesData && savedResumesData.map((data, index) => {
                const personalDetails = data?.content?.["PERSONAL DETAILS"];
                const firstName: string | undefined = personalDetails?.["Name"];
                const rawDate: string | undefined | null = data?.created_on;
                const newDate: string | undefined | null = `${rawDate?.substring(0, 10)} |  ${rawDate?.substring(11, 19)}`;
                const cleanedDate: string | undefined | null = newDate?.replace(/[^\w]|_/g, "").slice(0, -2);
                return (
                  <div key={index} className="card" style={{ marginBottom: '10px', border: selectedResume?._id === data._id ? "2px solid #1B75D0" : "" }} >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => setSelectedResume(data)}>
                      <p>{firstName ? firstName + '_Resume_' + cleanedDate : `New Resume${index + 1}_` + cleanedDate}</p>
                      <p>
                        {newDate}
                        <span onClick={() => { handleDeleteResume(data?._id) }}>
                          <HighlightOffRoundedIcon color='warning' style={{ marginLeft: '30px', marginRight: '10px' }} />
                        </span>
                      </p>
                    </div>
                  </div>
                )
              })
              }
            </div>
          </div>
          <div style={{ textAlign: "end", margin: "10px 10px" }}>
            <Button variant="outlined" onClick={() => {
              if (!selectedResume) {
                toast.error("Please select one resume");
              }
              else {
                setShowModal(false);
                addDataSaved(selectedResume?.original_data);
                setResumeContent(selectedResume?.formatted_data);
                sethtmlResumeContent(selectedResume?.formatted_data);
                localStorage.setItem('doc_id', selectedResume?.document_id);
                console.log("selectedResume?.document_id");
                console.log(selectedResume?.document_id);
                console.log(localStorage.getItem('doc_id'));
                localStorage.setItem('downloadmode', 'saved');
                try {
                  localStorage_msg['PERSONAL DETAILS'] = selectedResume?.content['PERSONAL DETAILS']
                  const name = selectedResume?.content['PERSONAL DETAILS']?.["Name"] ?? '';
                  localStorage.setItem(`resume_name_`, name);
                  localStorage_msg['PROFESSIONAL SUMMARY'] = selectedResume?.content['PROFESSIONAL SUMMARY']
                  localStorage_msg['EMPLOYMENT HISTORY'] = selectedResume?.content['EMPLOYMENT HISTORY']
                  localStorage_msg['EDUCATION'] = selectedResume?.content['EDUCATION']
                  localStorage_msg['SKILLS'] = selectedResume?.content['SKILLS']
                  localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg));
                  //console.log("prview call............5");
                  handleResumePreview("template1", "saved");

                  setActivePane("pane-BBB");
                  handleCoverTab();

                } catch (err) {
                  console.log(err)
                }
                handleCloseModal();
              }
            }}
            >
              Select
            </Button>
            &nbsp; &nbsp;
            <Button variant="outlined" onClick={() => {

              handleCloseModal();
            }}
            >
              Close
            </Button>
          </div>
        </Box>
      </Dialog>

      {/* <Dialog maxWidth="lg" open={showTemplates} onClose={handleCloseModal} style={{ width: "100%" }}>
        <Box sx={{ backgroundColor: "white", padding: "20px" }}>
          <div className="row">
            <h3 style={{ color: "#222", fontWeight: "bold", marginBottom: "20px" }}>Choose one template</h3>
            <div className="col-md-4">
              <label>
                <input
                  type="checkbox"
                  style={{ display: "none" }}
                  checked={selectedImage === 'template1'}
                  onChange={() => handleCheckboxChange('template1')} />
                <img src={ResumeTempImg1} alt="Image 1" style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template1' ? "2px solid #1B75D0" : "" }} />
              </label>
            </div>
            <div className="col-md-4">
              <label>
                <input style={{ display: "none" }} type="checkbox" onChange={() => handleCheckboxChange('template2')} />
                <img src={ResumeTempImg2} alt="Image 2" style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template2' ? "2px solid #1B75D0" : "" }} />
              </label>
            </div>
            <div className="col-md-4">
              <label>
                <input type="checkbox" style={{ display: "none" }} onChange={() => handleCheckboxChange('template3')} />
                <img src={ResumeTempImg3} alt="Image 3" style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template3' ? "2px solid #1B75D0" : "" }} />
              </label>
            </div>
          </div>
          <div style={{ textAlign: "end", margin: "10px 10px" }}>
      
            <Button
              onClick={() => {
                setShowModal(false);
              }}
              variant="outlined"
              disableElevation
            >
              Select
            </Button>
            <br></br>
          </div>
        </Box>
      </Dialog> */}

      <Dialog maxWidth="lg" className='MuiDialog-paperWidthLg' open={showSavedResumes} onClose={handleCloseModal} style={{ width: "100%" }}>
        <Box sx={{ backgroundColor: "white", padding: "20px", maxWidth: '600px' }}>
          <div >
            <div className="row justify-content-center">
              <h3 style={{ color: "#222", fontWeight: "bold", marginBottom: "20px", position: 'absolute', top: 0, left: 0, width: '100%', padding: '10px' }}>
                My Cover Letter</h3>
            </div>
            <div className="row" style={{ marginTop: '50px', marginBottom: '30px', maxHeight: '80%', padding: '10' }}>
              {savedCoverLettersData && savedCoverLettersData.map((data, index) => {
                const personalDetails = data?.content?.["PERSONAL DETAILS"];
                const firstName: string | undefined = savedResumesFirstName[index]/* personalDetails?.["Name"] */;
                const rawDate: string | undefined | null = data?.created_on;
                const newDate: string | undefined | null = `${rawDate?.substring(0, 10)}   ${rawDate?.substring(11, 19)}`;
                const cleanedDate: string | undefined | null = newDate?.replace(/[^\w]|_/g, "").slice(0, -2);
                return (
                  <div key={index} className="card" style={{ marginBottom: '10px', border: selectedCoverLetter?._id === data._id ? "2px solid #1B75D0" : "" }} >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={() => setSelectedCoverLetter(data)}>
                      <p>{firstName ? firstName + '_CoverLetter_' + cleanedDate : `New CoverLetter${index + 1}_` + cleanedDate}</p>
                      <p>
                        {newDate}
                        <span onClick={() => handleDeleteCoverLetter(data?._id)}>
                          <HighlightOffRoundedIcon color='warning' style={{ marginLeft: '30px', marginRight: '10px' }} />
                        </span>
                      </p>
                    </div>
                  </div>
                )
              })
              }
            </div>
          </div>

          <div style={{ textAlign: "end", margin: "10px 10px" }}>
            {/* <LoadingButton loading={DownloadLoading} style={{ backgroundColor: "#0076AD", color: "#FFF", padding: DownloadLoading ? "18px 50px" : "" }} variant="contained" sx={{ mr: "20px" }} onClick={handleDownload} disableElevation>
              {DownloadLoading ? "" : "Download"}
            </LoadingButton> */}
            <Button
              onClick={() => {
                setShowModal(false);
                setCoverContent(selectedCoverLetter?.formatted_data);
                localStorage.setItem('cover_doc_id', selectedCoverLetter?.document_id);
                setActivePane("pane-AAA");
                handleCoverTab();

                handleCloseModal();
              }}
              variant="outlined"
              disabled={!selectedCoverLetter}
              disableElevation
            >
              Select
            </Button>

            <br></br>
          </div>
        </Box>
      </Dialog>

    </>
  );
};

export default TotalPanel;