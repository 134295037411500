import React, { useEffect, useState } from 'react';
import "./Centri_CSS/dashboard.css";
import "../assets/css/bootstrap.min.css"
import logo from "../assets/image/logo-centri-life-white-color.png"
import Chart from 'chart.js/auto'; // Import Chart.js library
import flatpickr from 'flatpickr'; // Import Flatpickr library
import 'flatpickr/dist/flatpickr.min.css'; // Import Flatpickr styles

import { Editor } from '@tinymce/tinymce-react';
import vectorImg from "../assets/image/vector-1.svg";
import { TotalBuilts, TotalUsers, UserID, TotalUploadedFiles, TotalSpent, TotalSpentResume, ResumeUserPercentage, CoverUserPercentage, linkedIn,TotalResumeBuilder } from "../redux/api/types";
import { useAddAdditionalDetailsMutation, useAddContactInfoMutation, useAddEducatioMutation, useAddEmployeementHistoryMutation, useAddPersonalDetailsMutation, useAddProffesionalSummeryMutation, useAddSkillsMutation, useGetAdditionalDetailsMutation, useGetContactInfoMutation, useGetEducatioMutation, useGetEmployeementHistoryMutation, useGetPersonalDetailsMutation, useGetProffesionalSummeryMutation, useGetSkillsMutation, useTotalBuiltsMutation, useUserIdMutation, useTotalUploadedFilesMutation, useTotalManualUploadMutation, useTotalCountMutation, useTotalSpentMutation, useTotalSpentResumeMutation, useTotalSpentCoverMutation, useResumeUserPercentageMutation, useCoverUserPercentageMutation, useLinkedInCountMutation, useTotalResumeExportedMutation, useTotalCoverExportedMutation,useTotalResumeBuilderMutation,useTotalCoverLetterSavedMutation } from '../redux/api/manualApi';
import axios from 'axios';

function DashboardSection() {
  const blueChartRef = React.useRef<Chart | null>(null);
  const orangeChartRef = React.useRef<Chart | null>(null);
  const [ResumeFilter, setResumeFilter] = React.useState('6 Month');
  const [CoverFilter, setCoverFilter] = React.useState('6 Month');
  const [totalbuild, setTotalBuildCount] = React.useState('');
  const [coverbuild, setCoverBuildCount] = React.useState('');
  const [totalcoverlettersaved, setTotalCoverLetterSavedCount] = React.useState('');
  const [totalupload, setTotalUploadCount] = React.useState('');
  const [totalmanual, setTotalManualCount] = React.useState('');
  const [totalusers, setTotalUsersCount] = React.useState('');
  const [activeResumeCount, setActiveResumeCount] = React.useState('');
  const [activeCoverCount, setActiveCoverCount] = React.useState('');
  const [totalspent, setTotalSpentCount] = React.useState('');
  const [totalspentresume, setTotalSpentResumeCount] = React.useState('');
  const [totalspentcover, setTotalSpentCoverCount] = React.useState('');
  const [resumeuserpercentage, setResumeUserPercentageCount] = React.useState('');
  const [coveruserpercentage, setCoverUserPercentageCount] = React.useState('');
  const [linkedIn, setLinkedInCount] = React.useState('');
  const [totalResumeExp, settotalResumeExp] = React.useState('');
  const [totalCoverExp, settotalCoverExp] = React.useState('');
  const [resumeChartLabels, setResumeChartLabels] = React.useState<any>([]);
  const [resumeChartValues, setResumeChartValues] = React.useState<any>([])
  const [coverChartLabels, setCoverChartLabels] = React.useState([]);
  const [coverChartValues, setCoverChartValues] = React.useState([]);
  const baseUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/api/`;
  const [template, setTemplate] = React.useState<any>({});
  const [templates1_count, setTemplate1Count] = React.useState('');
  const [templates2_count, setTemplate2Count] = React.useState('');
  const [templates3_count, setTemplate3Count] = React.useState('');
  const [selectedOption, setSelectedOption] = useState('weekly');
  const [properties, setProperties] = useState<string[] | null>([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [totalResumeBuilder, settotalResumeBuilder] = React.useState('');
  const [timeSpentOnResume, setTimeSpentOnResume] = React.useState('');
  const [timeSpentOnCover, setTimeSpentOnCover] = React.useState('');
  const [timeSpentOnTool, setTimeSpentOnTool] = React.useState('');
  



  useEffect(()=> {
    const url = baseUrl+`aiResume/templates_count`;
    axios.get(url)
    .then((res)=> {
      setTemplate(res.data);
    }).catch((err)=> console.log(err))
  },[])

  
  // const [getTotalBuiltsFunc, getotalBuiltsState] = useTotalBuiltsMutation();
  // const getTotalBuildCount = async (period: any, property_id: string) => {
  //   const { data }: any = await getTotalBuiltsFunc({period, property_id})
  //   console.log('data', data)
  //   if (data) {
  //     setTotalBuildCount(data.total_resume_builds)
  //     setCoverBuildCount(data.total_cover_builds)

  //     console.log(totalbuild)
  //   }

  // }




 

  const [getTotalUsersFunc, getotatUsersState] = useTotalCountMutation();
  const getTotalUsersCount = async (period: any, property: string) => {
    const { data }: any = await getTotalUsersFunc({period, property})
    console.log('data', data)
    if (data) {
      /* setTotalUsersCount(data.user_count)
      console.log(totalusers) */

    }
  }

  const [getTotalSpentFunc, getotatSpentState] = useTotalSpentMutation();
  const getTotalSpentCount = async () => {
    const { data }: any = await getTotalSpentFunc({})
    console.log('data', data)
    if (data) {
      setTotalSpentCount(data.time_spent_in_tool)
      console.log(totalspent)

    }
  }

  const [getTotalSpentResumeFunc, getotatSpentResumeState] = useTotalSpentResumeMutation();
  const getTotalSpentResumeCount = async () => {
    const { data }: any = await getTotalSpentResumeFunc({})
    console.log('data', data)
    if (data) {
      setTotalSpentResumeCount(data.total_hours)
      console.log("value", totalspentresume)
    }
  }

  const [getTotalSpentCoverFunc, getotatSpentCoverState] = useTotalSpentCoverMutation();
  const getTotalSpentCoverCount = async () => {
    const { data }: any = await getTotalSpentCoverFunc({})
    console.log('data', data)
    if (data) {
      setTotalSpentCoverCount(data.total_hours)
      console.log("new", totalspentcover)
    }
  }

 
  const handleResume = (data:string ) => {
    const url = baseUrl+`aiResume/resume_user_percentages?type=${data}`;
    axios.get(url)
    .then((res)=> {
      console.log(res)
      let resumeArray = res.data?.data
      let resumeLabels = resumeArray.map((item: any) => item.month)
      let resumeValues = resumeArray.map((item: any) => item.percentage)
      setResumeChartValues(resumeValues)
      setResumeChartLabels(resumeLabels)
      
      createOrUpdateBlueChart();
    }).catch((err)=> {
      console.log(err);
    })
  }

  const handleCover = (data:string) => {
    const url = baseUrl+`aiResume/cover_user_percentages?type=${data}`;
    axios.get(url)
    .then((res)=> {
      let resumeArray = res.data?.data
      let coverLabels = resumeArray.map((item: any) => item.month)
      let coverValues = resumeArray.map((item: any) => item.percentage)
      setCoverChartLabels(coverLabels)
      setCoverChartValues(coverValues)
      createOrUpdateOrangeChart();
    }).catch((err)=> {
      console.log(err);
    })
  }

 
  const [getLinkedInFunc, geLinkedInState] = useLinkedInCountMutation();
  const getLinkedInCount = async () => {
    const { data }: any = await getLinkedInFunc({})
    console.log('data', data)
    if (data) {
      setLinkedInCount(data.count)
      console.log("value", linkedIn)

    }
  }


  // const [getTotalResumeExpFunc, geTotalResumeState] = useTotalResumeExportedMutation();
  // const getTotalResumeExpCount = async (period: any) => {
  //   const { data }: any = await getTotalResumeExpFunc({period})
  //   console.log('data', data)
  //   if (data) {
  //     settotalResumeExp(data?.count)
  //     console.log("value", linkedIn)

  //   }
  // }
   

  // const [getTotalCoverExpFunc, geTotalCoverState] = useTotalCoverExportedMutation();
  // const getTotalCoverExpCount = async (period: any) => {
  //   const { data }: any = await getTotalCoverExpFunc({period})
  //   console.log('data', data)
  //   if (data) {
  //     settotalCoverExp(data?.count)
  //     console.log("value", linkedIn)

  //   }
  // }


  const [getTotalResumeBuilderFunc, getTotalResumeBuilderState] = useTotalResumeBuilderMutation();

  const getTotalResumeBuilderCount =  async (period:any , property_id:string )  => {
    console.log("period...")
    console.log(period)
    const { data }: any = await getTotalResumeBuilderFunc({period , property_id})
    console.log('data', data)
    if (data) {
      setTotalUsersCount(data?.total_user_count)
      settotalResumeBuilder(data?.total_resumes_saved)
      setTotalCoverLetterSavedCount(data?.total_covers_saved)
      setTotalBuildCount(data?.total_user_count)
      setCoverBuildCount(data?.total_user_count)
      setTotalUploadCount(data?.resume_upload_count)
      setTotalManualCount(data?.resume_upload_count)
      setTotalManualCount(data?.resume_manual_count)
      settotalResumeExp(data?.exported_resume_count)
      settotalCoverExp(data?.exported_covers_count)
      setActiveResumeCount(data?.active_resume_builder_count);
      setActiveCoverCount(data?.active_cover_builder_count)
      setTemplate1Count(data?.templates1_count)
      setTemplate2Count(data?.templates2_count)
      setTemplate3Count(data?.templates3_count)
      setTimeSpentOnTool(data?.time_sepnt_on_tool);
      setTimeSpentOnResume(data?.time_spent_on_resume);
      setTimeSpentOnCover(data?.time_spent_on_cover);
      console.log("*******getTotalResumeBuilderFunc******")
      console.log(data?.total_resume_builds);
      console.log(data )
    }
  }


  // // const [getTotalResumeTimingFunc, getTotalResumeTimingState] = useTotalResumeTimingSpentMutation();

  // const getTotalResumeTime =  async ()  => {
  //   const { data }: any = useTotalResumeTimingSpentMutation()
  //   console.log('resumetimedata', data)
  //   if (data) {
  //     setResumeSpentTime(data?.total_hours);
  //   }
  // }

  // const [getTotalUploadFunc, getotatUploadsState] = useTotalUploadedFilesMutation();
  // const getTotalUploadCount = async (period:any ) => {
  //   const { data }: any = await getTotalUploadFunc({period})
  //   console.log('data', data)
  //   if (data) {
  //     setTotalUploadCount(data?.resume_upload_count)
  //     console.log(data)

  //   }
  // }

  // const [getTotalManualFunc, getotatManualState] = useTotalManualUploadMutation();
  // const getTotalManualCount = async (period:any ) => {
  //   const { data }: any = await getTotalManualFunc({period})
  //   console.log('data', data)
  //   if (data) {
  //     setTotalManualCount(data.resume_manual_count)
  //     console.log(data)

  //   }
  // }
  /* const [getTotalCoverLetterSavedFunc, getotalCoverLetterSavedState] = useTotalCoverLetterSavedMutation();
  const getTotalCoverLetterSavedCount = async (period:any ) => {
    const { data }: any = await getTotalManualFunc({period})
    console.log('data', data)
    if (data) {
      setTotalCoverLetterSavedCount(data.total_cover_built)
      console.log(data)

    }
  } */




  const handleResumeFilterClick = (filter: string) => {
    setResumeFilter(filter);
    handleResume(filter);
  };
  const handleCoverFilterClick = (filter: string) => {
    setCoverFilter(filter);
    handleCover(filter);
  };

  useEffect(() => {
    const fetchProperties = async () => {
      const res = await axios.get(baseUrl+'aiResume/properties_list')
      const result = res.data?.properties;
      console.log(result)
      setProperties(result);
    }
    fetchProperties();
  }, []);

  useEffect(() => {
    // getTotalBuildCount(selectedOption, selectedProperty)
    // getTotalUploadCount(selectedOption)
    // getTotalManualCount(selectedOption)
    getTotalUsersCount(selectedOption, selectedProperty)
    getTotalSpentCount()
    getTotalSpentResumeCount()
    getTotalSpentCoverCount()
    getLinkedInCount()
    // getTotalResumeExpCount(selectedOption)
    // getTotalCoverExpCount(selectedOption)
    getTotalResumeBuilderCount(selectedOption ,selectedProperty )
    // getTotalCoverLetterSavedCount(selectedOption)
  


    // Call the functions to create or update the charts


    // Initialize Flatpickr
    flatpickr("#calendar", {
      mode: "range",
      maxDate: "today",
      dateFormat: "j M Y",
      disable: [
        function (date) {
          return !(date.getDate() % 8);
        },
      ],
    });
  }, []);

  const createOrUpdateOrangeChart = () => {
    if (orangeChartRef.current) {
      orangeChartRef.current.destroy();
    }

    const orangeCtx = document.getElementById('orangeBarChart') as HTMLCanvasElement;
    orangeChartRef.current = new Chart(orangeCtx.getContext('2d')!, {
      type: 'bar',
      data: {
        labels: coverChartLabels,
        datasets: [{
          label: 'Traffic',
          data: coverChartValues,
          backgroundColor: '#FF8A01',
          borderColor: '#FF8A01',
          borderWidth: 1,
          barThickness: 30
        }],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value: any) {
                return value == 0 ? '0' : value % 1000 === 0 ? value / 1000 + 'k' : '';
              },
              maxTicksLimit: 11,
            },
            max: 7000,
          },
        },
      },
    });
  };

  // Function to create or update the Blue Chart instance
  const createOrUpdateBlueChart = () => {
    if (blueChartRef.current) {
      blueChartRef.current.destroy();
    }

    const blueCtx = document.getElementById('blueBarChart') as HTMLCanvasElement;
    blueChartRef.current = new Chart(blueCtx.getContext('2d')!, {
      type: 'bar',
      data: {
        labels: resumeChartLabels,
        datasets: [{
          label: 'Traffic',
          data: resumeChartValues,
          backgroundColor: '#00B5E9',
          borderColor: '#00B5E9',
          borderWidth: 1,
          barThickness: 30
        }],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value: any) {
                return value == 0 ? '0' : value % 1000 === 0 ? value / 1000 + 'k' : '';
              },
              maxTicksLimit: 11,
            },
            max: 7000,
          },
        },
      },
    });
  };

  const [formattedDate, setFormattedDate] = useState('');

   // Handler function for the button click event
   const handleButtonClick = () => {
      
      // Trigger the onChange event programmatically
      const event = new Event('change', { bubbles: true });
      const dropdown = document.getElementById('propertydropdown'); // Replace 'myDropdown' with the actual ID of your dropdown
      if (dropdown) {
        dropdown.dispatchEvent(event);
      }
    };

  const handleSelect = async (e: any) => {
    setSelectedOption(e.target.value);
    await getTotalUsersCount(e.target.value, selectedProperty);
    // await getTotalBuildCount(e.target.value, selectedProperty);
    // await getTotalResumeExpCount(e.target.value);
    // await getTotalCoverExpCount(e.target.value);
    await getTotalResumeBuilderCount(e.target.value ,selectedProperty);
  }

  const handleSelectProperty = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value)
    setSelectedProperty(e.target.value);
    await getTotalUsersCount(selectedOption, e.target.value);
    await getTotalResumeBuilderCount(selectedOption ,e.target.value);
  }

  useEffect(() => {
    const now = new Date();
    const day = now.getDate();
    const month = now.toLocaleString('en-us', { month: 'short' });
    const year = now.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;
    setFormattedDate(formattedDate);
  }, []);

  useEffect(()=>{
    // const resumespenturl = baseUrl + `aiResume/total_time_on_resume`;
    // axios.get(resumespenturl).then(response=>{
    //   setTimeSpentOnResume(response.data.total_hours);
    // }).catch(error=>{
    //   console.log(error);
    // })

    // const coverspenturl = baseUrl + `aiResume/total_time_on_cover`;
    // axios.get(coverspenturl).then(response=>{
    //   setTimeSpentOnCover(response.data.total_hours);
    // }).catch(error=>{
    //   console.log(error);
    // })

    // const toolspenturl = baseUrl + `aiResume/total_time_spent_on_tool`;
    // axios.get(toolspenturl).then(response=>{
    //   setTimeSpentOnTool(response.data.total_time_spent);
    // }).catch(error=>{
    //   console.log(error);
    // })
  })

  return (
    <>
      <div className="container-fluid main">
        <div className="filter d-flex flex-wrap">
          <div style={{ display: "flex", justifyContent:'flex-start' }}>
          <select value={selectedOption} onChange={handleSelect}>
            <option value="weekly">Last 7 Days</option>
            <option value="monthly">Last Month</option>
            <option value="yearly">Last Year</option>
          </select>
            {/* <span className="" style={{ marginLeft: '15px'}} >
              <input type="text" value={formattedDate} id="calendar" style={{ padding: '5px'}} />
            </span> */}
           
            <select className="property" id="propertydropdown" value={selectedProperty} onChange={handleSelectProperty}>
            <option value={""}>Select Property</option>
              {
                properties && properties.map((pro, index) => (
                  <option key={index} value={pro}>{pro}</option>
                ))
              }
            </select>
          </div>&nbsp;&nbsp;
          <button  onClick={handleButtonClick}>Go</button>
        </div>
        <div className="row g-2 my-4 mx-0">
          <div className="col-12 col-sm mx-1 ms-0 my -1 card blue-card">
            <div className="card-contain">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="16" fill="#E6F1F7" />
                <path
                  d="M16 20.875C18.0711 20.875 19.75 19.1961 19.75 17.125C19.75 15.0539 18.0711 13.375 16 13.375C13.9289 13.375 12.25 15.0539 12.25 17.125C12.25 19.1961 13.9289 20.875 16 20.875Z"
                  stroke="#0076AD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.375 14.875C23.2485 14.8735 24.1103 15.0762 24.8916 15.4669C25.6729 15.8575 26.3521 16.4253 26.875 17.125"
                  stroke="#0076AD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.125 17.125C5.64794 16.4253 6.32714 15.8575 7.10843 15.4669C7.88972 15.0762 8.75149 14.8735 9.625 14.875"
                  stroke="#0076AD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.6 24.25C11.0939 23.2386 11.862 22.3861 12.8167 21.7899C13.7714 21.1936 14.8744 20.8774 16 20.8774C17.1256 20.8774 18.2286 21.1936 19.1833 21.7899C20.1381 22.3861 20.9061 23.2386 21.4 24.25"
                  stroke="#0076AD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.62499 14.875C9.0556 14.8756 8.49779 14.7141 8.01676 14.4094C7.53572 14.1048 7.15133 13.6695 6.90848 13.1545C6.66563 12.6395 6.57437 12.066 6.64535 11.501C6.71633 10.9361 6.94663 10.403 7.30932 9.96404C7.67202 9.52511 8.15215 9.19845 8.6936 9.02225C9.23505 8.84605 9.81546 8.82758 10.367 8.96899C10.9186 9.11041 11.4185 9.40587 11.8084 9.82085C12.1983 10.2358 12.462 10.7532 12.5687 11.3125"
                  stroke="#0076AD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.4312 11.3125C19.538 10.7532 19.8017 10.2358 20.1916 9.82085C20.5815 9.40587 21.0814 9.11041 21.633 8.96899C22.1845 8.82758 22.7649 8.84605 23.3064 9.02225C23.8478 9.19845 24.328 9.52511 24.6907 9.96404C25.0534 10.403 25.2837 10.9361 25.3546 11.501C25.4256 12.066 25.3344 12.6395 25.0915 13.1545C24.8487 13.6695 24.4643 14.1048 23.9832 14.4094C23.5022 14.7141 22.9444 14.8756 22.375 14.875"
                  stroke="#0076AD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>Total Users</p>
            </div>
            <h2 className="dark-blue-text">{totalusers}</h2>
          </div>
          <div className="col-12 col-sm mx-1 my-1 card blue-card">
            <div className="card-contain">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="16" fill="#DAF7FF" />
                <path
                  d="M19.75 25H9.25C9.05109 25 8.86032 24.921 8.71967 24.7803C8.57902 24.6397 8.5 24.4489 8.5 24.25V10.75C8.5 10.5511 8.57902 10.3603 8.71967 10.2197C8.86032 10.079 9.05109 10 9.25 10H16.75L20.5 13.75V24.25C20.5 24.4489 20.421 24.6397 20.2803 24.7803C20.1397 24.921 19.9489 25 19.75 25Z"
                  stroke="#00B5E9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.5 10V7.75C11.5 7.55109 11.579 7.36032 11.7197 7.21967C11.8603 7.07902 12.0511 7 12.25 7H19.75L23.5 10.75V21.25C23.5 21.4489 23.421 21.6397 23.2803 21.7803C23.1397 21.921 22.9489 22 22.75 22H20.5"
                  stroke="#00B5E9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.25 18.25H16.75"
                  stroke="#00B5E9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.25 21.25H16.75"
                  stroke="#00B5E9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p>Total Resume Saved</p>
            </div>
            <h2 className="light-blue-text"> {totalResumeBuilder}</h2>
          </div>
          <div className="col-12 col-sm mx-1 my-1 card orange-card">
            <div className="card-contain">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="16" fill="#FFEDD9" />
                <path
                  d="M13 13H19"
                  stroke="#FF8A01"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13 16H19"
                  stroke="#FF8A01"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13 19H16"
                  stroke="#FF8A01"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.6906 24.25H8.5C8.30109 24.25 8.11032 24.171 7.96967 24.0303C7.82902 23.8897 7.75 23.6989 7.75 23.5V8.5C7.75 8.30109 7.82902 8.11032 7.96967 7.96967C8.11032 7.82902 8.30109 7.75 8.5 7.75H23.5C23.6989 7.75 23.8897 7.82902 24.0303 7.96967C24.171 8.11032 24.25 8.30109 24.25 8.5V18.6906C24.2503 18.788 24.2315 18.8845 24.1945 18.9746C24.1575 19.0647 24.1031 19.1466 24.0344 19.2156L19.2156 24.0344C19.1466 24.1031 19.0647 24.1575 18.9746 24.1945C18.8845 24.2315 18.788 24.2503 18.6906 24.25V24.25Z"
                  stroke="#FF8A01"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M24.1844 19H19V24.1844"
                  stroke="#FF8A01"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>Total Cover letter Saved</p>
            </div>
            <h2 className="orange-text">{totalcoverlettersaved} </h2>
          </div>
        </div>

        <div className="row g-2 my-4 mx-0">
          <div className="col-12 col-sm mx-1 ms-0 my -1 card blue-card">
            <div className="card-contain">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="16" fill="#E6F1F7" />
                <path
                  d="M16 20.875C18.0711 20.875 19.75 19.1961 19.75 17.125C19.75 15.0539 18.0711 13.375 16 13.375C13.9289 13.375 12.25 15.0539 12.25 17.125C12.25 19.1961 13.9289 20.875 16 20.875Z"
                  stroke="#0076AD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.375 14.875C23.2485 14.8735 24.1103 15.0762 24.8916 15.4669C25.6729 15.8575 26.3521 16.4253 26.875 17.125"
                  stroke="#0076AD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.125 17.125C5.64794 16.4253 6.32714 15.8575 7.10843 15.4669C7.88972 15.0762 8.75149 14.8735 9.625 14.875"
                  stroke="#0076AD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.6 24.25C11.0939 23.2386 11.862 22.3861 12.8167 21.7899C13.7714 21.1936 14.8744 20.8774 16 20.8774C17.1256 20.8774 18.2286 21.1936 19.1833 21.7899C20.1381 22.3861 20.9061 23.2386 21.4 24.25"
                  stroke="#0076AD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.62499 14.875C9.0556 14.8756 8.49779 14.7141 8.01676 14.4094C7.53572 14.1048 7.15133 13.6695 6.90848 13.1545C6.66563 12.6395 6.57437 12.066 6.64535 11.501C6.71633 10.9361 6.94663 10.403 7.30932 9.96404C7.67202 9.52511 8.15215 9.19845 8.6936 9.02225C9.23505 8.84605 9.81546 8.82758 10.367 8.96899C10.9186 9.11041 11.4185 9.40587 11.8084 9.82085C12.1983 10.2358 12.462 10.7532 12.5687 11.3125"
                  stroke="#0076AD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.4312 11.3125C19.538 10.7532 19.8017 10.2358 20.1916 9.82085C20.5815 9.40587 21.0814 9.11041 21.633 8.96899C22.1845 8.82758 22.7649 8.84605 23.3064 9.02225C23.8478 9.19845 24.328 9.52511 24.6907 9.96404C25.0534 10.403 25.2837 10.9361 25.3546 11.501C25.4256 12.066 25.3344 12.6395 25.0915 13.1545C24.8487 13.6695 24.4643 14.1048 23.9832 14.4094C23.5022 14.7141 22.9444 14.8756 22.375 14.875"
                  stroke="#0076AD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>Active Resume Builder</p>
            </div>
            <h2 className="dark-blue-text">{activeResumeCount}</h2>
          </div>
          <div className="col-12 col-sm mx-1 my-1 card blue-card">
            <div className="card-contain">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="16" fill="#DAF7FF" />
                <path
                  d="M19.75 25H9.25C9.05109 25 8.86032 24.921 8.71967 24.7803C8.57902 24.6397 8.5 24.4489 8.5 24.25V10.75C8.5 10.5511 8.57902 10.3603 8.71967 10.2197C8.86032 10.079 9.05109 10 9.25 10H16.75L20.5 13.75V24.25C20.5 24.4489 20.421 24.6397 20.2803 24.7803C20.1397 24.921 19.9489 25 19.75 25Z"
                  stroke="#00B5E9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.5 10V7.75C11.5 7.55109 11.579 7.36032 11.7197 7.21967C11.8603 7.07902 12.0511 7 12.25 7H19.75L23.5 10.75V21.25C23.5 21.4489 23.421 21.6397 23.2803 21.7803C23.1397 21.921 22.9489 22 22.75 22H20.5"
                  stroke="#00B5E9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.25 18.25H16.75"
                  stroke="#00B5E9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.25 21.25H16.75"
                  stroke="#00B5E9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p>Total Time Spent (Minutes)</p>
            </div>
            {/* <h2 className="light-blue-text"> {totalbuild}</h2> */}
            <div className="d-flex flex-wrap">
            <span className="dash-chip blue-bg">Tool : {timeSpentOnTool}</span>
              <span className="dash-chip orange-bg">Resume : {timeSpentOnResume}</span>
              <span className="dash-chip green-bg">Cover : {timeSpentOnCover}</span>
            </div>
          </div>
          <div className="col-12 col-sm mx-1 my-1 card orange-card">
            <div className="card-contain">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="16" fill="#FFEDD9" />
                <path
                  d="M13 13H19"
                  stroke="#FF8A01"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13 16H19"
                  stroke="#FF8A01"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13 19H16"
                  stroke="#FF8A01"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.6906 24.25H8.5C8.30109 24.25 8.11032 24.171 7.96967 24.0303C7.82902 23.8897 7.75 23.6989 7.75 23.5V8.5C7.75 8.30109 7.82902 8.11032 7.96967 7.96967C8.11032 7.82902 8.30109 7.75 8.5 7.75H23.5C23.6989 7.75 23.8897 7.82902 24.0303 7.96967C24.171 8.11032 24.25 8.30109 24.25 8.5V18.6906C24.2503 18.788 24.2315 18.8845 24.1945 18.9746C24.1575 19.0647 24.1031 19.1466 24.0344 19.2156L19.2156 24.0344C19.1466 24.1031 19.0647 24.1575 18.9746 24.1945C18.8845 24.2315 18.788 24.2503 18.6906 24.25V24.25Z"
                  stroke="#FF8A01"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M24.1844 19H19V24.1844"
                  stroke="#FF8A01"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>Active Cover Letter Builder</p>
            </div>
            <h2 className="orange-text">{activeCoverCount} </h2>
          </div>
        </div>
       
        <div className="mobilv g-2 my-2 mt-3 mx-0" style={{display:'flex', justifyContent: 'flex-start'}}>
          <div className=" ms-0 me-2 my-2 card blue-card" style={{width:'23%'}}>
            <div className="card-contain">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="16" fill="#CDFFED" />
                <path
                  d="M22.75 25H9.25C9.05109 25 8.86032 24.921 8.71967 24.7803C8.57902 24.6397 8.5 24.4489 8.5 24.25V7.75C8.5 7.55109 8.57902 7.36032 8.71967 7.21967C8.86032 7.07902 9.05109 7 9.25 7H18.25L23.5 12.25V24.25C23.5 24.4489 23.421 24.6397 23.2803 24.7803C23.1397 24.921 22.9489 25 22.75 25Z"
                  stroke="#25966D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.25 7V12.25H23.5"
                  stroke="#25966D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>Template Type Usage</p>
            </div>
            <div className="d-flex flex-wrap">
              <span className="dash-chip blue-bg">Template 1 : {templates1_count}</span>
              <span className="dash-chip orange-bg">Template 2 : {templates2_count}</span>
              <span className="dash-chip green-bg">Template 3 : {templates3_count}</span>
            </div>
          </div>

          <div className="my-2 ms-0 me-2 card blue-card" style={{width:'20%'}}>
            <div className="card-contain">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="16" fill="#FFDCD9" />
                <path
                  d="M16 20.875C18.0711 20.875 19.75 19.1961 19.75 17.125C19.75 15.0539 18.0711 13.375 16 13.375C13.9289 13.375 12.25 15.0539 12.25 17.125C12.25 19.1961 13.9289 20.875 16 20.875Z"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.375 14.875C23.2485 14.8735 24.1103 15.0762 24.8916 15.4669C25.6729 15.8575 26.3521 16.4253 26.875 17.125"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.125 17.125C5.64794 16.4253 6.32714 15.8575 7.10843 15.4669C7.88972 15.0762 8.75149 14.8735 9.625 14.875"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.6 24.25C11.0939 23.2386 11.862 22.3861 12.8167 21.7899C13.7714 21.1936 14.8744 20.8774 16 20.8774C17.1256 20.8774 18.2286 21.1936 19.1833 21.7899C20.1381 22.3861 20.9061 23.2386 21.4 24.25"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.62499 14.875C9.0556 14.8756 8.49779 14.7141 8.01676 14.4094C7.53572 14.1048 7.15133 13.6695 6.90848 13.1545C6.66563 12.6395 6.57437 12.066 6.64535 11.501C6.71633 10.9361 6.94663 10.403 7.30932 9.96404C7.67202 9.52511 8.15215 9.19845 8.6936 9.02225C9.23505 8.84605 9.81546 8.82758 10.367 8.96899C10.9186 9.11041 11.4185 9.40587 11.8084 9.82085C12.1983 10.2358 12.462 10.7532 12.5687 11.3125"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.4312 11.3125C19.538 10.7532 19.8017 10.2358 20.1916 9.82085C20.5815 9.40587 21.0814 9.11041 21.633 8.96899C22.1845 8.82758 22.7649 8.84605 23.3064 9.02225C23.8478 9.19845 24.328 9.52511 24.6907 9.96404C25.0534 10.403 25.2837 10.9361 25.3546 11.501C25.4256 12.066 25.3344 12.6395 25.0915 13.1545C24.8487 13.6695 24.4643 14.1048 23.9832 14.4094C23.5022 14.7141 22.9444 14.8756 22.375 14.875"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p>Resume Uploads</p>
            </div>
            <h2 className="red-text">{totalupload} </h2>
          </div>
          <div className=" m-2 ms-0 card blue-card" style={{width:'24%'}}>
            <div className="card-contain">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="16" fill="#FFDCD9" />
                <path
                  d="M16 20.875C18.0711 20.875 19.75 19.1961 19.75 17.125C19.75 15.0539 18.0711 13.375 16 13.375C13.9289 13.375 12.25 15.0539 12.25 17.125C12.25 19.1961 13.9289 20.875 16 20.875Z"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.375 14.875C23.2485 14.8735 24.1103 15.0762 24.8916 15.4669C25.6729 15.8575 26.3521 16.4253 26.875 17.125"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.125 17.125C5.64794 16.4253 6.32714 15.8575 7.10843 15.4669C7.88972 15.0762 8.75149 14.8735 9.625 14.875"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.6 24.25C11.0939 23.2386 11.862 22.3861 12.8167 21.7899C13.7714 21.1936 14.8744 20.8774 16 20.8774C17.1256 20.8774 18.2286 21.1936 19.1833 21.7899C20.1381 22.3861 20.9061 23.2386 21.4 24.25"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.62499 14.875C9.0556 14.8756 8.49779 14.7141 8.01676 14.4094C7.53572 14.1048 7.15133 13.6695 6.90848 13.1545C6.66563 12.6395 6.57437 12.066 6.64535 11.501C6.71633 10.9361 6.94663 10.403 7.30932 9.96404C7.67202 9.52511 8.15215 9.19845 8.6936 9.02225C9.23505 8.84605 9.81546 8.82758 10.367 8.96899C10.9186 9.11041 11.4185 9.40587 11.8084 9.82085C12.1983 10.2358 12.462 10.7532 12.5687 11.3125"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.4312 11.3125C19.538 10.7532 19.8017 10.2358 20.1916 9.82085C20.5815 9.40587 21.0814 9.11041 21.633 8.96899C22.1845 8.82758 22.7649 8.84605 23.3064 9.02225C23.8478 9.19845 24.328 9.52511 24.6907 9.96404C25.0534 10.403 25.2837 10.9361 25.3546 11.501C25.4256 12.066 25.3344 12.6395 25.0915 13.1545C24.8487 13.6695 24.4643 14.1048 23.9832 14.4094C23.5022 14.7141 22.9444 14.8756 22.375 14.875"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p>Resume Manually Added</p>
            </div>
            <h2 className="red-text">{totalmanual}</h2>
          </div>
          <div className="m-2 ms-0 card orange-card" style={{width:'25%'}}>
            <div className="card-contain">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="16" fill="#FFDCD9" />
                <path
                  d="M16 20.875C18.0711 20.875 19.75 19.1961 19.75 17.125C19.75 15.0539 18.0711 13.375 16 13.375C13.9289 13.375 12.25 15.0539 12.25 17.125C12.25 19.1961 13.9289 20.875 16 20.875Z"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.375 14.875C23.2485 14.8735 24.1103 15.0762 24.8916 15.4669C25.6729 15.8575 26.3521 16.4253 26.875 17.125"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.125 17.125C5.64794 16.4253 6.32714 15.8575 7.10843 15.4669C7.88972 15.0762 8.75149 14.8735 9.625 14.875"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.6 24.25C11.0939 23.2386 11.862 22.3861 12.8167 21.7899C13.7714 21.1936 14.8744 20.8774 16 20.8774C17.1256 20.8774 18.2286 21.1936 19.1833 21.7899C20.1381 22.3861 20.9061 23.2386 21.4 24.25"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.62499 14.875C9.0556 14.8756 8.49779 14.7141 8.01676 14.4094C7.53572 14.1048 7.15133 13.6695 6.90848 13.1545C6.66563 12.6395 6.57437 12.066 6.64535 11.501C6.71633 10.9361 6.94663 10.403 7.30932 9.96404C7.67202 9.52511 8.15215 9.19845 8.6936 9.02225C9.23505 8.84605 9.81546 8.82758 10.367 8.96899C10.9186 9.11041 11.4185 9.40587 11.8084 9.82085C12.1983 10.2358 12.462 10.7532 12.5687 11.3125"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.4312 11.3125C19.538 10.7532 19.8017 10.2358 20.1916 9.82085C20.5815 9.40587 21.0814 9.11041 21.633 8.96899C22.1845 8.82758 22.7649 8.84605 23.3064 9.02225C23.8478 9.19845 24.328 9.52511 24.6907 9.96404C25.0534 10.403 25.2837 10.9361 25.3546 11.501C25.4256 12.066 25.3344 12.6395 25.0915 13.1545C24.8487 13.6695 24.4643 14.1048 23.9832 14.4094C23.5022 14.7141 22.9444 14.8756 22.375 14.875"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p>Total Resumes Exported</p>
            </div>
            <h2 className="red-text">{totalResumeExp}</h2>
          </div>
          <div className="m-2 ms-0 card orange-card" style={{width:'28%'}}>
            <div className="card-contain">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="16" fill="#FFDCD9" />
                <path
                  d="M16 20.875C18.0711 20.875 19.75 19.1961 19.75 17.125C19.75 15.0539 18.0711 13.375 16 13.375C13.9289 13.375 12.25 15.0539 12.25 17.125C12.25 19.1961 13.9289 20.875 16 20.875Z"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.375 14.875C23.2485 14.8735 24.1103 15.0762 24.8916 15.4669C25.6729 15.8575 26.3521 16.4253 26.875 17.125"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.125 17.125C5.64794 16.4253 6.32714 15.8575 7.10843 15.4669C7.88972 15.0762 8.75149 14.8735 9.625 14.875"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.6 24.25C11.0939 23.2386 11.862 22.3861 12.8167 21.7899C13.7714 21.1936 14.8744 20.8774 16 20.8774C17.1256 20.8774 18.2286 21.1936 19.1833 21.7899C20.1381 22.3861 20.9061 23.2386 21.4 24.25"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.62499 14.875C9.0556 14.8756 8.49779 14.7141 8.01676 14.4094C7.53572 14.1048 7.15133 13.6695 6.90848 13.1545C6.66563 12.6395 6.57437 12.066 6.64535 11.501C6.71633 10.9361 6.94663 10.403 7.30932 9.96404C7.67202 9.52511 8.15215 9.19845 8.6936 9.02225C9.23505 8.84605 9.81546 8.82758 10.367 8.96899C10.9186 9.11041 11.4185 9.40587 11.8084 9.82085C12.1983 10.2358 12.462 10.7532 12.5687 11.3125"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.4312 11.3125C19.538 10.7532 19.8017 10.2358 20.1916 9.82085C20.5815 9.40587 21.0814 9.11041 21.633 8.96899C22.1845 8.82758 22.7649 8.84605 23.3064 9.02225C23.8478 9.19845 24.328 9.52511 24.6907 9.96404C25.0534 10.403 25.2837 10.9361 25.3546 11.501C25.4256 12.066 25.3344 12.6395 25.0915 13.1545C24.8487 13.6695 24.4643 14.1048 23.9832 14.4094C23.5022 14.7141 22.9444 14.8756 22.375 14.875"
                  stroke="#EA4335"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p>Total Cover Letters Exported</p>
            </div>
            <h2 className="red-text">{totalCoverExp}</h2>
          </div>
          {/* <div className="col-12 col-sm m-2 ms-0">
            <div className="card-contain">

            </div>

          </div> */}
        </div>
      </div>
    </>
  )
}

export default DashboardSection 
