import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Container,
  Stack,
  Card,
  Typography,
  TextField,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import { object, string, TypeOf } from "zod";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";

import { useVerifyEmailMutation } from "../../redux/api/authApi";
import { useAppDispatch } from "../../redux/store";
import { setModule } from "../../redux/features/genieSlice";
import { Link } from "react-router-dom";


/* CentriLife */
import '../../components/Centri_CSS/style.css';

import signinImg from '../../assets/image/logo signin.png';
import SideImg from '../../assets/image/Side Img.jpeg';
import { error } from "console";
import Header from "../../components/headers/Header";
import LandingHeader from "../../components/headers/LandingHeader";
import CommonHeader from "../../components/CentriLandingPage/CommonpageHeader";
import LandingPageFooter from "../../components/CentriLandingPage/LandingpageFooter";
/*  */

const verifySchema = object({
  code: string().min(1, "Verification code is required"),
});

export type VerifyInput = TypeOf<typeof verifySchema>;

const VerifyPage = (props: { page: any; setPassCallback: any }) => {
  const { page, setPassCallback } = props;
  const [verifyEmail, verifyState] = useVerifyEmailMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const methods = useForm<VerifyInput>({
    resolver: zodResolver(verifySchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<VerifyInput> = (values) => {
    verifyEmail(values);
  };

  const module = localStorage.getItem("module");

  useEffect(() => {
    //console.log(page);
    if (page === "forgotPass") {
      if (verifyState.isSuccess) {
        toast.success("Verified successfully");
        setPassCallback(true);
      }
      if (verifyState.isError) {
        if (Array.isArray((verifyState.error as any).data.detail)) {
          (verifyState.error as any).data.detail.map((el: any) =>
            toast.error(`${el.loc[1]} ${el.msg}`)
          );
        } else toast.error((verifyState.error as any).data.detail);
        setPassCallback(false);
      }
    }

    if (page === "signin") {
      if (verifyState.isSuccess) {
        toast.success("Verified successfully");
        localStorage.setItem("module", "RESUME");
          dispatch(setModule("RESUME"));
          navigate("/codegenie/resume");
    }
    if (verifyState.isError) {
        if (Array.isArray((verifyState.error as any).data.detail)) {
            (verifyState.error as any).data.detail.map((el: any) =>
                toast.error(`${el.loc[1]} ${el.msg}`)
            );
        } else toast.error((verifyState.error as any).data.detail);
    } 
     /*  if (module == "RESUME") {
        if (verifyState.isSuccess) {
          toast.success("Verified successfully");
          localStorage.setItem("module", "RESUME");
          dispatch(setModule("RESUME"));
          navigate("/codegenie/resume");
        }
      }
      else {
        if (verifyState.isError) {
          debugger
          if (Array.isArray((verifyState.error as any).data.detail)) {
            (verifyState.error as any).data.detail.map((el: any) =>
              toast.error(`${el.loc[1]} ${el.msg}`)
            );
          } else toast.error((verifyState.error as any).data.detail);
        }
      } */
    } 
  }, [verifyState]);

  console.log(verifyState);

  

  return (
    <>
      <CommonHeader />
      <div className="container-fluid resetPass verify">
        <div className="row">
          <div className="col-12 col-md-6 left-img">

            <img src={SideImg} alt="" width="100%" height="100%" />

          </div>

          <div className="col-12 col-md-6 card">
            <div className="text-center">
              <Link to="/" target="_self" rel="noreferrer">
                <img src={signinImg} alt="" className="logo" />
              </Link>
            </div>

            <FormProvider {...methods}>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmitHandler)}
            >
            <div className="box">
              {/* <form action=""> */}
                <p>Thanks for creating an account!<br />We have just emailed you a verification code. Please check your email to retrieve the code and follow the link back here.</p>
                <h1 className="mb-3">Verification Code</h1>
                <input type="text" placeholder="Verify Code"
                      style={{ border: errors.code ? '1px solid red' : '2px solid #B8BFCA' }}
                      {...register("code", { required: "Code is required" })} />
                {errors.code && <p style={{ color: 'red' }}>{"*" + errors.code.message}</p>}
                <button className="btn1" type="submit">{verifyState.isLoading ? 'Sending...' : 'Send'}</button>
              {/* </form> */}


            </div>
            </Box>
            </FormProvider>
          </div>
        </div>

      </div>
      <LandingPageFooter />
    </>
  );
};

export default VerifyPage;
