import { useState, useContext, useEffect, FormEvent } from "react";
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  SvgIcon,
  FormControlLabel,
  Switch,
  Divider,
  Stack,
  Modal,
  Paper,
  TextField
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

import { ReactComponent as Logo } from "../../logo.svg";
import { ReactComponent as CrownIcon } from "../../assets/ico_crown.svg";
import { ReactComponent as ProductIcon } from "../../assets/ico_product.svg";
import { ReactComponent as DownIcon } from "../../assets/ico_downarr.svg";

import { ColorModeContext } from '../../theme';
import { useLazyGetModulesQuery, useLazyGetProductQuery } from "../../redux/api/productApi";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setModule } from "../../redux/features/genieSlice";
import { logout } from "../../redux/features/userSlice";
import { useLazyCreateCheckoutSessionQuery } from "../../redux/api/subscriptionApi";
import PayNowButton from "../../pages/payment/payNowButton";
import "../../pages/custom_style.css";
import { IPlanDetail } from "../../redux/api/types";
import { useLazyRemainingWishCountQuery, useRemainingWishCountQuery } from "../../redux/api/genieApi";
import logo from "../../assets/newimg/logo.png";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ResumeBuilderHeader from "./ResumeBuilderHeader";



const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  marginLeft: 10,
  border: "1px solid #1D252D",
  borderRadius: 8,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
    background: theme.palette.mode === "dark" ? "white" : "#1D252D",
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "linear-gradient(#0168B5, #4BA5EB)"
        : "rgba(255,255,255,1)",
    boxSizing: "border-box",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette
    // .mode === "dark" ? "#1A2027" : "#fff",
    .mode === "dark" ? (localStorage.getItem('userRole') === 'user' ? "#1A2027" : "#F9FBFC") : (localStorage.getItem('userRole') === 'user' ? "#1A2027" : "#F9FBFC"),

  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
console.log('Theme=>' + Item);


function Header() {

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const colorMode = useContext(ColorModeContext);

  const [modules, setModules] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [openSetting, setOpenSetting] = useState(false);
  const [openModal, setOpenModal] = useState("Password");



  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const genieSelector = useAppSelector((state) => state.genieState);
  const user = useAppSelector((state) => state.userState.user);

  if (user && user.role) {
    const { role } = user;
    localStorage.setItem('userRole', role);
  }

  const userRole = localStorage.getItem('userRole');

  // Check if the user is logged in
  const isAuthenticated = localStorage.getItem("logged_in");

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [createCheckoutSession, checkoutState] = useLazyCreateCheckoutSessionQuery();
  const [getModules, getState] = useLazyGetModulesQuery();
  const smart_contracte_complexity = localStorage.getItem("complexity");
  const [openPaidModal, setOpenPriceModal] = useState(false);
  const [remainingWishCount, remWishState] = useLazyRemainingWishCountQuery();
  const remainingWishCountQuery = useRemainingWishCountQuery({ "email": user?.email || "" })


  console.log('state=>', getState);

  const [period, getPeriodState] = useState("monthly");
  const [getProduct, getProductState] = useLazyGetProductQuery();
  const [plans, setPlans] = useState<IPlanDetail[]>([
    {
      user: 1,
      plan_name: "",
      total_wishes: 0,
      price: "",
      period: "",
      product_id: "",
      price_id: "",
      subscription_level: "",
      feature: [],
      contracts: 0,
      type: "",
      button_text: "",
    },
  ]);
  const [wishRemCount, setWishRemCount] = useState(0);


  useEffect(() => {

    getModules("CodeGenie");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (remainingWishCountQuery.isSuccess)
      setWishRemCount(remainingWishCountQuery.data?.remaining_wish_count);
  }, [remainingWishCountQuery]);

  useEffect(() => {

    if (remWishState.isSuccess) {
      //console.log(remWishState.data?.remaining_wish_count);
      setWishRemCount(remWishState.data?.remaining_wish_count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remWishState]);

  useEffect(() => {

    if (checkoutState.isSuccess) {
      window.location = checkoutState.data?.checkout_url;
      //window.open(checkoutState.data?.checkout_url, '_blank');
    }
  }, [checkoutState, navigate]);

  useEffect(() => {

    const { data } = getProductState;
    if (data?.plan_details) {
      //console.log(data?.plan_details);
      setPlans([...data?.plan_details]);
    }
    else
      setPlans([
        {
          user: 1,
          plan_name: "",
          total_wishes: 0,
          price: "",
          period: "",
          product_id: "",
          price_id: "",
          subscription_level: "",
          feature: [],
          contracts: 0,
          type: "",
          button_text: "",
        },
      ]);
  }, [getProductState]);

  useEffect(() => {

    if (getState.isSuccess) {
      // setModules(getState.data.filter((item) => item !== ""));
      const ResumeItem = getState.data[4];
      setModules([ResumeItem]);
    }

    console.log('modules=>', modules);
  }, [getState]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    localStorage.setItem("logged_in", "false");
    //console.log("loggedin:" + localStorage.getItem("logged_in"));
    dispatch(logout());
    var module = localStorage.getItem("module");
    console.log("module111...." + module);
    if (module == "Any Code")
      navigate("/Anycode_landing");
    else
      navigate("/");
    toast.success("Log Out success");
    localStorage.removeItem("payment");
    localStorage.removeItem("msg");
    localStorage.removeItem("complexity");
    localStorage.removeItem("module");
    localStorage.removeItem("subscription_level");
    localStorage.removeItem("free_plan");
    localStorage.removeItem("userRole");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_id");
    localStorage.removeItem('property');
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>, subscription_level: string) {
    e.preventDefault();
    //validate before submit for same subscription level
    if (localStorage.getItem("subscription_level") == subscription_level) {
      var cn = window.confirm("You are trying to udgrade the same plan, that you have already registered. Do you want to continue?");
      if (!cn) return false;
    }

    if (subscription_level === "Free") {

      if (user?.email) {
        navigate("/codegenie/smart_contracts");
      } else {
        navigate("/signin");
      }
    } else {
      if (isAuthenticated != "true") {
        navigate('/signin');
      } else {
        createCheckoutSession({ "emailid": user?.email || "", "username": user?.name || "", "subscription_level": subscription_level });
      }

    }
  }


  const handleSetting = () => {
    navigate('/codegenie/settings');
  }

  return (
    <>
      <ResumeBuilderHeader />
    </>
  );
}
export default Header;
