import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";

import {
    IPersonalResponse,
    IPersonalDetails,
    IContactInfo,
    IEmployeementHistory,
    IEducation,
    ISaveResumeCover,
    TotalBuilts,
    UserID,
    TotalUploadedFiles,
    TotalManualUpload,
    TotalUsers,
    TotalSpent,
    TotalSpentResume,
    TotalSpentCover,
    ResumeUserPercentage,
    CoverUserPercentage,
    linkedIn,
    TotalResumeExported,
    TotalCoverExported,
    TotalResumeBuilder,
    TotalCoverletterSaved
} from "./types";

import { SaveResCov } from "../../components/genie/TotalPanel";

export const manualApi = createApi({
    reducerPath: "manualApi",
    baseQuery: customFetchBase,
    endpoints: (builder) => ({
        getPersonalDetails: builder.mutation<any, { user_id: String }>({
            query({ user_id }) {
                return {
                    url: `personalDetails/?user_id=${user_id}`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),
        addPersonalDetails: builder.mutation<IPersonalResponse, IPersonalDetails>({
            query(data) {
                return {
                    url: "personalDetails/",
                    method: "POST",
                    body: data,
                };
            },
        }),
        getContactInfo: builder.mutation<any, { user_id: String }>({
            query({ user_id }) {
                return {
                    url: `contactInformation/?user_id=${user_id}`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),
        addContactInfo: builder.mutation<IPersonalResponse, IContactInfo>({
            query(data) {
                return {
                    url: "contactInformation/",
                    method: "POST",
                    body: data,
                };
            },
        }),
        getEmployeementHistory: builder.mutation<any, { user_id: String }>({
            query({ user_id }) {
                return {
                    url: `employmentHistory/?user_id=${user_id}`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),
        addEmployeementHistory: builder.mutation<IPersonalResponse, IEmployeementHistory>({
            query(data) {
                return {
                    url: "employmentHistory/",
                    method: "POST",
                    body: data,
                };
            },
        }),
        getProffesionalSummery: builder.mutation<any, { user_id: String }>({
            query({ user_id }) {
                return {
                    url: `professionalSummary/?user_id=${user_id}`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),
        addProffesionalSummery: builder.mutation<IPersonalResponse, any>({
            query(data) {
                return {
                    url: "professionalSummary/",
                    method: "POST",
                    body: data,
                };
            },
        }),
        getEducatio: builder.mutation<any, { user_id: String }>({
            query({ user_id }) {
                return {
                    url: `education/?user_id=${user_id}`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),
        addEducatio: builder.mutation<IPersonalResponse, IEducation>({
            query(data) {
                return {
                    url: "education",
                    method: "POST",
                    body: data,
                };
            },
        }),
        getSkills: builder.mutation<any, { user_id: String }>({
            query({ user_id }) {
                return {
                    url: `skills/?user_id=${user_id}`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),
        addSkills: builder.mutation<IPersonalResponse, any>({
            query(data) {
                return {
                    url: "skills",
                    method: "POST",
                    body: data,
                };
            },
        }),
        getAdditionalDetails: builder.mutation<any, { user_id: String }>({
            query({ user_id }) {
                return {
                    url: `additionalDetails/?user_id=${user_id}`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),
        addAdditionalDetails: builder.mutation<IPersonalResponse, any>({
            query(data) {
                return {
                    url: "additionalDetails/",
                    method: "POST",
                    body: data,
                };
            },
        }),

        /* for resume/cover letter result saving */
        saveResumeCoverContent: builder.mutation<ISaveResumeCover, SaveResCov>({
            query(data) {
                return {
                    url: "aiResume/save",
                    method: "POST",
                    body: data,
                };
            },
        }),
                    /*  */


        getAllManualDetails: builder.mutation<IPersonalResponse, any>({
            query(user_id) {
                return {
                    url: `aiResume/userProfile/${user_id}`,
                    method: "GET"
                };
            },
        }),
        createResume: builder.mutation<IPersonalResponse, any>({
            query(data) {
                return {
                    url: `prompts/runresumetest`,
                    method: "POST",
                    body: data
                };
            },
        }),
        postJSONdata: builder.mutation<any, any>({
            query(data) {
                return {
                    url: `aiResume/save`,
                    method: "POST",
                    body: data
                };
            },
        }),
        downloadDoc: builder.mutation<any, any>({
            query(data) {
                return {
                    url: `aiResume/DataExport`,
                    method: "POST",
                    body: data
                };
            },
        }),
        downloadFile: builder.mutation<any, any>({
            query({ doc_id }) {
                return {
                    url: `aiResume/download_resume/${doc_id}`,
                    method: "GET",
                };
            },
        }),

        TotalBuilts: builder.mutation<TotalBuilts, {period: string, property_id: string}>({
            query({period, property_id}) {
                return {
                    url: `/aiResume/filter_counts?period=${period}&property_id=${property_id}`,
                    method: "GET",
                    params: {
                        period,
                        property_id
                    }
                };
            },
            transformResponse: (result: any) => result
        }),


        UserId: builder.mutation<UserID, { user_id: string }>({
            query({ user_id }) {
                return {
                    url: `/aiResume/user_builds/?user_id=${user_id}`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),

        TotalUploadedFiles: builder.mutation<TotalUploadedFiles, {period: string}>({
            query({period}) {
                return {
                    url: `/aiResume/users/count?period=monthly`,
                    method: "GET",
                    params: {
                        period,
                    }
                };
            },
            transformResponse: (result: any) => result
        }),

        TotalManualUpload: builder.mutation<TotalManualUpload, {period: string}>({
            query({period}) {
                return {
                    url: `/aiResume/users/count?period=monthly`,
                    method: "GET",
                    params: {
                        period,
                    }
                };
            },
            transformResponse: (result: any) => result
        }),


        TotalCoverLetterSaved: builder.mutation<TotalCoverletterSaved, {period: string , property_id: string}>({
            query({period , property_id}) {
                return {
                    url: `/aiResume/filter_counts?period=monthly`,
                    method: "GET",
                    params: {
                        period,
                        property_id
                    }
                };
            },
            transformResponse: (result: any) => result
        }),

        TotalCount: builder.mutation<TotalUsers, {period: string, property: string}>({
            query({period, property}) {
                return {
                    url: `/aiResume/count_users`,
                    method: "GET",
                    params: {
                        period,
                        property
                    }
                };
            },
            transformResponse: (result: any) => result
        }),



        TotalSpent: builder.mutation<TotalSpent, {}>({
            query() {
                return {
                    url: `/aiResume/total_time_spent`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),

        TotalSpentResume: builder.mutation<TotalSpentResume, {}>({
            query() {
                return {
                    url: `/aiResume/total_time_on_resume`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),

        TotalSpentCover: builder.mutation<TotalSpentCover, {}>({
            query() {
                return {
                    url: `/aiResume/total_time_on_cover`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),

        ResumeUserPercentage: builder.mutation<ResumeUserPercentage, {}>({
            query() {
                return {
                    url: `/aiResume/resume_user_percentages`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),

        CoverUserPercentage: builder.mutation<CoverUserPercentage, {}>({
            query() {
                return {
                    url: `/aiResume/cover_user_percentages`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),

        LinkedInCount: builder.mutation<linkedIn, {}>({
            query() {
                return {
                    url: `/aiResume/linkedin_list`,
                    method: "GET",
                };
            },
            transformResponse: (result: any) => result
        }),

        TotalResumeExported: builder.mutation<TotalResumeExported, {period: string}>({
            query({period}) {
                return {
                    url: `/aiResume/resume/export/`,
                    method: "GET",
                    params: {
                        period,
                    }
                };
            },
            transformResponse: (result: any) => result
        }),

        TotalResumeBuilder: builder.mutation<TotalResumeBuilder, {period: string , property_id: string}>(
            {
            
            query({period , property_id}) {
                return {
                    url: `/aiResume/filter_count?period=${period}&property_id=${property_id}`,
                    method: "GET"
                };
            },
            transformResponse: (result: any) => result
        }),

        TotalCoverExported: builder.mutation<TotalCoverExported, {period: string}>({
            query({period}) {
                return {
                    url: `/aiResume/cover/export/`,
                    method: "GET",
                    params: {
                        period,
                    }
                };
            },
            transformResponse: (result: any) => result
        }),
    }),
});

export const {
    useAddPersonalDetailsMutation,
    useGetPersonalDetailsMutation,
    useAddContactInfoMutation,
    useGetContactInfoMutation,
    useAddEmployeementHistoryMutation,
    useGetEmployeementHistoryMutation,
    useAddEducatioMutation,
    useGetEducatioMutation,
    useAddSkillsMutation,
    useGetSkillsMutation,
    useAddProffesionalSummeryMutation,
    useGetProffesionalSummeryMutation,
    useAddAdditionalDetailsMutation,
    useGetAdditionalDetailsMutation,
    useGetAllManualDetailsMutation,
    useCreateResumeMutation,
    usePostJSONdataMutation,
    useDownloadDocMutation,
    useDownloadFileMutation,
    useSaveResumeCoverContentMutation,
    useTotalBuiltsMutation,
    useUserIdMutation,
    useTotalUploadedFilesMutation,
    useTotalManualUploadMutation,
    useTotalCountMutation,
    useTotalSpentMutation,
    useTotalSpentResumeMutation,
    useTotalSpentCoverMutation,
    useResumeUserPercentageMutation,
    useCoverUserPercentageMutation,
    useLinkedInCountMutation,
    useTotalResumeExportedMutation,
    useTotalCoverExportedMutation,
    useTotalResumeBuilderMutation,
    useTotalCoverLetterSavedMutation
} = manualApi;
