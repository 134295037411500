import { useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Divider,
  FormControl,
  TextField,
  SvgIcon,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialTwitter,
  LoginSocialGithub,
  LoginSocialLinkedin,
  IResolveParams,
} from "reactjs-social-login";
import { LoadingButton } from "@mui/lab";
//import { useCookies } from "react-cookie";
import { setCookie, getCookie, removeCookie } from "../..//utils/cookies.js";
import BackSignin from "../../assets/back_signin.png";
import Logo from "../../logo.svg";
import Keyimg from "../../assets/image/key-off1.svg";

import { ReactComponent as GoogleIcon } from "../../assets/ico_google.svg";
import { ReactComponent as FacebookIcon } from "../../assets/ico_facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/ico_twtter.svg";
import { ReactComponent as GithubIcon } from "../../assets/ico_github.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/ico_linkedin.svg";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
// import body2 from "../pages/auth/text-2.png";

/* Centri Life */
import '../../components/Centri_CSS/style.css';

import sideImg from '../../assets/image/Side Img.jpeg';
import logoSigninImg from '../../assets/image/logo signin.png';
/*  */
import CommonHeader from "../../components/CentriLandingPage/CommonpageHeader";
import LandingPageFooter from "../../components/CentriLandingPage/LandingpageFooter";

import {
  useSigninUserMutation,
  useSocialAuthMutation,
} from "../../redux/api/authApi";
import { useAppDispatch } from "../../redux/store";
import { setModule } from "../../redux/features/genieSlice";
import axios from "axios";

const signinSchema = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters"),
});

export type SigninInput = TypeOf<typeof signinSchema>;


const SigninPage = () => {

  const [signinUser, signinState] = useSigninUserMutation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [authSocial, socialState] = useSocialAuthMutation();

  const REDIRECT_URI = window.location.href;

  const user_id = localStorage.getItem('user_id');
  const baseUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/api/`;

  useEffect(() => {

    if (signinState.isSuccess) {
      toast.success("Login Success");

      localStorage.setItem("logged_in", "true");
      localStorage.setItem("free_plan", signinState.data.free_plan.toString());
      localStorage.setItem("remaining_wish_count", signinState.data.remaining_wish_count.toString());
      const subscriptionLevel = signinState.data.subscription_level || '';
      localStorage.setItem("subscription_level", subscriptionLevel);
      localStorage.setItem("user_email", signinState.data.user_email || '');
      localStorage.setItem("user_id", signinState.data.user_id || '');
      localStorage.setItem('userRole', signinState.data.role);
      localStorage.setItem('Tab', 'Resume');

      if (!signinState.data.verified) navigate("/verify");
      else {
        if (signinState.data.role === "user") {
          // if (localStorage.getItem("module") === "Any Code") {
          //   dispatch(setModule("Any Code"));
          //   navigate("/codegenie/any_code");
          // }
          // else if (localStorage.getItem("module") === "SMART CONTRACTS") {
          //   dispatch(setModule("SMART CONTRACTS"));
          //   navigate("/codegenie/smart_contracts");
          // }
          // else if (signinState.data.initial_product === "Any Code") {
          //   localStorage.setItem("module", "Any Code");
          //   dispatch(setModule("Any Code"));
          //   navigate("/codegenie/any_code");
          // }
          // else {
          //   localStorage.setItem("module", "SMART CONTRACTS");

          localStorage.setItem("module", "RESUME");
          dispatch(setModule("RESUME"));
          console.log("signin ")
          window.location.href = `${window.location.protocol}//${window.location.host}/codegenie/resume`
          
          // navigate("/innerpageresumebuilder");
          //   navigate("/codegenie/resume");
          // }

        } else {
          window.location.href = `${window.location.protocol}//${window.location.host}/admin/dashboard`
          // navigate("/admin/dashboard");
        }
      }
    }
    if (signinState.isError) {
      localStorage.setItem("logged_in", "false");
      if (Array.isArray((signinState.error as any).data.detail)) {
        (signinState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((signinState.error as any).data.detail);
    }
  }, [signinState]);

  useEffect(() => {
    if (socialState.isSuccess) {
      toast.success("Social signup success");

      localStorage.setItem("logged_in", "true");
      localStorage.setItem('userRole', socialState.data.role);
      localStorage.setItem("user_id", socialState.data!.user_id);
      if (socialState.data.role === "user") {
        // localStorage.setItem("module", "SMART CONTRACTS");
        dispatch(setModule("RESUME"));
        // navigate("/codegenie/resume");

        window.location.href = `${window.location.protocol}//${window.location.host}/codegenie/resume`
      } else {
        navigate("/admin/dashboard");
      }
    }
    if (socialState.isError) {

      localStorage.setItem("logged_in", "false");

      if (Array.isArray((socialState.error as any).data.detail)) {
        (socialState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((socialState.error as any).data.detail);
    }
  }, [socialState]);


  const methods = useForm<SigninInput>({
    resolver: zodResolver(signinSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<SigninInput> = (values) => {
    signinUser(values);
  };

  return (
    <div>
      <CommonHeader />
      <div className="container-fluid login">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 left-img">
            <img src={sideImg} alt="" width="100%" height="100%" />
          </div>
          <div className="col-12 col-md-6 card">
            <div className="text-center">
              <img src={logoSigninImg} alt="" className="logo" onClick={() => navigate('/')} />
            </div>
            <h1>Sign In to your account</h1>
            <FormProvider {...methods}>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmitHandler)}
              >
                <div className="row">

                  <div className="col-12 mb-3">
                    <label className="email">Email*</label><br />
                    <input
                      {...register("email", { required: "Email is required" })}
                      type="email"
                      placeholder="Your email address"
                      id="email"
                      style={{ border: errors.email ? '1px solid red' : '2px solid #B8BFCA' }}
                    />
                    {errors.email && <p style={{ color: 'red' }}>{"*" + errors.email.message}</p>}
                  </div>

                  <div className="col-12 mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="password">Password*</label>
                      <a href="/forgot" className="forget">Forgot Password?</a>
                    </div>
                    <input
                      {...register("password", { required: 'min 8 characters password required' })}
                      type="password"
                      placeholder="Your password"
                      style={{ border: errors.password ? '1px solid red' : '2px solid #B8BFCA' }}
                      id="password"
                    />
                    {errors.password && <p style={{ color: 'red' }}>{"*" + errors.password.message}</p>}
                    {/* <span
                  className="toggle-password">
                  <img
                    src={Keyimg}
                    alt="Toggle Password"
                    width="20"
                    height="20"
                  />
                </span> */}
                  </div>
                </div>
                <button className="btn1" type="submit" style={{ border: '1px solid #0076AD', backgroundColor: '#0076AD' }}>{signinState.isLoading ? 'Logging In...' : 'Log In'}</button>
                <hr className="hr-text" data-content="Or" />
                <div className="d-flex justify-content-center">
                  <LoginSocialGoogle
                    client_id={process.env.REACT_APP_GG_APP_ID || ""}
                    scope="email"
                    onResolve={({ provider, data }: IResolveParams) => {
                      //console.log(data);
                      if (data)
                        authSocial({
                          provider: provider as string,
                          email: data?.email || "" as string,
                          name: data?.name || "" as string,
                          id: data.sub as string,
                        });
                    }}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.5" y="0.5" width="54" height="55" rx="27" stroke="#CACBCC" />
                      <path
                        d="M38.06 28.25C38.06 27.47 37.99 26.72 37.86 26H27.5V30.255H33.42C33.165 31.63 32.39 32.795 31.225 33.575V36.335H34.78C36.86 34.42 38.06 31.6 38.06 28.25Z"
                        fill="#4285F4" />
                      <path
                        d="M27.4999 38.9998C30.4699 38.9998 32.9599 38.0148 34.7799 36.3348L31.2249 33.5748C30.2399 34.2348 28.9799 34.6248 27.4999 34.6248C24.6349 34.6248 22.2099 32.6898 21.3449 30.0898H17.6699V32.9398C19.4799 36.5348 23.1999 38.9998 27.4999 38.9998Z"
                        fill="#34A853" />
                      <path
                        d="M21.345 30.0901C21.125 29.4301 21 28.7251 21 28.0001C21 27.2751 21.125 26.5701 21.345 25.9101V23.0601H17.67C16.9 24.5929 16.4993 26.2847 16.5 28.0001C16.5 29.7751 16.925 31.4551 17.67 32.9401L21.345 30.0901Z"
                        fill="#FBBC05" />
                      <path
                        d="M27.4999 21.375C29.1149 21.375 30.5649 21.93 31.7049 23.02L34.8599 19.865C32.9549 18.09 30.4649 17 27.4999 17C23.1999 17 19.4799 19.465 17.6699 23.06L21.3449 25.91C22.2099 23.31 24.6349 21.375 27.4999 21.375Z"
                        fill="#EA4335" />
                    </svg>
                  </LoginSocialGoogle>
                </div>
                <div className="d-flex">
                  <p>New user? <a href="/signup">Create an Account</a></p>
                </div>
              </Box>
            </FormProvider>
          </div>
        </div>
      </div>
      <LandingPageFooter />
    </div>
  );
};

export default SigninPage;