import LandingPageHeader from '../components/CentriLandingPage/LandingpageHeader';
import LandingPageFooter from '../components/CentriLandingPage/LandingpageFooter';
import homeImg from '../assets/image/home.jpeg';
import pinterestImg from '../assets/image/pinterest.jpeg';
import dribbelImg from '../assets/image/dribbel.jpeg';
import spotifyImg from '../assets/image/spotify.jpeg';
import workImg from '../assets/image/work.jpeg';
import StartBuldingResumeImg from '../assets/image/StartBuldingResume.jpg';
import '../components/Centri_CSS/landingpage.css';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
function SecondLandingPage() {
    const params = useParams()
    const navigate = useNavigate();
    const options = {
        loop: true,
        margin: 10,
        center: true,
        nav: true,
        dots: false,
        navText: ['<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d = "M16 4C9.37258 4 4 9.37259 4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4Z" stroke = "#3C8A37" stroke - width="2" stroke - miterlimit="10" /><path d="M15.2373 11.7623L10.9998 15.9998L15.2373 20.2373" stroke="#3C8A37" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M21 16L11 16" stroke="#3C8A37" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>', '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d = "M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke = "#3C8A37" stroke - width="2" stroke - miterlimit="10" /><path d="M16.7627 20.2377L21.0002 16.0002L16.7627 11.7627" stroke="#3C8A37" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M11 16H21" stroke="#3C8A37" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1024: {
                items: 3
            }
        },
        autoplay: true
    };

    const redirectToResume = () => {
        const isLogin = localStorage.getItem('logged_in');
        if (isLogin) {
            navigate('/codegenie/resume')
        } else {
            window.location.href = '/signup';
        }
    }
    useEffect(() => {
        const url = new URL(window.location.href);
        const propertyParam = url.searchParams.get('property');
        localStorage.setItem("property", propertyParam || "")
    }, [])
    return (
        <div style={{ overflowX: "hidden" }}>
            <LandingPageHeader />
            <section className="container-fluid">
                <div className="main container-fluid px-0">
                    <div className="content">
                        <div className="row">
                            <div className="col-md-6 set-content">
                                <div className="row">
                                    <h1> 
                                    Need a New or Better Resume? <br></br>You're in the Right Spot
                                    </h1>
                                </div>
                                <div className="row">
                                    <p>
                                    Potential employers are looking for someone like you. So how do you make a great first impression? Start with this cutting-edge tool. It's designed to help you build cover letters and resumes that capture attention—in all the right ways.
                                    </p>
                                </div>
                                <div className="">
                                    <button type="button" className="btn btn-primary" onClick={redirectToResume}>
                                        Create Your Resume
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={homeImg} alt="home" className="home-img" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Centri Section --> */}
                <section className="centri-section">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="centri-img">
                                <img src={workImg} alt="work" width="100%" height="100%" />
                            </div>
                        </div>

                        <div className="col-lg-7 unlock">
                            <h1 className="mb-1">Why Use the Centri Life Resume-Builder?</h1>

                            <div className="row centri-section-content mt-4">

                                <div className="col-12 col-md-6 " style={{ display: "flex", justifyContent: "space-around", flexDirection: "column" }}>
                                    <div className='set'>
                                        <h3 className="hedding" style={{ fontWeight: '900' }}>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.338 21.9375L14.938 28.45C14.8669 28.6413 14.739 28.8064 14.5714 28.9229C14.4038 29.0395 14.2046 29.102 14.0005 29.102C13.7963 29.102 13.5971 29.0395 13.4295 28.9229C13.2619 28.8064 13.134 28.6413 13.063 28.45L10.663 21.9375C10.6123 21.8 10.5324 21.6752 10.4288 21.5716C10.3252 21.468 10.2004 21.3881 10.063 21.3375L3.55045 18.9375C3.35908 18.8664 3.19402 18.7385 3.07747 18.5709C2.96091 18.4033 2.89844 18.2041 2.89844 18C2.89844 17.7958 2.96091 17.5966 3.07747 17.429C3.19402 17.2614 3.35908 17.1335 3.55045 17.0625L10.063 14.6625C10.2004 14.6118 10.3252 14.5319 10.4288 14.4283C10.5324 14.3248 10.6123 14.1999 10.663 14.0625L13.063 7.54996C13.134 7.35859 13.2619 7.19353 13.4295 7.07698C13.5971 6.96042 13.7963 6.89795 14.0005 6.89795C14.2046 6.89795 14.4038 6.96042 14.5714 7.07698C14.739 7.19353 14.8669 7.35859 14.938 7.54996L17.338 14.0625C17.3886 14.1999 17.4685 14.3248 17.5721 14.4283C17.6757 14.5319 17.8005 14.6118 17.938 14.6625L24.4505 17.0625C24.6418 17.1335 24.8069 17.2614 24.9234 17.429C25.04 17.5966 25.1025 17.7958 25.1025 18C25.1025 18.2041 25.04 18.4033 24.9234 18.5709C24.8069 18.7385 24.6418 18.8664 24.4505 18.9375L17.938 21.3375C17.8005 21.3881 17.6757 21.468 17.5721 21.5716C17.4685 21.6752 17.3886 21.8 17.338 21.9375V21.9375Z" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M22 2V8" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M25 5H19" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M28 9V13" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M30 11H26" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            AI-Powered
                                            Content
                                        </h3>
                                        <div className="para">
                                            <p>
                                            State-of-the-art AI technology builds content that highlights your skills, experience, and achievements.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='set'>
                                        <h3 className="hedding" style={{ fontWeight: '900' }}>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 12H20" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12 16H20" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12 20H16" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M19.5875 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H26C26.2652 5 26.5196 5.10536 26.7071 5.29289C26.8946 5.48043 27 5.73478 27 6V19.5875C27.0005 19.7174 26.9753 19.846 26.926 19.9661C26.8766 20.0862 26.8041 20.1955 26.7125 20.2875L20.2875 26.7125C20.1955 26.8041 20.0862 26.8766 19.9661 26.926C19.846 26.9753 19.7174 27.0005 19.5875 27V27Z" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M26.9125 20H20V26.9125" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            Easy Saving and Editing
                                        </h3>
                                        <div className="para">
                                            <p>
                                            Create an account to save your resumes + cover letters and edit them whenever you'd like.
                                            </p>
                                        </div>
                                    </div>

                                    <div className='set'>
                                        <h3 className="hedding" style={{ fontWeight: '900' }}>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 13L19 23" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.7252 22.9248L8.8252 26.8248" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.2252 19.4248L5.3252 23.3248" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M17.0125 11.5248L23.875 3.87483C24.4386 3.31125 25.203 2.99463 26 2.99463C26.797 2.99463 27.5614 3.31125 28.125 3.87483C28.6886 4.43842 29.0052 5.2028 29.0052 5.99983C29.0052 6.79686 28.6886 7.56125 28.125 8.12483L20.475 14.9873C20.3742 15.0782 20.293 15.1886 20.2362 15.3119C20.1794 15.4351 20.1484 15.5686 20.1448 15.7043C20.1413 15.8399 20.1655 15.9749 20.2158 16.1009C20.2661 16.2269 20.3415 16.3414 20.4375 16.4373L21.5875 17.5873C21.9585 17.9639 22.1665 18.4712 22.1665 18.9998C22.1665 19.5284 21.9585 20.0358 21.5875 20.4123L12 29.9998L2 19.9998L11.5875 10.4123C11.964 10.0413 12.4714 9.83336 13 9.83336C13.5286 9.83336 14.036 10.0413 14.4125 10.4123L15.5625 11.5623C15.6584 11.6583 15.7729 11.7337 15.8989 11.784C16.025 11.8344 16.1599 11.8585 16.2956 11.855C16.4312 11.8515 16.5647 11.8204 16.688 11.7636C16.8112 11.7069 16.9217 11.6256 17.0125 11.5248V11.5248Z" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>Customization
                                        </h3>
                                        <div className="para">
                                            <p>
                                            Choose from a range of templates and options to reflect your personal style.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 " style={{ display: "flex", justifyContent: "space-around", flexDirection: "column" }}>
                                    <div className='set'>
                                        <h3 className="hedding" style={{ fontWeight: '900' }}>
                                            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.5 27C16.5 27 4 20 4 11.5C4 9.99737 4.52062 8.54114 5.47328 7.37908C6.42593 6.21703 7.75178 5.42093 9.22525 5.12624C10.6987 4.83154 12.2288 5.05646 13.5551 5.76272C14.8814 6.46898 15.9221 7.61296 16.5 9.00001V9.00001C17.0779 7.61296 18.1186 6.46898 19.4449 5.76272C20.7712 5.05646 22.3013 4.83154 23.7748 5.12624C25.2482 5.42093 26.5741 6.21703 27.5267 7.37908C28.4794 8.54114 29 9.99737 29 11.5C29 20 16.5 27 16.5 27Z" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            User-Friendly Interface
                                        </h3>
                                        <div className="para">
                                            <p>
                                                {/*Creating a professional resume has never been easier. You'll be guided through the process step by step. */}
                                                You'll be guided through the process step by step.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='set'>
                                        <h3 className="hedding" style={{ fontWeight: '900' }}>
                                            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M26.5 11H6.5C5.94772 11 5.5 11.4477 5.5 12V26C5.5 26.5523 5.94772 27 6.5 27H26.5C27.0523 27 27.5 26.5523 27.5 26V12C27.5 11.4477 27.0523 11 26.5 11Z" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12 11V6.5C12 5.30653 12.4741 4.16193 13.318 3.31802C14.1619 2.47411 15.3065 2 16.5 2C17.6935 2 18.8381 2.47411 19.682 3.31802C20.5259 4.16193 21 5.30653 21 6.5V11" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16.5 20.5C17.3284 20.5 18 19.8284 18 19C18 18.1716 17.3284 17.5 16.5 17.5C15.6716 17.5 15 18.1716 15 19C15 19.8284 15.6716 20.5 16.5 20.5Z" fill="#1D4ED8" />
                                            </svg>
                                            Privacy and
                                            Security
                                        </h3>
                                        <div className="para">
                                            <p>
                                                We take your privacy and data security seriously. Your information is safe with
                                                us.
                                            </p>
                                        </div>
                                    </div>

                                    <div className='set'>
                                        <h3 className="hedding" style={{ fontWeight: '900' }}>
                                            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.5 9H12.5" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M20.5 13H12.5" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M25.5 27V5C25.5 4.44772 25.0523 4 24.5 4L8.5 4C7.94772 4 7.5 4.44772 7.5 5V27C7.5 27.5523 7.94772 28 8.5 28H24.5C25.0523 28 25.5 27.5523 25.5 27Z" stroke="#1D4ED8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16.5 24C17.3284 24 18 23.3284 18 22.5C18 21.6716 17.3284 21 16.5 21C15.6716 21 15 21.6716 15 22.5C15 23.3284 15.6716 24 16.5 24Z" fill="#1D4ED8" />
                                            </svg>
                                            Access
                                            Anywhere, Anytime
                                        </h3>
                                        <div className="para">
                                            <p>
                                                Create or update your resume on any device with an internet connection. Your
                                                resume is always at your fingertips.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="textimonal-section">
                    <span>Testimonals</span>
                    <div className="row">
                        <h2>What Users Are Saying</h2>
                    </div>

                    <OwlCarousel className="owl-theme" {...options}>

                        <div className="card">
                            <div className="d-flex justify-content-between">
                                <p className="card-title">“Centri Life's AI created targeted resumes that got me noticed. Quick, personalized, and incredibly effective!”
                                </p>
                                <svg width="18" height="18" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_0_1561)">
                                        <path
                                            d="M12.5296 3.01687C12.5381 3.14452 12.5381 3.27216 12.5381 3.40099C12.5381 7.32628 9.67035 11.8533 4.42656 11.8533V11.851C2.87753 11.8533 1.36067 11.391 0.0566406 10.5192C0.281883 10.5475 0.508254 10.5616 0.735189 10.5622C2.0189 10.5633 3.26591 10.1145 4.27583 9.28804C3.05591 9.26393 1.98616 8.4351 1.61245 7.2251C2.03979 7.31099 2.48011 7.29334 2.89954 7.17393C1.56954 6.89393 0.612689 5.67628 0.612689 4.26216C0.612689 4.24922 0.612689 4.23687 0.612689 4.22452C1.00898 4.45452 1.45269 4.58216 1.90656 4.59628C0.653899 3.72393 0.26777 1.98746 1.02422 0.62981C2.47164 2.48569 4.6072 3.61393 6.8997 3.73334C6.66995 2.70157 6.98382 1.6204 7.72446 0.895104C8.87269 -0.229602 10.6786 -0.171955 11.7579 1.02393C12.3964 0.892751 13.0083 0.648633 13.5683 0.302751C13.3555 0.990398 12.9101 1.57452 12.3151 1.94569C12.8802 1.87628 13.4323 1.71863 13.9522 1.47804C13.5695 2.07569 13.0874 2.59628 12.5296 3.01687Z"
                                            fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1561">
                                            <rect width="14" height="12" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <div className="card-footer" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src="https://mdbcdn.b-cdn.net/img/new/avatars/1.webp" className="rounded-circle"
                                    style={{ width: "30px", height: "30px" }} alt="Avatar" />
                                <div>
                                    <h6>Jake W.</h6>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="d-flex justify-content-between align-items-start">
                                <p className="card-title">“Centri Life made resume writing effortless and highly effective. It’s a must-have for serious job hunters!”
                                </p>
                                <svg width="18" height="18" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_0_1561)">
                                        <path
                                            d="M12.5296 3.01687C12.5381 3.14452 12.5381 3.27216 12.5381 3.40099C12.5381 7.32628 9.67035 11.8533 4.42656 11.8533V11.851C2.87753 11.8533 1.36067 11.391 0.0566406 10.5192C0.281883 10.5475 0.508254 10.5616 0.735189 10.5622C2.0189 10.5633 3.26591 10.1145 4.27583 9.28804C3.05591 9.26393 1.98616 8.4351 1.61245 7.2251C2.03979 7.31099 2.48011 7.29334 2.89954 7.17393C1.56954 6.89393 0.612689 5.67628 0.612689 4.26216C0.612689 4.24922 0.612689 4.23687 0.612689 4.22452C1.00898 4.45452 1.45269 4.58216 1.90656 4.59628C0.653899 3.72393 0.26777 1.98746 1.02422 0.62981C2.47164 2.48569 4.6072 3.61393 6.8997 3.73334C6.66995 2.70157 6.98382 1.6204 7.72446 0.895104C8.87269 -0.229602 10.6786 -0.171955 11.7579 1.02393C12.3964 0.892751 13.0083 0.648633 13.5683 0.302751C13.3555 0.990398 12.9101 1.57452 12.3151 1.94569C12.8802 1.87628 13.4323 1.71863 13.9522 1.47804C13.5695 2.07569 13.0874 2.59628 12.5296 3.01687Z"
                                            fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1561">
                                            <rect width="14" height="12" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <div className="card-footer" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp" className="rounded-circle"
                                    style={{ width: "30px", height: "30px" }} alt="Avatar" />
                                <div>
                                    <h6>Walter M.</h6>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="d-flex justify-content-between align-items-start">
                                <p className="card-title">“ Centri Life transformed my job applications. Each resume and cover letter was perfectly customized. Got more interviews than ever!”
                                </p>
                                <svg width="18" height="18" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_0_1561)">
                                        <path
                                            d="M12.5296 3.01687C12.5381 3.14452 12.5381 3.27216 12.5381 3.40099C12.5381 7.32628 9.67035 11.8533 4.42656 11.8533V11.851C2.87753 11.8533 1.36067 11.391 0.0566406 10.5192C0.281883 10.5475 0.508254 10.5616 0.735189 10.5622C2.0189 10.5633 3.26591 10.1145 4.27583 9.28804C3.05591 9.26393 1.98616 8.4351 1.61245 7.2251C2.03979 7.31099 2.48011 7.29334 2.89954 7.17393C1.56954 6.89393 0.612689 5.67628 0.612689 4.26216C0.612689 4.24922 0.612689 4.23687 0.612689 4.22452C1.00898 4.45452 1.45269 4.58216 1.90656 4.59628C0.653899 3.72393 0.26777 1.98746 1.02422 0.62981C2.47164 2.48569 4.6072 3.61393 6.8997 3.73334C6.66995 2.70157 6.98382 1.6204 7.72446 0.895104C8.87269 -0.229602 10.6786 -0.171955 11.7579 1.02393C12.3964 0.892751 13.0083 0.648633 13.5683 0.302751C13.3555 0.990398 12.9101 1.57452 12.3151 1.94569C12.8802 1.87628 13.4323 1.71863 13.9522 1.47804C13.5695 2.07569 13.0874 2.59628 12.5296 3.01687Z"
                                            fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1561">
                                            <rect width="14" height="12" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="card-footer" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src="https://mdbcdn.b-cdn.net/img/new/avatars/3.webp" className="rounded-circle"
                                    style={{ width: "30px", height: "30px" }} alt="Avatar" />
                                <div >
                                    <h6>Steve B.</h6>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="d-flex justify-content-between align-items-start">
                                <p className="card-title">“Incredible accuracy in tailoring my resume with Centri Life! It saved hours and significantly boosted my job prospects.”
                                </p>
                                <svg width="18" height="18" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_0_1561)">
                                        <path
                                            d="M12.5296 3.01687C12.5381 3.14452 12.5381 3.27216 12.5381 3.40099C12.5381 7.32628 9.67035 11.8533 4.42656 11.8533V11.851C2.87753 11.8533 1.36067 11.391 0.0566406 10.5192C0.281883 10.5475 0.508254 10.5616 0.735189 10.5622C2.0189 10.5633 3.26591 10.1145 4.27583 9.28804C3.05591 9.26393 1.98616 8.4351 1.61245 7.2251C2.03979 7.31099 2.48011 7.29334 2.89954 7.17393C1.56954 6.89393 0.612689 5.67628 0.612689 4.26216C0.612689 4.24922 0.612689 4.23687 0.612689 4.22452C1.00898 4.45452 1.45269 4.58216 1.90656 4.59628C0.653899 3.72393 0.26777 1.98746 1.02422 0.62981C2.47164 2.48569 4.6072 3.61393 6.8997 3.73334C6.66995 2.70157 6.98382 1.6204 7.72446 0.895104C8.87269 -0.229602 10.6786 -0.171955 11.7579 1.02393C12.3964 0.892751 13.0083 0.648633 13.5683 0.302751C13.3555 0.990398 12.9101 1.57452 12.3151 1.94569C12.8802 1.87628 13.4323 1.71863 13.9522 1.47804C13.5695 2.07569 13.0874 2.59628 12.5296 3.01687Z"
                                            fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1561">
                                            <rect width="14" height="12" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="card-footer" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src="https://mdbcdn.b-cdn.net/img/new/avatars/4.webp" className="rounded-circle"
                                    style={{ width: "30px", height: "30px" }} alt="Avatar" />
                                <div>
                                    <h6>Susan K.</h6>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="d-flex justify-content-between align-items-start">
                                <p className="card-title">“Truly amazed at how Centri Life tailored my resume for each job. It’s fast, precise, and a total game-changer.”
                                </p>
                                <svg width="18" height="18" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_0_1561)">
                                        <path
                                            d="M12.5296 3.01687C12.5381 3.14452 12.5381 3.27216 12.5381 3.40099C12.5381 7.32628 9.67035 11.8533 4.42656 11.8533V11.851C2.87753 11.8533 1.36067 11.391 0.0566406 10.5192C0.281883 10.5475 0.508254 10.5616 0.735189 10.5622C2.0189 10.5633 3.26591 10.1145 4.27583 9.28804C3.05591 9.26393 1.98616 8.4351 1.61245 7.2251C2.03979 7.31099 2.48011 7.29334 2.89954 7.17393C1.56954 6.89393 0.612689 5.67628 0.612689 4.26216C0.612689 4.24922 0.612689 4.23687 0.612689 4.22452C1.00898 4.45452 1.45269 4.58216 1.90656 4.59628C0.653899 3.72393 0.26777 1.98746 1.02422 0.62981C2.47164 2.48569 4.6072 3.61393 6.8997 3.73334C6.66995 2.70157 6.98382 1.6204 7.72446 0.895104C8.87269 -0.229602 10.6786 -0.171955 11.7579 1.02393C12.3964 0.892751 13.0083 0.648633 13.5683 0.302751C13.3555 0.990398 12.9101 1.57452 12.3151 1.94569C12.8802 1.87628 13.4323 1.71863 13.9522 1.47804C13.5695 2.07569 13.0874 2.59628 12.5296 3.01687Z"
                                            fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1561">
                                            <rect width="14" height="12" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="card-footer" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src="https://mdbcdn.b-cdn.net/img/new/avatars/5.webp" className="rounded-circle"
                                    style={{ width: "30px", height: "30px" }} alt="Avatar" />
                                <div>
                                    <h6>Mary S.</h6>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="d-flex justify-content-between align-items-start">
                                <p className="card-title">“ Centri Life transformed my job applications. Each resume and cover letter was perfectly customized. Got more interviews than ever!”
                                </p>
                                <svg width="18" height="18" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_0_1561)">
                                        <path
                                            d="M12.5296 3.01687C12.5381 3.14452 12.5381 3.27216 12.5381 3.40099C12.5381 7.32628 9.67035 11.8533 4.42656 11.8533V11.851C2.87753 11.8533 1.36067 11.391 0.0566406 10.5192C0.281883 10.5475 0.508254 10.5616 0.735189 10.5622C2.0189 10.5633 3.26591 10.1145 4.27583 9.28804C3.05591 9.26393 1.98616 8.4351 1.61245 7.2251C2.03979 7.31099 2.48011 7.29334 2.89954 7.17393C1.56954 6.89393 0.612689 5.67628 0.612689 4.26216C0.612689 4.24922 0.612689 4.23687 0.612689 4.22452C1.00898 4.45452 1.45269 4.58216 1.90656 4.59628C0.653899 3.72393 0.26777 1.98746 1.02422 0.62981C2.47164 2.48569 4.6072 3.61393 6.8997 3.73334C6.66995 2.70157 6.98382 1.6204 7.72446 0.895104C8.87269 -0.229602 10.6786 -0.171955 11.7579 1.02393C12.3964 0.892751 13.0083 0.648633 13.5683 0.302751C13.3555 0.990398 12.9101 1.57452 12.3151 1.94569C12.8802 1.87628 13.4323 1.71863 13.9522 1.47804C13.5695 2.07569 13.0874 2.59628 12.5296 3.01687Z"
                                            fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_0_1561">
                                            <rect width="14" height="12" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="card-footer" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src="https://mdbcdn.b-cdn.net/img/new/avatars/3.webp" className="rounded-circle"
                                    style={{ width: "30px", height: "30px" }} alt="Avatar" />
                                <div>
                                    <h6>Steve B.</h6>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </section> */}
                <hr></hr>
                {/* <!-- Start Building Page --> */}
                <section className="start-building-section">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <img src={StartBuldingResumeImg} alt="Build" width="100%" />
                        </div>
                        <div className="col-12 col-md-6 start-content">
                            <div>
                                <h2>Start Building Your Success Story Today</h2>
                                <p>Great resumes open doors. Are you ready to unlock new possibilities for your future?</p>
                                <button className="btn btn-primary" onClick={redirectToResume}>Create Your Resume</button>
                            </div>
                        </div>
                    </div>
                </section>


            </section>

            <LandingPageFooter />
        </div >
    )
}

export default SecondLandingPage
