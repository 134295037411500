import UserDashboard from '../components/DashboardSection';
import ResumeBuilderHeader from '../components/headers/ResumeBuilderHeader';

const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const DashboardPage = () => {
    return <div>

        <ResumeBuilderHeader />


        <UserDashboard />

    </div >;
}


export default DashboardPage;