import {
  Container,
  Box,
  Typography,
  Button,
  SvgIcon,
  Stack,
  Avatar,
} from "@mui/material";
import { ArrowForward, Margin } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import { Arrowcircleright } from "../assets/icons/Arrowcircleright";
import { Arrowcircleright1 } from "../assets/icons/Arrowcircleright1";
import { Arrowright1 } from "../assets/icons/Arrowright1";
import { Arrowright5 } from "../assets/icons/Arrowright5";
import { Bracketscurly2 } from "../assets/icons/Bracketscurly2";
import { Bugbeetle } from "../assets/icons/Bugbeetle";
import { Caretright1 } from "../assets/icons/Caretright1";
import { Caretright2 } from "../assets/icons/Caretright2";
import { Caretup } from "../assets/icons/Caretup";
import { Caretup1 } from "../assets/icons/Caretup1";
import { Circlewavy1 } from "../assets/icons/Circlewavy1";
import { Code3 } from "../assets/icons/Code3";
import { Cpu1 } from "../assets/icons/Cpu1";
import { Diamondsfour } from "../assets/icons/Diamondsfour";
import { Eye } from "../assets/icons/Eye";
import { Gitbranch } from "../assets/icons/Gitbranch";
import { Gitpullrequest } from "../assets/icons/Gitpullrequest";
import { Gradient1 } from "../assets/icons/Gradient1";
import { Image1 } from "../assets/icons/Image1";
import { Note1 } from "../assets/icons/Note1";
import { Paperplanetilt1 } from "../assets/icons/Paperplanetilt1";
import { Playcircle } from "../assets/icons/Playcircle";
import { Tag } from "../assets/icons/Tag";
import { Target } from "../assets/icons/Target";
import { Terminalwindow } from "../assets/icons/Terminalwindow";
import { Treestructure2 } from "../assets/icons/Treestructure2";
import { Usergear } from "../assets/icons/Usergear";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';



import img1 from "../assets/newimg/img-1.png";
// import img2 from "../assets/newimg/body-img.png"; 
import img2 from "../assets/newimg/body-img.png";
import text2 from "../assets/newimg/text-2.png";
import img3 from "../assets/newimg/icon1.png";
import img4 from "../assets/newimg/icon2.png";
import img5 from "../assets/newimg/icon3.png";
import img6 from "../assets/newimg/icon4.png";
import img7 from "../assets/newimg/icon5.png";
import img8 from "../assets/newimg/icon6.png";
import img9 from "../assets/newimg/icon7.png";
import img10 from "../assets/newimg/icon8.png";
import img11 from "../assets/newimg/icon9.png";
import img12 from "../assets/newimg/icon10.png";
import img13 from "../assets/newimg/icon11.png";
import img14 from "../assets/newimg/icon12.png";
import img15 from "../assets/newimg/icon13.png";
import img16 from "../assets/newimg/icon14.png";
import img17 from "../assets/newimg/icon15.png";
import img18 from "../assets/newimg//lock.png";
import img19 from "../assets/newimg/icon-1-1.png";
import img20 from "../assets/newimg/icon-1-2.png";
import img21 from "../assets/newimg/icon-1-3.png";
import img22 from "../assets/newimg/line.png";
import img23 from "../assets/newimg//lineicon1.png";
import img24 from "../assets/newimg/line.png";
import img25 from "../assets/newimg/lineicon2.png";
import img55 from "../assets/newimg/lineicon3.png";
import img26 from "../assets/newimg/line.png";
import img27 from "../assets/newimg/lineicon3.png";
import img28 from "../assets/newimg/mask_group-img.png";
import img29 from "../assets/newimg/bt_icon1.png";
import img30 from "../assets/newimg/bt_icon2.png";
import img31 from "../assets/newimg/bt_icon3.png";
import img32 from "../assets/newimg/bt_icon4.png";
import img33 from "../assets/newimg/bt_icon5.png";
import img34 from "../assets/newimg/bt_icon6.png";
import img35 from "../assets/newimg/bt_icon7.png";
import img36 from "../assets/newimg/bt_icon8.png";
import img37 from "../assets/newimg/bt_icon9.png";
import img38 from "../assets/newimg/icon-1-1.png";
import img39 from "../assets/newimg/icon-1-2.png";
import img40 from "../assets/newimg//icon-1-3.png";
import img41 from "../assets/newimg/icon-1-3.png";
import img42 from "../assets/newimg/icon-1-3.png";
import img43 from "../assets/newimg/gridicon1.png";
import img44 from "../assets/newimg/gridicon1.png";
import img45 from "../assets/newimg/gridicon1.png";
import img46 from "../assets/newimg/gridicon1.png";
import img47 from "../assets/newimg/img-1.png";
import img48 from "../assets/newimg/img-1.png";
import img49 from "../assets/newimg/img-1.png";
import img50 from "../assets/newimg/img-1.png";
import img51 from "../assets/newimg/card-img-1.png";
import img52 from "../assets/newimg/card-img-2.png";
import img53 from "../assets/newimg/card-img-3.png";
import img54 from "../assets/newimg/logo.png";
import video1 from "../assets/newimg/video.mp4";
import text1 from "../assets/newimg/text-1.png";
import body2 from "../assets/newimg/text-2.png";
import lock from "../assets/img/lock.png";
import python from "../assets/img/pythonicon.png";
import react from "../assets/img/react.png";
import vue from "../assets/img/vuejs.png";
import angular from "../assets/img/angularjsbig.png";
import nodejs from "../assets/img/nodejs.png";
import java from "../assets/img/java.png";
import cprogram from "../assets/img/c#.png";
import c1 from "../assets/img/c++.png";
import c2 from "../assets/img/c.png";
import php from "../assets/img/php.png";
import ruby from "../assets/img/ruby.png";
import go from "../assets/img/go.png";
import typescript from "../assets/img/typescript.png";
import swift from "../assets/img/swift.png";
import kotlin from "../assets/img/kotlin.png";
import rust from "../assets/img/rust.png";
import scala from "../assets/img/scala.png";
import r from "../assets/img/r.png";
import matlab from "../assets/img/matlab.png";
import perl from "../assets/img/perl.png";
import javascript1 from "../assets/img/javascript1.png";
import html5 from "../assets/img/html5.png";
import css from "../assets/img/css.png";
import sql from "../assets/img/sql.png";
import dart from "../assets/img/dart.png";
import flutter from "../assets/img/flutter.png";
import icon1 from "../assets/img/icon-1-1.png";
import iconbg from "../assets/img/icon-bg.png";
import gridicon2 from "../assets/newimg/gridicon2.png";
import gridicon3 from "../assets/newimg/gridicon3.png";
import gridicon4 from "../assets/newimg/gridicon4.png";
import logo from "../assets/newimg/logo.png";

import blog1 from "../assets/img/card-img-1.png";
import blog2 from "../assets/img/card-img-2.png";
//import blog3 from "../assets/img/blogs3.png";
import blog4 from "../assets/img/card-img-3.png";

import testimonial1 from "../assets/img/ellipse-34-2.png";
import testimonial2 from "../assets/img/ellipse-34-1.png";
import testimonial3 from "../assets/img/ellipse-34.svg";

// import "./newstyle.css";

/* imports for centriLife */
import '../components/Centri_CSS/landingpage.css';

import centriLogo from '../assets/image/logo-centri-life-white-color.png';
import homeImg from '../assets/image/home.jpeg';
import pinterestImg from '../assets/image/pinterest.jpeg';
import dribbelImg from '../assets/image/dribbel.jpeg';
import spotifyImg from '../assets/image/spotify.jpeg';
import laptopImg from '../assets/image/Laptop.jpeg';
import workImg from '../assets/image/work.jpeg';
import sparkleImg from '../assets/image/Sparkle.jpeg';
import fileImg from '../assets/image/file.jpeg';
import brushImg from '../assets/image/brush.jpeg';

import LandingPageHeader from "../components/CentriLandingPage/LandingpageHeader";
import LandingPageFooter from "../components/CentriLandingPage/LandingpageFooter";


/*  */

// import ".assests/Anycode.css";
import LandingHeader from "../components/headers/LandingHeader.headers";
import FAQItem from "../components/FAQItem";
import AuditItem from "../components/AuditItem";
import SliderItem from "../components/SliderItem";
import ItemsCarousel from 'react-items-carousel';
import TestimonialItem from "../components/TestimonialItem";
import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import { useState } from "react";
import { IGetBlogs } from "../redux/api/types";
//import { useGetBlogDetailsQuery } from "../redux/api/promptApi";

import { object, string, TypeOf } from "zod";
import { useSubscribeNewsMutation } from "../redux/api/authApi";
import { toast } from "react-toastify";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";




const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>


const faqs = [
  {
    title: "What is Code Genie?",
    content:
      "Code Genie is an advanced AI-driven tool designed to audit smart contracts from various blockchain networks. It employs OpenAI's LLM model to provide comprehensive and accurate analysis of smart contract codes.",
  },
  {
    title: "How does Code Genie differ from traditional smart contract auditing methods?",
    content:
      "Unlike traditional methods that rely on manual scrutiny, Code Genie uses artificial intelligence to rapidly analyze and rectify smart contract vulnerabilities, ensuring enhanced accuracy and speed.",
  },
  {
    title: "How long does a Code Genie audit take?",
    content:
      "Leveraging AI technology, Code Genie can complete a full smart contract audit in mere seconds with just a single click.",
  },
  {
    title:
      "Is Code Genie suitable for users without coding knowledge?",
    content:
      "Absolutely! Code Genie is designed to be user-friendly. You do not need to possess coding expertise to audit your contracts using Code Genie.",
  },
  {
    title:
      "How does Code Genie ensure the upgradability of smart contracts?",
    content:
      "Code Genie's audit encompasses various aspects of smart contracts, including upgradability. It checks for future-readiness, ensuring that your contracts can adapt to changes and innovations in the blockchain ecosystem.",
  },
  {
    title: "How cost-effective is using Code Genie compared to other auditing services?",
    content:
      "Code Genie offers high-quality auditing services at a fraction of the industry's average cost. With both free and paid plans available, it provides affordable solutions for startups, developers, and agencies alike.",
  },
  {
    title: "Can Code Genie detect and rectify all types of vulnerabilities in a smart contract?",
    content:
      "Code Genie offers a comprehensive audit, identifying a wide range of vulnerabilities from security to gas usage. Moreover, it provides real-time solutions to rectify detected issues.",
  },
  {
    title: "How secure is the information and code I share with Code Genie?",
    content:
      "User security and data privacy are top priorities for Code Genie. All information and code shared are processed securely, ensuring that your data remains confidential.",
  },
  {
    title: "What blockchain networks does Code Genie support?",
    content:
      "Code Genie is versatile and designed to audit smart contracts from a myriad of blockchain networks. Detailed specifications can be found on the platform.",
  },
  {
    title: "How can I get started with Code Genie?",
    content:
      "Simply visit the Code Genie website, sign up, and you can start auditing your smart contracts with the click of a button. The platform also offers tutorials and guides to assist newcomers.",
  }
];

const audits = [
  {
    title: "Functionality: Ensuring Your Smart Contracts Work as Intended",
    icon: <Bracketscurly2 className="icon-instance-node-4" color="white" />,
    content: "Functionality lies at the core of any smart contract. With Code Genie, you can be confident that your smart contracts will execute their intended functions flawlessly. Our AI-powered auditing tool meticulously analyzes your code to identify any discrepancies or errors that might hinder your contract's functionality.",
  },
  {
    title: "Security: Safeguarding Your Smart Contracts from Threats",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "Security breaches can be catastrophic for your smart contracts. Code Genie's robust security checks go beyond conventional methods, identifying potential vulnerabilities and recommending best practices to mitigate risks. Protect your smart contracts from malicious attacks and unauthorized access with Code Genie.",
  },
  {
    title: "Gas Usage: Optimizing Efficiency and Cost-effectiveness",
    icon: <Gitpullrequest className="icon-instance-node-4" color="white" />,
    content: "Efficient gas usage is essential for optimal performance. Code Genie not only identifies gas-guzzling code segments but also suggests optimizations to reduce transaction costs. Save both time and money by ensuring your smart contracts run smoothly without wasting unnecessary resources.",
  },
  {
    title: "Visibility: Enhancing Transparency and Traceability",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "Transparency is key in the blockchain world. Code Genie enhances the visibility of your smart contracts by pinpointing areas where transparency could be improved. By fostering clear and traceable code, your users can trust the integrity of your smart contracts and make informed decisions.",
  }, {
    title: "Variables: Thorough Analysis of Data Storage",
    icon: <Code3 className="icon-instance-node-4" color="white" />,
    content: "Smart contracts rely on variables to store crucial data. Code Genie examines your variable declarations and usage patterns, ensuring that data storage is efficient and logically structured. Eliminate potential data inconsistencies with our comprehensive variable analysis.",
  }, {
    title: "Event Logs: Capturing Crucial Contract Interactions",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "Event logs play a vital role in understanding contract interactions. Code Genie reviews your event logging mechanisms, making certain that all significant interactions are appropriately captured. Gain valuable insights into your smart contract's execution for both auditing and analysis purposes.",
  }, {
    title: "Upgradability: Planning for Future Contract Evolution",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "Adapting your smart contracts over time is essential. Code Genie assesses your contract's upgradability by examining your upgrade mechanisms and compatibility considerations. Ensure your contracts can evolve while maintaining security and functionality.",
  }, {
    title: "Ownership: Clarifying Contract Ownership and Access Control",
    icon: <Usergear className="user-gear" />,
    content: "Ownership structures define who controls your smart contracts. Code Genie reviews your ownership patterns and access control mechanisms, helping you establish clear ownership guidelines and prevent unauthorized modifications.",
  }, {
    title: "Balance: Validating Token and Asset Management",
    icon: <Circlewavy1 className="icon-instance-node-4" color="white" />,
    content: "Balances are critical in token and asset management contracts. Code Genie validates your balance management functions, ensuring that token transfers and asset distributions are executed accurately and securely.",
  }, {
    title: "Fallback: Managing Unintended Contract Interactions",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "Fallback functions handle unexpected interactions with your contracts. Code Genie analyzes your fallback mechanisms, ensuring they are well-defined and protect your contracts from unintended behavior.",
  }, {
    title: "Self Destruct: Secure Contract Closure",
    icon: <Bugbeetle className="icon-instance-node-4" />,
    content: "Properly terminating a contract is as important as deploying it. Code Genie examines your self-destruct functions, verifying that contract closure is executed securely and with appropriate considerations for remaining funds or assets.",
  }, {
    title: "Exploit Scenarios: Identifying Potential Attack Vectors",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "Threats can come from various angles. Code Genie simulates potential exploit scenarios, helping you anticipate and prevent vulnerabilities before they are exploited by malicious actors.",
  },
];


const slider = [
  {
    title: "Extremely Accurate",
    icon: <Target className="icon-instance-node-3" color="white" />,
    content: " Code Genie uses a Large Language Model AI that conducts a full analysis of the smart contract code The AI will detect issues that might be overlooked by human eyes. Code Genie will even attempt to fix the bugs and issues found in your contract, assisting your developers in real time. See below for the various types of analysis performed by our AI.",
  },
  {
    title: "Extremely Fast &amp; Easy to Use",
    icon: <Paperplanetilt1 className="icon-instance-node-3" color="white" />,
    content: "Leveraging new AI technology, Code Genie can conduct a full smart contract audit in just a few seconds with a single click.You don't need any coding knowledge to self-audit your contracts. Try it for free...",
  },
  {
    title: "Extemely Affordable",
    icon: <Tag className="icon-instance-node-3" color="white" />,
    content: " Code Genie costs a fraction of the industry average. Our do-it-yourself approach could save you between $5,000 to $15,000+, the typical amount spent on auditing smart contracts in the industry.",
  },
  {
    title: "Secure your Digital Assets",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: " Code Genie makes it easy for blockchain teams building NFTs, DeFi, crypto, and other projects to self-audit their smart contracts. If at any time you wish to verify if your contract is safe, you can use Code Genie for an instant analysis and receive feedback with just one click.",
  }, {
    title: "The Value",
    icon: <Code3 className="icon-instance-node-4" color="white" />,
    content: " With Code Genie, blockchain projects can now self-audit their smart contracts instantly and securely with just one click, saving time, money, and reducing the risk of hacking or security fraud.",
  }, {
    title: ">Bug Free Contracts",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "Ultimately, by using Code Genie, companies can feel more confident about their deployed contracts. Code Genie helps identify vulnerabilities in smart contracts and assists developers in resolving these issues. Thus, Code Genie can optimize the company's development process, increase its development team's efficiency, and deliver high-quality, bug-free software products in a timely manner.",

  },
];


const testimonials = [
  {
    name: "Kristina Bruhahn - Continuum CEO,",
    icon: <img src={testimonial1} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
    content: "This is excellent for the industry and I love the data points.",
    reference: "Blockchain Compliance Expert"
  },
  {
    name: "Rutvik G.",
    icon: <img src={testimonial2} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
    content: "Code Genie gives all functions analysis and the kind of stuff that is in the function. So it is good to have an auditing perspective. Accuracy is also excellent. (regarding a Solidity smart contract Functionality audit)",
    reference: "Smart Contract Developer"
  },
  {
    name: "Jamal Woodley - CEO,",
    icon: <img src={testimonial3} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
    content: "I was able to use Code Genie to resolve the issue that I was having with a smart contract in just a few minutes. Surprising excellent time saver no headaches Rohan #mintmadecerfied let’s go.",
    reference: "Conceptualize AI Inc"
  }
];

/*const blogs = [
  {
      title: "A Dive into Decentralization: How Code Genie Elevates Smart Contracts",
      image: <img className="card-img-top" src={blog1} alt="" />,
      text: "In the vast cosmos of technological advancements, decentralization emerges as the North Star, guiding countless innovations and reshaping the very bedrock of digital interactions. At the heart of this transformative paradigm is the realm of smart contracts—self-executing contracts with the agreement directly written into lines of code. But as powerful as they are, ensuring their security, efficiency, and functionality is a task that demands a unique blend of precision, speed, and foresight. This is where Code Genie enters the scene, not just as another tool but as a revolution in itself.",
      href: "/blogs/A_Drive_into_Decentralization",
  },
  {
      title: "The AI Advantage: Code Genie's Mastery Over Traditional Auditing Methods",
      image: <img className="card-img-top" src={blog2} alt="" />,
      text: "In the intricate landscape of blockchain technology, smart contracts stand as pillars of innovation, offering a transparent, tamper-proof way to codify and automate agreements. As powerful as they might be, these contracts are not immune to human errors, and any mistake can lead to devastating financial consequences. Enter the world of smart contract auditing, where the focus isn't just on detecting problems but predicting them, mitigating them, and ensuring the seamless execution of every agreement. While traditional auditing methods have made significant strides, they are increasingly being overshadowed by the prowess of AI-driven tools, with Code Genie leading the charge.",
      href: "/blogs/The_AI_Advantage",
  },
  {
      title: "Beyond Security: The Multi-Dimensional Benefits of Code Genie’s Audits",
      image: <img className="card-img-top" src={blog4} alt="" />,
      text: "At the heart of the decentralized revolution, smart contracts are changing the way we transact, trust, and transform digital agreements. Given their pivotal role in underpinning a myriad of applications, the security of these contracts naturally comes to the forefront. However, while security remains paramount, it's merely one dimension of a broader spectrum that defines a robust smart contract. With Code Genie's innovative approach to audits, the horizon expands beyond mere security checks, unveiling a comprehensive suite of benefits that position smart contracts for optimal performance and longevity.",
      href:"/blogs/Beyond_Security",
  },
  {
      title: "Unveiling Code Genie: Revolutionizing Smart Contract Audits in the Blockchain Era",
      image: <img className="card-img-top" src={blog5} alt="" />,
      text: "The world of smart contracts is intricate and complex. Ensuring their flawlessness is paramount, not just for functionality but also to fortify them against potential breaches and vulnerabilities. Enter Code Genie – a groundbreaking AI tool that is redefining how we perceive smart contract audits.",
      href:"/blogs/Unveiling_Code_Genie",
  },
  {
      title: "Cost-Effective Auditing: Exploring Code Genie's Affordability in Blockchain",
      image: <img className="card-img-top" src={blog6} alt="" />,
      text: "The blockchain sphere is witnessing an exponential surge in the deployment of smart contracts, paving the way for a new era of digital agreements. However, as the complexity and intricacy of these contracts grow, so does the need for rigorous auditing. Traditionally, this has translated into substantial financial investments, often becoming a deterrent for startups and small projects. Enter Code Genie - an innovative solution that's not only pushing the boundaries of audit precision but also making it economically viable for all. Let's embark on a journey to unpack the affordability of Code Genie's auditing services.",
      href:"/blogs/Cost_Effective_Auditing",
  },
  {
      title: "A Novice in Blockchain? Here's Why Code Genie is Your Best Bet",
      image: <img className="card-img-top" src={blog7} alt="" />,
      text: "Blockchain, with its disruptive potential, is luring enthusiasts from all walks of life. But as novices dive deep into this ocean of possibilities, they often find themselves grappling with the complexities of smart contracts. These self-executing contracts, albeit powerful, require meticulous attention to detail. The stakes are high, and the margin for error? Minuscule. If you find yourself tiptoeing on the edge of this intimidating precipice, Code Genie is here to offer a safety net. Here's how this revolutionary tool ensures that even those without a coding background can navigate the smart contract maze with confidence.",
      href:"/blogs/A_Novice_In_Blockchain",
  },
  {
      title: "One Click, Multiple Solutions: The Comprehensive Auditing of Code Genie",
      image: <img className="card-img-top" src={blog8} alt="" />,
      text: "The blockchain universe is rapidly expanding, and as it does, the complexity of navigating its intricacies grows in tandem. From startups to seasoned developers, everyone is in search of a tool that simplifies the labyrinthine world of smart contracts. Enter Code Genie, the unparalleled smart contract auditing solution that promises not just detection but holistic enhancement. With its one-click audit feature, Code Genie is revolutionizing the way we approach blockchain efficiency, security, and functionality. Let's delve deeper into how Code Genie's comprehensive auditing is setting new standards in the blockchain landscape.",
      href:"/blogs/One_Click_Multiple_Solutions",
  },
  {
      title: "Real-time Rectifications: Code Genie's Proactive Approach to Smart Contract Audits",
      image: <img className="card-img-top" src={blog9} alt="" />,
      text: "In the intricate web of blockchain technology, smart contracts have emerged as the pivotal touchpoint. Their seamless operation determines the efficacy and trustworthiness of blockchain applications. However, as vital as detection of issues is, the true mettle of an auditing tool lies in its rectification capabilities. Code Genie, a trailblazer in the blockchain auditing domain, exemplifies this paradigm by not only identifying but also rectifying smart contract issues in real-time. With the increasing search volume around \"proactive smart contract solutions\" and \"real-time blockchain fixes\", it's evident that the market seeks tools that do more than just highlight problems. Code Genie is perfectly positioned to meet this demand.",
      href:"/blogs/Real_time_Rectifications",
  },
  {
      title: "Upgrading the Upgradable: How Code Genie Ensures Smart Contract Evolution",
      image: <img className="card-img-top" src={blog10} alt="" />,
      text: "The dynamic realm of blockchain technology is akin to a constantly moving train – with new stations being added at each turn. Amidst this progression, smart contracts act as the tracks, guiding and ensuring smooth transitions. Just like rail tracks need upgrades for newer trains, smart contracts need upgradability for evolving requirements. Recognizing this pivotal aspect, Code Genie offers specialized audits that emphasize ensuring smart contract upgradability, fostering adaptability in a world where change is the only constant.",
      href:"/blogs/Upgrading_the_Upgradable",
  },
  {
      title: "Breaking Down the Technical: Code Genie’s Audit Explained",
      image: <img className="card-img-top" src={blog11} alt="" />,
      text: "The blockchain ecosystem, while fascinating, often seems like an intricate web of complexities to the untrained eye. One such element is smart contract auditing, a crucial component to ensure security, efficiency, and robustness in the ever-evolving blockchain universe. Enter Code Genie, a revolutionary tool reshaping the audit landscape. But how does Code Genie make the magic happen? Let's embark on a deep dive into the technicalities and unravel the mysteries behind its auditing magic",
      href:"/blogs/Breaking_Down_the_Technical",
  },
  {
      title: "The Ethical Edge: Code Genie's Commitment to Transparent Auditing",
      image: <img className="card-img-top" src={blog12} alt="" />,
      text: "In the digital age, as the blockchain realm expands at a blistering pace, trust becomes the bedrock of all technological interactions. The blockchain, lauded for its immutable transparency, is no exception. Yet, as layers of complexities unfold, ensuring transparency, especially in areas like smart contract auditing, poses a challenge. Enter Code Genie, the beacon of ethical, transparent, and honest smart contract auditing. This tool stands tall not just for its technical prowess but also for its unwavering commitment to ethical standards.",
      href:"/blogs/The_Ethical_Edge",
  },
  {
      title: "The Evolution of Audits: Tracing Code Genie’s Revolutionary Journey",
      image: <img className="card-img-top" src={blog13} alt="" />,
      text: "In the heart of the digital renaissance, the concept of smart contracts has emerged as a groundbreaking innovation. As these self-executing contracts gain traction, ensuring their accuracy and security is paramount. Enter the world of smart contract audits, a niche but a vital segment of the blockchain ecosystem. This article traces the transformative journey of smart contract auditing, spotlighting the revolutionary contributions of Code Genie.",
      href:"/blogs/The_Evolution_of_Audits",
  },
];*/

const Cost = [
  {
    title: "Early Detection is Cheaper:",
    icon: <Bracketscurly2 className="icon-instance-node-4" color="white" />,
    content: "The cost to fix a bug increases the later it's found in the software development lifecycle. For example, a bug found during the requirements or design phase can be up to 100 times cheaper to fix than if it's detected after a product release.",
  },
  {
    title: "Post-release Costs",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "The Systems Sciences Institute at IBM reported that the cost to fix an error found after product release was four to five times more than one uncovered during design, and up to 100 times more than one identified in the maintenance phase.",
  },
  {
    title: "Economic Impact",
    icon: <Gitpullrequest className="icon-instance-node-4" color="white" />,
    content: "The Consortium for IT Software Quality (CISQ) estimated that poor software quality resulting from software bugs cost U.S. organizations $2.84 trillion in 2020.",
  },
  {
    title: "High-Profile Failures",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "There have been several high-profile software failures that resulted in significant financial losses. For instance, the 2012 FlashCrash linked to algorithmic trading resulted in a temporary loss of $1 trillion in market value.",
  }, {
    title: "Development Time",
    icon: <Code3 className="icon-instance-node-4" color="white" />,
    content: "In many projects, debugging consumes up to 50% of the development time, according to various reports. This means significant time (and thus money) is spent on finding and fixing issues rather than on creating new features.",
  }, {
    title: "Reputation and Revenue",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "For consumer-facing products, especially in the tech industry, a significant bug can tarnish a company's reputation and result in loss of users or sales. For instance, Apple's iOS Maps mishap in 2012 led to public apologies and recommended use of competitors.",
  }, {
    title: "Safety and Litigation Costs",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "In critical systems, like healthcare or aviation, software bugs can have dire consequences, leading to safety concerns and significant litigation costs.",
  }, {
    title: "Maintenance Overheads",
    icon: <Usergear className="user-gear" />,
    content: "A study suggested that around 60-80% of the cost of software goes into maintenance, and a significant portion of that is fixing bugs  .",
  }, {
    title: "Cost of Downtime",
    icon: <Circlewavy1 className="icon-instance-node-4" color="white" />,
    content: "For businesses that rely heavily on software (like online retail, finance, or service providers), downtime due to software bugs can be incredibly costly. Amazon, for example, would lose an estimated $220,000 per minute if its site went down",
  }, {
    title: "Developer Costs:",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "According to Cambridge University, global software developers spend approximately $312 billion a year debugging software errors.",
  },
];

const Benefits = [
  {
    title: "Code Functionality",
    icon: <Bracketscurly2 className="icon-instance-node-4" color="white" />,
    content: " Summary: Ensuring that the code works as intended and meets the specified requirements value:  Functional code is foundational; if it doesn't work, nothing else matters. This ensures that the software performs its designated tasks and fulfills its purpose.",

  },
  {
    title: "Logical Errors",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: " Summary: Checking for flaws in the logic that could cause unexpected behavior or results. value: Efficient code runs faster, uses fewer resources, and provides a better user experience, especially crucial in real-time systems.",

  },
  {
    title: "Coding Standards and Style",
    icon: <Gitpullrequest className="icon-instance-node-4" color="white" />,
    content: " Summary: nsuring that the code adheres to established organizational or industry coding standards and is consistent in style.  value: Consistent coding practices make the codebase maintainable, understandable, and less prone to errors introduced by misreading or misunderstanding code segments.",

  },
  {
    title: "Readability and Maintainability",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "Summary:  Verifying that the code is well-organized, adequately commented, and structured in a way that's easy for other developers to understand and modify. value:  Readable and maintainable code reduces future developmental costs and allows teams to make updates or find and fix bugs more efficiently.",

  }, {
    title: "Development Time",
    icon: <Code3 className="icon-instance-node-4" color="white" />,
    content: "In many projects, debugging consumes up to 50% of the development time, according to various reports. This means significant time (and thus money) is spent on finding and fixing issues rather than on creating new features.",

  }, {
    title: "Reputation and Revenue",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: " Summary: For consumer-facing products, especially in the tech industry, a significant bug can tarnish a company's reputation and result in loss of users or sales. value: For instance, Apple's iOS Maps mishap in 2012 led to public apologies and recommended use of competitors.",

  }, {
    title: "Security Vulnerabilities",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "Summary: Probing the code for potential security flaws or vulnerabilities that could be exploited maliciously.  value: Secure code protects user data, maintains trust, and ensures the software is resilient against cyber threats.",

  }, {
    title: "Code Redundancy",
    icon: <Usergear className="user-gear" />,
    content: "Summary: Checking for repetitive code or functions that could be optimized or consolidated.value:  Reducing redundancy minimizes the chance of errors, decreases the codebase size, and simplifies future modifications.  .",

  }, {
    title: "Testing and Coverage",
    icon: <Circlewavy1 className="icon-instance-node-4" color="white" />,
    content: "Summary: Ensuring that tests exist for the code and that they cover a wide range of scenarios. value:  Comprehensive testing ensures that any changes or additions to the code don't introduce new errors, safeguarding software reliability.",

  }, {
    title: "Integration and Dependencies",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "Summary: Reviewing how new code integrates with existing systems and ensuring dependencies are current and compatible.value:  Proper integration ensures smooth system operations, while updated dependencies prevent potential security risks and compatibility issues.",

  },

  {
    title: ">Documentation",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "Checking if the code comes with adequate documentation, including inline comments and external documentation.value:  Documentation aids in onboarding new team members, supports maintainability, and serves as a reference during debugging.",

  },

];


const LandingPage = () => {
  //const genieSelector = useAppSelector((state) => state.genieState);
  let module_name = "SMART CONTRACTS";//genieSelector.module;
  // localStorage.setItem("module", module_name);

  //blogs listing and displaying
  const [blogs, setBlogs] = useState<IGetBlogs[]>([]);

  //const getBlogs = useGetBlogDetailsQuery({ limitval: 3, skipval: 0 });
  const [showAllBlogs, setShowAllBlogs] = useState(false);

  useEffect(() => {
    // Scroll to the top of the landing page
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (getBlogs && getBlogs.data) {
  //     setBlogs([...getBlogs.data]);
  //     console.log(blogs);
  //   }
  // }, [getBlogs])



  //


  const navigate = useNavigate();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 100;
  const slidestoshow = (isMobile) ? 1 : 3;
  const audittoshow = (isMobile) ? 1 : 3;
  const testimonialtoshow = (isMobile) ? 1 : 3;


  var slidersettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidestoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,
  };

  var auditsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,


  };
  var costsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,


  };

  var benefitssettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,


  };



  var testimonialsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: testimonialtoshow,
    slidesToScroll: 1,
    arrows: true,
    // nextArrow: true,
    // prevArrow: ,


  };

  const module = localStorage.getItem('module');

  const filteredBlogs = blogs.filter((blog) => {
    if (module === 'SMART CONTRACTS' && blog.cat === 'cat1') {
      return true;
    }
    return false;
  });

  const slicedBlogs = filteredBlogs.slice(0, 3);


  const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();

  const methods = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .then((response) => {
        if ('data' in response) {
          const status = response.data.status;
          console.log(status);
          if (status === 'success') {
            toast.success(response.data.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
        } else {
          // Error case
          console.error('Error sending query:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error sending query:', error);

      });
  }

  return (

    <body style={{ overflowX: 'hidden' }}>

      <LandingPageHeader />

      <section className="container-fluid">
        <div className="main container-fluid px-0">
          <div className="content">
            <div className="row">
              <div className="col-md-6 set-content">
                <div className="row mb-3">
                  <h1>
                    Create a winning resume and cover letter with Centri Life
                    Resume & Cover Letter Wizard
                  </h1>
                </div>
                <div className="row">
                  <p>
                    Our AI-powered platform makes it easy to create professional
                    resumes and cover letters that are tailored for specific job
                    opportunities. Upload your existing resume or start from
                    scratch, and let Centri Life guide you towards success in your
                    job search.
                  </p>
                </div>
                <div className="">
                  <button type="button" className="btn btn-primary" onClick={() => navigate('/signup')}>
                    Start Building Your Perfect Resume Today
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <img src={homeImg} alt="home" className="home-img" />
              </div>
            </div>
          </div>
        </div>

        <div className="sponsor">
          <h2 className="text-sponsor">Trusted by</h2>

          <div className="sponsor-img">
            <div>
              <img src={pinterestImg} alt="brand" />
            </div>

            <div>
              <img src={dribbelImg} alt="brand" />
            </div>

            <div>
              <img src={spotifyImg} alt="brand" />
            </div>
          </div>
        </div>

        <div className="container-fluid bg-white">
          <div className="row">
            <div className="col-lg-12">
              <img src={laptopImg} alt="laptop" width="100%" className="laptop-img" />
            </div>
          </div>
        </div>

        <div className="container-fluid bg-white center-content">
          <div className="row unlock">
            <h1 className="unlock-header">
              Unlock the power of AI for your job applications.
            </h1>
            <p className="text-center center-block text mt-3">
              With this unique tool, you can effortlessly create professional
              resumes and cover letters that are tailored to each job opportunity.
              Let our technology help you stand out from the crowd and land your
              dream job.
            </p>
          </div>
        </div>

        <div className="container-fluid para-container text">
          <div className="row">
            <div className="col-lg-5">
              <div className="work-img">
                <img src={workImg} alt="work" width="100%" height="100%" />
              </div>
            </div>
            <div className="col-lg-7 unlock">
              <h1 className="mb-1">Why Centri Life?</h1>
              <div className="row mb-3">
                <h3 className="hedding">
                  <span><img src={sparkleImg} alt="sparkle" /></span>Create a standout resume in minutes.
                </h3>
                <div className="para">
                  <p>
                    Don't let the stress of creating a resume hold you back. Tell
                    us about your experience and achievements or upload an
                    existing resume to generate a professional, AI-based resume in
                    minutes. Stand out from the competition and land your dream
                    job with ease.
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <h3 className="hedding">
                  <span><img src={fileImg} alt="sparkle" /></span>Stand
                  out with a resume that reflects your professional brand.
                </h3>
                <div className="para">
                  <p>
                    Your LinkedIn profile is an essential part of your
                    professional brand. With this tool you can easily add your
                    LinkedIn profile information directly into the resume builder.
                    Create a standout resume that aligns with your online presence
                    and impress hiring managers.
                  </p>
                </div>
              </div>

              <div className="row mb-3">
                <h3 className="hedding">
                  <span><img src={brushImg} alt="sparkle" /></span>Stand
                  out with professional resume designs.
                </h3>
                <div className="para">
                  <p>
                    Don't settle for a generic resume design. With Centri Life
                    Resume & Cover Letter Wizard, you can choose from 3
                    professionally designed templates that will make your resume
                    stand out from the crowd. Plus, easily export your resume in
                    Word, PDF, or Google Doc formats for seamless sharing and
                    printing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <h2 className="text-content">
            Unlock your career potential with Centri Life Resume & Cover Letter
            Wizard.
          </h2>
          <button className="btn btn-primary small" onClick={() => navigate('/signup')}>
            Start Building Your Perfect Resume Today
          </button>
        </div>
      </section>

      <LandingPageFooter />
    </body>
  );
};

export default LandingPage;