import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Container,
  Stack,
  SvgIcon,
  Divider,
  FormControl,
  TextField,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialTwitter,
  LoginSocialGithub,
  LoginSocialLinkedin,
  IResolveParams,
} from "reactjs-social-login";
import { toast } from "react-toastify";
import { object, string, TypeOf } from "zod";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import { ReactComponent as GoogleIcon } from "../../assets/ico_google.svg";
import { ReactComponent as FacebookIcon } from "../../assets/ico_facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/ico_twtter.svg";
import { ReactComponent as GithubIcon } from "../../assets/ico_github.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/ico_linkedin.svg";

import BackSignup from "../../assets/back_signup.png";
//import Logo from "../../assets/logo_white.png";
import Logo from "../../logo.svg";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import {
  useSignupUserMutation,
  useSocialAuthMutation,
} from "../../redux/api/authApi";
import { useAppDispatch } from "../../redux/store";
import { setModule } from "../../redux/features/genieSlice";

/* CentriLife */
import '../../components/Centri_CSS/style.css';

import signinImg from '../../assets/image/logo signin.png';
import SideImg from '../../assets/image/Side Img.jpeg';
import { error } from "console";
/*  */
import CommonHeader from "../../components/CentriLandingPage/CommonpageHeader";
import LandingPageFooter from "../../components/CentriLandingPage/LandingpageFooter";

const signupSchema = object({
  name: string().min(1, "Full name is required"),
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters"),
  passwordConfirm: string().min(1, "Please confirm your password"),
  initial_product: string().optional(),
  property: string().optional()
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "Passwords do not match",
});

export type SignupInput = TypeOf<typeof signupSchema>;

const SignupPage = () => {
  const [termsCheck, setTermsCheck] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [property, setProperty] = useState<string | null>(null);

  const [authSocial, socialState] = useSocialAuthMutation();
  const [signupUser, signupState] = useSignupUserMutation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm<SignupInput>({
    resolver: zodResolver(signupSchema),
    mode: 'onChange',
  });


  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = methods;


  /* Password strength analyzing */
  const password = watch('password', '');

  const validatePasswordStrength = (value: string) => {
    if (value.length >= 8) {
      return { strength: 'Excellent', color: '#087446' };
    } else {
      return { strength: 'Poor', color: 'red' };
    }
  };
  /*  */

  useEffect(() => {
    const url = new URL(window.location.href);
    const propertyParam = url.searchParams.get('property');
    localStorage.setItem("property",propertyParam??"");
    setProperty(propertyParam);
  }, [])


  useEffect(() => {
    if (socialState.isSuccess) {
      toast.success("Social signup success");
      localStorage.setItem("logged_in", "true");
      if (socialState.data.role === "user") {
        localStorage.setItem("user_id", socialState.data.user_id || '');
        // if (localStorage.getItem("module") != "Any Code") {
        // localStorage.setItem("module", "SMART CONTRACTS");
        dispatch(setModule("RESUME"));
        window.location.href = `${window.location.protocol}//${window.location.host}/innerpageresumebuilder`
        // }
        // else {
        //   navigate("/codegenie/any_code");
        // }

      } else {
        navigate("/admin/dashboard");
      }
    }
    if (socialState.isError) {
      if (Array.isArray((socialState.error as any).data.detail)) {
        (socialState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((socialState.error as any).data.detail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialState]);

  useEffect(() => {
    if (signupState.isSuccess) {
      toast.success("User created successfully");
      localStorage.setItem('module', "RESUME");
      navigate("/verify");
    }
    if (signupState.isError) {
      if (Array.isArray((signupState.error as any).data.detail)) {
        (signupState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((signupState.error as any).data.detail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupState]);

  const onSubmitHandler: SubmitHandler<SignupInput> = (values) => {
    console.log(values)
    let prop: string = property??"";
    if(!prop) prop =   localStorage.getItem("property")??""
   
    values['property'] = prop;
    signupUser(values);
  };

  return (

    <div style={{ overflowX: "hidden" }}>
      <CommonHeader />
      <div className="container-fluid signup" style={{ marginLeft: '0px' }}>
        <div className="row align-items-center">
          <div className="col-12 col-md-6 left-img">
            <img src={SideImg} alt="" width="100%" height="100%" />
          </div>


          <div className="col-12 col-md-6 card">
            <div className="text-center">
              <img src={signinImg} alt="" className="logo" onClick={() => navigate('/')} />
            </div>

            <div>
              <h1>Create an account</h1>
              <h3>Sign up with your Google account</h3>
              <div className="d-flex justify-content-center">
                <LoginSocialGoogle
                  client_id={process.env.REACT_APP_GG_APP_ID || ""}
                  scope="email"
                  onResolve={({ provider, data }: IResolveParams) => {
                    if (data)
                      authSocial({
                        provider: provider as string,
                        email: data?.email || "" as string,
                        name: data?.name || "" as string,
                        id: data.sub as string
                      });
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}
                >
                  <svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="54" height="55" rx="27" stroke="#CACBCC" />
                    <path
                      d="M38.06 28.25C38.06 27.47 37.99 26.72 37.86 26H27.5V30.255H33.42C33.165 31.63 32.39 32.795 31.225 33.575V36.335H34.78C36.86 34.42 38.06 31.6 38.06 28.25Z"
                      fill="#4285F4" />
                    <path
                      d="M27.4999 38.9998C30.4699 38.9998 32.9599 38.0148 34.7799 36.3348L31.2249 33.5748C30.2399 34.2348 28.9799 34.6248 27.4999 34.6248C24.6349 34.6248 22.2099 32.6898 21.3449 30.0898H17.6699V32.9398C19.4799 36.5348 23.1999 38.9998 27.4999 38.9998Z"
                      fill="#34A853" />
                    <path
                      d="M21.345 30.0901C21.125 29.4301 21 28.7251 21 28.0001C21 27.2751 21.125 26.5701 21.345 25.9101V23.0601H17.67C16.9 24.5929 16.4993 26.2847 16.5 28.0001C16.5 29.7751 16.925 31.4551 17.67 32.9401L21.345 30.0901Z"
                      fill="#FBBC05" />
                    <path
                      d="M27.4999 21.375C29.1149 21.375 30.5649 21.93 31.7049 23.02L34.8599 19.865C32.9549 18.09 30.4649 17 27.4999 17C23.1999 17 19.4799 19.465 17.6699 23.06L21.3449 25.91C22.2099 23.31 24.6349 21.375 27.4999 21.375Z"
                      fill="#EA4335" />
                  </svg>
                </LoginSocialGoogle>
              </div>
              <hr className="hr-text" data-content="Or" />
            </div>

            <FormProvider {...methods}>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmitHandler)}
              >
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <label className="first">First Name*</label>
                    <input type="text" id="ipt" placeholder="Jane"
                      {...register("name", { required: "First Name is required" })}
                      style={{ border: errors.name ? '1px solid red' : '2px solid #B8BFCA' }} />
                    {errors.name && <p style={{ color: 'red' }}>{"*" + errors.name.message}</p>}
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <label className="last">Last Name*</label>
                    <input type="text" name="" id="ipt" placeholder="Doe" />
                  </div>

                  <div className="col-12 mb-3">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label className="email">Email*</label><br />
                        <input id="input"
                          {...register("email", { required: "Email is required" })}
                          type="email"
                          placeholder="Enter your email"
                          style={{ border: errors.email ? '1px solid red' : '2px solid #B8BFCA' }} />
                        {errors.email && <p style={{ color: 'red' }}>{"*" + errors.email.message}</p>}
                      </div>

                      <div className="col-12 mb-3">
                        <div className="d-flex justify-content-between">
                          <label className="password">Password*</label>
                          <a href="/forgot" className="forget">Forgot Password?</a>
                        </div>
                        <input id="input"
                          {...register("password", { required: 'min 8 characters password required' })}
                          type="password"
                          placeholder="Your password, min 8 characters"
                          style={{ border: errors.password ? '1px solid red' : '2px solid #B8BFCA' }}
                          onFocus={() => setIsTyping(true)}
                          onBlur={() => setIsTyping(false)} />
                        {errors.password && <p style={{ color: 'red' }}>{"*" + errors.password.message}</p>}
                      </div>

                      <div className="col-12 mb-3">
                        <label className="email">Confirm Password*</label><br />
                        <input id="input"
                          {...register("passwordConfirm", { required: "min 8 characters password required" })}
                          type="password"
                          placeholder="Your password, min 8 characters"
                          style={{ border: errors.passwordConfirm ? '1px solid red' : '2px solid #B8BFCA' }} />
                        {errors.passwordConfirm && <p style={{ color: 'red' }}>{"*" + errors.passwordConfirm.message}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                {isTyping && (
                  <div className="row">
                    {[...Array(3)].map((_, index) => (
                      <div key={index} className="col-3 col-md-3 mb-3">
                        <svg className="svgg">
                          <rect
                            width="100"
                            height="5"
                            style={{
                              fill: validatePasswordStrength(password).color,
                            }}
                          />
                        </svg>
                      </div>
                    ))}
                    <div className="col-3 col-md-3 mb-3">
                      <h6
                        style={{
                          color: validatePasswordStrength(password).strength === 'Poor' ? 'red' : 'green',
                        }}
                      >
                        {validatePasswordStrength(password).strength}
                      </h6>
                    </div>
                  </div>
                )}

                <div className="d-flex mb-3">
                  <span>
                    <input type="checkbox" id="checkbox" checked={termsCheck}
                      onChange={(e) => setTermsCheck(e.target.checked)} />
                  </span>&ensp;
                  <p>I certify that I have read and agree to the <a href="/privacy" target="_blank"> Artificial Intelligence Policy.</a></p>
                </div>
                <button className="btn1" type="submit"
                  disabled={!termsCheck}
                  style={{ background: termsCheck ? '#0076AD' : '#9F9F9F', cursor: termsCheck ? 'pointer' : 'not-allowed', border: termsCheck ? '#0076AD' : '#9F9F9F' }}> {signupState.isLoading ? "Creating account..." : "Create Account"}</button>
              </Box>
            </FormProvider>
          </div>

        </div>
      </div>
      <LandingPageFooter />
    </div>
  );
};

export default SignupPage;