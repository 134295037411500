import { useEffect, useState } from "react";
import LandingPageHeader from '../components/CentriLandingPage/LandingpageHeader';
import LandingPageFooter from '../components/CentriLandingPage/LandingpageFooter';
import {
  Box,
  Typography,
  Divider,
  Container,
  Stack,
  SvgIcon,
  TextField,
  Button,
  Grid,
  Checkbox,
  Autocomplete,
  FormControl
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LoadingButton } from "@mui/lab";
import { KeyboardBackspace, KeyboardArrowLeft, KeyboardArrowRight, SettingsPowerTwoTone } from "@mui/icons-material";
import { object, string, TypeOf, array, number } from "zod";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {
  SubmitHandler,
  useForm,
  FormProvider,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import img54 from '../assets/newimg/logo.png';
import { useGetPaymentListQuery } from "../redux/api/paymentApi";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/store";
import { IPaymentDetails, IPaymentHistory, IPersonalDetails } from "../redux/api/types";
import { useUpdatePassMutation } from "../redux/api/authApi";
import { logout } from "../redux/features/userSlice";
import { useGetUserDetailsQuery } from "../redux/api/genieApi";
import { useGetPricesDetailsQuery } from "../redux/api/productApi";
import { useAddAdditionalDetailsMutation, useAddContactInfoMutation, useAddEducatioMutation, useAddEmployeementHistoryMutation, useAddPersonalDetailsMutation, useAddProffesionalSummeryMutation, useAddSkillsMutation, useGetAdditionalDetailsMutation, useGetContactInfoMutation, useGetEducatioMutation, useGetEmployeementHistoryMutation, useGetPersonalDetailsMutation, useGetProffesionalSummeryMutation, useGetSkillsMutation } from '../redux/api/manualApi';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

const saveSchema = object({
  product_name: string().min(1, "Product name is required").optional(),
  product_module: string().optional(),
  module_description: string().optional(),
  source_check: array(string()).optional(),
  source_text: string().optional(),
  source_image: string().optional(),
  source_url: string().optional(),
  input_box_title: string().optional(),
  input_box_description: string().optional(),
  export_check: array(string()).optional(),
  export_word: string().optional(),
  export_pdf: string().optional(),
  export_text: string().optional(),
  id: string().optional(),
});

export type ProductSettingSaveInput = TypeOf<typeof saveSchema>;



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: '#1377C3', 
    color: 'lightblack',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white', // Set background color for odd rows to white
    color: 'black', // Set text color for odd rows to black
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F2F6FA', // Set background color for even rows to gray
    color: 'darkslategray',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const updatepasswordform = object({
  email: string(),
  oldPassword: string(),
  password: string(),
  passwordConfirm: string(),
})


export type updatepasswordval = TypeOf<typeof updatepasswordform>


const Usersetting = () => {


  console.log('isMobile=>', isMobile);
  console.log('MobileView=>', MobileView);

  const navigate = useNavigate();


  const [subscriptionDate, setSubscriptionDate] = useState(null);
  const [planDetails, setPlanDetails] = useState<string | null>(null);
  const [openMenu, setOpenMenu] = useState("Change Password");
  const useremail = localStorage.getItem('user_email');
  const [updateVal, setUpdateVal] = useState<updatepasswordval>({ email: useremail || '', oldPassword: '', password: '', passwordConfirm: '' })
  const [updatepassMutation] = useUpdatePassMutation();
  const [updatePersonalDataMutation] = useAddPersonalDetailsMutation();
  const userDet = useGetUserDetailsQuery({ email: useremail || '' });



  console.log("email....")
  console.log(useremail)

  useEffect(() => {
    if (userDet.isSuccess) {

      setSubscriptionDate(userDet.data.userdet["subscription_date"]?.split("T")[0]);
      var user_price_id = userDet.data.userdet["price_id"]
      for (let i = 0; i < userDet.data.userdet["matchedPlanDetails"].length; i++) {
        if ('plan_details' in userDet.data.userdet["matchedPlanDetails"][i]) {
          //console.log(userDet.data.userdet["matchedPlanDetails"][i]["plan_details"].length);
          for (let j = 0; j < userDet.data.userdet["matchedPlanDetails"][i]["plan_details"].length; j++) {
            var price_id = userDet.data.userdet["matchedPlanDetails"][i]["plan_details"][j]["price_id"];
            if (price_id == user_price_id) {
              setPlanDetails(userDet.data.userdet["matchedPlanDetails"][i]["plan_details"][j]["plan_name"])
            }

          }
        }
        //console.log(userDet.data.userdet["matchedPlanDetails"][1]["plan_details"].length);

      }

    }

  }, [userDet]);




  // console.log(userDet.data["userdet"]["subscription_date"])

  const [paymentList, setPaymentList] = useState<IPaymentDetails[] | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = isMobile ? 5 : 10;

  const { data, isSuccess, isError } = useGetPaymentListQuery({ email: useremail || '', skip: (currentPage - 1) * pageSize, limit: pageSize });

  useEffect(() => {
    setPaymentList(data?.payments);
  }, [data])

  useEffect(() => {
    if (data && data?.payments) {
      const paymentHistory = data?.payments.map((payment, index) => ({
        ...payment,
        serialNumber: (currentPage - 1) * pageSize + index + 1,
      }));
      setPaymentList(paymentHistory);


    }
  }, [data, currentPage]);

  // Pagination
  let totalPages = Math.ceil((data?.totalrecords ?? 0) / pageSize);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const generatePageNumbers = (totalPages: number, currentPage: number): (number | '...')[] => {
    const pageNumbers: (number | '...')[] = [];
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) < 3) {
        pageNumbers.push(i);
      } else if (pageNumbers[pageNumbers.length - 1] !== '...') {
        pageNumbers.push('...');
      }
    }
    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers(totalPages, currentPage);
  //

  const [errors, setErrors] = useState<updatepasswordval>({
    email: '',
    oldPassword: '',
    password: '',
    passwordConfirm: '',
  });

  const customInputStyle = {
    color: 'black',
    border: errors.oldPassword || errors.password || errors.passwordConfirm ? '1px solid red' : '1px solid gray',
  };

  /* const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdateVal({ ...updateVal, [name]: value });
  }; */

  const validateForm = () => {
    const newErrors: updatepasswordval = {
      email: '',
      oldPassword: '',
      password: '',
      passwordConfirm: '',
    };

    // Implement your custom validation logic here


    if (updateVal.oldPassword.trim() === '') {
      newErrors.oldPassword = 'Password is required';
    } else if (updateVal.oldPassword.length < 8) {
      newErrors.oldPassword = 'Password must be at least 8 characters';
    }

    if (updateVal.password.trim() === '') {
      newErrors.password = 'Password is required';
    } else if (updateVal.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
    }

    if (updateVal.passwordConfirm.trim() === '') {
      newErrors.passwordConfirm = 'Password is required';
    } else if (updateVal.passwordConfirm !== updateVal.password) {
      newErrors.passwordConfirm = 'Passwords must match';
    }

    setErrors(newErrors);

    // Check if there are any errors
    return Object.values(newErrors).every((error) => error === '');
  };


  const handleUpdatePassword = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const isFormValid = validateForm();
    e.preventDefault();
    if (isFormValid) {

      const loadingIndicator = document.getElementById('Ploading1') as HTMLDivElement;
      const text = document.getElementById('Pnext1') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      text.style.display = 'none';


      updatepassMutation(updateVal)
        .then((response: any) => {

          loadingIndicator.style.display = 'none';
          loadingIndicator.style.textAlign = 'none';
          loadingIndicator.setAttribute("disabled", "false")
          text.style.display = 'block';

          if ('data' in response) {
            console.log(response)
            toast.success(response.data.message);
            setUpdateVal({
              email: updateVal.email,
              oldPassword: '',
              password: '',
              passwordConfirm: '',
            });
          } else {

            if (response.error && response.error.data) {
              toast.error(response.error.data.detail)

            }
            else {
              toast.error("Somethng went wrong..")
            }
            console.error('Error sending query:', response.error);
          }
        })
        .catch((error) => {
          console.error('Error sending query:', error);

        });
    }
  }

  const handleCancel = () => {
    setUpdateVal({
      email: '',
      oldPassword: '',
      password: '',
      passwordConfirm: '',
    });
  }

  const dispatch = useAppDispatch();

  const handleLogout = () => {
    localStorage.setItem("logged_in", "false");
    //console.log("loggedin:" + localStorage.getItem("logged_in"));
    dispatch(logout());
    var module = localStorage.getItem("module");
    console.log("module111...." + module);
    if (module == "Any Code")
      navigate("/Anycode_landing");
    else
      navigate("/");
    toast.success("Log Out success");
    localStorage.removeItem("payment");
    localStorage.removeItem("msg");
    localStorage.removeItem("complexity");
    localStorage.removeItem("module");
    localStorage.removeItem("subscription_level");
    localStorage.removeItem("free_plan");
    localStorage.removeItem("userRole");
    localStorage.removeItem('property');

  }



  // Redux Datas
  const [createPersonalDetails, personalDetailsState] = useAddPersonalDetailsMutation();
  const [getPersonalDataFucn, getPersonalDataState] = useGetPersonalDetailsMutation();

  const [createcontactInfo, contactInfoState] = useAddContactInfoMutation();
  const [getContactInfoFunc, getContactInfoState] = useGetContactInfoMutation();

  const [createEmployeementHistory, employeementHistoryState] = useAddEmployeementHistoryMutation();
  const [getEmployeementHistoryFunc, getEmployeeemntHistoryState] = useGetEmployeementHistoryMutation();

  const [createEducation, educationState] = useAddEducatioMutation();
  const [getEducationFunc, getEducationState] = useGetEducatioMutation();

  const [createSkills, skillsState] = useAddSkillsMutation();
  const [getSkillsFunc, getSkillsState] = useGetSkillsMutation();

  const [createAdditionalDetails, AdditionalDetailsState] = useAddAdditionalDetailsMutation();
  const [getAdditionalDetailsFunc, getAdditionalDetailsState] = useGetAdditionalDetailsMutation();

  const [createProfesionalSummery, ProfesionalSummeryState] = useAddProffesionalSummeryMutation();
  const [getProfesionalSummeryFunc, getProfesionalSummeryState] = useGetProffesionalSummeryMutation();


  const [employeementHistorySummaryState, setemployeementHistorySummaryState] = useState<any>("");
  const [employeementHistorySummary, setemployeementHistorySummary] = useState<any>("");

  const [educationHistorySummaryState, seteducationHistorySummaryState] = useState<any>("");
  const [educationHistorySummary, seteducationHistorySummary] = useState<any>("");

  const [personaldetailsSummaryState, setpersonaldetailsSummaryState] = useState<any>("");
  const [personaldetailsSummary, setpersonaldetailsSummary] = useState<any>("");

  const [additionalSummaryState, setadditionalSummaryState] = useState<any>("");
  const [additionalSummary, setadditionalSummary] = useState<any>("");


  const [activeTab, setActiveTab] = useState('tab-DD');

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [showCrossIcon, setShowCrossIcon] = useState("");
  const [editorContent, setEditorContent] = useState<string>("<p>This is the initial content of the editor</p>");
  const [additionalDetails, setAdditionalDetails] = useState<any>({ personalDetails: false, employeementHistory: false, education: false, skills: false, additional: false });
  const [selectedPhoto, setSelectedPhoto] = useState<any>("");
  const [addAditionalSkills, setaddAditionalSkills] = useState<string>("");
  const [proffesionalSummery, setproffesionalSummery] = useState<string>("");
  const [proffesionalSummeryState, setproffesionalSummeryState] = useState<string>("");
  const [personalDetails, setpersonalDetails] = useState({
    firstname: "",
    lastname: "",
    desiredjob: "",
    photo: ""
  });
  const [contactInfo, setcontactInfo] = useState({
    email: "",
    phone: "",
    country: "",
    city: "",
    address: "",
    postalcode: ""
  });
  const [employeementHistory, setemployeementHistory] = useState<any>([{
    user_id: localStorage.getItem("user_id"),
    job_title: "",
    company: "",
    star_tdate: "",
    end_date: "",
    location: "",
    summary: "",
  }]);
  const [education, seteducation] = useState([{
    user_id: localStorage.getItem("user_id"),
    institution: "",
    degree: "",
    start_date: "",
    end_date: "",
    location: "",
  }]);
  const [Skill, setSkill] = useState<any>([]);

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    desiredJobTitle: false,
    email: false,
    phone: false,
    country: false,
    city: false,
    address: false,
    postalcode: false,
    employeementHistory: [{
      job_title: false,
      company: false,
      start_date: false,
      end_date: false,
      location: false,
    }],
    educationHistory: [{ institution: false, degree: false, start_date: false, end_date: false, location: false }],
    Skill: false,
    ProffesionalSummery: false,
  });


  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
    // Additional logic for tab click if needed
  };

  const handleHrefClick = () => {
    window.location.href = '/codegenie/resume';
  };



  const setProgress = (index: number) => {
    const progress = document.querySelector("#progress");
    const stepButtons = document.querySelectorAll(".step-button");

    progress?.setAttribute("value", String((((index - 1) * 100) / (stepButtons.length - 2)) - (index > 3 ? 12 : 5)));
    Array.from(stepButtons).forEach((item, secindex) => {
      if (index > secindex) {
        item.classList.add("done");
      }
      if (index < secindex) {
        item.classList.remove("done");
      }
    });

    const currentStepContent = document.getElementById(`step${index + 1}`);
    Array.from(document.getElementsByClassName("step-hide show")).forEach(
      (el) => el.classList.remove("show")
    );
    currentStepContent?.classList.add("show");
    setCurrentIndex(index);
  };

  const handleEditorChange = (content: any, editor: any, setState: any) => {
    const currentValue = editor.getContent(); // This gets the current content of the editor
    setState(currentValue)
  };

  const openFileSelector = () => {
    const fileInput = document.getElementById("pdfInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      alert("Selected file: " + selectedFile.name);
    }
  };


  const handleAddPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // The result property contains the base64 encoded image data
        const base64String = reader.result;
        setSelectedPhoto(base64String);
      };

      // Read the image file as a data URL
      reader.readAsDataURL(file);
    }
  };
  /* const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalDetails(event.target.value);
  }; */

  const handleShowEditor = (key: string) => {
    let prevKey = Object.entries(additionalDetails).find((val) => val[1] == true)

    setAdditionalDetails({ ...additionalDetails, [key]: prevKey?.[0] == key ? false : true })
    /* setShowEditor(true); */
  };



  async function ChangeStep(stepButtons: any, errorObj: object, insertFunc: any, value: object, loadingIndicator: any, text: any) {

    alert('test...')
    const { data }: any = await insertFunc(value)
    alert('testt...1')
    if (data && data["status"]) {

      // if (currentIndex < stepButtons.length) {
      //   setTimeout(() => {
      //     loadingIndicator.style.display = 'none';
      //     text.style.display = 'block';
      //     loadingIndicator.setAttribute("disabled", "false")
      //     setCurrentIndex((prevIndex) => prevIndex == 6 ? prevIndex : prevIndex + 1);
      //     setProgress(currentIndex == 6 ? currentIndex : currentIndex + 1);
      //   }, 300)
      // } else {
      //   loadingIndicator.style.display = 'none';
      //   text.style.display = 'block';
      //   loadingIndicator.setAttribute("disabled", "false")

      // }
      alert("Photo Added Succesfully")
    }
    // if (Object.values(errorObj).some(val => val == true)) {
    //   loadingIndicator.style.display = 'none';
    //   text.style.display = 'block';
    //   loadingIndicator.setAttribute("disabled", "false")
    // } else {
    //   try {


    //   } catch {
    //     loadingIndicator.style.display = 'none';
    //     text.style.display = 'block';
    //     loadingIndicator.setAttribute("disabled", "false")
    //   }
    // }
  }


  const handleNextClick = (name: string) => {
    const stepButtons = document.querySelectorAll(".step-button");


    if (name == "PersonalDetails") {
      const loadingIndicator = document.getElementById('Ploading') as HTMLDivElement;
      const text = document.getElementById('Pnext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      text.style.display = 'none';

      const newErrors = {
        ...formErrors,
        firstName: personalDetails.firstname.trim() === '',
        lastName: personalDetails.lastname.trim() === '',
        desiredJobTitle: personalDetails.desiredjob.trim() === '',
      }
      setFormErrors(newErrors)
      ChangeStep(stepButtons, newErrors, createPersonalDetails, { "user_id": localStorage.getItem("user_id"), "first_name": personalDetails.firstname, "last_name": personalDetails.lastname, "desired_job_title": personalDetails.desiredjob, "photo": selectedPhoto, summary: personaldetailsSummary }, loadingIndicator, text)
    }
    else if (name == "contactInformation") {
      const loadingIndicator = document.getElementById('Cloading') as HTMLDivElement;
      const text = document.getElementById('Cnext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      text.style.display = 'none';
      const newErrors = {
        ...formErrors,
        email: !isValidEmail(contactInfo.email.trim()),
        phone: contactInfo.phone.trim() === '' || contactInfo.phone.trim().length < 10,
        country: contactInfo.country.trim() === '',
        city: contactInfo.city.trim() === '',
        address: contactInfo.address.trim() === '',
        postalcode: contactInfo.postalcode.trim() === '',
      }
      setFormErrors(newErrors)

      ChangeStep(stepButtons, newErrors, createcontactInfo, { user_id: localStorage.getItem("user_id"), email: contactInfo.email, phone: String(contactInfo.phone), country: contactInfo.country, city: contactInfo.city, postal_code: String(contactInfo.postalcode), address: contactInfo.address }, loadingIndicator, text)

    }

    else if (name == "EmployeementHistory") {
      const loadingIndicator = document.getElementById('EMloading') as HTMLDivElement;
      const text = document.getElementById('EMnext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      text.style.display = 'none';
      const newErrors = {
        ...formErrors,
        employeementHistory: employeementHistory.map((item: any, index: any) => ({
          job_title: employeementHistory[index].job_title === '',
          company: employeementHistory[index].company === '',
          start_date: employeementHistory[index].start_date === '',
          location: employeementHistory[index].location === '',
        }))
      }
      setFormErrors(newErrors)
      if (newErrors["employeementHistory"].some((item: any) => Object.values(item).includes(true))) {
        loadingIndicator.style.display = 'none';
        loadingIndicator.style.textAlign = 'none';
        loadingIndicator.setAttribute("disabled", "false")
        text.style.display = 'block';
      } else {
        ChangeStep(stepButtons, newErrors, createEmployeementHistory, { user_id: localStorage.getItem("user_id"), employment_history: employeementHistory, summary: employeementHistorySummary }, loadingIndicator, text)
      }
    }
    else if (name == "Education") {
      const loadingIndicator = document.getElementById('EDloading') as HTMLDivElement;
      const text = document.getElementById('EDnext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      text.style.display = 'none';
      const newErrors = {
        ...formErrors,
        educationHistory: education.map((item: any, index: any) => ({
          institution: education[index].institution === '',
          degree: education[index].degree === '',
          start_date: education[index].start_date === '',
          end_date: education[index].end_date === '',
          location: education[index].location === '',
        }))
      }
      setFormErrors(newErrors)
      if (newErrors["educationHistory"].some((item: any) => Object.values(item).includes(true))) {
        loadingIndicator.style.display = 'none';
        loadingIndicator.style.textAlign = 'none';
        loadingIndicator.setAttribute("disabled", "false")
        text.style.display = 'block';
      } else {
        ChangeStep(stepButtons, newErrors, createEducation, { user_id: localStorage.getItem("user_id"), education_history: education, summary: educationHistorySummary }, loadingIndicator, text)
      }

    }
    else if (name == "Skills") {
      const loadingIndicator = document.getElementById('Sloading') as HTMLDivElement;
      const text = document.getElementById('Snext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      text.style.display = 'none';
      const newErrors = {
        ...formErrors,
        Skill: Skill.length == 0,
      }
      setFormErrors(newErrors)

      ChangeStep(stepButtons, newErrors, createSkills, { user_id: localStorage.getItem("user_id"), skills: Skill }, loadingIndicator, text)

    }
    else if (name == "PersonalSummery") {
      const loadingIndicator = document.getElementById('Proloading') as HTMLDivElement;
      const text = document.getElementById('Pronext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      text.style.display = 'none';

      const newErrors = {
        ...formErrors,
        ProffesionalSummery: proffesionalSummery == "",
      }
      setFormErrors(newErrors)

      ChangeStep(stepButtons, newErrors, createProfesionalSummery, { user_id: localStorage.getItem("user_id"), summary: proffesionalSummery }, loadingIndicator, text)

    }
    else if (name == "AdditionalDetails") {
      const loadingIndicator = document.getElementById('Aloading') as HTMLDivElement;
      const text = document.getElementById('Anext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      text.style.display = 'none';

      ChangeStep(stepButtons, formErrors, createAdditionalDetails, { user_id: localStorage.getItem("user_id"), summary: additionalSummary }, loadingIndicator, text)
    }


  };

  const isValidEmail = (email: string): boolean => {
    // Implement your email validation logic, you can use a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    const stepButtons = document.querySelectorAll(".step-button");
    Array.from(stepButtons).forEach((button, index) => {
      button.addEventListener("click", () => {
        setProgress(index);
      });
      button.setAttribute("aria-expanded", index === currentIndex ? "true" : "false");
    });


    return () => {
      Array.from(stepButtons).forEach((button, index) => {
        button.removeEventListener("click", () => setProgress(index));
      });
    };
  }, [currentIndex]);


  const handleBackClick = () => {
    setFormErrors({
      firstName: false,
      lastName: false,
      desiredJobTitle: false,

      email: false,
      phone: false,
      country: false,
      city: false,
      address: false,
      postalcode: false,
      employeementHistory: [...employeementHistory.map((item: any) => ({
        job_title: false,
        company: false,
        start_date: false,
        end_date: false,
        location: false,
      }))],
      educationHistory: [...education.map((item: any) => ({
        institution: false,
        degree: false,
        start_date: false,
        end_date: false,
        location: false
      }))],
      Skill: false,
      ProffesionalSummery: false
    })
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      setProgress(currentIndex - 1);
    }
  };

  const getPersonalData = async () => {
    const { data }: any = await getPersonalDataFucn({ user_id: localStorage.getItem("user_id") || '' })
    if (data && data["status"] == "success") {

      setpersonalDetails({
        ...personalDetails,
        firstname: data["data"]["first_name"] ? data["data"]["first_name"] : "",
        lastname: data["data"]["last_name"] ? data["data"]["last_name"] : "",
        desiredjob: data["data"]["desired_job_title"] ? data["data"]["desired_job_title"] : "",
        photo: data["data"]["photo"] ? data["data"]["photo"] : ""
      })
      setSelectedPhoto(data["data"]["photo"] ? data["data"]["photo"] : "");
      setpersonaldetailsSummaryState(data["data"]["summary"] ? data["data"]["summary"] : "")
      setpersonaldetailsSummary(data["data"]["summary"] ? data["data"]["summary"] : "")
    }
  }
  const getContactInfoData = async () => {

    const { data }: any = await getContactInfoFunc({ user_id: localStorage.getItem("user_id") || '' })
    if (data && data["status"] == "success") {
      setcontactInfo({
        ...contactInfo,
        email: data["data"]["email"] ? data["data"]["email"] : "",
        postalcode: data["data"]["postal_code"] ? data["data"]["postal_code"] : "",
        city: data["data"]["city"] ? data["data"]["city"] : "",
        country: data["data"]["country"] ? data["data"]["country"] : "",
        address: data["data"]["address"] ? data["data"]["address"] : "",
        phone: data["data"]["phone"] ? data["data"]["phone"] : ""
      })
    }
  }
  const getProfesionalSummery = async () => {

    const { data }: any = await getProfesionalSummeryFunc({ user_id: localStorage.getItem("user_id") || '' })

    if (data && data["status"] == "success") {

      setproffesionalSummery(data["data"]["summary"])
      setproffesionalSummeryState(data["data"]["summary"])
    }
  }
  const getEmployeementHistoryData = async () => {

    const { data }: any = await getEmployeementHistoryFunc({ user_id: localStorage.getItem("user_id") || '' })
    if (data && data["status"] == "success") {
      const { employment_history, summary } = data["data"];
      if (employeementHistory.length > 0) {
        let errorObj = employment_history.map((val: any) => ({ job_title: false, company: false, start_date: false, end_date: false, location: false }))
        setFormErrors({
          ...formErrors,
          employeementHistory: errorObj
        })
        setemployeementHistory([...employment_history])
        setemployeementHistorySummaryState(summary)
      }
    }
  }
  const getEducationData = async () => {

    const { data }: any = await getEducationFunc({ user_id: localStorage.getItem("user_id") || '' })
    if (data && data["status"] == "success") {
      const { education_history, summary } = data["data"];
      if (education_history.length > 0) {
        let errorObj = education_history.map((val: any) => ({ institution: false, degree: false, start_date: false, end_date: false, location: false }))
        setFormErrors({
          ...formErrors,
          educationHistory: errorObj
        })
        seteducation([...education_history])
        seteducationHistorySummaryState(summary)
      }
    }
  }
  const getSkillData = async () => {

    const { data }: any = await getSkillsFunc({ user_id: localStorage.getItem("user_id") || '' })
    if (data && data["status"] == "success") {
      setSkill(data["data"]["skills"] ? data["data"]["skills"] : [])
    }
  }
  const getAdditionalDetails = async () => {
    const { data }: any = await getAdditionalDetailsFunc({ user_id: localStorage.getItem("user_id") || "" })
    if (data && data["status"] == "success") {
      const { summary } = data["data"]
      setadditionalSummaryState(summary)
    }

  }

  const deletePhoto = async () => {
    setSelectedPhoto("");
    const data: any = await createPersonalDetails({ "user_id": localStorage.getItem("user_id"), "first_name": personalDetails.firstname, "last_name": personalDetails.lastname, "desired_job_title": personalDetails.desiredjob, "photo": "" });
    getPersonalData();
  }

  const handleUploadPhoto = async (e: React.MouseEvent<HTMLButtonElement>) => {



    const value: IPersonalDetails = {
      "user_id": localStorage.getItem("user_id") ?? "", "first_name": personalDetails.firstname ?? "", "last_name": personalDetails.lastname ?? "",
      "desired_job_title": personalDetails.desiredjob ?? "", "photo": selectedPhoto
    };
    e.preventDefault();
    //console.log(value);

    const loadingIndicator = document.getElementById('Ploading') as HTMLDivElement;
    const text = document.getElementById('Pnext') as HTMLSpanElement;
    loadingIndicator.style.display = 'block';
    loadingIndicator.style.textAlign = 'block';
    loadingIndicator.setAttribute("disabled", "true")
    text.style.display = 'none';

    const { data, error }: any = await updatePersonalDataMutation(value)

    if (data && data["status"]) {
      loadingIndicator.style.display = 'none';
      text.style.display = 'block';
      loadingIndicator.setAttribute("disabled", "false")
      toast.success("Photo updated.");
    }
    else {
      loadingIndicator.style.display = 'none';
      text.style.display = 'block';
      loadingIndicator.setAttribute("disabled", "false")
      toast.success("Something went wrong.");
    }




    //   updatepassMutation(updateVal)
    //     .then((response: any) => {
    //       if ('data' in response) {
    //         console.log(response)
    //         toast.success(response.data.message);
    //         setUpdateVal({
    //           email: '',
    //           oldPassword: '',
    //           password: '',
    //           passwordConfirm: '',
    //         });
    //       } else {

    //         if (response.error && response.error.data) {
    //           toast.error(response.error.data.detail)

    //         }
    //         else {
    //           toast.error("Somethng went wrong..")
    //         }
    //         console.error('Error sending query:', response.error);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Error sending query:', error);

    //     });



    // const stepButtons = document.querySelectorAll(".step-button");
    //   const loadingIndicator = document.getElementById('Ploading') as HTMLDivElement;
    //   const text = document.getElementById('Pnext') as HTMLSpanElement;
    //   loadingIndicator.style.display = 'block';
    //   loadingIndicator.style.textAlign = 'block';
    //   loadingIndicator.setAttribute("disabled", "true")
    //   text.style.display = 'none';

    //   const newErrors = {
    //     ...formErrors,
    //     firstName: personalDetails.firstname.trim() === '',
    //     lastName: personalDetails.lastname.trim() === '',
    //     desiredJobTitle: personalDetails.desiredjob.trim() === '',
    //   }
    //   setFormErrors(newErrors)




    //ChangeStep(stepButtons, newErrors, createPersonalDetails, { "user_id": localStorage.getItem("user_id"), "first_name": personalDetails.firstname??"", "last_name": personalDetails.lastname??"", "desired_job_title": personalDetails.desiredjob??"", "photo": selectedPhoto, summary: personaldetailsSummary }, loadingIndicator, text)

  }

  const addSkills = () => {
    if (addAditionalSkills.trim() == "") {
      setFormErrors({
        ...formErrors,
        Skill: true
      })
    } else {
      setFormErrors({
        ...formErrors,
        Skill: false
      })
      if (!Skill.includes(addAditionalSkills)) {
        setSkill([...Skill, addAditionalSkills])
        setaddAditionalSkills("")
      } else {
        alert("Skill Already Exist")
      }
    }

  }
  const addDefault = (data: string) => {
    if (Skill.includes(data)) {
      setSkill([...Skill.filter((val: string) => val !== data)]);
    } else {
      setSkill([...Skill, data])
    }
  }

  const handleCrossIconClick = (value: string) => {
    setSkill([...Skill.filter((val: string) => val !== value)]);
  };


  // This Function Used For Handle EmploymentHistory and Education Onchnage
  const onChangeHandelerFunction = (index: any, value: any, key: any, state: any, setSate: any) => {
    const updatedData = [...state];
    updatedData[index] = { ...updatedData[index], [key]: value };
    setSate(updatedData);
  }

  const addExtra = (setState: any, state: any, obj: any, errorKeyName: string) => {
    if (errorKeyName == "employeementHistory") {
      let isValidated = state.filter((item: any) => {
        if (item["job_title"] == "") {
          return true
        } else if (item["company"] == "") {
          return true
        } else if (item["start_date"] == "") {
          return true
        } else if (item["end_date"] == "") {
          return true
        } else if (item["location"] == "") {
          return true
        }
      })
      if (isValidated.length == 0) {
        const addedData = [...state, obj]
        setFormErrors({ ...formErrors, employeementHistory: [...addedData.map((item: any) => ({ job_title: false, company: false, start_date: false, end_date: false, location: false }))] })
        setState(addedData)
      } else {
        formErrors.employeementHistory[formErrors.employeementHistory.length - 1] = { job_title: true, company: true, start_date: true, end_date: true, location: true }
        setFormErrors({ ...formErrors })
      }
    } else {
      let isValidated = state.filter((item: any) => {
        if (item["institution"] == "") {
          return true
        } else if (item["degree"] == "") {
          return true
        } else if (item["start_date"] == "") {
          return true
        } else if (item["end_date"] == "") {
          return true
        } else if (item["location"] == "") {
          return true
        }
      })
      if (isValidated.length == 0) {
        const addedData = [...state, obj]
        setFormErrors({ ...formErrors, educationHistory: [...addedData.map((item: any) => ({ institution: false, degree: false, start_date: false, end_date: false, location: false }))] })
        setState(addedData)
      } else {
        formErrors.educationHistory[formErrors.educationHistory.length - 1] = { institution: true, degree: true, start_date: true, end_date: true, location: true }
        setFormErrors({ ...formErrors })
      }
    }

  }
  useEffect(() => {

    if (currentIndex == 0) {
      getPersonalData()
    }
    if (currentIndex == 1) {
      getContactInfoData()
    }
    if (currentIndex == 2) {
      getProfesionalSummery()
    }
    if (currentIndex == 3) {
      getEmployeementHistoryData()
    }
    if (currentIndex == 4) {
      getEducationData()
    }
    if (currentIndex == 5) {
      getSkillData()
    }
    if (currentIndex == 6) {
      getAdditionalDetails()
    }
    const stepButtons = document.querySelectorAll(".step-button");
    Array.from(stepButtons).forEach((button, index) => {
      button.addEventListener("click", () => {
        setProgress(index);
      });

      button.setAttribute("aria-expanded", index === currentIndex ? "true" : "false");
    });


    return () => {
      Array.from(stepButtons).forEach((button, index) => {
        button.removeEventListener("click", () => setProgress(index));
      });
    };
  }, [currentIndex]);

  return (
    <>
      <div className="">
        <LandingPageHeader />
      </div>
      <div className="landingPageHeader">
        <Box padding={2}>
          <Typography
            textAlign="center"
            variant="h4"
            sx={{
              background:
                "black",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              letterSpacing: "-0.8px",
              fontWeight: "bold",
            }}
          >
            User Settings
          </Typography>
        </Box>
        <Container style={{minHeight:"800px"}}>
          <Stack>
            <Box
              padding={2}
              border="1px solid #CACBCC"
              borderRadius={1}
              component="form"
              noValidate
            >


              {/* <Grid container style={{ borderRadius: '5px' }}>
                <Grid item xs={12} md={4} lg={4} style={{ padding: '1%' }}>
                  <Grid item xs={12} md={12} lg={12} style={{ padding: '1%', textAlign: 'center' }}>
                    
                    <Typography color="black" textAlign={'left'}
                      variant="h6"
                      fontWeight={600}>Add Upload</Typography>
                    <Typography color="black"
                      variant="h6" textAlign={'left'}
                      fontWeight={300}>Profile Photo</Typography>
                  </Grid>
                  <br></br>

                </Grid>

                <Grid item xs={12} md={8} lg={8} style={{ padding: '1%' }}>

                  <Box style={{ backgroundColor: '#F9FBFC', padding: '1%', borderRadius: '5px' }}>

                    <Stack style={{}}>
                      <Grid container>
                        <Grid item xs={12} md={6} lg={6} style={{ padding: '1%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '31%' }}>
                          <div className="col-12 col-md-6 mt-3">
                            <label htmlFor="photoInput" style={{ cursor: 'pointer' }}>
                              <p>
                                {selectedPhoto ? (
                                  <img
                                    src={selectedPhoto}
                                    alt="Selected"
                                    width="48"
                                    style={{ borderRadius: '10px' }}
                                  />
                                ) : (
                                  <svg width="49" height="48" viewBox="0 0 49 48" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" width="48" height="48" rx="8" fill="#CFD4DB" />
                                    <path
                                      d="M34.5 34H14.5C13.9696 34 13.4609 33.7893 13.0858 33.4142C12.7107 33.0391 12.5 32.5304 12.5 32V18C12.5 17.4696 12.7107 16.9609 13.0858 16.5858C13.4609 16.2107 13.9696 16 14.5 16H18.5L20.5 13H28.5L30.5 16H34.5C35.0304 16 35.5391 16.2107 35.9142 16.5858C36.2893 16.9609 36.5 17.4696 36.5 18V32C36.5 32.5304 36.2893 33.0391 35.9142 33.4142C35.5391 33.7893 35.0304 34 34.5 34Z"
                                      stroke="#292C33" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round" />
                                    <path
                                      d="M24.5 29C26.9853 29 29 26.9853 29 24.5C29 22.0147 26.9853 20 24.5 20C22.0147 20 20 22.0147 20 24.5C20 26.9853 22.0147 29 24.5 29Z"
                                      stroke="#292C33" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round" />
                                  </svg>
                                )}
                            {selectedPhoto ? <><DeleteForeverOutlinedIcon style={{ color: 'red' }} />
                              <span style={{ color: 'red', fontWeight: 'bold' }}><a href="#" style={{textDecoration: 'none'}} onClick={() => { deletePhoto();}}>Delete</a></span></> : ''}
                              </p>
                            </label>
                            
                            <input
                              type="file"
                              id="photoInput"
                              style={{ display: 'none' }}
                              accept="image/*"
                              onChange={handleAddPhoto}
                            />

                          </div>

                        </Grid>
                        <Grid item xs={12} md={6} lg={6} style={{ padding: '1%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '31%' }}>
                          <div className="col-12 col-md-12 mt-3">
                            <div className="max">
                             
                            </div>
                            <button onClick={(event) => handleUploadPhoto(event)} className="btn1" id="nextButton" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <div className="spinner-border" style={{ display: "none" }} id="Ploading" role="status">

                                <span className="sr-only">Loading...</span>

                              </div>

                              <span style={{ display: "block" }} id="Pnext" >Update Photo</span>
                            </button>
                          </div>

                        </Grid>

                      </Grid>

                    </Stack>
                  </Box>

                </Grid>

              </Grid> */}

              <Grid container style={{ borderRadius: '5px' }}>
                <Grid item xs={12} md={4} lg={4} style={{ padding: '1%' }}>


                  <Grid item xs={12} md={12} lg={12} style={{ padding: '1%', textAlign: 'center' }}>
                    <Typography color="black" textAlign={'left'}
                      variant="h6"
                      fontWeight={600}>Change Password</Typography>
                    <Typography color="black" textAlign={'left'}
                      variant="h6"
                      fontWeight={300}>You can change password</Typography>
                  </Grid>
                  <br></br>

                </Grid>

                <Grid item xs={12} md={8} lg={8} style={{ padding: '1%' }}>

                  <Box style={{ backgroundColor: '#F9FBFC', padding: '3%', borderRadius: '5px' }}>


                    <Stack>
                      <Grid container paddingY={'1%'}>

                        {/* <Grid item xs={4} md={3} lg={3} style={{ padding: '1% 0%' }}> */}
                        <Typography color="#000"
                          variant="h6"
                          fontWeight={400}>Old Password</Typography>
                        {/* </Grid> */}
                        {/* <Grid item xs={8} md={9} lg={9} style={{ padding: '1% 0%' }}> */}
                        <TextField

                          InputProps={{
                            style: customInputStyle,
                          }}
                          sx={{ width: "100%" }}
                          type="password"
                          placeholder="Enter your old Password here."
                          // onChange={handleChange}
                          onChange={(e) => setUpdateVal({ ...updateVal, oldPassword: e.target.value })}

                        />
                        {errors.oldPassword && <p style={{ color: 'red', fontSize: '10px' }}>{errors.oldPassword}</p>}
                        {/* </Grid> */}
                      </Grid>

                      <Grid container paddingY={'1%'}>
                        {/* <Grid item xs={4} md={3} lg={3} style={{ padding: '1% 0%' }}> */}
                        <Typography color="#000"
                          variant="h6"
                          fontWeight={400}>New Password</Typography>
                        {/* </Grid> */}
                        {/* <Grid item xs={8} md={9} lg={9} style={{ padding: '1% 0%' }}> */}
                        <TextField
                          InputProps={{
                            style: customInputStyle,
                          }}
                          sx={{ width: "100%" }}
                          type="Password"
                          placeholder="Enter new password here."
                          onChange={(e) => setUpdateVal({ ...updateVal, password: e.target.value })}
                        // onChange={handleChange}
                        />
                        {errors.password && <p style={{ color: 'red', fontSize: '10px' }}>{errors.password}</p>}
                        {/* </Grid> */}
                      </Grid>

                      <Grid container paddingY={'1%'}>
                        {/* <Grid item xs={4} md={3} lg={3} style={{ padding: '1% 0%' }}> */}
                        <Typography color="#000"
                          variant="h6"
                          fontWeight={400}>Retype Password</Typography>
                        {/* </Grid> */}
                        {/* <Grid item xs={8} md={9} lg={9} style={{ padding: '1% 0%' }}> */}
                        <TextField
                          InputProps={{
                            style: customInputStyle,
                          }}
                          sx={{ width: "100%" }}
                          type="Password"
                          placeholder="retype new password here."
                          onChange={(e) => setUpdateVal({ ...updateVal, passwordConfirm: e.target.value })}
                        // onChange={handleChange}
                        />
                        {errors.passwordConfirm && <p style={{ color: 'red', fontSize: '10px' }}>{errors.passwordConfirm}</p>}
                        {/* </Grid> */}
                      </Grid>

                      <Grid container style={{ paddingTop: '1%', paddingBottom: '1%', justifyContent: 'center', alignItems: 'center', marginLeft: '8%' }}>
                        {/* <Grid item xs={12} md={9} lg={9} style={{ padding: '1%' }}> */}
                        {/* <Grid item xs={12} md={6} lg={6} style={{ display: 'flex', paddingTop: '1%', paddingBottom: '1%' }}>
                          <Button variant="outlined" style={{ marginRight: '1%', width: '100%', borderColor: "black", color: 'black' }} onClick={handleCancel}>Cancel</Button>
                        </Grid> */}

                        <Grid item xs={12} md={6} lg={6} style={{ display: 'flex', paddingTop: '1%', paddingBottom: '1%', }}>
                          <button onClick={(event) => handleUpdatePassword(event)} className="btn1" id="nextButton" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className="spinner-border" style={{ display: "none" }} id="Ploading1" role="status">

                              <span className="sr-only">Loading...</span>

                            </div>

                            <span style={{ display: "block" }} id="Pnext1" >Update Password</span>
                          </button>
                          {/* <Button variant="contained" style={{ marginRight: '1%', width: '100%', backgroundColor: 'black', color: '#FFF' }} onClick={handleUpdatePassword}>Update Password</Button> */}
                        </Grid>
                        {/* */}

                        {/* </Grid> */}
                      </Grid>
                    </Stack>
                  </Box>
                </Grid>

              </Grid>
              <Divider />


            </Box>
          </Stack >
        </Container >
      </div>
      <div className="landingPageHeader">

      </div>
      <div className="footer-F">
        <LandingPageFooter />
      </div>
    </>
  );
};

export default Usersetting;
