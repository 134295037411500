import { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Stack,
  Typography,
  FormControl,
  TextField,
  Box,
  Button,
  SvgIcon,
} from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { object, string, TypeOf } from "zod";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import BackSignin from "../../assets/back_signin.png";
import Logo from "../../logo.svg";
import VerifyPage from "./verify.page";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


/* CentriLife */
import '../../components/Centri_CSS/style.css';

import signinImg from '../../assets/image/logo signin.png';
import SideImg from '../../assets/image/Side Img.jpeg';
import { error } from "console";
import Header from "../../components/headers/Header";
import LandingHeader from "../../components/headers/LandingHeader";
import CommonHeader from "../../components/CentriLandingPage/CommonpageHeader";
import LandingPageFooter from "../../components/CentriLandingPage/LandingpageFooter";
/*  */


import {
  useForgotPasswordMutation,
  useResetPasswordMutation
} from "../../redux/api/authApi";

const forgotPassSchema = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
});

const resetSchema = object({
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters"),
  passwordConfirm: string().min(1, "Please confirm your password"),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "Passwords do not match",
});

export type ForgotPassInput = TypeOf<typeof forgotPassSchema>;
export type ResetInput = TypeOf<typeof resetSchema>;

const ForgotPage = () => {
  const [forgotPassUser, forgotPassState] = useForgotPasswordMutation();
  const [resetPassword, resetPassState] = useResetPasswordMutation();
  const [mailSent, mailSentState] = useState(false);
  const [resetPass, resetState] = useState(false);
  const [resetEmail, resetEmailState] = useState({});
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<ForgotPassInput>({
    resolver: zodResolver(forgotPassSchema),
  });

  const resetMethods = useForm<ResetInput>({
    resolver: zodResolver(resetSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const {
    register: resetRegister,
    handleSubmit: handleResetSubmit,
    formState: { errors: resetErrors },
  } = resetMethods;

  useEffect(() => {
    if (forgotPassState.isSuccess) {
      toast.success("Email sent successfully");
      //navigate("/signin");
      mailSentState(true);
    }
    if (forgotPassState.isError) {
      if (Array.isArray((forgotPassState.error as any).data.detail)) {
        (forgotPassState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((forgotPassState.error as any).data.detail);
      mailSentState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPassState]);

  useEffect(() => {
    if (resetPassState.isSuccess) {
      toast.success("Password reset successfully");
      navigate("/signin");
    }
    if (resetPassState.isError) {
      if (Array.isArray((resetPassState.error as any).data.detail)) {
        (resetPassState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((resetPassState.error as any).data.detail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPassState]);

  const onSubmitHandler: SubmitHandler<ForgotPassInput> = (values) => {
    //console.log(values);
    setIsLoading(true);
    forgotPassUser(values);
    resetEmailState(values);
  };

  const onResetSubmitHandler: SubmitHandler<ResetInput> = (values) => {
    debugger;
    console.log(values);
    resetPassword({ ...resetEmail, ...values });
  };

  // Callback function to handle data received from the
  //child component
  const handleCallback = (childData: boolean | ((prevState: boolean) => boolean)) => {
    // Update the name in the component's state
    resetState(childData);
    mailSentState(false);
  }

  type PasswordInput = HTMLInputElement;

  function togglePassword(inputId: string): void {
    const passwordInput: PasswordInput | null = document.getElementById(inputId) as PasswordInput;
    if (passwordInput) {
      const type: string = passwordInput.type === "password" ? "text" : "password";
      passwordInput.type = type;
    }
  }

  function toggleConfirmPassword(inputId: string): void {
    const passwordInput: PasswordInput | null = document.getElementById(inputId) as PasswordInput;
    if (passwordInput) {
      const type: string = passwordInput.type === "password" ? "text" : "password";
      passwordInput.type = type;
    }
  }

  return (
    <>
      {mailSent ? (
        <VerifyPage page="forgotPass" setPassCallback={handleCallback} />
      ) : (
        /*  <Container maxWidth="xl">
           <Grid container p={4}> */
        <div>
          {resetPass ? (
            <>
              <CommonHeader />
              <div className="container-fluid resetPass resetPass2">
                <div className="row align-items-center">
                  <div className="col-12 col-md-6 left-img">
                    <img src={SideImg} alt="" width="100%" height="100%" />
                  </div>
                  <div className="col-12 col-md-6 card">
                    <div className="text-center">
                      <Link to="/" target="_self" rel="noreferrer">
                        <img src={signinImg} alt="" className="logo" />
                      </Link>
                    </div>
                    <h1>Reset Your Password</h1>
                    {/* <h6>No worries, we will send you reset instructions</h6> */}
                    <h6>Password must have:</h6>
                    <ul className="unorderlist">
                      <li>A Minimum of 8 characters</li>
                      <li>Both uppercase and lowercase letters</li>
                      <li>A number</li>
                      <li>One special character</li>
                    </ul>

                    <FormProvider {...resetMethods}>
                      <Box
                        component="form"
                        noValidate
                        onSubmit={handleResetSubmit(onResetSubmitHandler)}
                      >
                        <div className="row">
                          <div className="col-12 mb-3">
                            <label className="password">New Password</label><br />
                            <input type="password" /* name="password" */ id="input" placeholder="Your new password"
                              {...resetRegister("password", { required: 'min 8 characters password required' })}
                              style={{ border: resetErrors.password ? '1px solid red' : '2px solid #B8BFCA' }} />
                            {resetErrors.password && <p style={{ color: 'red' }}>{"*" + resetErrors.password.message}</p>}
                          </div>
                          <div className="col-12 confirm-pasword mb-3">
                            <label className="password">Confirm Password</label><br />
                            <input type="password" /* name="passwordConfirm" */ id="input" placeholder="Re-enter your new password"
                              {...resetRegister("passwordConfirm", { required: 'min 8 characters password required' })}
                              style={{ border: resetErrors.passwordConfirm ? '1px solid red' : '2px solid #B8BFCA' }} />
                            {resetErrors.passwordConfirm && <p style={{ color: 'red' }}>{"*" + resetErrors.passwordConfirm.message}</p>}

                          </div>
                        </div>
                        <button className="btn1" type="submit">Update</button>
                      </Box>
                    </FormProvider>
                  </div>
                </div>
              </div>
              <LandingPageFooter />
            </>
          )
            : (
              <>
                <CommonHeader />
                <div className="container-fluid resetPass">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-6 left-img">
                      <img src={SideImg} alt="" width="100%" height="100%" />
                    </div>
                    <div className="col-12 col-md-6 card">
                      <div className="text-center mb-3">
                        <Link to="/" target="_self" rel="noreferrer">
                          <img src={signinImg} alt="" className="logo" />
                        </Link>
                      </div>
                      <h1>Reset Your Password</h1>
                      <h6>No worries, Enter your email first.</h6>
                      <FormProvider {...methods}>
                        <Box
                          component="form"
                          noValidate
                          onSubmit={handleSubmit(onSubmitHandler)}
                        >
                          <div className="row">
                            <div className="col-12">
                              <label className="email">Email*</label><br />
                              <input id="input"
                              {...register("email", { required: "Email is required" })}
                              type="email"
                              placeholder="Enter your email"
                              style={{ border: errors.email ? '1px solid red' : '2px solid #B8BFCA' }}/>
                              {errors.email && <p style={{ color: 'red' }}>{"*" + errors.email.message}</p>}
                            </div>
                          </div>
                          <button className="btn1" type="submit" disabled={isLoading}>{isLoading ? 'Submitting...' : 'Submit'}</button>
                          <button className="btn2" type="button">Cancel</button>
                        </Box>
                      </FormProvider>
                    </div>
                  </div>
                </div>
                <LandingPageFooter />
              </>
            )}
          {/* </Grid>
        </Container > */}
        </div>
      )}
    </>
  );
};

export default ForgotPage;
