import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../components/Centri_CSS/resume-builder.css";
import { Editor } from '@tinymce/tinymce-react';
import { useAddAdditionalDetailsMutation, useAddContactInfoMutation, useAddEducatioMutation, useAddEmployeementHistoryMutation, useAddPersonalDetailsMutation, useAddProffesionalSummeryMutation, useAddSkillsMutation, useCreateResumeMutation, useDownloadDocMutation, useDownloadFileMutation, useGetAdditionalDetailsMutation, useGetAllManualDetailsMutation, useGetContactInfoMutation, useGetEducatioMutation, useGetEmployeementHistoryMutation, useGetPersonalDetailsMutation, useGetProffesionalSummeryMutation, useGetSkillsMutation, usePostJSONdataMutation } from '../redux/api/manualApi';
import resumeTemplateOne from "../assets/image/ResumeTemplateOne_New.png"
import resumeTemplateTwo from "../assets/image/ResumeTemplateTwo_New.png";
import resumeTemplateThree from "../assets/image/ResumeTemplateThree_New.png";
import { Dropdown } from 'react-bootstrap';
import { Backdrop, Box, Button, CircularProgress, Dialog, Modal, TextareaAutosize, SvgIcon } from '@mui/material';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';
import MagicWand from './MagicWand';
import ReactQuill from 'react-quill';
import { useLinkedInCountMutation } from '../redux/api/manualApi';
import { generateDocument } from './docxtemplater/templater1';
import axios from 'axios';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { object, string, TypeOf, number, any } from "zod";
import { MobileView, isMobile } from 'react-device-detect';
import { ReactComponent as FeedBackIcon } from '../assets/ico_feedback.svg';
import { useFeedBackMutation } from '../redux/api/authApi';
import { useExportCoverFileMutation } from "../redux/api/genieApi";
import './Centri_CSS/loader.css';
import {
  Grid,
  TextField
} from "@mui/material";
import { isJSDoc } from 'typescript';
import { DatePicker } from '@mui/lab';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Height } from '@mui/icons-material';



const baseUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/api/`;
// import {NewTemplate} from './docxtemplater/templater1';
let allCompleted = 0;

interface ResumeData {
  _id: string;
  user_id?: string;
  document_id?: string;
  is_type?: string;
  content?: {
    "PERSONAL DETAILS"?: {
      "First Name": string;
      Name?: string;
      Address?: string;
      City?: string;
      State?: string;
      Country?: string;
      Phone?: string;
      Email?: string;
    };
    "PROFESSIONAL SUMMARY"?: string;
    "EMPLOYMENT HISTORY"?: Array<Record<string, string | string[]>>;
    "EDUCATION"?: Array<Record<string, string>>;
    "SKILLS"?: string;
  };
  original_data?: string | null;
  formatted_data?: string | null;
  created_on?: string | null;
  updated_on?: string | null;
}

const feedbackform = object({
  email: string(),
  rating: number(),
  feedback: string(),
  productname: string(),
  date: string()
});

const strToObj = (str: string): Record<string, string> => {
  const details = str.split('##').filter(Boolean);
  const object: Record<string, string> = {};

  details.forEach((detail) => {
    const [key, value] = detail.split(':').map((item) => item.trim());
    object[key] = value;
  });
  return object;
};

const strToArr = (str: string): Record<string, string | string[]>[] => {
  const detailsArr = str.split('##').filter(Boolean);
  const array: Record<string, string | string[]>[] = [];

  for (const details_item of detailsArr) {
    let details: string[] = details_item.split('\n').filter(Boolean);
    const object: Record<string, string | string[]> = {};

    details.forEach((detail, index) => {
      const [key, ...values] = detail.split(':').map((item: string) => item.trim());
      const value = values.join(':').trim();

      if (key === "Responsibilities") {
        // Handle responsibilities as an array
        const responsibilities: string[] = [];
        for (let i = index + 1; i < details.length && details[i].startsWith('-'); i++) {
          responsibilities.push(details[i].replace(/^-/, '').trim());
        }
        if (responsibilities.length > 0 && key) {
          object[key] = responsibilities;
        }
      } else if (!detail.startsWith('-') && key) {
        // Exclude lines starting with hyphen from the top-level array
        object[key] = value;
      }
    });

    if (Object.keys(object).length > 0) {
      array.push(object);
    }
  }

  return array;
};

let downloadmode = "normal";


const imagedivider = `<td width="1%" style="vertical-align: text-top; margin-left: 0rem; align-content: end;"><table width='100%' style="line-height: 0px; margin-left: 0rem;">
<tr style="margin-left: 1rem; text-align: center;">
  <td style="height: 50px; text-align: center; margin-left: 1rem; padding: 0px;
  border-right: 2px solid #0076ad;
  border-collapse: collapse;">
    <!-- <span
      class="vertical"
      style="
        border-left: 10px solid #101111;
                height: 20px;
                position: relative;
                left: 50%;
      "
    >&nbsp;</span> -->
  </td>
</tr>
<tr>
  <td style="height: 28px; text-align: center; padding: 0px;
  border-right: 2px solid rgb(255, 255, 255);
  border-collapse: collapse;">
    
  </td>
</tr>
<tr>
  <td style="height: 50px; text-align: center; padding: 0px;
  border-right: 2px solid #0076ad;
  border-collapse: collapse;">
    
  </td>
</tr> 
</table></td>`;


const htmlTemplate = `<table width='100%' border='0'>
    <!--- Personal details starts-->
    <tr>
      <td width='100%' style="margin-right: 5px !important;">
        <table width='100%' style="line-height: 26px;">
          <tr>
            
            <td width='30%' align='left' style="padding-right: 5px;padding-bottom: 0;padding-top: 0;">{{address}}</td>
            <td width='70%' align='right' style="text-align: right;padding-right: 5px;font-size: 18px;padding-bottom: 0;padding-top: 0;font-weight: bold;"><strong>{{name}}</strong></td>
            
          </tr>
          <tr>
            <td width='30%' align='left' style="padding-right: 5px;padding-bottom: 0;padding-top: 0;">{{email}}</td>
            <td width='70%' align='right' style="padding-right: 5px;padding-bottom: 0;padding-top: 0;">{{designation}}</td>
            
          </tr>
          <tr>
            <td width='30%' align='left' style="padding-right: 5px;padding-bottom: 0;padding-top: 0;">{{phone}}</td>
          </tr>
        </table>
      </td>
      

    </tr>
   
  
   <!--- Personal details Ends-->
  

  <tr><td colspan='3' align='left' style='font-size: 14px; font-weight: bold;'>PROFESSIONAL SUMMARY</td></tr>
  <tr style="padding: 5px 0px;"><td colspan='3' align='left' style="text-align: justify; font-weight: lighter;padding-right:5px;">{{professional_summary}}</td></tr>
  
  
    <tr><td colspan='3' style='height: 10px;'>&nbsp;</td></tr>
    <tr><td colspan='3' align='left' style="font-size: 14px; font-weight: bold;">EMPLOYMENT HISTORY </td></tr>
    <tr><td colspan='3' align='left' style="font-size: 12px; font-weight: bold;">{{employment_history}} </td>
    </tr>


    <tr><td colspan='3' style='height: 10px;'>&nbsp;</td></tr>
    <tr><td colspan='3' align='left' style='font-size: 14px; font-weight: bold;'><br>{{educational_title}} </td></tr>
    {{spacingtd}}
    <tr><td colspan='3' align='left' style="font-size: 12px; font-weight: 400px; margin-top: 20px;">{{educational_history}}</td></tr>

  
    <tr style="padding: 0px 0px;"><td colspan='3' align='left' tyle="font-size: 14px; font-weight: 400px; margin-top: 10px;"><br><span style='font-size: 14px; font-weight: bold;'>SKILLS</span><br><br><ul>{{skills}}</ul></td></tr>
  </table>`;


  const htmlTemplate2 = `<table width='100%' style="line-height: 26px;">
  <tr style="font-size: 14px; width: 100% !important;">
    <td align="right" width="33.3%">{{name}} &nbsp; &nbsp; . &nbsp; &nbsp; </td>
    
    <td align="center" width="33%">{{email}} &nbsp; &nbsp; . &nbsp; &nbsp; </td>
    
    <td align="left" width="33.1%">{{phone}}</td>
  </tr>
 
  <tr style="height: 10px;">&nbsp;</tr>
  <tr align="center">
    <td width="33.3%">&nbsp;</td>
  
    <td align="center" width="33%" style="letter-spacing: -0.02em;
    line-height: 24px;
    font-weight: bolder; font-size: 16px;">{{name}}</td>
  
    <td width="33.1%">&nbsp;</td>
  </tr>
  <tr align="center">
    <td width="33.3%">&nbsp;</td>

    <td align="center" width="33%" style="letter-spacing: -0.02em;
    line-height: 24px;
    font-weight: lighter; font-size: 14px;">{{designation}}</td>
    
    <td width="33.1%">&nbsp;</td>
  </tr>
  <tr align="center">
    <td width="33.3%">&nbsp;</td>
    
    <td align="center" width="33%" style="letter-spacing: -0.02em;
    line-height: 24px;
    font-weight: bolder; font-size: 16px;">* * *</td>
    
    <td width="33.1%">&nbsp;</td>
  </tr>
</table>

<!--- Personal details Ends-->

<table>
<!-- <tr><td colspan='3' align='left' style='font-size: 14px; font-weight: bold;'>PROFESSIONAL SUMMARY</td></tr> -->
<tr style="padding: 5px 5px;"><td colspan='3' align='left' style="text-align: justify; font-weight: lighter;padding: 5px 5px;">{{professional_summary}}</td></tr>


<tr><td colspan='3' align='left' width="100%"  style="padding: 5px 5px;" padding="5px;"><hr /></td></tr>
<tr><td colspan='3' align='left' style="font-size: 14px; font-family: system-ui, -apple-system, 'Segoe UI', Roboto,
'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji'; font-weight: bold;">EMPLOYMENT HISTORY </td></tr>
<tr><td colspan='3' align='left' width="100%"  style="padding: 5px 5px;"  padding="5px;"><hr /></td></tr>

<tr><td colspan='3' align='left' style="font-size: 14px; font-family: system-ui, -apple-system, 'Segoe UI', Roboto,
'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji'; font-weight: 400px;">{{employment_history}} </td>
</tr>

<tr><td colspan='3' align='left' style="padding: 5px 5px;" padding="5px;"  width="100%"><hr /></td></tr>
<tr><td colspan='3' align='left' style='font-size: 14px; font-weight: bold;'>{{educational_title}} </td></tr>
<tr><td colspan='3' align='left' style="padding: 5px 5px;" padding="5px;"  width="100%"><hr /></td></tr>
<tr><td colspan='3' align='left' style="font-size: 14px; font-family: system-ui, -apple-system, 'Segoe UI', Roboto,
'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji'; font-weight: 400px;">{{educational_history}}</td></tr>

<tr><td colspan='3' style='height: 10px;'>&nbsp;</td></tr>
<tr><td colspan='3' align='left' style="padding: 5px 5px;" padding="5px;" width="100%"><hr /></td></tr>
<tr><td colspan='3' align='left' style='font-size: 14px; font-weight: bold;'>SKILLS</td></tr>
<tr><td colspan='3' align='left' style="padding: 5px 5px;" padding="5px;" width="100%"><hr /></td></tr>
<tr style="padding: 0px 0px;"><td colspan='3' align='left' tyle="font-size: 14px; font-family: system-ui, -apple-system, 'Segoe UI', Roboto,
'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji'; font-weight: 400px; margin-top: 20px;">{{skills}}</td></tr>
</table>`;


  const htmlTemplate3 = `<table width='100%'>
<!--- Personal details starts-->
<tr>
  <td width="100%" align="left" style="font-size: 16px; font-weight: 500; line-height: 1.2;padding: 5px 5px;">{{name}}</td>
  </tr>
<tr>
  <td width="100%" align="left" style="text-transform: uppercase!important;
  font-size: 14px;
  margin-bottom: 8px !important;
  font-weight: 500;
  line-height: 1.2;">{{designation}}</td>
</tr>
<tr>
  <td width="100%" align="left" style="letter-spacing: -0.02em;
  line-height: 24px;
  font-weight: 500;" >{{name}} / {{phone}} / {{email}}</td>
</tr>

<!--- Personal details Ends-->

<tr><td  align='left' width='100%' style="border-bottom: 1px;"><hr /></td></tr>
</table>
<table width="100%">
<tr><td width="25%" align='left' style='font-size: 14px; font-weight: bold;vertical-align: top;'>PROFESSIONAL SUMMARY</td>
<td width="75%" align='left' style="text-align: justify; font-weight: lighter;padding: 5px 5px;">{{professional_summary}}</td></tr>
<tr><td colspan='2' align='left'><hr /></td></tr>

<tr><td width="100%" colspan='2'  align='left' style="font-size: 14px;  font-weight: bold;">EMPLOYMENT HISTORY</td>
</tr>
{{employment_history}}

  <!-- <tr><td colspan='3' style='height: 10px;'>&nbsp;</td></tr> -->

<tr><td colspan='2' align='left' width='100%'>{{hr}}</td></tr>


<tr><td width="25%"   align='left' style='font-size: 14px; font-weight: bold;vertical-align: top;'>{{educational_title}} </td>
  <td width="75%" colspan='2' align='left' style='font-weight: 400px;vertical-align: top;'>{{educational_history}}</td></tr>

<tr><td colspan='2' align='left' width='100%'><hr /></td></tr>
<tr><td colspan='3' style='height: 10px;'>&nbsp;</td></tr>

<tr><td width="25%" align='left' style='font-size: 14px; font-weight: bold;vertical-align: top;'>SKILLS</td>
<td width="75%" colspan='2' align='left' style='font-weight: lighter;'> {{skills}}</td></tr>
</table>`;



export type FeedBackForm = TypeOf<typeof feedbackform>

function InnerPageResumeBuilderSection() {

  // Redux Datas
  const [tab, setTab] = useState('Manual');
  const [createPersonalDetails, personalDetailsState] = useAddPersonalDetailsMutation();
  const [getPersonalDataFucn, getPersonalDataState] = useGetPersonalDetailsMutation();

  const [createcontactInfo, contactInfoState] = useAddContactInfoMutation();
  const [getContactInfoFunc, getContactInfoState] = useGetContactInfoMutation();

  const [createEmployeementHistory, employeementHistoryState] = useAddEmployeementHistoryMutation();
  const [getEmployeementHistoryFunc, getEmployeeemntHistoryState] = useGetEmployeementHistoryMutation();

  const [createEducation, educationState] = useAddEducatioMutation();
  const [getEducationFunc, getEducationState] = useGetEducatioMutation();

  const [createSkills, skillsState] = useAddSkillsMutation();
  const [getSkillsFunc, getSkillsState] = useGetSkillsMutation();

  const [createAdditionalDetails, AdditionalDetailsState] = useAddAdditionalDetailsMutation();
  const [getAdditionalDetailsFunc, getAdditionalDetailsState] = useGetAdditionalDetailsMutation();

  const [createProfesionalSummery, ProfesionalSummeryState] = useAddProffesionalSummeryMutation();
  const [getProfesionalSummeryFunc, getProfesionalSummeryState] = useGetProffesionalSummeryMutation();

  const [getAllManualDetailsFunc, getAllManualDetailsState] = useGetAllManualDetailsMutation();

  const [createResumeFunc, createResumeState] = useCreateResumeMutation();
  const [PostJSONFunc, PostJSONState] = usePostJSONdataMutation();
  const [downloadDocFunc, downloadDocState] = useDownloadDocMutation();
  const [exportCoverFile, exportCoverState] = useExportCoverFileMutation();
  const [downloadFileFunc, downloadFileState] = useDownloadFileMutation();

  const [employeementHistorySummaryState, setemployeementHistorySummaryState] = useState<any>("");
  const [employeementHistorySummary, setemployeementHistorySummary] = useState<any>("");

  const [educationHistorySummaryState, seteducationHistorySummaryState] = useState<any>("");
  const [educationHistorySummary, seteducationHistorySummary] = useState<any>("");

  const [personaldetailsSummaryState, setpersonaldetailsSummaryState] = useState<any>("");
  const [personaldetailsSummary, setpersonaldetailsSummary] = useState<any>("");

  const [additionalSummaryState, setadditionalSummaryState] = useState<any>("");
  const [additionalSummary, setadditionalSummary] = useState<any>("");

  const [PersonalDetailsJSON, setPersonalDetailsJSON] = useState("");
  const [SkillsJSON, setSkillsJSON] = useState<any>([]);
  const [EducationHistoryJSON, setEducationHistoryJSON] = useState<any>([]);
  const [EmploymentHistoryJSON, setEmploymentHistoryJSON] = useState<any>([]);
  const [PersonalSummaryJSON, setPersonalSummaryJSON] = useState<any>("");

  const [OriginalPersonalDetailsJSON, setOriginalPersonalDetailsJSON] = useState<any>("");
  const [OriginalSkillsJSON, setOriginalSkillsJSON] = useState<any>("");
  const [OriginalEducationHistoryJSON, setOriginalEducationHistoryJSON] = useState<any>("");
  const [OriginalEmploymentHistoryJSON, setOriginalEmploymentHistoryJSON] = useState<any>("");
  const [OriginalPersonalSummaryJSON, setOriginalPersonalSummaryJSON] = useState<any>("");


  const [content, setContent] = useState('');
  const [loadingCreateResume, setloadingCreateResume] = useState(false);
  const [activeTab, setActiveTab] = useState('tab-DD');
  const [isEditable, setisEditable] = useState(false);
  const [isEditableJobDesc, setisEditableJobDesc] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const jobDescRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [displayResumeData, setdisplayResumeData] = useState<string>("");
  const [ResumePromptData, setResumePromptData] = useState<string>("");

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showViewTemplateModal, setShowViewTemplateModal] = useState(false);

  const [DownloadLoading, setDownloadLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>("template1");
  const [JobDescData, setJobDescData] = useState<string>('');

  const [tabBbuttonColor, settabBButtonColor] = useState('#ccc');
  const [tabBbuttongbColor, settabBButtonbgColor] = useState('#fff');
  const [cursorvar, setCursorvar] = useState('not-allowed');
  const [tabBbuttonColor1, settabBButtonColor1] = useState('#ccc');
  const [tabBbuttongbColor1, settabBButtonbgColor1] = useState('#fff');
  const [disabled1, setdisabled1] = useState(false);
  const [disabled2, setdisabled2] = useState(true);
  const [disabled3, setdisabled3] = useState(true);
  const [disabled4, setdisabled4] = useState(true);
  const [disabled5, setdisabled5] = useState(true);
  const [disabled6, setdisabled6] = useState(true);

  //   const [JobDescData, setJobDescData] = useState<string>(`Job Title: 

  // Location: 

  // Job Type: Full-time

  // Company Description:


  // Responsibilities:


  // Requirements:


  // Preferred Qualifications:


  // Perks and Benefits:


  // How to Apply:
  // `);
  const [CoverData, setCoverData] = useState<string>("");
  const [selectedResume, setSelectedResume] = useState<any>("");
  const [selectedCoverLetter, setSelectedCoverLetter] = useState<any>("");
  const [TabName, setTabName] = useState<string>("");
  const [JobDataLoading, setJobDataLoading] = useState<boolean>(false);

  const [changeDesignModalhandle, setChangeDesignModalHandle] = useState(true);
  const [downloadBtnhandle, setDownloadBtnhandle] = useState(false);

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [showCrossIcon, setShowCrossIcon] = useState("");
  const [RightData, setRightData] = useState<any>([]);
  const [editorContent, setEditorContent] = useState<string>("<p>This is the initial content of the editor</p>");
  const [additionalDetails, setAdditionalDetails] = useState<any>({ personalDetails: false, employeementHistory: false, education: false, skills: false, additional: false });
  const [selectedPhoto, setSelectedPhoto] = useState<any>("");
  const [addAditionalSkills, setaddAditionalSkills] = useState<string>("");
  const [proffesionalSummery, setproffesionalSummery] = useState<string>("");
  const [proffesionalSummeryState, setproffesionalSummeryState] = useState<string>("");
  const [savedResumesData, setSavedResumesData] = useState<ResumeData[]>([]);
  const [savedCoverLettersData, setSavedCoverLettersData] = useState<ResumeData[]>([]);
  const [savedResumesFirstName, setSavedResumesFirstName] = useState<string[]>([]);
  const [htmlResumeContent, sethtmlResumeContent] = useState('');
  const userEmail = localStorage.getItem('user_email');
  const productName = localStorage.getItem('module');
  const currentDate = new Date().toISOString();
  const [feedbackForm, setFeedbackForm] = useState<FeedBackForm>({ email: userEmail || "", rating: 0, feedback: '', productname: productName || '', date: currentDate })
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const ratings = [1, 2, 3, 4, 5];
  const [feedbackMutation] = useFeedBackMutation();
  const navigate = useNavigate()

  const [activePane, setActivePane] = useState("pane-AAA");


  const [isClicked, setIsClicked] = useState(false);
  const [clicked, setClicked] = useState(false);

  const [isType, setIsType] = useState<string>("resume")
  const [personalDetails, setpersonalDetails] = useState({
    firstname: "",
    lastname: "",
    desiredjob: "",
    photo: ""
  });
  const [contactInfo, setcontactInfo] = useState({
    email: "",
    phone: "",
    country: "",
    city: "",
    address: "",
    postalcode: ""
  });
  const [employeementHistory, setemployeementHistory] = useState<any>([{
    user_id: localStorage.getItem("user_id"),
    job_title: "",
    company: "",
    start_date: "",
    end_date: "",
    location: "",
    summary: "",
  }]);
  const [education, seteducation] = useState([{
    user_id: localStorage.getItem("user_id"),
    institution: "",
    degree: "",
    start_date: "",
    end_date: "",
    location: "",
    summary: "",
  }]);
  const [Skill, setSkill] = useState<any>([]);
  const [skillstab, setSkillsTab] = useState<any>(false);
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    desiredJobTitle: false,
    email: false,
    phone: false,
    country: false,
    city: false,
    address: false,
    postalcode: false,
    employeementHistory: [{
      job_title: false,
      company: false,
      start_date: false,
      location: false,
    }],
    educationHistory: [{ institution: false, degree: false, end_date: false, location: false }],
    Skill: false,
    ProffesionalSummery: false,
  });

  const buttonRef = useRef<HTMLButtonElement>(null);


  const handleClear = () => {
    const clearedPersonalDetails: {
      firstname: string;
      lastname: string;
      desiredjob: string;
      photo: string;
    } = {
      ...personalDetails,
      firstname: '',
      lastname: '',
      desiredjob: '',
      photo: ''
    };

    setpersonalDetails(clearedPersonalDetails);
    setemployeementHistory([{
      user_id: localStorage.getItem("user_id"),
      job_title: "",
      company: "",
      start_date: "",
      end_date: "",
      location: "",
      summary: "",
    }]);
    seteducation([{
      user_id: localStorage.getItem("user_id"),
      institution: "",
      degree: "",
      start_date: "",
      end_date: "",
      location: "",
      summary: "",
    }]);
    setSkill([])

    const clearedContactInfo: {
      email: string,
      phone: string,
      country: string,
      city: string,
      address: string,
      postalcode: string
    } = {
      ...contactInfo,
      email: "",
      phone: "",
      country: "",
      city: "",
      address: "",
      postalcode: ""
    };



    setcontactInfo(clearedContactInfo);

    // const clearedEmployeementHistory: {
    //   user_id: string,
    //   job_title: string,
    //   company: string,
    //   start_date: string,
    //   end_date: string,
    //   location: string,
    //   summary: string,
    // } = {
    //   ...employeementHistory,
    //   user_id: "",
    //   job_title: "",
    //   company: "",
    //   start_date: "",
    //   end_date: "",
    //   location: "",
    //   summary: "",
    // };

    // setemployeementHistory(clearedEmployeementHistory);
    localStorage.removeItem('Document_id');
    localStorage.removeItem('document_id');
    localStorage.removeItem('cover_document_id');
    localStorage.removeItem('cover_doc_id');
    localStorage.removeItem('doc_id');
    setdisplayResumeData('');
    sethtmlResumeContent('');
    setJobDescData('');
    setCoverData('');
    setCurrentIndex(0);
    setProgress(0);
  }

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
    // Additional logic for tab click if needed
  };

  const handleHrefClick = () => {
    window.location.href = '/codegenie/resume';
  };



  const setProgress = (index: number) => {
    const progress = document.querySelector("#progress");
    const stepButtons = document.querySelectorAll(".step-button");

    progress?.setAttribute("value", String((((index - 1) * 100) / (stepButtons.length - 2)) - (index > 3 ? 12 : 5)));
    Array.from(stepButtons).forEach((item, secindex) => {
      if (index > secindex) {
        item.classList.add("done");
      }
      if (index < secindex) {
        item.classList.remove("done");
      }
    });

    const currentStepContent = document.getElementById(`step${index + 1}`);
    Array.from(document.getElementsByClassName("step-hide show")).forEach(
      (el) => el.classList.remove("show")
    );
    currentStepContent?.classList.add("show");
    setCurrentIndex(index);
  };

  const handleEditorChange = (content: any, editor: any, setState: any) => {
    const currentValue = editor.getContent(); // This gets the current content of the editor
    setState(currentValue)
  };

  const openFileSelector = () => {
    localStorage.setItem('openUpload', 'true');
    navigate(`/codegenie/resume`, { state: { params: 'upload' } })
    // const fileInput = document.getElementById("pdfInput") as HTMLInputElement;
    // if (fileInput) {
    //   fileInput.click();
    // }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      alert("Selected file: " + selectedFile.name);
    }
  };


  const handleAddPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = async () => {
        // The result property contains the base64 encoded image data
        const base64String = reader.result;
        setSelectedPhoto(base64String);
        const data: any = await createPersonalDetails({ "user_id": localStorage.getItem("user_id"), "first_name": personalDetails.firstname, "last_name": personalDetails.lastname, "desired_job_title": personalDetails.desiredjob, "photo": base64String })
      };

      // Read the image file as a data URL
      reader.readAsDataURL(file);
    }
  };
  /* const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalDetails(event.target.value);
  }; */

  const handleShowEditor = (key: string) => {
    let prevKey = Object.entries(additionalDetails).find((val) => val[1] == true)
    setAdditionalDetails({ ...additionalDetails, [key]: prevKey?.[0] == key ? false : true })
    /* setShowEditor(true); */
  };



  async function ChangeStep(stepButtons: any, errorObj: object, insertFunc: any, value: object, loadingIndicator: any, text: any) {
    if (Object.values(errorObj).some(val => val == true)) {
      loadingIndicator.style.display = 'none';
      text.style.display = 'block';
      loadingIndicator.setAttribute("disabled", "false")
    } else {
      try {
        const { data, error }: any = await insertFunc(value)
        if (error) {
          loadingIndicator.style.display = 'none';
          text.style.display = 'block';
          loadingIndicator.setAttribute("disabled", "false")
          try {
            let err_details = error.data.detail;
            err_details.forEach((err: any) => {
              toast.error(`${err.loc}: ${err.msg}`);
            });
          } catch (e) {
            toast.error(`Something went wrong`);
          }
        }
        if (data && data["status"]) {
          if (currentIndex < stepButtons.length) {
            setTimeout(() => {
              loadingIndicator.style.display = 'none';
              text.style.display = 'block';
              loadingIndicator.setAttribute("disabled", "false")
              setCurrentIndex((prevIndex) => prevIndex == 6 ? prevIndex : prevIndex + 1);
              setProgress(currentIndex == 6 ? currentIndex : currentIndex + 1);
            }, 300)
          } else {
            loadingIndicator.style.display = 'none';
            text.style.display = 'block';
            loadingIndicator.setAttribute("disabled", "false")
            toast.success("Data Added Succesfully")
          }
        }
      } catch {
        loadingIndicator.style.display = 'none';
        text.style.display = 'block';
        loadingIndicator.setAttribute("disabled", "false")
      }
    }
  }


  const handleNextClick = (name: string) => {
    const stepButtons = document.querySelectorAll(".step-button");


    if (name === "PersonalDetails") {
      const loadingIndicator = document.getElementById('Ploading') as HTMLDivElement;
      const text = document.getElementById('Pnext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      text.style.display = 'none';
      setdisabled2(false);
      const newErrors = {

        ...formErrors,
        firstName: personalDetails.firstname.trim() === '',
        lastName: personalDetails.lastname.trim() === '',
        desiredJobTitle: personalDetails.desiredjob.trim() === '',
      }
      setFormErrors(newErrors)
      ChangeStep(stepButtons, newErrors, createPersonalDetails, { "user_id": localStorage.getItem("user_id"), "first_name": personalDetails.firstname, "last_name": personalDetails.lastname, "desired_job_title": personalDetails.desiredjob, "photo": selectedPhoto, summary: personaldetailsSummary }, loadingIndicator, text)
    }
    else if (name == "contactInformation") {
      const loadingIndicator = document.getElementById('Cloading') as HTMLDivElement;
      const text = document.getElementById('Cnext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      setdisabled3(false);
      text.style.display = 'none';
      const newErrors = {
        ...formErrors,
        email: !isValidEmail(contactInfo.email.trim()),
        phone: contactInfo.phone.trim() === '' || contactInfo.phone.trim().length < 10,
        // country: contactInfo.country.trim() === '',
        city: contactInfo.city.trim() === '',
        // address: contactInfo.address.trim() === '',
        // postalcode: contactInfo.postalcode.trim() === '',
      }
      setFormErrors(newErrors)

      ChangeStep(stepButtons, newErrors, createcontactInfo, { user_id: localStorage.getItem("user_id"), email: contactInfo.email, phone: String(contactInfo.phone), country: contactInfo.country, city: contactInfo.city, postal_code: String(contactInfo.postalcode), address: contactInfo.address }, loadingIndicator, text)

    }

    else if (name === "EmployeementHistory") {
      const loadingIndicator = document.getElementById('EMloading') as HTMLDivElement;
      const text = document.getElementById('EMnext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      setdisabled5(false);
      text.style.display = 'none';
      const newErrors = {
        ...formErrors,
        employeementHistory: employeementHistory.map((item: any, index: any) => ({
          job_title: employeementHistory[index].job_title === '',
          company: employeementHistory[index].company === '',
          start_date: employeementHistory[index].start_date === '',
          // end_date: employeementHistory[index].end_date === '',
          location: employeementHistory[index].location === '',
        }))
      }
      setFormErrors(newErrors)
      if (newErrors["employeementHistory"].some((item: any) => Object.values(item).includes(true))) {
        loadingIndicator.style.display = 'none';
        loadingIndicator.style.textAlign = 'none';
        loadingIndicator.setAttribute("disabled", "false")
        text.style.display = 'block';
      } else {
        ChangeStep(stepButtons, newErrors, createEmployeementHistory, { user_id: localStorage.getItem("user_id"), employment_history: employeementHistory }, loadingIndicator, text)
      }
    }
    else if (name == "Education") {
      const loadingIndicator = document.getElementById('EDloading') as HTMLDivElement;
      const text = document.getElementById('EDnext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      setdisabled6(false);
      text.style.display = 'none';
      const newErrors = {
        ...formErrors,
        educationHistory: education.map((item: any, index: any) => ({
          institution: education[index].institution === '',
          degree: education[index].degree === '',
          //start_date: education[index].start_date === '',
          end_date: education[index].end_date === '',
          location: education[index].location === '',
        }))
      }
      setFormErrors(newErrors)
      if (newErrors["educationHistory"].some((item: any) => Object.values(item).includes(true))) {
        loadingIndicator.style.display = 'none';
        loadingIndicator.style.textAlign = 'none';
        loadingIndicator.setAttribute("disabled", "false")
        text.style.display = 'block';
      } else {
        ChangeStep(stepButtons, newErrors, createEducation, { user_id: localStorage.getItem("user_id"), education_history: education, summary: educationHistorySummary }, loadingIndicator, text)
      }

    }
    else if (name === "Skills") {
      setSkillsTab(true);
      const loadingIndicator = document.getElementById('Sloading') as HTMLDivElement;
      const text = document.getElementById('Snext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      text.style.display = 'none';
      const newErrors = {
        ...formErrors,
        Skill: Skill.length === 0,
      }
      setFormErrors(newErrors)

      ChangeStep(stepButtons, newErrors, createSkills, { user_id: localStorage.getItem("user_id"), skills: Skill }, loadingIndicator, text)

    }
    else if (name === "PersonalSummery") {
      const loadingIndicator = document.getElementById('Proloading') as HTMLDivElement;
      const text = document.getElementById('Pronext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      text.style.display = 'none';
      setdisabled4(false);
      const newErrors = {
        ...formErrors,
        ProffesionalSummery: proffesionalSummery === "",
      }
      setFormErrors(newErrors)

      ChangeStep(stepButtons, newErrors, createProfesionalSummery, { user_id: localStorage.getItem("user_id"), summary: proffesionalSummery }, loadingIndicator, text)

    }
    else if (name === "AdditionalDetails") {
      const loadingIndicator = document.getElementById('Aloading') as HTMLDivElement;
      const text = document.getElementById('Anext') as HTMLSpanElement;
      loadingIndicator.style.display = 'block';
      loadingIndicator.style.textAlign = 'block';
      loadingIndicator.setAttribute("disabled", "true")
      text.style.display = 'none';
      ChangeStep(stepButtons, formErrors, createAdditionalDetails, { user_id: localStorage.getItem("user_id"), summary: additionalSummary }, loadingIndicator, text)
    }


  };

  const isValidEmail = (email: string): boolean => {
    // Implement your email validation logic, you can use a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFormSubmit = () => {
    feedbackMutation(feedbackForm)
      .then((response) => {
        toast.success('FeedBack Sent Successfully!!');
        setIsPopupOpen(false);
        setFeedbackForm({
          email: userEmail || "",
          rating: 0,
          feedback: '',
          productname: productName || '',
          date: currentDate
        });

      })
      .catch((error) => {
        console.error('Error sending feedback:', error);

      });
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Update feedbackForm with the textarea value
    setFeedbackForm((prevForm) => ({
      ...prevForm,
      feedback: event.target.value,
    }));

  };

  const handleRating = (event: number) => {
    // Update feedbackForm with the textarea value
    setFeedbackForm((prevForm) => ({
      ...prevForm,
      rating: event
    }));

  };


  const handleBackClick = () => {
    setFormErrors({
      firstName: false,
      lastName: false,
      desiredJobTitle: false,

      email: false,
      phone: false,
      country: false,
      city: false,
      address: false,
      postalcode: false,
      employeementHistory: [...employeementHistory.map((item: any) => ({
        job_title: false,
        company: false,
        start_date: false,
        // end_date: false,
        location: false,
      }))],
      educationHistory: [...education.map((item: any) => ({
        institution: false,
        degree: false,
        //start_date: false,
        end_date: false,
        location: false
      }))],
      Skill: false,
      ProffesionalSummery: false
    })
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      setProgress(currentIndex - 1);
    }
  };

  const getPersonalData = async () => {
    const { data }: any = await getPersonalDataFucn({ user_id: localStorage.getItem("user_id") || '' })

    if (data && data["status"] === "success") {

      setpersonalDetails({
        ...personalDetails,
        firstname: data["data"]["first_name"] ? data["data"]["first_name"] : "",
        lastname: data["data"]["last_name"] ? data["data"]["last_name"] : "",
        desiredjob: data["data"]["desired_job_title"] ? data["data"]["desired_job_title"] : "",
        photo: data["data"]["photo"] ? data["data"]["photo"] : ""
      })
      setSelectedPhoto(data["data"]["photo"] ? data["data"]["photo"] : "");
      setpersonaldetailsSummaryState(data["data"]["summary"] ? data["data"]["summary"] : "")
      setpersonaldetailsSummary(data["data"]["summary"] ? data["data"]["summary"] : "")
    }
  }

  const deletePhoto = async () => {
    console.log(personalDetails);
    setSelectedPhoto("");
    const data: any = await createPersonalDetails({ "user_id": localStorage.getItem("user_id"), "first_name": personalDetails.firstname, "last_name": personalDetails.lastname, "desired_job_title": personalDetails.desiredjob, "photo": "" });
    getPersonalData();
  }

  const getContactInfoData = async () => {

    const { data }: any = await getContactInfoFunc({ user_id: localStorage.getItem("user_id") || '' })
    if (data && data["status"] === "success") {
      setcontactInfo({
        ...contactInfo,
        email: data["data"]["email"] ? data["data"]["email"] : "",
        postalcode: data["data"]["postal_code"] ? data["data"]["postal_code"] : "",
        city: data["data"]["city"] ? data["data"]["city"] : "",
        country: data["data"]["country"] ? data["data"]["country"] : "",
        address: data["data"]["address"] ? data["data"]["address"] : "",
        phone: data["data"]["phone"] ? data["data"]["phone"] : ""
      })
    }
  }
  const getProfesionalSummery = async () => {

    const { data }: any = await getProfesionalSummeryFunc({ user_id: localStorage.getItem("user_id") || '' })

    if (data && data["status"] === "success") {

      setproffesionalSummery(data["data"]["summary"])
      setproffesionalSummeryState(data["data"]["summary"])
    }
  }
  const getEmployeementHistoryData = async () => {

    const { data }: any = await getEmployeementHistoryFunc({ user_id: localStorage.getItem("user_id") || '' })
    if (data && data["status"] === "success") {
      const { employment_history, summary } = data["data"];
      if (employeementHistory.length > 0) {
        let errorObj = employment_history.map((val: any) => ({ job_title: false, company: false, start_date: false, location: false }))
        setFormErrors({
          ...formErrors,
          employeementHistory: errorObj
        })
        setemployeementHistory([...employment_history])
        setemployeementHistorySummaryState(summary)

        for (var i = 0; i < employeementHistory.length; i++) {

          if (employeementHistory[i]["end_date"].split("-")[0] == "" && employeementHistory[i]["end_date"].split("-")[1] == "") {
            console.log(employeementHistory[i]["end_date"].split("-")[0] + "***" + employeementHistory[i]["end_date"].split("-")[1]);
            let checkboxElement = document.getElementById('present-' + i) as HTMLInputElement;
            checkboxElement.checked = true;
            handlePresentCheckboxChange(i);
          }

        }

      }
    }
  }
  const getEducationData = async () => {

    const { data }: any = await getEducationFunc({ user_id: localStorage.getItem("user_id") || '' })
    if (data && data["status"] === "success") {
      const { education_history, summary } = data["data"];
      if (education_history.length > 0) {
        let errorObj = education_history.map((val: any) => ({ institution: false, degree: false, end_date: false, location: false }))
        setFormErrors({
          ...formErrors,
          educationHistory: errorObj
        })
        seteducation([...education_history])
        seteducationHistorySummaryState(summary)
      }
    }
  }
  const getSkillData = async () => {

    const { data }: any = await getSkillsFunc({ user_id: localStorage.getItem("user_id") || '' })
    if (data && data["status"] === "success") {
      setSkill(data["data"]["skills"] ? data["data"]["skills"] : [])
    }
  }
  const getAdditionalDetails = async () => {
    const { data }: any = await getAdditionalDetailsFunc({ user_id: localStorage.getItem("user_id") || "" })
    if (data && data["status"] === "success") {
      const { summary } = data["data"]
      setadditionalSummaryState(summary)
    }

  }

  const addSkills = () => {
    if (addAditionalSkills.trim() === "") {
      setFormErrors({
        ...formErrors,
        Skill: true
      })
    } else {
      setFormErrors({
        ...formErrors,
        Skill: false
      })
      if (!Skill.includes(addAditionalSkills)) {
        setSkill([...Skill, addAditionalSkills])
        setaddAditionalSkills("")
      } else {
        alert("Skill Already Exist")
      }
    }

  }
  const addDefault = (data: string) => {
    if (Skill.includes(data)) {
      setSkill([...Skill.filter((val: string) => val !== data)]);
    } else {
      setSkill([...Skill, data])
    }
  }

  const handleCrossIconClick = (value: string) => {
    // Display confirmation dialog
    const confirmDelete = window.confirm("Are you sure you want to delete this skill?");

    // If user confirms deletion, execute setSkill
    if (confirmDelete) {
      setSkill([...Skill.filter((val: string) => val !== value)]);
    }
  };

  /* handling month and year selection */
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedEndMonth, setSelectedEndMonth] = useState('');
  const [selectedEndYear, setSelectedEndYear] = useState('');
  const [graduationEndMonth, setGraduationEndMonth] = useState('');
  const [graduationEndYear, setGraduationEndYear] = useState('');

  const handleMonthChange = (value: any, index: any, field1: any, field2: any) => {
    //setSelectedMonth(e.target.value);
    let selectedMonth = value;
    let dropdownElement = document.getElementById(field2 + '-' + index) as HTMLInputElement;
    let selectedYear = dropdownElement.value;
    let concatenatedValue = selectedYear + "-" + selectedMonth;
    onChangeHandelerFunction(index, concatenatedValue, "start_date", employeementHistory, setemployeementHistory);
    //handleConcatenatedChange(e.target.value, selectedYear, selectedIndex);
  };

  const handleYearChange = (value: any, index: any, field1: any, field2: any) => {
    let selectedYear = value;
    let dropdownElement = document.getElementById(field2 + '-' + index) as HTMLInputElement;
    let selectedMonth = dropdownElement.value;
    let concatenatedValue = selectedYear + "-" + selectedMonth;
    console.log(concatenatedValue);
    onChangeHandelerFunction(index, concatenatedValue, "start_date", employeementHistory, setemployeementHistory);
  };

  const handleEndMonthChange = (value: any, index: any, field1: any, field2: any) => {
    //setSelectedMonth(e.target.value);
    let selectedMonth = value;
    let dropdownElement = document.getElementById(field2 + '-' + index) as HTMLInputElement;
    let selectedYear = dropdownElement.value;
    let concatenatedValue = selectedYear + "-" + selectedMonth;
    onChangeHandelerFunction(index, concatenatedValue, "end_date", employeementHistory, setemployeementHistory);
  };

  const handleEndYearChange = (value: any, index: any, field1: any, field2: any) => {
    let selectedYear = value;
    let dropdownElement = document.getElementById(field2 + '-' + index) as HTMLInputElement;
    let selectedMonth = dropdownElement.value;
    let concatenatedValue = selectedYear + "-" + selectedMonth;
    onChangeHandelerFunction(index, concatenatedValue, "end_date", employeementHistory, setemployeementHistory);
  };

  const handleGraduationEndMonthChange = (value: any, index: any, field1: any, field2: any) => {
    let selectedMonth = value;
    let dropdownElement = document.getElementById(field2 + '-' + index) as HTMLInputElement;
    let selectedYear = dropdownElement.value;
    let concatenatedValue = selectedYear + "-" + selectedMonth;
    onChangeHandelerFunction(index, concatenatedValue, "end_date", education, seteducation);
  };

  const handleGraduationEndYearChange = (value: any, index: any, field1: any, field2: any) => {
    let selectedYear = value;
    let dropdownElement = document.getElementById(field2 + '-' + index) as HTMLInputElement;
    let selectedMonth = dropdownElement.value;
    let concatenatedValue = selectedYear + "-" + selectedMonth;
    onChangeHandelerFunction(index, concatenatedValue, "end_date", education, seteducation);
  };

  const handleConcatenatedChange = (month: string, year: string, index: number) => {
    const concatenatedValue = `${year}-${month}`;
    onChangeHandelerFunction(index, concatenatedValue, "start_date", employeementHistory, setemployeementHistory);
  };

  const handleEndConcatenatedChange = (month: string, year: string, index: number) => {
    const concatenatedValue = `${year}-${month}`;
    onChangeHandelerFunction(index, concatenatedValue, "end_date", employeementHistory, setemployeementHistory);
  };

  const handleGraduationEndConcatenatedChange = (month: string, year: string, index: number) => {
    const concatenatedValue = `${year}-${month}`;
    onChangeHandelerFunction(index, concatenatedValue, "end_date", education, seteducation);
  };
  /*  */

  // Function to handle checkbox change
  const handlePresentCheckboxChange = (index: any) => {


    // Get all checkboxes with a particular name attribute
    let checkboxes = document.querySelectorAll('input[type="checkbox"][name="present"]');
    // Traverse through the checkboxes
    var rowindex = 0;
    var concatenatedValue = '';
    checkboxes.forEach((checkbox) => {
      // Do something with each checkbox
      var checkboxid = checkbox.id; // Example: Log whether the checkbox is 
      let checkboxElement = document.getElementById('present-' + rowindex) as HTMLInputElement;
      let dropdownElement = document.getElementById('endmonth-' + rowindex) as HTMLInputElement;
      let dropdownElement1 = document.getElementById('endyear-' + rowindex) as HTMLInputElement;
      if (checkboxElement.checked && rowindex == index) {
        checkboxElement.checked = true;
        dropdownElement.value = '';
        dropdownElement.disabled = true;
        dropdownElement1.value = '';
        dropdownElement1.disabled = true;
        let selectedYear = dropdownElement1.value;
        let selectedMonth = dropdownElement.value;
        concatenatedValue = selectedYear + "-" + selectedMonth;
        onChangeHandelerFunction(rowindex, concatenatedValue, "end_date", employeementHistory, setemployeementHistory);
      }
      else {
        checkboxElement.checked = false;
        dropdownElement.disabled = false;
        dropdownElement1.disabled = false;
      }

      rowindex++;

    });



  };

  const onChangeHandelerFunction = (index: any, value: any, key: any, state: any, setSate: any) => {
    const updatedData = [...state];
    updatedData[index] = { ...updatedData[index], [key]: value }
    console.log(updatedData);
    setSate(updatedData);
  }

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, index) => currentYear - index);

  const addExtra = (setState: any, state: any, obj: any, errorKeyName: string) => {
    if (errorKeyName === "employeementHistory") {
      let isValidated = state.filter((item: any) => {
        if (item["job_title"] === "") {
          return true
        } else if (item["company"] === "") {
          return true
        } else if (item["start_date"] === "") {
          return true
        } else if (item["location"] === "") {
          return true
        }
      })
      if (isValidated.length === 0) {
        const addedData = [...state, obj]
        setFormErrors({ ...formErrors, employeementHistory: [...addedData.map((item: any) => ({ job_title: false, company: false, start_date: false, location: false }))] })
        setState(addedData)
      } else {
        formErrors.employeementHistory[formErrors.employeementHistory.length - 1] = { job_title: true, company: true, start_date: true, location: true }
        setFormErrors({ ...formErrors })
      }
    } else {
      let isValidated = state.filter((item: any) => {
        if (item["institution"] === "") {
          return true
        } else if (item["degree"] === "") {
          return true
        } else if (item["start_date"] === "") {
          return true
        } else if (item["location"] === "") {
          return true
        }
      })
      if (isValidated.length === 0) {
        const addedData = [...state, obj]
        setFormErrors({ ...formErrors, educationHistory: [...addedData.map((item: any) => ({ institution: false, degree: false, end_date: false, location: false }))] })
        setState(addedData)
      } else {
        formErrors.educationHistory[formErrors.educationHistory.length - 1] = { institution: true, degree: true, end_date: true, location: true }
        setFormErrors({ ...formErrors })
      }
    }

  }
  const [mainData, setMainData] = useState([]);

  let personal = '';
  let profsummary = '';
  let employeementdet = '';
  let educationdet = '';
  let skills = '';
  let coverData = '';

  // Define the type for the contact object
  interface PERSONAL_DETAILS {
    Name: string;
    Position: string;
    Phone: string;
    Email: string;
    City: string;
    State: string;
    Country: string;
  }


  async function fetchPromptData(prompt: string, code: string, job_dec: String) {

    const params = {
      prompt: prompt,
      code: code,
      job_description: job_dec,
      product_name: "CodeGenie",
      product_module: "RESUME",
      "free_plan": 0,
      "subscription_level": "Free"
    };
    const options = {
      method: 'POST',
      body: JSON.stringify(params)
    };

    const url = baseUrl + 'prompts/run_stream_test';

    setloadingCreateResume(true);
    downloadmode = "normal";
    localStorage.setItem("downloadmode", downloadmode);
    const response = await fetch(url, options);
    //console.log("response", response)
    if (!response.ok || !response.body) {
      throw response.statusText;
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder();


    while (true) {
      //console.log("inside while......")
      const { value, done } = await reader.read();
      if (done) {
        allCompleted++;

        if (prompt === "COVER LETTER") {
          setJobDataLoading(false);
          let user_id = localStorage.getItem("user_id");
          axios.post(baseUrl + `aiResume/end_cover_timer/${user_id}`).then(response => {
            //console.log('Ended cover timer successfully!!');
          }).catch(error => {
            //console.log('Oops!!Error on ending cover timer...');
          });
          //setloadingCreateResume(false);
          toast.success("Cover Letter completed.");
          //console.log("Cover Letter completed.");
          //console.log(coverData);
          setCoverData(coverData);
          handleSaveCover();

        }

        if (allCompleted == 6) {
          allCompleted = 0;
          //setloadingCreateResume(false);
          let user_id = localStorage.getItem("user_id");
          axios.post(baseUrl + `aiResume/end_resume_timer/${user_id}`).then(response => {
            //console.log('Ended resume timer successfull!!');
          }).catch(error => {
            //console.log('Oops!!Error on ending resume timer...');
          })
          setloadingCreateResume(false);
          toast.success("Resume completed successfully");
          fullResume = finetuneResume(fullResume);

          setdisplayResumeData(fullResume);
          //alert('save resume --1');
          handleSave();
          toggleColor();

        }
      }
      const decodedChunk = decoder.decode(value, { stream: true });

      if (prompt === "COVER LETTER") {
        coverData = coverData + decodedChunk
        setCoverData(coverData);
        //console.log(CoverData);
      }
      else if (prompt === "PERSONAL DETAILS") {
        personal = personal + decodedChunk
        setOriginalPersonalDetailsJSON((value: string) => personal);
        setPersonalDetailsJSON((value: string) => personal);
        localStorage_msg['PERSONAL DETAILS'] = personal;
        localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg));
        ;
      }
      else if (prompt === "PROFESSIONAL SUMMARY") {
        profsummary = profsummary + decodedChunk;
        profsummary = finetuneResume(profsummary);
        setOriginalPersonalSummaryJSON((value: string) => profsummary);
        setPersonalSummaryJSON((value: string) => profsummary);
        localStorage_msg['PROFESSIONAL SUMMARY'] = profsummary;
        localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg));

      }
      else if (prompt === "EMPLOYMENT HISTORY") {
        employeementdet = employeementdet + decodedChunk
        setOriginalEmploymentHistoryJSON((value: string) => employeementdet)
        setEmploymentHistoryJSON((value: string) => employeementdet);
        localStorage_msg['EMPLOYMENT HISTORY'] = employeementdet;
        localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg));

      }
      else if (prompt === "EDUCATION") {
        educationdet = educationdet + decodedChunk
        setOriginalEducationHistoryJSON((value: string) => educationdet);
        setEducationHistoryJSON((value: string) => educationdet);
        localStorage_msg['EDUCATION'] = educationdet
        //console.log(educationdet)
        localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg));

      }
      else if (prompt === "SKILLS") {
        skills = skills + decodedChunk
        skills = finetuneResume(skills);
        setOriginalSkillsJSON((value: string) => skills);
        setSkillsJSON((value: string) => skills);
        localStorage_msg['SKILLS'] = skills
        localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg));

      }
      // else if (prompt === "COVER LETTER") {
      //   const decodedChunk = decoder.decode(value, { stream: true });
      //   setCoverletterData(value => value + decodedChunk)
      // }

      fullResume = "'PERSONAL DETAILS'\n............................\n" + finetuneResume(personal);
      fullResume = fullResume + "\n\n'PROFESSIONAL SUMMARY'\n............................" + finetuneResume(profsummary);
      fullResume = fullResume + "\n\n'EMPLOYMENT HISTORY'\n............................" + finetuneResume(employeementdet);
      fullResume = fullResume + "\n\n'EDUCATION'\n............................" + finetuneResume(educationdet.replace(/\*.*?\*/g, ''));
      fullResume = fullResume + "\n\n'SKILLS'\n............................" + finetuneResume(skills.replace(/\*.*?\*/g, '').replace("SKILLS", ""));
      fullResume = fullResume.replace(/##/g, '');

      setdisplayResumeData(fullResume);

      handleResumePreview("normal", "");

      if (done) {
        break;
      }
    }

  }
  const user_id = localStorage.getItem('user_id');

  let htmltemplate = "template1";



  const handleResumePreview = (mode: string, data: any) => {
    //let use html template and show the resume with html tempalte for beautification
    let htmlContent = htmlTemplate
    let template = "template1";
    if (htmltemplate) template = htmltemplate;

    //console.log(template);

    if (template == "template1")
      htmlContent = htmlTemplate;
    else if (template == "template2")
      htmlContent = htmlTemplate2;
    else if (template == "template3")
      htmlContent = htmlTemplate3;


    let pd: PERSONAL_DETAILS = {
      Name: "",
      Position: "",
      Phone: "",
      Email: "",
      City: "",
      State: "",
      Country: ""
    };

    // console.log("localStorage.getItem('localStorage_msg')......")
    // console.log(localStorage.getItem("localStorage_msg"))
    //let personal_details: PERSONAL_DETAILS = localStorage_msg["PERSONAL DETAILS"] !== "" ? { ...strToObj(localStorage_msg["PERSONAL DETAILS"]), ...pd } : pd;
    let data_msg: any;
    if (mode != "saved")
      data_msg = JSON.parse(String(localStorage.getItem("localStorage_msg")))
    else
      data_msg = data


    if (!data_msg) return;
    let strhtmlTemplate = "";
    if (data_msg["PERSONAL DETAILS"]) {
      let personal_details: any;
      if (mode != "saved")
        if (typeof data_msg["PERSONAL DETAILS"] === 'object')
          personal_details = data_msg["PERSONAL DETAILS"];
        else
          personal_details = strToObj(data_msg["PERSONAL DETAILS"].trim());
      else
        personal_details = data_msg["PERSONAL DETAILS"]



      if (personal_details.Name == undefined || personal_details.Name == "") {
        if (personal_details["First Name"] || personal_details["Last Name"])
          personal_details.Name = personal_details["First Name"] + " " + personal_details["Last Name"];
        else
          personal_details.Name = "";
      }
      if (personal_details.Position == undefined || !personal_details.Position) personal_details.Position = "";
      strhtmlTemplate = htmlContent.replace("{{name}}", personal_details.Name.trim());
      strhtmlTemplate = htmlContent.replaceAll("{{name}}", personal_details.Name.trim());
      if (personal_details.Position == undefined || !personal_details.Position) personal_details.Position = "";
      strhtmlTemplate = strhtmlTemplate.replace("{{designation}}", personal_details.Position.trim());
      if (personal_details.Email == undefined || !personal_details.Email) personal_details.Email = "";
      strhtmlTemplate = strhtmlTemplate.replace("{{email}}", personal_details.Email.trim());
      if (personal_details.Phone == undefined || !personal_details.Phone) personal_details.Phone = "";
      strhtmlTemplate = strhtmlTemplate.replace("{{phone}}", personal_details.Phone.trim());
      if (personal_details.Address == undefined || !personal_details.Address) personal_details.Address = "";

      let address = '';
      if (personal_details.Address == "" && personal_details.Address != "[Not Provided]") {

        if (personal_details.City && personal_details.City != "[Not Provided]") {
          address = personal_details.City
        }

        if (personal_details.State && personal_details.State != "[Not Provided]") {
          if (address.length > 0)
            address = address + ", " + personal_details.State
          else
            address = personal_details.State
        }

        if (personal_details.Country && personal_details.Country != "[Not Provided]") {
          if (address.length > 0)
            address = address + ", " + personal_details.Country
          else
            address = personal_details.Country
        }


      }
      else {
        address = personal_details.Address
      }

      strhtmlTemplate = strhtmlTemplate.replace("{{address}}", address.trim());
    }


    let photo = selectedPhoto as string;
    //const base64String = btoa(photo?"data:image/jpeg;base64,/9j/4AAQSk"+photo:"");
    let imagestring = "<img src='{{octet_stream_data_photo}}' alt='' style='width:100px; padding: 0px 0px 22px 5px; ' />";
    if (photo) {
      if (template == "template1") {
        imagestring = "<td width='16%' style='padding: 0px; align-items: end;'><img src='{{octet_stream_data_photo}}' alt='' style='width:100px; padding: 0px 0px 36px 5px; ' /></td>";
        imagestring = imagestring.replace("{{octet_stream_data_photo}}", photo ? photo : '');
        strhtmlTemplate = strhtmlTemplate.replace("{{imagestring}}", imagestring);
        strhtmlTemplate = strhtmlTemplate.replace("{{imagedivider}}", imagedivider);
        strhtmlTemplate = strhtmlTemplate.replace("{{headerper}}", '83%');

      }
      else {
        imagestring = "<img src='{{octet_stream_data_photo}}' alt='' style='width:100px; padding: 0px 0px 22px 5px; ' />";
        imagestring = imagestring.replace("{{octet_stream_data_photo}}", photo ? photo : '');
        strhtmlTemplate = strhtmlTemplate.replace("{{imagestring}}", imagestring);

      }

    }
    else {
      strhtmlTemplate = strhtmlTemplate.replace("{{imagestring}}", "");
      strhtmlTemplate = strhtmlTemplate.replace("{{imagedivider}}", "");
      strhtmlTemplate = strhtmlTemplate.replace("{{headerper}}", '100%');
    }


    //set professional summary
    let prosfsum = finetuneResume(data_msg['PROFESSIONAL SUMMARY']).replace("\n", "");
    strhtmlTemplate = strhtmlTemplate.replace("{{professional_summary}}", prosfsum);



    let emphistory: any;
    if (mode != "saved")
      if (typeof data_msg["EMPLOYMENT HISTORY"] === 'object')
        emphistory = data_msg["EMPLOYMENT HISTORY"]
      else
        emphistory = strToArr(finetuneResume(data_msg["EMPLOYMENT HISTORY"]))
    else
      emphistory = data_msg["EMPLOYMENT HISTORY"]

    let emprecord = ""
    for (const item of emphistory) {

      let job_title = item["Job Title"];
      let company = item["Company"];
      let period_of_work: any = item["Period of work"];

      let location = item["Location"];

      if ((job_title || company || item["Period of Work"] || location) && template == "template3") {
        if (!location || location == undefined) location = "";
        emprecord += "<tr><td colspan='2' align='left' style='font-weight: bold;'>&nbsp;</td></tr><tr><td width='25%'  align='left' style='font-weight: bold; text-align: left;'>" + item["Period of Work"] + "<br>" + job_title + "</td>";
        emprecord += "<td width='75%' colspan='2'  align='left' style='font-weight: bold; text-align: left;vertical-align: top;'>" + company + ", " + location + "</td></tr>";
      }
      else {
        emprecord += "<tr><td colspan='3' align='left' style='font-weight: bold;'>";
        if (job_title)
          emprecord += "<br>" + job_title + ", "
        if (company)
          emprecord += company + ", "

        if (item["Period of Work"])
          emprecord += item["Period of Work"] + " "
        if (location)
          emprecord += location
        emprecord += "</td></tr>"
      }


      if (item["Responsibilities"] && template == "template3") {
        let responsibilities_list: any = item["Responsibilities"]
        emprecord += "<tr><td width='25%'  align='left' style='font-weight: 400px; vertical-align: top;padding-right:5px;'>&nbsp;</td><td  align='left' style='font-weight: 400px; text-align: justify;vertical-align: top;padding-right:5px;'>"
        for (const respitem of responsibilities_list) {
          //Split the string into an array using newline character
          let array_list: any = respitem.split('\n');
          //Traverse through the array using a for loop
          let resp = "<ul>";
          for (let respitem1 of array_list) {
            respitem1 = respitem1.replace(/\*/gi, '');
            resp += "<li>" + respitem1 + "</li>"

          }
          resp += "</ul>";
          emprecord += resp;

        }
        emprecord += "</td></tr>";
      }
      else if (item["Responsibilities"]) {

        let responsibilities_list: any = item["Responsibilities"]
        emprecord += "<tr><td colspan='3' align='left' style='font-weight: 400px; text-align: justify;vertical-align: top;padding-right:5px;'>";
        for (const respitem of responsibilities_list) {
          //Split the string into an array using newline character
          let array_list: any = respitem.split('\n');
          //Traverse through the array using a for loop
          let resp = "<ul>"
          for (let respitem1 of array_list) {
            respitem1 = respitem1.replace(/\*/gi, '');
            resp += "<li>" + respitem1 + "</li>"

          }
          resp += "</ul>"
          emprecord += resp;

        }
        emprecord += "</td></tr>"

      }

    }
    strhtmlTemplate = strhtmlTemplate.replace("{{employment_history}}", emprecord);

    //set educational history
    let eduhistory: any;
    if (mode != "saved")
      if (typeof data_msg["EDUCATION"] === 'object')
        eduhistory = data_msg["EDUCATION"]
      else
        eduhistory = strToArr(finetuneResume(data_msg["EDUCATION"].replace(/\*.*?\*/g, '')));
    else
      eduhistory = data_msg["EDUCATION"]
    let edurecord = "";
    let institution_name = "";
    let degree = "";


    for (const record of eduhistory) {

      if (!record["Degree"] && !record["Institution Name"] && !record["Period of Study"])
        continue;

      let period_of_study: any = record["Period of Study"]

      if (template == "template3") {
        if (record["Degree"] && record["Institution Name"]) {
          degree = record["Degree"];
          institution_name = record["Institution Name"];
          edurecord += "<strong>" + degree + "</strong>,<br>";
          edurecord += institution_name + "<br><br>";
          degree = "";
          institution_name = "";
        }
        else if (record["Degree"]) {
          degree = record["Degree"];
          edurecord += "<strong>" + degree + "</strong>,<br>";
          edurecord += institution_name + "<br><br> ";
          degree = "";
          institution_name = "";
        }
        else if (record["Institution Name"])
          institution_name = record["Institution Name"]
      }
      else if (template != "template3") {
        if (record["Degree"] && record["Institution Name"]) {
          degree = record["Degree"];
          institution_name = record["Institution Name"];
          edurecord += "<tr><td colspan='3' align='left' style='padding-bottom:10px;'>";
          institution_name = record["Institution Name"];
          edurecord += "<strong>" + degree + "</strong>,<br>";
          edurecord += institution_name + "<br>";
          edurecord += "</td></tr>";
          degree = "";
          institution_name = "";
        }
        else if (record["Degree"]) {
          degree = record["Degree"];
          edurecord += "<strong>" + degree + "</strong>,<br>";
          edurecord += institution_name + "<br>";
          edurecord += "</td></tr>";
          degree = "";
          institution_name = "";
        }
        else if (record["Institution Name"]) {
          edurecord += "<tr><td colspan='3' align='left'  style='padding-bottom:10px;'>";
          institution_name = record["Institution Name"];
        }


      }

      if (record["summary"])
        edurecord += "<tr><td colspan='3' align='left'>" + record["summary"] + "</td></tr>"

    }

    if (edurecord) {

      strhtmlTemplate = strhtmlTemplate.replace("{{educational_title}}", "EDUCATIONAL HISTORY");
      if (template == "template1")
        strhtmlTemplate = strhtmlTemplate.replace("{{spacingtd}}", "<tr><td colspan='3' style='height: 10px;'>&nbsp;</td></tr>");
      strhtmlTemplate = strhtmlTemplate.replace("{{educational_history}}", edurecord);
      strhtmlTemplate = strhtmlTemplate.replace("{{hr}}", " <hr />");
      strhtmlTemplate = strhtmlTemplate.replace("{{hr}}", " <hr />");
    }
    else {
      strhtmlTemplate = strhtmlTemplate.replace("{{educational_title}}", "");
      strhtmlTemplate = strhtmlTemplate.replace("{{spacingtd}}", "");
      strhtmlTemplate = strhtmlTemplate.replace("{{educational_history}}", "");
      strhtmlTemplate = strhtmlTemplate.replace("{{hr}}", "");
      strhtmlTemplate = strhtmlTemplate.replace("{{hr}}", "");
    }


    //update skillset
    let skilsets: any = finetuneResume(data_msg['SKILLS'].replace(/\*.*?\*/g, '').replace("SKILLS", ""));
    // Split the string into an array of skill sets using newline character '\n'
    // Use map to iterate over each skill and remove leading/trailing whitespace
    // Filter out any empty strings from the resulting array
    let skills_list: string[] | null = null;
    if (typeof skilsets === 'string') {
      skills_list = skilsets.split('\n').map((skill: string) => skill.trim()).filter((skill: string) => skill !== '');
    }

    let skillset = ""
    if (skills_list) {
      for (let record of skills_list) {
        const pattern: RegExp = /\bJOB DESCRIPTION\b/i;
        record = record.replace(/\*/gi, '');
        let rskill = record.replace("-", "").trim();
        if (skillset === "" && !pattern.test(rskill) && rskill.trim().length > 7) {
          skillset = "<li>" + rskill + "</li>"
        }
        else if (!pattern.test(rskill) && rskill.trim().length > 7) {
          skillset += "<li>" + rskill + "</li>"
        }


      }
    }

    skillset = skillset.replace("\n", "<br>")

    skillset = skillset
      .replace(/Skills?:/gi, '')  // Remove 'Skills:' or 'Skill:' (case-insensitive)
      .replace(/Skills?/gi, '')   // Remove 'Skills' or 'Skill' (case-insensitive)
      .replace(/\*/gi, '');       // Remove asterisks

    strhtmlTemplate = strhtmlTemplate.replace("{{skills}}", skillset);

    //console.log(strhtmlTemplate);
    sethtmlResumeContent('');
    sethtmlResumeContent(strhtmlTemplate);

    handleCloseModal2();
    //setShowModal(false);

  }

  const handleResumeExportClick = () => {
    const user_id = localStorage.getItem('user_id');
    axios.post(baseUrl + `aiResume/end_resume_timer/${user_id}`).then(response => {
      //console.log('Ended resume timer successfull!!');
    }).catch(error => {
      //console.log('Oops!!Error on ending resume timer...');
    })
  }

  const handleCoverExportClick = () => {
    const user_id = localStorage.getItem('user_id');
    axios.post(baseUrl + `aiResume/end_cover_timer/${user_id}`).then(response => {
      //console.log('Ended cover timer successfully!!');
    }).catch(error => {
      //console.log('Oops!!Error on ending cover timer...');
    })
  }


  const handleRecordToolTime = () => {
    let user_id = localStorage.getItem("user_id")
    axios.post(baseUrl + `aiResume/start_tool_timer/${user_id}`).then(response => {
      //console.log('Ended resume timer successfull!!');
    }).catch(error => {
      //console.log('Oops!!Error on ending resume timer...');
    })
  }



  const CreateResume = async () => {

    localStorage.removeItem("localStorage_msg");
    setdisplayResumeData("");
    setContent('');
    setCoverData('');
    localStorage.removeItem("doc_id");
    localStorage.removeItem("cover_doc_id");
    setloadingCreateResume(true);
    fullResume = '';
    personal = '';
    profsummary = '';
    employeementdet = '';
    educationdet = '';
    localStorage.setItem("cover_doc_id", "");
    localStorage.setItem("doc_id", "");

    let user_id = localStorage.getItem('user_id');


    let imethod = "manual";
    let uid = localStorage.getItem("user_id");

    localStorage.setItem("downloadmode", "normal");

    axios.post(baseUrl + 'aiResume/create_resume?input_method=' + imethod + '&user_id=' + uid).then(response => {
      //console.log('Resume upload method added');
    }).catch(error => {
      //console.log('Oops!!Error on Resume upload method added');

    })


    axios.post(baseUrl + `aiResume/start_resume_timer/${user_id}`).then(response => {
      //console.log('Resume timer started successfully!!');
    }).catch(error => {
      //console.log('Oops!!Error on starting resume timer');

    })

    axios.post(baseUrl + `aiResume/start_cover_timer/${user_id}`).then(response => {
      //console.log('Cover timer started successfully!!');
    }).catch(error => {
      //console.log('Oops!!Error on starting cover timer');
    })

    const { data }: any = await getAllManualDetailsFunc(localStorage.getItem("user_id"));
    function StrinConcat(data: any) {
      let str = ""
      data.reduce((a: any, b: any) => {
        if (typeof a === "string") {
          str = a + Object.entries(b).flat(Infinity).join()
        } else {
          str = Object.entries(a).flat(Infinity).join() + Object.entries(b).flat(Infinity).join()
        }
        return str
      }, {})
      return str.replace(/<[^>]*>/g, '')
    }
    // console.log(data["data"])
    if (data && data["status"] === "success") {
      const Resume = Object.entries(data["data"]["personal_details"]).flat(Infinity).join() + Object.entries(data["data"]["contact_info"]).flat(Infinity).join() + data["data"]["professional_summary"]["summary"].replace(/<[^>]*>/g, '') + StrinConcat(data["data"]["education"]["education_history"]) + StrinConcat(data["data"]["employment_history"]["employment_history"]) + data["data"]["skills"]["skills"].join();
      setResumePromptData(Resume)
      setJobDataLoading(true)
      const PersonalDetails = { Prompts: "PERSONAL DETAILS", code: Resume, key: "Personal Details", job_dec: Resume }
      const Proffesional = { Prompts: "PROFESSIONAL SUMMARY", code: Resume, key: "Profesional Summery", job_dec: JobDescData == "" ? Resume : JobDescData }
      const Education = { Prompts: "EDUCATION", code: Resume, key: "Education Details", job_dec: Resume }
      const EmploymentHistory = { Prompts: "EMPLOYMENT HISTORY", code: Resume, key: "Employment History", job_dec: Resume }
      const Skill = { Prompts: "SKILLS", code: Resume, key: "Skills", job_dec: Resume }
      const arr = [PersonalDetails, EmploymentHistory, Education, Proffesional, Skill]
      let finalArray: any = []


      setOriginalPersonalDetailsJSON((value: string) => "")
      setPersonalDetailsJSON((value: string) => "")
      setOriginalPersonalSummaryJSON((value: string) => "")
      setPersonalSummaryJSON((value: string) => "")
      setOriginalEmploymentHistoryJSON((value: string) => "")
      setEmploymentHistoryJSON((value: string) => "")
      setOriginalEducationHistoryJSON((value: string) => "")
      setEducationHistoryJSON((value: string) => "")
      setOriginalSkillsJSON((value: string) => "");
      setSkillsJSON((value: string) => "");

      callCoverLetter(Resume);
      await Promise.all(arr.map(async (val) => {
        const PromptData = {
          "prompt": val["Prompts"],
          "code": val["code"],
          "job_description": val["job_dec"] ? val["job_dec"] : val["code"],
          "free_plan": 0,
          "subscription_level": "Free"
        }


        await fetchPromptData(val["Prompts"], val["code"], val["job_dec"])

      })).then(() => {
        setContent(displayResumeData)
        //console.log(displayResumeData)
        //setloadingCreateResume(false)

      })
      if (displayResumeData) {
        //alert('save resume --2')
        //handleSave();
      }
    }
    else {
      setloadingCreateResume(false)
      toast.error("Please fill the all data")
    }
  }


  const CreateResume_old = async () => {
    setdisplayResumeData("");
    setContent('');
    setCoverData('');
    localStorage.removeItem("doc_id");
    localStorage.removeItem("cover_doc_id");
    setloadingCreateResume(true)
    setJobDataLoading(true)
    const { data }: any = await getAllManualDetailsFunc(localStorage.getItem("user_id"));
    function StrinConcat(data: any) {
      let str = ""
      data.reduce((a: any, b: any) => {
        if (typeof a === "string") {
          str = a + Object.entries(b).flat(Infinity).join()
        } else {
          str = Object.entries(a).flat(Infinity).join() + Object.entries(b).flat(Infinity).join()
        }
        return str
      }, {})
      return str.replace(/<[^>]*>/g, '')
    }
    // console.log(data["data"])
    if (data && data["status"] === "success") {
      const Resume = Object.entries(data["data"]["personal_details"]).flat(Infinity).join() + Object.entries(data["data"]["contact_info"]).flat(Infinity).join() + data["data"]["professional_summary"]["summary"].replace(/<[^>]*>/g, '') + StrinConcat(data["data"]["education"]["education_history"]) + StrinConcat(data["data"]["employment_history"]["employment_history"]) + data["data"]["skills"]["skills"].join();
      setResumePromptData(Resume)
      const PersonalDetails = { Prompts: "PERSONAL DETAILS", code: Resume, key: "Personal Details", job_dec: Resume }
      const Proffesional = { Prompts: "PROFESSIONAL SUMMARY", code: Resume, key: "Profesional Summery", job_dec: JobDescData == "" ? Resume : JobDescData }
      const Education = { Prompts: "EDUCATION", code: Resume, key: "Education Details", job_dec: Resume }
      const EmploymentHistory = { Prompts: "EMPLOYMENT HISTORY", code: Resume, key: "Employment History", job_dec: Resume }
      const Skill = { Prompts: "SKILLS", code: Resume, key: "Skills", job_dec: Resume }
      const arr = [PersonalDetails, EmploymentHistory, Education, Proffesional, Skill]
      let finalArray: any = []

      await Promise.all(arr.map(async (val) => {
        const PromptData = {
          "prompt": val["Prompts"],
          "code": val["code"],
          "job_description": val["job_dec"],
        }


        const responce: any = await createResumeFunc(PromptData);


        if (val["key"] === "Skills") {
          const filterd = responce["data"]["msg"].split(`${responce["data"]["msg"].includes("**") ? "**" : responce["data"]["msg"].includes("\n\n") ? "\n\n" : ":\n"}`).filter(Boolean)
          if (filterd[0].toLowerCase().replace("\n\n", "") === "skills:" || filterd[0].toLowerCase().replace("\n\n", "") === "skills") {
            filterd.shift()
          }
          // console.log(filterd)
          setdisplayResumeData((preValue) => preValue + "skills:" + `${filterd.join("").startsWith("skills:") ? "\n\n" : "\n"}` + filterd.join("") + "\n-----------\n")
          let RemoveNextLine = await responce["data"]["msg"]
          // RemoveNextLine.shift();
          setSkillsJSON(RemoveNextLine)
          setOriginalSkillsJSON(responce["data"]["msg"])
          finalArray = [{ [val["key"]]: RemoveNextLine, key: val["key"] }, ...finalArray]

        }

        else if (val["key"] === "Education Details") {
          setOriginalEducationHistoryJSON(responce["data"]["msg"]);
          const replacedEdu = responce["data"]["msg"].replace(responce["data"]["msg"].includes("Education Section:") ? "Education Section:" : responce["data"]["msg"].includes("Education Section") ? "Education Section" : responce["data"]["msg"].includes("Education:") ? "Education:" : "Education", "")
          const filterd = replacedEdu.split(`${replacedEdu.includes("**") ? "**" : "\n\n"}`).filter(Boolean)
          if (filterd[0].toLowerCase().replace("\n\n", "") === "education:" || filterd[0].toLowerCase().replace("\n\n", "") === "education") {
            filterd.shift()
          }

          setdisplayResumeData((preValue) => preValue + "Education Details:\n\n" + replacedEdu.split("##").join(replacedEdu.includes("\n") ? "" : "\n") + "\n-----------\n")
          const EducationData = replacedEdu.split("##").filter(Boolean);
          const EducationDetail = EducationData.map((description: any) => {
            const details = description.split('\n').filter(Boolean);
            const Educat: any = {};
            details.forEach((detail: any) => {
              const [key, value] = detail.split(':').map((item: any) => item.trim());
              Educat[key] = value;
            });

            return Educat;
          });
          const filteredArray = EducationDetail.filter((item: any) => Object.keys(item).length > 0);
          filteredArray.shift();
          setEducationHistoryJSON([...filteredArray])
          finalArray = [{ [val["key"]]: filteredArray, key: val["key"] }, ...finalArray]

        }
        // }

        else if (val["key"] === "Personal Details") {
          setOriginalPersonalDetailsJSON(responce["data"]["msg"])
          setdisplayResumeData((preValue) => preValue + "Personal Details:\n\n" + responce["data"]["msg"].split("##").join(responce["data"]["msg"].includes("\n") ? "" : "\n") + "\n-----------\n")
          let RemoveHash = responce["data"]["msg"].split(`##`)
          let PersonalDetails = RemoveHash.map((val: any) => {
            val.trim()
            return val.split(":")
          })
          // Converting Array set to JSON
          const PersonalDetailsData: any = await ConvertArrayToJSON(PersonalDetails);
          setPersonalDetailsJSON({ ...PersonalDetailsData })
          finalArray = [{ [val["key"]]: PersonalDetails, key: val["key"] }, ...finalArray]

        }

        else if (val["key"] === "Employment History") {
          setOriginalEmploymentHistoryJSON(responce["data"]["msg"])
          const replaceEmp = responce["data"]["msg"].replace(responce["data"]["msg"].includes("Employment History:") ? "Employment History" : "Employment History", "")
          setdisplayResumeData((preValue) => preValue + "Employment History:\n\n" + replaceEmp.split("##").join(replaceEmp.includes("\n") ? "" : "\n") + "\n-----------\n")
          const jobEntries = replaceEmp.split('## Job Title:');
          jobEntries.shift();
          const parsedJobs = jobEntries.map((entry: any) => {
            const lines = entry.trim().split('\n');
            const jobTitle = lines[0].trim();
            const company = lines[1].trim().replace('Company:', '');
            // const periodOfWork = lines[2].trim().replace('Period of Work:', '');
            const responsibilities = lines.slice(5).map((res: any) => res.trim().replace("##", ""));

            return {
              "Job Title": jobTitle,
              "Company": company,
              // "Period Of Work": periodOfWork,
              "Responsibilities": responsibilities,
            };
          });

          setEmploymentHistoryJSON([...parsedJobs])

          finalArray = [{ [val["key"]]: parsedJobs, key: val["key"] }, ...finalArray]

        } else {
          setdisplayResumeData((preValue) => preValue + "Proffesional Summary:\n" + responce["data"]["msg"] + "\n-----------\n")
          setOriginalPersonalSummaryJSON(responce["data"]["msg"])
          setPersonalSummaryJSON(responce["data"]["msg"])
          finalArray = [{ [val["key"]]: responce["data"]["msg"], key: val["key"] }, ...finalArray]
        }
        setRightData(finalArray)
      })).then(() => {
        setContent(displayResumeData)
        setloadingCreateResume(false)
        callCoverLetter(displayResumeData)
      })

    }
    else {
      setloadingCreateResume(false)
      toast.error("Please fill the all data")
    }
  }

  const ConvertArrayToJSON = (Arr: any) => {
    const Data: any = {};
    try {
      Arr.forEach((item: any) => {
        const label = item[0].trim().replace('\n', '');
        const value = item[1].trim();
        Data[label] = value;
      });
    } catch {
      Data[""] = ""
    }
    return Data
  }

  interface EmploymentHistory {
    "Job Title": string;
    "Company": string;
    "Period Of Work": string;
    "Responsibilities": string[];
  }

  interface EducationHistory {
    "Institution Name": string;
    "Degree": string;
    // "Period of Study": string;
  }

  interface PersonalDetails {
    "First Name": string;
    "Name": string;
    "Position": string;
    "Email": string;
    "Phone": string;
    "City": string;
    "State": string;
    "Country": string;
  }

  interface ConvertedData {
    employmentHistory: EmploymentHistory[];
    educationHistory: EducationHistory[];
    personalDetails: PersonalDetails;
    personalSummary: string;
    skills: string[];
  }

  const finetuneResume = (formattedMsg: string) => {
    formattedMsg = formattedMsg.replace("***** PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("{PROFESSIONAL SUMMARY}", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("***** END PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("***** END OF PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("{SKILLS}", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("SKILLS:", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("***** SKILLS *****", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("***** SKILLS *****", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("{EDUCATION}", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("***** EDUCATION *****", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("***** RESUME *****", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("{RESUME}", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("***** SKILLS SECTION *****", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("***** JOB DESCRIPTION *****", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("*", '');
    formattedMsg = formattedMsg.replace("RESUME", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("END OF", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("END", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace("{}", '').replace(/\r?\n\r?\n/g, '\r\n');
    formattedMsg = formattedMsg.replace(/^##/gm, "\n##");
    formattedMsg = formattedMsg.replace(/ ## /gm, "\n");
    formattedMsg = formattedMsg.replace(" OF ", '');
    formattedMsg = formattedMsg.replace(/^## /g, '\n');
    formattedMsg = formattedMsg.replace(/^##/g, '\n');
    formattedMsg = formattedMsg.replace(/^(\*)+(.)+(\*)+$/g, '');
    formattedMsg = formattedMsg.replace("{JOB DESCRIPTION}", '');
    formattedMsg = formattedMsg.replace("SKILLS SECTION", '');
    formattedMsg = formattedMsg.replace("*  *****", '');
    formattedMsg = formattedMsg.replace("** JOB DESCRIPTION *****", '');
    formattedMsg = formattedMsg.replace(/\*\*\*\*\*.\*\*\*\*\*/g, '');
    formattedMsg = formattedMsg.replaceAll("[Not Provided]", '');
    formattedMsg = formattedMsg.replaceAll("Not Provided", '');
    formattedMsg = formattedMsg.replaceAll("[City]", '');
    formattedMsg = formattedMsg.replaceAll("[State]", '');
    formattedMsg = formattedMsg.replaceAll("[City Name]", '');
    formattedMsg = formattedMsg.replaceAll("[State Name]", '');
    formattedMsg = formattedMsg.replaceAll("[Country]", '');
    formattedMsg = formattedMsg.replaceAll("[Country Name]", '');
    formattedMsg = formattedMsg.replaceAll("[Placeholder]", '');
    formattedMsg = formattedMsg.replaceAll("•", '');
    return formattedMsg;
  }


  const convertJsonToString = (key: string, value: any) => {
    try {
      if (typeof value === 'string' && value.trim() !== '') {
        // const parsedValue = JSON.parse(value);
        // const msg = parsedValue.msg || parsedValue || value;
        // const formattedMsg = msg.trim();
        let formattedMsg = value.trim();
        formattedMsg = formattedMsg.replace("***** PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
        formattedMsg = formattedMsg.replace("{PROFESSIONAL SUMMARY}", '').replace(/\r?\n\r?\n/g, '\r\n');
        formattedMsg = formattedMsg.replace("***** END PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
        formattedMsg = formattedMsg.replace("***** END OF PROFESSIONAL SUMMARY *****", '').replace(/\r?\n\r?\n/g, '\r\n');
        formattedMsg = formattedMsg.replace("{SKILLS}", '').replace(/\r?\n\r?\n/g, '\r\n');
        formattedMsg = formattedMsg.replace("SKILLS:", '').replace(/\r?\n\r?\n/g, '\r\n');
        formattedMsg = formattedMsg.replace("***** SKILLS *****", '').replace(/\r?\n\r?\n/g, '\r\n');
        formattedMsg = formattedMsg.replace("***** SKILLS *****", '').replace(/\r?\n\r?\n/g, '\r\n');
        formattedMsg = formattedMsg.replace("{EDUCATION}", '').replace(/\r?\n\r?\n/g, '\r\n');
        formattedMsg = formattedMsg.replace("***** EDUCATION *****", '').replace(/\r?\n\r?\n/g, '\r\n');
        formattedMsg = formattedMsg.replace("***** RESUME *****", '').replace(/\r?\n\r?\n/g, '\r\n');
        formattedMsg = formattedMsg.replace(/^(\*)+(.)+(\*)+$/g, '');
        formattedMsg = formattedMsg.replace(/\*\*\*\*\*.\*\*\*\*\*/g, '');
        return `\n${formattedMsg}\n\n`;
      } else if (typeof value === 'object' && value !== null && 'msg' in value) {
        return `\n${(value as { msg?: string }).msg}\n\n`;
      } else {
        return `\n${JSON.stringify(value)}\n\n`;
      }
    } catch (error) {
      console.error(`Error parsing JSON for key '${key}' and value ${value}:`, error);
      return "";
    }
  };

  const copyToClipboard = () => {
    // Use navigator.clipboard.writeText to copy text to clipboard
    navigator.clipboard.writeText(content)
      .then(() => {
        toast.success('Data copied to clipboard');
      })
      .catch((error) => {
        toast.error('Unable to copy to clipboard');
      });
  };
  const copyCoverDataToClipboard = () => {
    // Use navigator.clipboard.writeText to copy text to clipboard
    navigator.clipboard.writeText(CoverData)
      .then(() => {
        toast.success('Data copied to clipboard');
      })
      .catch((error) => {
        toast.error('Unable to copy to clipboard');
      });
  };

  const strToArr = (str: string): Record<string, string | string[]>[] => {
    const detailsArr = str.split('##').filter(Boolean);
    const array: Record<string, string | string[]>[] = [];

    for (const details_item of detailsArr) {
      let details: string[] = details_item.split('\n').filter(Boolean);
      const object: Record<string, string | string[]> = {};

      details.forEach((detail, index) => {
        const [key, ...values] = detail.split(':').map((item: string) => item.trim());
        const value = values.join(':').trim();

        if (key === "Responsibilities") {
          // Handle responsibilities as an array
          const responsibilities: string[] = [];
          for (let i = index + 1; i < details.length && details[i].startsWith('-'); i++) {
            responsibilities.push(details[i].replace(/^-/, '').trim());
          }
          if (responsibilities.length > 0 && key) {
            object[key] = responsibilities;
          }
        } else if (!detail.startsWith('-') && key) {
          // Exclude lines starting with hyphen from the top-level array
          object[key] = value;
        }
      });

      if (Object.keys(object).length > 0) {
        array.push(object);
      }
    }

    return array;
  };

  const strToObj = (str: string): Record<string, string> => {
    const details = str.split('##').filter(Boolean);
    const object: Record<string, string> = {};

    details.forEach((detail) => {
      const [key, value] = detail.split(':').map((item) => item.trim());
      object[key] = value;
    });
    return object;
  };


  const handleSave = async () => {
    /*  if (displayResumeData === "") {
       toast.error("First Create Resume")
       return
     } */


    const convertedData: any = {
      "EMPLOYMENT HISTORY": [],
      EDUCATION: [],
      "PERSONAL DETAILS": {},
      "PROFESSIONAL SUMMARY": "",
      SKILLS: [],
    };

    const sections = content === "" ? displayResumeData.split("-----------") : content.split('-----------');
    //for (const element of sections) {
    // let localStorage_msg = {
    //   'PERSONAL DETAILS': '',
    //   'PROFESSIONAL SUMMARY': '',
    //   'EMPLOYMENT HISTORY': '',
    //   'EDUCATION': '',
    //   'SKILLS': '',
    //   'COVER LETTER': '',
    // }    



    //if (element != "\n") {

    //const obj: Record<string, unknown> = {
    convertedData["PERSONAL DETAILS"] = localStorage_msg["PERSONAL DETAILS"] !== "" ? strToObj(localStorage_msg["PERSONAL DETAILS"]) : "";
    convertedData["PROFESSIONAL SUMMARY"] = localStorage_msg["PROFESSIONAL SUMMARY"] !== "" ? localStorage_msg["PROFESSIONAL SUMMARY"] : "";
    convertedData["EMPLOYMENT HISTORY"] = localStorage_msg["EMPLOYMENT HISTORY"] !== "" ? strToArr(localStorage_msg["EMPLOYMENT HISTORY"]) : "";
    convertedData["EDUCATION"] = localStorage_msg["EDUCATION"] !== "" ? strToArr(localStorage_msg["EDUCATION"]) : "";
    convertedData["SKILLS"] = localStorage_msg["SKILLS"] !== "" ? localStorage_msg["SKILLS"] : "";
    //};



    // if (splitedContent[0] === "Personal Details:" || splitedContent[0] === "\nPersonal Details:") {
    //   const lines = splitedContent[1].replace("Desired Job Title", "Position").split('\n');
    //   const result: Record<string, string> = {};
    //   lines.forEach((line: any) => {
    //     if (line.trim() != "") {
    //       const [key, value] = line.split(':').map((part: any) => part.trim());
    //       result[key] = value;
    //     }
    //   });
    //   convertedData["PERSONAL DETAILS"] = { ...result }
    // }

    // else if (splitedContent[0] === "\nProffesional Summary:" || splitedContent[0] === "Proffesional Summary:") {
    //   convertedData["PROFESSIONAL SUMMARY"] = splitedContent[1].trim()
    // }
    // else if (splitedContent[0] === "\nEducation Details:" || splitedContent[0] === "Education Details:") {
    //   const result: Record<string, string>[] = [];

    //   splitedContent.filter((item: any) => item.trim() != "" || !item.startsWith("(Note:") || !item.startsWith("(Certainly") || !item.startsWith("```")).forEach((section: any) => {
    //     if (section.trim().startsWith("Institution Name") || section.trim().startsWith("\n Institution Name")) {
    //       const data: any = {}
    //       const lines = section.split('\n');
    //       lines.forEach((item: any) => {
    //         const [key, value] = item.split(":")
    //         data[key] = value
    //       });
    //       result.push(data)
    //     }
    //   });
    //   convertedData["EDUCATION"] = [...result]
    // }
    // else {
    //   if (splitedContent[0] === "\nEmployment History:") {
    //     const employmentData: any = [];
    //     splitedContent.filter((item: any) => item.trim() != "" || !item.startsWith("(Note:") || !item.startsWith("(Certainly") || !item.startsWith("```")).forEach((section: any) => {
    //       const data: any = {}
    //       const resArr: any = []
    //       if (section.toLocaleLowerCase().trim().startsWith("job title:")) {
    //         const splitedData = section.split("\n")
    //         splitedData.forEach((item: any) => {
    //           if (item.startsWith("-")) {
    //             resArr.push(item)
    //           } else {
    //             const [key, value] = item.split(":")
    //             data[key.trim()] = value
    //           }
    //         });
    //         data["Responsibilities"] = resArr
    //         employmentData.push(data)
    //       }
    //     });
    //     convertedData["EMPLOYMENT HISTORY"] = employmentData
    //   }
    // }
    //}
    //}

    let user_id = localStorage.getItem("user_id")

    /* axios.post(baseUrl + `aiResume/end_resume_timer/${user_id}`).then(response=>{
      console.log('Ended resume timer successfull!!');
    }).catch(error=>{
      console.log('Oops!!Error on ending resume timer...');
    }) */


    const cover: any = {
      "user_id": localStorage.getItem("user_id"),
      "is_type": "resume",
      "input_method": "manual",
      "content": convertedData,
      "original_data": '',
      "form_data": { personalDetails, contactInfo, employeementHistory, education, Skill },
      "formatted_data": displayResumeData,
      "json_data": {}
    }
    const { data }: any = await PostJSONFunc(cover);
    if (data && data["status"] === "success") {
      fetchData()
      //toast.success("Resume Saved Successfully")
      localStorage.setItem("doc_id", data["document_id"])
      //console.log("Resume document_id........")
      //console.log(data["document_id"])
    }
  };


  const handleSave_old = async () => {
    if (displayResumeData === "") {
      toast.error("First Create Resume")
      return
    }
    const convertedData: any = {
      "EMPLOYMENT HISTORY": [],
      EDUCATION: [],
      "PERSONAL DETAILS": {},
      "PROFESSIONAL SUMMARY": "",
      SKILLS: [],
    };

    const sections = content === "" ? displayResumeData.split("-----------") : content.split('-----------');
    for (const element of sections) {
      if (element != "\n") {


        const splitData = element.replace(/(\n\n|\r)+/g, '------')
        const splitedContent = splitData.split("------")
        // console.log("elemenet", splitedContent)
        if ((splitedContent[0] === "\nskills:" || splitedContent[0] === "skills:\n") || splitedContent[0] === "skills:") {
          convertedData["SKILLS"] = splitedContent[1]
        } else if (splitedContent[0].startsWith("\nskills:") || splitedContent[0].startsWith("skills:\n")) {
          convertedData["SKILLS"] = splitedContent[0].replace("skills:", "")
        }
        else if (splitedContent[0] === "Personal Details:" || splitedContent[0] === "\nPersonal Details:") {
          const lines = splitedContent[1].replace("Desired Job Title", "Position").split('\n');
          const result: Record<string, string> = {};
          lines.forEach((line: any) => {
            if (line.trim() != "") {
              const [key, value] = line.split(':').map((part: any) => part.trim());
              result[key] = value;
            }
          });
          convertedData["PERSONAL DETAILS"] = { ...result }
        }

        else if (splitedContent[0] === "\nProffesional Summary:" || splitedContent[0] === "Proffesional Summary:") {
          convertedData["PROFESSIONAL SUMMARY"] = splitedContent[1].trim()
        }
        else if (splitedContent[0] === "\nEducation Details:" || splitedContent[0] === "Education Details:") {
          const result: Record<string, string>[] = [];

          splitedContent.filter((item: any) => item.trim() != "" || !item.startsWith("(Note:") || !item.startsWith("(Certainly") || !item.startsWith("```")).forEach((section: any) => {
            if (section.trim().startsWith("Institution Name") || section.trim().startsWith("\n Institution Name")) {
              const data: any = {}
              const lines = section.split('\n');
              lines.forEach((item: any) => {
                const [key, value] = item.split(":")
                data[key] = value
              });
              result.push(data)
            }
          });
          convertedData["EDUCATION"] = [...result]
        }
        else {
          if (splitedContent[0] === "\nEmployment History:") {
            const employmentData: any = [];
            splitedContent.filter((item: any) => item.trim() != "" || !item.startsWith("(Note:") || !item.startsWith("(Certainly") || !item.startsWith("```")).forEach((section: any) => {
              const data: any = {}
              const resArr: any = []
              if (section.toLocaleLowerCase().trim().startsWith("job title:")) {
                const splitedData = section.split("\n")
                splitedData.forEach((item: any) => {
                  if (item.startsWith("-")) {
                    resArr.push(item)
                  } else {
                    const [key, value] = item.split(":")
                    data[key.trim()] = value
                  }
                });
                data["Responsibilities"] = resArr
                employmentData.push(data)
              }
            });
            convertedData["EMPLOYMENT HISTORY"] = employmentData
          }
        }
      }
    }
    const cover: any = {
      "user_id": localStorage.getItem("user_id"),
      "is_type": "resume",
      "input_method": "manual",
      "content": convertedData,
      "original_data": '',
      "form_data": { personalDetails, contactInfo, employeementHistory, education, Skill },
      "formatted_data": displayResumeData,
      "json_data": {}
    }
    const { data }: any = await PostJSONFunc(cover);
    if (data["status"] === "success") {
      toast.success("Cover Letter Saved Successfully")
      localStorage.setItem("doc_id", data["document_id"])
      //console.log("cover document_id........")
      //console.log(data["document_id"])
    }
  };


  const EditResumeData = () => {
    if (isEditable) {
      toast.error("You Can't Edit Your Resume Data")
      setisEditable(false)
    } else {
      toast.success("You Can Edit Your Resume Data")
      setisEditable(true)
    }
  }
  const EditCoverData = () => {
    if (isEditable) {
      toast.error("You Can't Edit Your Cover Data")
      setisEditableJobDesc(false)
    } else {
      toast.success("You Can Edit Your Cover Data")
      setisEditableJobDesc(true)
    }
  }
  // console.log(content)
  const handleContentChange = (e: React.FormEvent<HTMLPreElement>) => {
    const currentTarget = e.currentTarget as unknown;
    const updatedContent = (currentTarget as HTMLDivElement)?.innerHTML;
    setContent(updatedContent);
  }
  const handleCoverContentChange = (e: React.FormEvent<HTMLPreElement>) => {
    const currentTarget = e.currentTarget as unknown;
    const updatedContent = (currentTarget as HTMLDivElement)?.innerHTML;
    setCoverData(updatedContent);
  }
  const handleJobContentChange = (e: any) => {

    setJobDescData(e.target.value);
  }
  const handleOptionSelect = (option: string) => {
    //console.log("in option select")
    if (localStorage.getItem("doc_id")) {
      setSelectedOption(option);
      handleDownload(option);
      //setShowModal(true);

    } else {
      toast.error("Save First Then Export....")
    }

  };

  /* Current timing and date with removed special characters and spaces inbetween */
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  const second = String(now.getSeconds()).padStart(2, '0');

  const timestamp = `${year}-${month}-${day} | ${hour}:${minute}`;
  const cleanedTimeStamp = timestamp.replace(/[^0-9a-zA-Z]/g, '');
  /*  */

  const handleCoverOptionSelect = async (option: string) => {
    //console.log("in cover option select")
    if (localStorage.getItem("cover_doc_id")) {
      handleCoverExportClick();
      setSelectedOption(option);
      if (option === 'googledocs') {
        try {
          setDownloadLoading(true)
          const { data }: any = await exportCoverFile({ user_id: localStorage.getItem("user_id"), document_id: localStorage.getItem("cover_doc_id"), template_id: selectedImage, document_type: option })
          // localStorage.setItem("cover_doc_id", data["document_id"])
          const res = await axios.get(baseUrl + `aiResume/download_signed_resume/${data["document_id"]}/${localStorage.getItem('resume_name_')}`);
          const result = res.data;
          //console.log(result);
          let file_url = result?.url;
          const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(file_url)}`;
          //console.log(googleDocsViewerUrl);
          window.open(googleDocsViewerUrl, '_blank');
          setShowModal(false)
          setDownloadLoading(false)
          setSelectedOption("");
        } catch {
          setDownloadLoading(false)
        }
      } else {
        try {
          setDownloadLoading(true)
          const { data }: any = await exportCoverFile({ user_id: localStorage.getItem("user_id"), document_id: localStorage.getItem("cover_doc_id"), template_id: selectedImage, document_type: option })
          // localStorage.setItem("cover_doc_id", data["document_id"])
          const file: any = await downloadFileFunc({ doc_id: data["document_id"] });
          const base64String: string = file["data"]["binary_data"]
          // downloadBase64AsPDF(base64String, localStorage.getItem("cover_doc_id") + `.${option == "pdf" ? "pdf" : "docx"}`, "word")
          // toast.success(`Downloaded: ${localStorage.getItem("cover_doc_id")}.${option == "pdf" ? "pdf" : "docx"}`);
          const personalDetailsString = localStorage.getItem("localStorage_msg");
          if (personalDetailsString) {
            const nameMatch = personalDetailsString.match(/First Name: (.+?)##/);
            const name = nameMatch ? nameMatch[1] : localStorage.getItem("doc_id");
            downloadBase64AsPDF(base64String, name + `_CoverLetter_${cleanedTimeStamp}.${option == "pdf" ? "pdf" : "docx"}`, "word")
            toast.success(`Downloaded: ${name}_CoverLetter_${cleanedTimeStamp}.${option === "pdf" ? "pdf" : "docx"}`);
          }
          setShowModal(false)
          setDownloadLoading(false)
          setSelectedOption("");
        } catch {
          toast.error("Error")
          setDownloadLoading(false)
        }
      }
    } else {
      console.log("Save First Then Export");
      //toast.error("Save First Then Export")
    }

  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseTemplateModal = () => {
    setShowTemplateModal(false);
  };
  const handleCloseViewTemplateModal = () => {
    setShowViewTemplateModal(false);
  };

  const handleCheckboxChange = (image: string) => {

    setSelectedImage((prevSelectedImage) => (prevSelectedImage === image ? null : image));
    setSelectedTemplate(image);
    htmltemplate = image;
    handleResumePreview("normal", "");


    //handleDownload();

  };
  const handleDownload = async (option: string = "word") => {

    if (!selectedImage) setSelectedImage(selectedTemplate);
    if (!selectedImage) setSelectedImage('template1');
    console.log("selectedImage....");
    console.log(selectedImage);
    console.log(selectedTemplate);

    if (downloadmode == "cover") {
      if (selectedOption === 'pdf')
        handleCoverOptionSelect('pdf');
      else
        handleCoverOptionSelect('word');
      return;
    }

    let format = selectedOption;
    if (!format) format = option;
    console.log("after if condition...");
    console.log(selectedImage);


    //console.log(selectedOption)
    if (format === 'pdf') {
      try {
        //console.log("localStorage.getItem('doc_id')");
        //console.log(localStorage.getItem("doc_id"));
        const { data }: any = await downloadDocFunc({ user_id: localStorage.getItem("user_id"), document_id: localStorage.getItem("doc_id"), template_id: selectedImage, document_type: format, is_type: isType })
        // localStorage.setItem("doc_id", data["document_id"])
        const file: any = await downloadFileFunc({ doc_id: data["document_id"] });
        const base64String: string = file["data"]["binary_data"]
        // downloadBase64AsPDF(base64String, localStorage.getItem("doc_id") + `.${selectedOption === "pdf" ? "pdf" : "docx"}`, "pdf")
        // toast.success(`Downloaded: ${localStorage.getItem("doc_id") + "." + (selectedOption === "pdf" ? "pdf" : "docx")}`);
        const personalDetailsString = localStorage.getItem("localStorage_msg");
        let tmpdata: any = JSON.parse(personalDetailsString ?? "");
        if ((typeof tmpdata["PERSONAL DETAILS"]) == 'string') {
          tmpdata["PERSONAL DETAILS"] = strToObj(tmpdata["PERSONAL DETAILS"] ?? "");
          console.log(tmpdata["PERSONAL DETAILS"])
        }
        if (personalDetailsString) {
          const nameMatch = personalDetailsString.match(/First Name: (.+?)##/);
          const name = nameMatch ? nameMatch[1] : tmpdata["PERSONAL DETAILS"]["Name"] ? tmpdata["PERSONAL DETAILS"]["Name"] : tmpdata["PERSONAL DETAILS"]["First Name"] + "_" + tmpdata["PERSONAL DETAILS"]["Last Name"];
          downloadBase64AsPDF(base64String, name + `_Resume_${cleanedTimeStamp}.${format === "pdf" ? "pdf" : "docx"}`, "pdf")
          toast.success(`Downloaded: ${name}_Resume_${cleanedTimeStamp}.${format === "pdf" ? "pdf" : "docx"}`);
        }

        //in case new generated resume
        if (downloadmode == "normal") handleCoverOptionSelect('pdf');

        setShowModal(false)
        setDownloadLoading(false)
        setSelectedOption("");
      } catch (error) {
        console.log(error)
        toast.error("Error")
        setDownloadLoading(false)
      }
    } else if (format === 'word') {
      try {
        const { data }: any = await downloadDocFunc({ user_id: localStorage.getItem("user_id"), document_id: localStorage.getItem("doc_id"), template_id: selectedImage, document_type: format, is_type: isType })
        // localStorage.setItem("doc_id", data["document_id"])
        const file: any = await downloadFileFunc({ doc_id: data["document_id"] });
        const base64String: string = file["data"]["binary_data"]
        // downloadBase64AsPDF(base64String, localStorage.getItem("doc_id") + `.${selectedOption === "word" ? "docx" : "pdf"}`, "word")
        // toast.success(`Downloaded: ${localStorage.getItem("doc_id") + "." + (selectedOption === "word" ? "docx" : "pdf")}`);
        const personalDetailsString = localStorage.getItem("localStorage_msg");
        let tmpdata: any = JSON.parse(personalDetailsString ?? "");

        if ((typeof tmpdata["PERSONAL DETAILS"]) == 'string') {
          tmpdata["PERSONAL DETAILS"] = strToObj(tmpdata["PERSONAL DETAILS"] ?? "");
          console.log(tmpdata["PERSONAL DETAILS"])
        }
        if (personalDetailsString) {
          const nameMatch = personalDetailsString.match(/First Name: (.+?)##/);
          const name = nameMatch ? nameMatch[1] : tmpdata["PERSONAL DETAILS"]["Name"] ? tmpdata["PERSONAL DETAILS"]["Name"] : tmpdata["PERSONAL DETAILS"]["First Name"] + "_" + tmpdata["PERSONAL DETAILS"]["Last Name"];
          downloadBase64AsPDF(base64String, name + `_Resume_${cleanedTimeStamp}.${format === "word" ? "docx" : "pdf"}`, "word")
          toast.success(`Downloaded: ${name}_Resume_${cleanedTimeStamp}.${format === "word" ? "docx" : "pdf"}`);
        }

        //in case of new generated resume
        if (downloadmode == "normal") handleCoverOptionSelect('word');

        setShowModal(false)
        setDownloadLoading(false)
        setSelectedOption("");
      } catch (error) {
        console.log(error)
        toast.error("Error")
        setDownloadLoading(false)
      }
      // if (selectedImage === "" || selectedImage == null) {
      //   toast.error("Select the Template")
      // } else {
      //   try {
      //     setDownloadLoading(true)
      //     let payload = {
      //       'resume': JSON.stringify(content)
      //     };
      //     const response = await axios.post(`${baseUrl}prompts/resume_to_json`, payload);
      //     const result = response.data;
      //     generateDocument(result);
      //     setShowModal(false)
      //     setDownloadLoading(false)
      //     setSelectedOption("");
      //   } catch (error) {
      //     console.log(error)
      //     toast.error("Error")
      //     setDownloadLoading(false)
      //   }
      //}

    } else if (format === 'googledocs') {
      try {
        let name = personalDetails.firstname + " " + personalDetails.lastname;
        const { data }: any = await downloadDocFunc({ user_id: localStorage.getItem("user_id"), document_id: localStorage.getItem("doc_id"), template_id: selectedImage, document_type: format, is_type: isType })
        localStorage.setItem("doc_id", data["document_id"])
        const res = await axios.get(baseUrl + `aiResume/download_signed_resume/${data["document_id"]}/${name}`);
        const result = res.data;
        //console.log(result);
        let file_url = result?.url;
        const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(file_url)}`;
        //console.log(googleDocsViewerUrl);
        window.open(googleDocsViewerUrl, '_blank');

        //in case of new generated resume
        if (downloadmode == "normal") handleCoverOptionSelect('googledocs');

        setShowModal(false)
        setDownloadLoading(false)
        setSelectedOption("");
      } catch (error) {
        //console.log(error)
        toast.error("Error")
        setDownloadLoading(false)
      }
    }
  };

  function downloadBase64AsPDF(base64String: string, fileName: string, filetype: string) {

    const binaryString = atob(base64String);
    const byteNumbers = new Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteNumbers[i] = binaryString.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray], { type: 'application/octet-stream' });
    if (filetype == 'pdf')
      blob = new Blob([byteArray], { type: 'application/pdf' });
    else if (filetype == 'word')
      blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  }
  async function callCoverLetter(resumeData: string) {
    // if (CoverData === "") {
    const PromptData = {
      "prompt": "COVER LETTER",
      "code": displayResumeData ? displayResumeData : resumeData,
      "job_description": JobDescData === "" ? resumeData : JobDescData,
    }

    await fetchPromptData(PromptData["prompt"], PromptData["code"], PromptData["job_description"]);
    //if (data["status"] === "success") {
    //   setCoverData(data["msg"])
    //   setJobDataLoading(false)

    // } else {
    //   toast.error("Error")
    // }
    // }
  }

  const fetchData = async () => {
    try {
      let user_id = localStorage.getItem("user_id");

      const responseResumes = await axios.get(`${baseUrl}aiResume/getDocument?user_id=${user_id}`);
      const resultResumes = responseResumes.data?.data;

      if (Array.isArray(resultResumes)) {
        setSavedResumesData(resultResumes as ResumeData[]);
      } else {
        setSavedResumesData([]);
      }

      const responseCoverLetters = await axios.get(`${baseUrl}aiResume/getDocument?user_id=${user_id}&is_type=cover`);
      const resultCoverLetters = responseCoverLetters.data?.data;

      if (Array.isArray(resultCoverLetters)) {
        setSavedCoverLettersData(resultCoverLetters as ResumeData[]);
      } else {
        setSavedCoverLettersData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect hook to call update resume time spent and then every 30 seconds
  useEffect(() => {
    //console.log("resume timer...........")
    handleRecordToolTime(); // Call fetchData initially

    // Set up interval to call fetchData every 30 seconds
    const toolintervalId = setInterval(handleRecordToolTime, 20000);
    localStorage.setItem("tooltimer", toolintervalId.toString());

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(toolintervalId);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  let localStorage_msg = {
    'PERSONAL DETAILS': '',
    'PROFESSIONAL SUMMARY': '',
    'EMPLOYMENT HISTORY': '',
    'EDUCATION': '',
    'SKILLS': '',
    'COVER LETTER': '',
  }
  let fullResume = '';

  useEffect(() => {
    let personal = '';
    let summary = '';
    let employeement = '';
    let education = '';
    let skills = '';
    localStorage_msg = JSON.parse(String(localStorage.getItem("localStorage_msg")));

    //console.log(localStorage_msg);
    //console.log(localStorage.getItem("downloadmode"));
    //when resume selected mode set to saved, then skip the remaining code block

    if (localStorage.getItem("downloadmode") == "saved") return;

    if (localStorage_msg != null) {

      //console.log("localStorage_msg['PERSONAL DETAILS']");
      //console.log(localStorage_msg['PERSONAL DETAILS']);
      if (typeof localStorage_msg['PERSONAL DETAILS'] != 'object' && localStorage_msg['PERSONAL DETAILS'] != null)
        if (localStorage_msg['PERSONAL DETAILS'].trim() !== '') {
          const heading1 = `'PERSONAL DETAILS'\n............................\n`;
          const strData1 = convertJsonToString('PERSONAL DETAILS', localStorage_msg['PERSONAL DETAILS'].trim())
          personal = heading1 + strData1
        }
      if (typeof localStorage_msg['PROFESSIONAL SUMMARY'] != 'object' && localStorage_msg['PROFESSIONAL SUMMARY'] != null)
        if (localStorage_msg['PROFESSIONAL SUMMARY'].trim() !== '') {
          const heading2 = `'PROFESSIONAL SUMMARY'\n............................\n`;
          const strData2 = convertJsonToString('PROFESSIONAL SUMMARY', localStorage_msg['PROFESSIONAL SUMMARY'].trim())
          if (personal) {
            summary = heading2 + strData2
          }
        }
      if (typeof localStorage_msg['EMPLOYMENT HISTORY'] != 'object' && localStorage_msg['EMPLOYMENT HISTORY'] != null)
        if (localStorage_msg['EMPLOYMENT HISTORY'].trim() !== '') {
          const heading3 = `'EMPLOYMENT HISTORY'\n............................\n`;
          const strData3 = convertJsonToString('EMPLOYMENT HISTORY', localStorage_msg['EMPLOYMENT HISTORY'].trim())
          if (personal && summary) {
            employeement = heading3 + strData3
          }
        }
      if (typeof localStorage_msg['EDUCATION'] != 'object' && localStorage_msg['EDUCATION'] != null)
        if (localStorage_msg['EDUCATION'].trim() !== '') {
          const heading4 = `'EDUCATION'\n............................\n`;
          const strData4 = convertJsonToString('EDUCATION', localStorage_msg['EDUCATION'].trim())
          if (personal && summary && employeement) {
            education = heading4 + strData4
          }
        }
      if (typeof localStorage_msg['SKILLS'] != 'object' && localStorage_msg['SKILLS'] != null)
        if (localStorage_msg['SKILLS'].trim() !== '') {
          const heading5 = `'SKILLS'\n............................\n`;
          const strData5 = convertJsonToString('SKILLS', localStorage_msg['SKILLS'].trim())
          if (personal && summary && employeement && education) {
            skills = heading5 + strData5
          }
        }
    }


    //setdisplayResumeData(personal+summary+employeement+education+skills);

  }, [JSON.parse(String(localStorage.getItem("localStorage_msg")))]);

  //JSON.parse(String(localStorage.getItem("localStorage_msg")));





  const handleSaveCover = async () => {
    let user_id1 = localStorage.getItem("user_id")
    axios.post(baseUrl + `aiResume/end_cover_timer/${user_id1}`).then(response => {
      //console.log('Ended cover timer successfully!!');
    }).catch(error => {
      //console.log('Oops!!Error on ending cover timer...');
    })

    //console.log("CoverData....");
    //console.log(coverData)
    let tmpCoverdata = CoverData;
    if (CoverData === "" && coverData) {
      setCoverData(coverData);
      tmpCoverdata = coverData;
    }

    if (tmpCoverdata === "") {
      //toast.error("First Create Cover Letter");
      //console.log("Why coming here....");
      //console.log("First Create Cover Letter");
      //setTimeout(handleSaveCover, 3000);
      //return () => clearTimeout(timer);
    } else {

      const cover: any = {
        "user_id": localStorage.getItem("user_id"),
        "is_type": "cover",
        "input_method": "manual",
        "content": { "COVER LETTER": tmpCoverdata },
        "original_data": JobDescData,
        "form_data": "",
        "formatted_data": tmpCoverdata,
        "json_data": {}
      }
      const { data }: any = await PostJSONFunc(cover);
      if (data && data["status"] === "success") {
        fetchData();
        //toast.success("Cover Letter Saved Successfully")

        localStorage.setItem("cover_doc_id", data["document_id"])
        //console.log("cover_doc_id");
        //console.log(data["document_id"]);
      } else {
        toast.error("Error")
      }
    }
  }

  useEffect(() => {

    const stepButtons = document.querySelectorAll(".step-button");
    //console.log("loadingCreateResume:::"+loadingCreateResume)
    if (loadingCreateResume === false && JobDataLoading == false) {
      getPersonalData()
    }

    setContent(displayResumeData)
    if (currentIndex === 0 && loadingCreateResume === false && JobDataLoading == false) {
      getPersonalData()
    }
    if (currentIndex === 1 && loadingCreateResume === false && JobDataLoading == false) {
      getContactInfoData()
    }
    if (currentIndex === 2 && loadingCreateResume === false && JobDataLoading == false) {
      getProfesionalSummery()
    }
    if (currentIndex === 3 && loadingCreateResume === false && JobDataLoading == false) {
      getEmployeementHistoryData()
    }
    if (currentIndex === 4 && loadingCreateResume === false && JobDataLoading == false) {
      getEducationData()
    }
    if (currentIndex === 5 && loadingCreateResume === false && JobDataLoading == false) {
      getSkillData()
    }
    if (currentIndex === 6 && loadingCreateResume === false && JobDataLoading == false) {
      getAdditionalDetails()
    }
    Array.from(stepButtons).forEach((button, index) => {
      button.addEventListener("click", () => {
        setProgress(index);
      });

      button.setAttribute("aria-expanded", index === currentIndex ? "true" : "false");
    });
    return () => {
      Array.from(stepButtons).forEach((button, index) => {
        button.removeEventListener("click", () => setProgress(index));
      });
    };
  }, [currentIndex, displayResumeData]);

  const fetchLinkdin = (): void => {
    //console.log('fetch linkedin');
    const inputElement = document.getElementById('exampleInputPassword1') as HTMLInputElement | null;
    if (inputElement) {
      const inputValue: string = inputElement.value;
      //console.log(inputValue);
      //dispatch(useLinkedInCountMutation());

    }
  }

  const handleFormData = (data: any) => {
    //console.log(data)
  }

  const toggleColor = () => {
    const buttonElement = document.querySelector('button.dropdown-toggle.btn');

    if (buttonElement) {
      // Change style properties dynamically
      settabBButtonColor('#0076ad');
      settabBButtonbgColor('#fff');
      setCursorvar('pointer');
      buttonElement.classList.remove('btn-primary');
      buttonElement.classList.add('btn-primary-1');

    }
  }

  const handleDeleteCoverLetter = async (data: string) => {
    //console.log(data);
    try {
      let user_id = localStorage.getItem("user_id")
      const res = (await axios.delete(`${baseUrl}aiResume/resume/delete/${data}?user_id=${user_id}`))
      if (res?.data?.status === "success") {
        const response = await axios.get(`${baseUrl}aiResume/getDocument?user_id=${user_id}&is_type=cover`);
        const result = response.data?.data;
        //console.log(result);
        if (Array.isArray(result)) {
          setSavedCoverLettersData((result as ResumeData[]));
        } else {
          setSavedCoverLettersData([]);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteResume = async (data: string) => {
    alert('Sure, You want to delete this resume?');
    try {
      let user_id = localStorage.getItem("user_id")
      const res = (await axios.delete(`${baseUrl}aiResume/resume/delete/${data}?user_id=${user_id}`))
      if (res?.data?.status === "success") {
        const response = await axios.get(`${baseUrl}aiResume/getDocument?user_id=${user_id}`);
        const result = response.data?.data;
        //console.log(result);
        if (Array.isArray(result)) {
          setSavedResumesData((result as ResumeData[]));
        } else {
          setSavedResumesData([]);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const addDataSaved = (data: string) => {
    //setCode(data);
  }

  useEffect(() => {
    const UpdatedFirstNames = savedResumesData.map((data) => {
      const personalDetails = data?.content?.["PERSONAL DETAILS"];
      return personalDetails?.["First Name"] ?? '';
    })
    setSavedResumesFirstName(UpdatedFirstNames);
  }, [savedResumesData]);

  const handleAddResManualClick = () => {
    navigate('/codegenie/resume');
  }


  const handleCoverletterTab = () => {
    setIsType('cover');
    setActivePane(prevPane => (prevPane === "pane-AAA" ? "pane-BBB" : "pane-AAA"));
    settabBButtonColor('#fff');
    settabBButtonbgColor('#0076ad');
  }

  // const handleStoreAllValues = () =>{
  const allValues = {
    personalDetails,
    contactInfo,
    proffesionalSummery,
    employeementHistory,
    education,
    addAditionalSkills,
    additionalSummary
  }
  // }

  const borderColor = MobileView ? '1px solid #F2F2F2' : 'transparent';


  return (
    <>
      {/* {loadingCreateResume && (
        <Box position="absolute">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <Box>
              <MagicWand />

              <Typography textAlign="center">Creating Resume</Typography>

            </Box>
          </Backdrop>
        </Box>
      )} */}
      <div className='resume-bulder-class overflow-hidden'>

        <section className="ourwork-tab-wrap container-fluid">
          {/* <div className='imgupload'>
            <Grid item xs={12} md={6} lg={6} style={{ padding: '1%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '31%' }}>
              <div className="col-12 col-md-6 mt-3">
                <label htmlFor="photoInput" style={{ cursor: 'pointer' }}>
                  <p>
                    {selectedPhoto ? (
                      <img
                        src={selectedPhoto}
                        alt="Selected"
                        width="48"
                        style={{ borderRadius: '8px' }}
                      />
                    ) : (
                      <svg width="49" height="48" viewBox="0 0 49 48" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" width="48"  rx="8" fill="#CFD4DB" />
                        <path
                          d="M34.5 34H14.5C13.9696 34 13.4609 33.7893 13.0858 33.4142C12.7107 33.0391 12.5 32.5304 12.5 32V18C12.5 17.4696 12.7107 16.9609 13.0858 16.5858C13.4609 16.2107 13.9696 16 14.5 16H18.5L20.5 13H28.5L30.5 16H34.5C35.0304 16 35.5391 16.2107 35.9142 16.5858C36.2893 16.9609 36.5 17.4696 36.5 18V32C36.5 32.5304 36.2893 33.0391 35.9142 33.4142C35.5391 33.7893 35.0304 34 34.5 34Z"
                          stroke="#292C33" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round" />
                        <path
                          d="M24.5 29C26.9853 29 29 26.9853 29 24.5C29 22.0147 26.9853 20 24.5 20C22.0147 20 20 22.0147 20 24.5C20 26.9853 22.0147 29 24.5 29Z"
                          stroke="#292C33" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round" />
                      </svg>
                    )}
                    Add Photo
                  </p>
                </label>
                <input
                  type="file"
                  id="photoInput"
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleAddPhoto}
                />
              </div>
            </Grid>
          </div> */}

          {/* <!-- <div className="main container-fluid"> --> */}
          <div className='innerpage' style={{ marginTop: '6%' }}>
            <h4 className="text-center text-black my-3 resumebuild">Resume Builder</h4>
            <hr />

            <div className=" row px-4 ">
              {/* <!-- Column 1 --> */}
              <div className='col-lg-12'>
                <ul id="tabs" className="row nav nav-tabs left-tabs" role="tablist" style={{ border: '1px solid #CFD4DB', padding: '1%', width: '100%' }}>
                  <li className="nav-item nav-item-1 col-md-1 col-12" style={{ /* display: 'flex', */ alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff', borderRadius: '3px' }}>
                    <a id="tab-A" href="#pane-A" /* className="nav-link active" */ className={`nav-link active${clicked ? 'active' : ''}`} data-toggle="tab" role="tab" onClick={handleAddResManualClick} style={{ display: "flex", justifyContent: "center", alignItems: "center", textDecoration: 'none', color: 'inherit', border: '1px solid #F2F2F2', background: 'none', fontWeight: 500, fontSize: '14px', height: '100%' }}>Step 1 <br></br> Add Resume
                    </a>
                  </li>
                  <li className="nav-item nav-item-1 col-md-1 col-12" style={{ backgroundColor: '#fff', borderRadius: '3px' }}>
                    <a id="tab-B" href="#pane-B" className="nav-link" data-toggle="tab" role="tab" style={{ display: "flex", justifyContent: "center", alignItems: "center", textDecoration: 'none', color: 'inherit', border: '1px solid #F2F2F2', background: 'none', fontWeight: 500, fontSize: '14px', height: '100%' }}>Step 2 <br></br> Add Job<br /> Description</a>
                  </li>
                  <li className="nav-item nav-item-1 col-md-1 col-12" style={{ backgroundColor: '#fff' }}>
                    <a href="#" className={skillstab ? "nav-link" : ""} data-toggle="tab" role="tab" style={{ cursor: skillstab ? 'pointer' : 'not-allowed', display: "flex", justifyContent: "center", alignItems: "center", textDecoration: 'none', color: 'inherit', border: '1px solid #F2F2F2', background: 'none', fontWeight: 500, fontSize: '14px', height: '100%' }} onClick={skillstab ? () => CreateResume() : undefined}>
                      <div className={loadingCreateResume ? "spinner-border" : "spinner-border d-none"} style={{ display: "none" }} id="Ploading1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <span style={{ display: loadingCreateResume ? "block" : "block" }} id="Pnext1">Step 3 <br></br> Create Resume</span>
                    </a>
                  </li>
                  <li className="nav-item nav-item-1 col-md-1 col-12" style={{ backgroundColor: '#fff' }}>
                    <a id="" href="" onClick={skillstab ? () => setShowModal2(true) : undefined} className={skillstab ? "nav-link" : ""} data-toggle="tab" role="tab" style={{ cursor: skillstab ? 'pointer' : 'not-allowed', display: "flex", justifyContent: "center", alignItems: "center", textDecoration: 'none', color: 'inherit', border: '1px solid #F2F2F2', background: 'none', fontWeight: 500, fontSize: '14px', height: '100%' }}>Step 4 <br></br> Change Design</a>
                    {/* <a id="" href="" className="nav-link" data-toggle="tab" role="tab">Download Resume</a> */}
                    {/* <Dropdown >
                      <Dropdown.Toggle style={{width: '100%', fontWeight: 500, fontSize: '14px'}}>
                        Download<br/>
                        Resume
                        <Dropdown.Menu >
                          <Dropdown.Item onClick={() => {handleOptionSelect('pdf');}} style={{ fontSize: "14px", fontWeight: "400" }}>PDF</Dropdown.Item>
                          <Dropdown.Item onClick={() => {handleOptionSelect('word'); }} style={{ fontSize: "14px", fontWeight: "400" }}>MS Word</Dropdown.Item>
                          <Dropdown.Item onClick={() => {handleOptionSelect('googledocs'); }} style={{ fontSize: "14px", fontWeight: "400" }}>Google Docs</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown.Toggle>

                    </Dropdown> */}
                  </li>
                  <li className="nav-item nav-item-1 col-md-2 col-12" style={{ backgroundColor: '#fff', borderRadius: '3px', border: '2px solid #F2F2F2' }}>
                    <a id="tab-A" /* href={`#${activePane}`} */ className={/* !coverContent ? "" :  */"nav-link"} data-toggle="tab" role="tab" style={{ cursor: tabBbuttongbColor === '#fff' && tabBbuttonColor === '#ccc' ? 'not-allowed' : 'pointer', display: 'flex', color: 'blue', /* textDecoration: 'underLine' */height: '100%', justifyContent: 'center' }} >
                      <Button id="tab-B-Button" variant="outlined" /* onClick={!coverContent ? undefined : () => handleCoverTab()} */onClick={ ()=>handleCoverletterTab()} style={{ cursor: tabBbuttongbColor === '#fff' && tabBbuttonColor === '#ccc' ? 'not-allowed' : 'pointer', margin: '1.4% 0%', borderRadius: '4px', padding: '17px 10px', boxShadow: 'none', lineHeight: 'inherit', color: tabBbuttonColor , background: tabBbuttongbColor , border: "none" }}>{activePane === "pane-BBB" ? <>Step 5 <br></br> Show Resume</> : <>Step 5 <br></br> Show Cover Letter</>}</Button>
                    </a>
                  </li>
                  <li className="nav-item nav-item-1 col-md-1 col-12" style={{ backgroundColor: '#fff', borderRadius: '3px', border: '2px solid #F2F2F2' }}>
                    <a id="tab-A" className="nav-link bg" style={{ textAlign: 'center', alignContent: 'center', /* cursor: 'not-allowed', */ display: 'inline-block', color: 'blue', textDecoration: 'underLine', background: "#fff" }}>

                      <Dropdown drop="up"   onBlur={() => { toggleColor() }} style={{ justifyContent: "center", alignItems: "center", textDecoration: 'none', /* color: tabBbuttonColor, */ border: 'none', /* background: tabBbuttongbColor, */ fontWeight: 500, fontSize: '14px', height: '100%'/* , cursor: 'not-allowed' */ }}>

                        <Dropdown.Toggle  onMouseOut={() => { toggleColor() }}  onBlur={() => { toggleColor() }}  >
                          <span>Step 6 <br></br></span>Download
                          <Dropdown.Menu >
                            <Dropdown.Item onClick={() => { handleOptionSelect('pdf'); }} >PDF</Dropdown.Item>
                            <Dropdown.Item onClick={() => { handleOptionSelect('word'); }} >MS Word</Dropdown.Item>
                            <Dropdown.Item onClick={() => { handleOptionSelect('googledocs'); }} >Google Docs</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown.Toggle>

                      </Dropdown>
                    </a>
                  </li>
                  {/* <li className="nav-item nav-item-1 col-md-1 col-12" style={{ backgroundColor: '#fff', borderRadius: '3px', border: '2px solid #F2F2F2' }}>
                    <a id="tab-A" className="nav-link bg" style={{ textAlign: 'center', alignContent: 'center',  display: 'inline-block', color: 'blue', textDecoration: 'underLine', background: "#fff" }}>

                      <Dropdown drop="up" className="custom-dropdown-menu1" onBlur={() => { toggleColor() }} style={{ justifyContent: "center", alignItems: "center", textDecoration: 'none', color: tabBbuttonColor, border: 'none', background: tabBbuttongbColor, fontWeight: 500, fontSize: '14px', height: '100%', cursor: 'not-allowed' }}>

                        <Dropdown.Toggle onMouseOut={() => { toggleColor() }} onBlur={() => { toggleColor() }} style={{ justifyContent: "center", alignItems: "center", textDecoration: 'none',  border: 'none',  fontWeight: 500, fontSize: '14px', height: '100%' }}>
                          <span>Step 6<br></br></span>Download
                          <Dropdown.Menu >
                            <Dropdown.Item onClick={() => { handleOptionSelect('pdf'); }} >PDF</Dropdown.Item>
                            <Dropdown.Item onClick={() => { handleOptionSelect('word'); }} >MS Word</Dropdown.Item>
                            <Dropdown.Item onClick={() => { handleOptionSelect('googledocs'); }} >Google Docs</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown.Toggle>

                      </Dropdown>
                    </a>
                  </li> */}
                  <li className="nav-item nav-item-1 col-md-2 col-12" style={{ display: window.innerWidth >= 500 ? 'block' : 'none', textAlign: 'center' }}>
                  &nbsp;&nbsp;<span className="spinner-border" style={{ display: "none" }} id="Ploading" role="status">&nbsp;</span>
                  </li>

                  <li className="nav-item nav-item-1 col-md-2 col-12" style={{ backgroundColor: '#fff', border: '2px solid #F2F2F2' }}>
                    <a id="tab-A" className="nav-link bg " style={{ background: "#fff", display: 'inline-block', textDecoration: 'underLine', /*  cursor: 'not-allowed' */ }} onClick={() => setShowTemplateModal(true)}>
                      <Button variant="outlined" style={{ backgroundColor: '#fff !important',  borderRadius: '4px', padding: '0px 10px', boxShadow: 'none', paddingTop: '10%', paddingBottom: '10%', border: 'none' }}>View Saved Resumes</Button>
                    </a>
                  </li>

                </ul>
              </div>
              <div className="col-lg-5 left">
                {/* <!-- Header Tabs --> */}

                <div id="content" className="tab-content mt-2" role="tablist">
                  <div id="pane-A" className="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-A">
                    <div className="card-header" role="tab" id="heading-A">
                      <h5 className="mb-0">
                        {/* <!-- Note: `data-parent` removed from here --> */}
                        <a data-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
                          Accordion 1
                        </a>
                      </h5>
                    </div>

                    {/* <!-- Note: New place of `data-parent` --> */}
                    <div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel"
                      aria-labelledby="heading-A">
                      <div className="card-body new-card-body p-0">
                        <div className="row">
                          {/* <ul id="tabs" className="nav nav-tabs" role="tablist">
                            <li className="nav-item col-12 col-sm">

                              <a id="tab-AA" href="/codegenie/resume" className="nav-link menu-tabs ${activeTab === 'tab-AA' ? 'active' : ''}`" data-toggle="tab" role="tab" onClick={handleHrefClick}>

                                <svg width="17" height="17" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect width="32" height="32" rx="4" fill="#E06F41" />
                                  <path d="M16 9.25V22.75" stroke="white" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round" />
                                  <path d="M8.5 12.25V9.25H23.5V12.25" stroke="white" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round" />
                                  <path d="M13 22.75H19" stroke="white" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round" />
                                </svg>
                                Text</a>
                            </li>
                            <li className="nav-item col-12 col-sm">

                              <a id="tab-BB" href="#pane-BB" className="nav-link menu-tabs" data-toggle="tab" role="tab" onClick={openFileSelector}>
                                <svg width="17" height="17" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect x="0.75" width="32" height="32" rx="4" fill="#7AB65D" />
                                  <path
                                    d="M25 8.5H8.5C8.08579 8.5 7.75 8.83579 7.75 9.25V22.75C7.75 23.1642 8.08579 23.5 8.5 23.5H25C25.4142 23.5 25.75 23.1642 25.75 22.75V9.25C25.75 8.83579 25.4142 8.5 25 8.5Z"
                                    stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  <path
                                    d="M7.75 19.7499L12.4656 15.0343C12.5354 14.9634 12.6186 14.9072 12.7103 14.8687C12.8021 14.8303 12.9005 14.8105 13 14.8105C13.0995 14.8105 13.1979 14.8303 13.2897 14.8687C13.3814 14.9072 13.4646 14.9634 13.5344 15.0343L17.7156 19.2155C17.7854 19.2864 17.8686 19.3427 17.9603 19.3811C18.0521 19.4195 18.1505 19.4393 18.25 19.4393C18.3495 19.4393 18.4479 19.4195 18.5397 19.3811C18.6314 19.3427 18.7146 19.2864 18.7844 19.2155L20.7156 17.2843C20.7854 17.2134 20.8686 17.1572 20.9603 17.1187C21.0521 17.0803 21.1505 17.0605 21.25 17.0605C21.3495 17.0605 21.4479 17.0803 21.5397 17.1187C21.6314 17.1572 21.7146 17.2134 21.7844 17.2843L25.75 21.2499"
                                    stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  <path
                                    d="M19.375 14.5C19.9963 14.5 20.5 13.9963 20.5 13.375C20.5 12.7537 19.9963 12.25 19.375 12.25C18.7537 12.25 18.25 12.7537 18.25 13.375C18.25 13.9963 18.7537 14.5 19.375 14.5Z"
                                    fill="white" />
                                </svg>
                                Upload</a>
                            </li>
                            <li className="nav-item col-12 col-sm">

                              <a id="tab-DD" href="#pane-DD" className="nav-link menu-tabs active" data-toggle="tab" role="tab">

                                <svg width="17" height="17" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect x="0.25" width="32" height="32" rx="4" fill="#DF900A" />
                                  <path
                                    d="M13.25 24.2499H8.75C8.55109 24.2499 8.36033 24.1709 8.21967 24.0302C8.07902 23.8896 8 23.6988 8 23.4999V19.3093C7.99966 19.2119 8.01853 19.1154 8.05553 19.0253C8.09253 18.9352 8.14694 18.8533 8.21563 18.7843L19.4656 7.53429C19.5354 7.46343 19.6186 7.40715 19.7103 7.36874C19.8021 7.33033 19.9005 7.31055 20 7.31055C20.0995 7.31055 20.1979 7.33033 20.2897 7.36874C20.3814 7.40715 20.4646 7.46343 20.5344 7.53429L24.7156 11.7155C24.7865 11.7853 24.8428 11.8685 24.8812 11.9603C24.9196 12.052 24.9394 12.1505 24.9394 12.2499C24.9394 12.3494 24.9196 12.4478 24.8812 12.5396C24.8428 12.6313 24.7865 12.7145 24.7156 12.7843L13.25 24.2499Z"
                                    stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M17 10L22.25 15.25" stroke="white" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round" />
                                  <path d="M24.5 24.25H13.25L8.04688 19.0469" stroke="white" strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M19.625 12.625L10.625 21.625" stroke="white" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round" />
                                </svg>
                                Manual</a>
                            </li>
                            <li className="nav-item" style={{ width: '74px' }}>
                              <span id="tab-D" className="nav-link menu-tabs" data-toggle="tab" role="tab" onClick={handleClear}>
                                <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ff7b00">
                                  <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                  <g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M15.0722 3.9967L20.7508 9.83395L17.0544 13.5304L13.0758 17.5H21.0041V19H7.93503L4.00195 15.0669L15.0722 3.9967ZM10.952 17.5L15.4628 12.9994L11.8268 9.3634L6.12327 15.0669L8.55635 17.5H10.952Z" fill="#1F2328" /> </g>
                                </svg>
                                New
                              </span>
                            </li>
                          </ul>
                          <input
                            type="file"
                            id="pdfInput"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                          /> */}

                          <div id="content" className="tab-content" role="tablist">
                            <div id="pane-AA" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-A">
                              <div className="card-header" role="tab" id="heading-A">
                                <h5 className="mb-0">
                                  <a data-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
                                    Text
                                  </a>
                                </h5>
                              </div>

                              <div id="collapse-A" className="collapse show" role="tabpanel" aria-labelledby="heading-A">
                                <div className="card-body new-card-body mt-2">
                                  <div className="row">
                                    <div className="col-md-12 px-0">
                                      <div className="row text-tab p-0">
                                        <div className="col-12 p-3">
                                          <h1>Rohan Hall</h1>
                                          <p>Mobile: +1 310 748 8901</p>
                                          <p>rohanhall001@gmail.com</p>
                                          <p>
                                            http://www.linkedin.com/in/rohanhall
                                          </p>
                                          <p>American Citizen</p>

                                          <h1 className='mt-4'>OVERVIEW OF EXPERIENCE</h1>
                                          <h4>
                                            CTO - Entrepreneur - Executive - Author -
                                            Thought Leader
                                          </h4>
                                          <p className='mb-3'>
                                            . 8 plus years Experienced CTO for
                                            private as well as a Publicly Traded,
                                            investor funded, technology company for
                                            blockchain/crypto/payments/financial
                                            services, complying with all FinCen and
                                            SEC rules.
                                          </p>
                                          <p className='mb-3'>
                                            . Member of Board of Directors and
                                            Advisor to other Boards including
                                            determining short term and long-term
                                            strategy for the company.
                                          </p>
                                          <p className='mb-3'>
                                            . A big picture thinker with long term
                                            strategic vision of the industry and the
                                            impact current and emerging technologies
                                            will make in the future. Created product
                                            vision and building 5-year roadmap for
                                            multiple companies.
                                          </p>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                {currentIndex == 6 ? (
                                  <button className="btn1 ms-auto border-none" id="nextButton">
                                    Create Resume
                                  </button>
                                ) : (
                                  <p></p>
                                )
                                }
                              </div>
                            </div>

                            <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog"
                              aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <div className="form-group">
                                      <label>Profile URL</label>
                                      <input type="text" className="form-control" id="exampleInputPassword1"
                                        placeholder="Profile URL" />
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                      Close
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={() => { fetchLinkdin(); }}>
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id="pane-DD" className="card tab-pane fade p-0  show  active" role="tabpanel" aria-labelledby="tab-D">
                              <div className="card-header" role="tab" id="heading-D">
                                <h5 className="mb-0">
                                  <a className="collapsed" data-toggle="collapse" href="#collapse-D" aria-expanded="false"
                                    aria-controls="collapse-D">
                                    Manual
                                  </a>
                                </h5>
                              </div>
                              <div id="collapse-D" className="collapse" role="tabpanel" data-parent="#content"
                                aria-labelledby="heading-D">
                                <div className="card-body p-0 mt-2">
                                  <div className="row">
                                    <div className="col-md-12 px-0">
                                      <div className="accordion" id="accordionExample">
                                        <div className="steps">
                                          <progress id="progress" value="0" max="100"></progress>

                                          <div className="step-item">
                                            <button className="step-button text-center" type="button" disabled={disabled1} data-bs-toggle="collapse"
                                              data-bs-target="#step1" aria-expanded="true" aria-controls="step1" style={{height: '30px'}}></button>
                                            <div className="step-title">
                                              Personal Details
                                            </div>
                                          </div>
                                          <div className="step-item">
                                            <button className="step-button text-center collapsed" type="button" disabled={disabled2}
                                              data-bs-toggle="collapse" data-bs-target="#step2" aria-expanded="false"
                                              aria-controls="step2" style={{height: '30px'}}></button>
                                            <div className="step-title">
                                              Contact Information
                                            </div>
                                          </div>
                                          <div className="step-item">
                                            <button className="step-button text-center collapsed" type="button" disabled={disabled3}
                                              data-bs-toggle="collapse" data-bs-target="#step3" aria-expanded={"false"}
                                              aria-controls="step3" style={{height: '30px'}}></button>
                                            <div className="step-title">
                                              Summary
                                            </div>
                                          </div>
                                          <div className="step-item">
                                            <button className="step-button text-center collapsed" type="button" disabled={disabled4}
                                              data-bs-toggle="collapse" data-bs-target="#step4" aria-expanded="false"
                                              aria-controls="step4" style={{height: '30px'}}></button>
                                            <div className="step-title">
                                              Job History
                                            </div>
                                          </div>
                                          <div className="step-item">
                                            <button className="step-button text-center collapsed" type="button" disabled={disabled5}
                                              data-bs-toggle="collapse" data-bs-target="#step5" aria-expanded="false"
                                              aria-controls="step5" style={{height: '30px'}}></button>
                                            <div className="step-title">Education</div>
                                          </div>
                                          <div className="step-item">
                                            <button className="step-button text-center collapsed" type="button" disabled={disabled6}
                                              data-bs-toggle="collapse" data-bs-target="#step6" aria-expanded="false"
                                              aria-controls="step6" style={{height: '30px'}}></button>
                                            <div className="step-title">Skills</div>
                                          </div>
                                        </div>
                                        <div className="data">

                                          <div id="step1" className="collapse step-hide show" aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                              <div className="row personal-dtls">
                                                <div className="col-12 px-0">
                                                  <h2 className='my-3'>Personal Details</h2>
                                                  <p className="para mb-4">
                                                    Personal details such as name
                                                    and job title are essential in a
                                                    resume to give the recruiter a
                                                    quick overview.
                                                  </p>
                                                </div>
                                                <div className="row mb-4">
                                                  <div className="col-12 col-md-6 px-0">
                                                    <label>First Name</label><br />
                                                    <input
                                                      type="text"
                                                      name=""
                                                      id="input"
                                                      // placeholder="Sukanta"
                                                      value={personalDetails.firstname}
                                                      onChange={(e) => setpersonalDetails({ ...personalDetails, firstname: e.target.value })}
                                                    />
                                                    <div style={{ color: 'red' }}>{formErrors.firstName ? "First name is required" : ""}</div>
                                                  </div>
                                                  <div className="col-12 col-md-6 px-0 p">
                                                    <label>Last Name</label><br />
                                                    <input
                                                      type="text"
                                                      name=""
                                                      id="input"
                                                      // placeholder="Besra"
                                                      value={personalDetails.lastname}
                                                      onChange={(e) => setpersonalDetails({ ...personalDetails, lastname: e.target.value })}
                                                    />
                                                    <div style={{ color: 'red' }}>{formErrors.lastName ? "Last name is required" : ""}</div>
                                                  </div>
                                                </div>
                                                <div className="row mb-4">
                                                  <div className="col-12 col-md-6 px-0">
                                                    <label>Desired job title</label><br />
                                                    <input
                                                      type="text"
                                                      name=""
                                                      id="input"
                                                      // placeholder="Project Manager"
                                                      value={personalDetails.desiredjob}
                                                      onChange={(e) => setpersonalDetails({ ...personalDetails, desiredjob: e.target.value })}
                                                    />
                                                    <div style={{ color: 'red' }}>{formErrors.desiredJobTitle ? "Desired job title name is required" : ""}</div>
                                                  </div>
                                                  {/* <div className="col-12 col-md-6 mt-3">
                                                    <label htmlFor="photoInput" style={{ cursor: 'pointer' }}>
                                                      <p>
                                                        {selectedPhoto ? (
                                                          <img
                                                            src={selectedPhoto}
                                                            alt="Selected"
                                                            width="48"
                                                            style={{ borderRadius: '8px' }}
                                                          />
                                                        ) : (
                                                          <svg width="49" height="48" viewBox="0 0 49 48" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <rect x="0.5" width="48" rx="8" fill="#CFD4DB" />
                                                            <path
                                                              d="M34.5 34H14.5C13.9696 34 13.4609 33.7893 13.0858 33.4142C12.7107 33.0391 12.5 32.5304 12.5 32V18C12.5 17.4696 12.7107 16.9609 13.0858 16.5858C13.4609 16.2107 13.9696 16 14.5 16H18.5L20.5 13H28.5L30.5 16H34.5C35.0304 16 35.5391 16.2107 35.9142 16.5858C36.2893 16.9609 36.5 17.4696 36.5 18V32C36.5 32.5304 36.2893 33.0391 35.9142 33.4142C35.5391 33.7893 35.0304 34 34.5 34Z"
                                                              stroke="#292C33" strokeWidth="2" strokeLinecap="round"
                                                              strokeLinejoin="round" />
                                                            <path
                                                              d="M24.5 29C26.9853 29 29 26.9853 29 24.5C29 22.0147 26.9853 20 24.5 20C22.0147 20 20 22.0147 20 24.5C20 26.9853 22.0147 29 24.5 29Z"
                                                              stroke="#292C33" strokeWidth="2" strokeLinecap="round"
                                                              strokeLinejoin="round" />
                                                          </svg>
                                                        )}
                                                        {selectedPhoto ? <><DeleteForeverOutlinedIcon style={{color: 'red'}}/>
                                                        <span style={{ color: 'red', fontWeight: 'bold' }}><a href="#" style={{textDecoration: 'none'}} onClick={() => { deletePhoto();}}>Delete</a></span></> :'Add Photo'}
                                                      </p>
                                                    </label>

                                                   
                                                    <input
                                                      type="file"
                                                      id="photoInput"
                                                      style={{ display: 'none' }}
                                                      accept="image/*"
                                                      onChange={handleAddPhoto}
                                                    />
                                                  </div> */}
                                                </div>
                                                <button className="ipt text-start"
                                                  placeholder="+ Additional Personal Details" onClick={() => handleShowEditor("personalDetails")}> + Additional Personal Details </button>
                                                {(additionalDetails.personalDetails || personaldetailsSummaryState) && (
                                                  <div className="box1">
                                                    <ReactQuill value={personaldetailsSummary} onChange={(content: any) => setpersonaldetailsSummary(content)} modules={{ toolbar: [['bold', 'italic', 'underline'], [{ 'list': 'ordered' }, { 'list': 'bullet' }]] }} formats={["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent"]} />
                                                  </div>

                                                )}
                                                <button onClick={() => handleNextClick("PersonalDetails")} className="btn1 ms-auto" id="nextButton" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                  <div className="spinner-border" style={{ display: "none" }} id="Ploading" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                  </div>
                                                  <span style={{ display: "block" }} id="Pnext" >Next</span>
                                                </button>
                                              </div>
                                            </div>
                                          </div>

                                          <div id="step2" className="collapse step-hide" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample">
                                            <div className="card-body contact-info">
                                              <div className="row">
                                                <div className="col-12 px-0">
                                                  <h2 className='my-3'>Contact Information</h2>
                                                  <p className="para mb-4">
                                                    Make it easy for a potential employer to reach you.
                                                  </p>
                                                </div>
                                                <div className="row mb-4">
                                                  <div className="col-12 col-md-6 px-0">
                                                    <label>Email</label><br />
                                                    <input type="text" name="" value={contactInfo.email} onChange={(e) => setcontactInfo({ ...contactInfo, email: e.target.value })} id="input" placeholder="jhon@example.com" />
                                                    <div style={{ color: 'red' }}>{(formErrors.email && !isValidEmail(contactInfo.email.trim()) && contactInfo.email.trim() !== "") ? "Enter Valid Email" : formErrors.email ? "Email is required" : ""}</div>
                                                  </div>
                                                  <div className="col-12 col-md-6 p">
                                                    <label>Phone</label><br />
                                                    <input type="text" name="" id="input" value={contactInfo.phone} onChange={(e) => {
                                                      if (/^\d+$/.test(e.target.value) || e.target.value === "") {
                                                        setcontactInfo({ ...contactInfo, phone: e.target.value })
                                                      }
                                                    }} placeholder="555-123-4567" />
                                                    <div style={{ color: 'red' }}>{formErrors.phone && contactInfo.phone === "" ? "Phone is required" : formErrors.phone && contactInfo.phone.trim().length < 10 ? "Phone number should consist of 10 digits" : ""}</div>
                                                  </div>
                                                </div>
                                                <div className="row mb-4">
                                                  <div className="col-12 col-md-6 px-0">
                                                    {/* <label>Country</label><br /> */}
                                                    <label>City</label><br />
                                                    <input type="text" name="" id="input" value={contactInfo.country} onChange={(e) => setcontactInfo({ ...contactInfo, country: e.target.value })} placeholder="San Francisco" />
                                                    <div style={{ color: 'red' }}>{formErrors.country ? "City is required" : ""}</div>
                                                  </div>
                                                  <div className="col-12 col-md-6 p">
                                                    {/* <label>City</label><br /> */}
                                                    <label>State</label><br />
                                                    <input type="text" name="" id="input" value={contactInfo.city} onChange={(e) => setcontactInfo({ ...contactInfo, city: e.target.value })} placeholder="California" />
                                                    <div style={{ color: 'red' }}>{formErrors.city ? "State is required" : ""}</div>
                                                  </div>
                                                </div>
                                                <div className="row mb-4">
                                                  <div className="col-12 col-md-6 px-0 disabled" style={{ display: 'none' }}>
                                                    <label>Address</label><br />
                                                    <input type="text" name="" id="input" value={contactInfo.address} onChange={(e) => setcontactInfo({ ...contactInfo, address: e.target.value })} placeholder="" />
                                                    <div style={{ color: 'red' }}>{formErrors.address ? "Address is required" : ""}</div>
                                                  </div>
                                                  <div className="col-12 col-md-6 p">
                                                    <label>Zip Code</label><br />
                                                    <input type="text" name="" id="input" value={contactInfo.postalcode} onChange={(e) => {
                                                      if (/^[0-9]/.test(e.target.value) || e.target.value === "") {
                                                        setcontactInfo({ ...contactInfo, postalcode: e.target.value })
                                                      }
                                                    }} placeholder="94016" />
                                                    {/*                                                 <input type="text" name="" id="input" value={contactInfo.postalcode} onChange={(e) => setcontactInfo({ ...contactInfo, postalcode: e.target.value })} placeholder="55921" /> */}
                                                    <div style={{ color: 'red' }}>{formErrors.postalcode ? "Zip code is required" : ""}</div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <button onClick={handleBackClick} className="btn2" id="backButton">
                                                Back
                                              </button>
                                              <button onClick={() => handleNextClick("contactInformation")} className="btn1 ms-auto" id="nextButton" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="spinner-border" style={{ display: "none" }} id="Cloading" role="status">
                                                  <span className="sr-only">Loading...</span>
                                                </div>
                                                <span style={{ display: "block" }} id="Cnext" >Next</span>
                                              </button>
                                            </div>
                                          </div>
                                          <div id="headingThree"></div>
                                          <div id="step3" className="collapse step-hide" aria-labelledby="headingThree"
                                            data-bs-parent="#accordionExample">
                                            <div className="card-body px-1">
                                              <div className="row proffesional-summery">
                                                <div className="col-12">
                                                  <h2 className='my-3'>Professional Summary</h2>
                                                  <p className="para mb-4">
                                                    Include your professional title,
                                                    years of experience, and your
                                                    most impressive achievements.
                                                    Each achievement should be
                                                    measurable and expressed in
                                                    numbers.
                                                  </p>
                                                  <div className="box1">
                                                    <ReactQuill value={proffesionalSummery} onChange={(content: any) => setproffesionalSummery(content)} modules={{ toolbar: [['bold', 'italic', 'underline'], [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'link']] }} formats={["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link"]} />
                                                  </div>
                                                  <div style={{ color: 'red' }}>{formErrors.ProffesionalSummery ? "Professional summary is required" : ""}</div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <button onClick={handleBackClick} className="btn2" id="backButton">
                                                Back
                                              </button>
                                              <button onClick={() => handleNextClick("PersonalSummery")} className="btn1 ms-auto" id="nextButton" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="spinner-border" style={{ display: "none" }} id="Proloading" role="status">
                                                  <span className="sr-only">Loading...</span>
                                                </div>
                                                <span style={{ display: "block" }} id="Pronext" >Next</span>
                                              </button>
                                            </div>
                                          </div>
                                          <div id="headingFour"></div>
                                          <div id="step4" className="collapse step-hide" aria-labelledby="headingFour"
                                            data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                              <div className="row employeement">
                                                <div className="col-12">
                                                  <h2 className='my-3'>Job History</h2>
                                                  <p className="para mb-4">
                                                    Show employers your past
                                                    experience and what you have
                                                    accomplished. Include simple,
                                                    clear examples with action verbs
                                                    to demonstrate your skills.
                                                  </p>
                                                </div>
                                                {
                                                  employeementHistory.map((obj: any, index: any) => (
                                                    <div key={index}>
                                                      <div className='d-flex justify-content-end' style={{ paddingTop: "10px" }}>
                                                        <button className='btn btn-danger' style={{ textAlign: "right", fontSize: "12px", padding: "5px 10px" }} onClick={() => {
                                                          if (employeementHistory.length === 1) {
                                                            alert("Minimum One Record Needed")
                                                          } else {

                                                            // Display confirmation dialog
                                                            const confirmDelete = window.confirm("Are you sure you want to delete this record?");
                                                            // If user confirms deletion, execute setEmployerHistory
                                                            if (confirmDelete) {
                                                              setemployeementHistory([...employeementHistory.filter((val: string, ind: any) => ind !== index)])
                                                            }
                                                          }
                                                        }}>&#10006; </button>
                                                      </div>
                                                      <div className="row mb-4">

                                                        <div className="col-12 col-md-6">
                                                          <label>Job title</label><br />
                                                          <input type="text" name="" id="input" value={obj["job_title"] ? obj["job_title"] : ""} onChange={(e) => onChangeHandelerFunction(index, e.target.value, "job_title", employeementHistory, setemployeementHistory)} placeholder="Project Manager" />
                                                          <div style={{ color: 'red' }}>{formErrors.employeementHistory[index]["job_title"] ? "Job title is required" : ""}</div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                          <label>Company</label><br />
                                                          <input type="text" name="" value={obj["company"] ? obj["company"] : ""} onChange={(e) => onChangeHandelerFunction(index, e.target.value, "company", employeementHistory, setemployeementHistory)} id="input" placeholder="CVS" />
                                                          <div style={{ color: 'red' }}>{formErrors.employeementHistory[index]["company"] ? "Company is required" : ""}</div>
                                                        </div>
                                                      </div>
                                                      <div className="row mb-4">
                                                        <div className="col-12 col-md-6 pt-3">

                                                          <label>Start Date</label><br />
                                                          <select id={`startmonth-${index}`} name={`startmonth-${index}`} value={obj["start_date"].split("-")[1]} onChange={(e) => handleMonthChange(e.target.value, index, "startmonth", "startyear")} style={{ width: '50%', height: '70%', borderRadius: '10px', border: '2px solid #B8BFCA' }}>
                                                            <option value="">Month</option>
                                                            <option value="01">January</option>
                                                            <option value="02">February</option>
                                                            <option value="03">March</option>
                                                            <option value="04">April</option>
                                                            <option value="05">May</option>
                                                            <option value="06">June</option>
                                                            <option value="07">July</option>
                                                            <option value="08">August</option>
                                                            <option value="09">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                          </select>
                                                          <select id={`startyear-${index}`} name={`startyear-${index}`} value={obj["start_date"].split("-")[0]} onChange={(e) => handleYearChange(e.target.value, index, "startyear", "startmonth")} style={{ width: '50%', height: '70%', borderRadius: '10px', border: '2px solid #B8BFCA' }}>
                                                            <option value="">Year</option>
                                                            {years.map(year => (
                                                              <option key={year} value={year}>{year}</option>
                                                            ))}
                                                          </select>
                                                          {/* <input type="month" max={obj["end_date"] ? obj["end_date"] : ""} value={obj["start_date"] ? obj["start_date"] : ""} onChange={(e) => onChangeHandelerFunction(index, e.target.value, "start_date", employeementHistory, setemployeementHistory)} id="input" placeholder="Jan, 2020" /> */}
                                                          <div style={{ color: 'red' }}>{formErrors.employeementHistory[index]["start_date"] ? "Start date is required" : ""}</div>
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-3">
                                                          <label>End Date</label><br />
                                                          <select id={`endmonth-${index}`} name={`endmonth-${index}`} value={obj["end_date"].split("-")[1]} onChange={(e) => handleEndMonthChange(e.target.value, index, "endmonth", "endyear")}
                                                            style={{ width: '50%', height: '70%', borderRadius: '10px', border: '2px solid #B8BFCA' }}

                                                          >
                                                            <option value="">Month</option>
                                                            <option value="01">January</option>
                                                            <option value="02">February</option>
                                                            <option value="03">March</option>
                                                            <option value="04">April</option>
                                                            <option value="05">May</option>
                                                            <option value="06">June</option>
                                                            <option value="07">July</option>
                                                            <option value="08">August</option>
                                                            <option value="09">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                          </select>

                                                          <select id={`endyear-${index}`} name={`endyear-${index}`} value={obj["end_date"].split("-")[0]} onChange={(e) => handleEndYearChange(e.target.value, index, "endyear", "endmonth")}
                                                            style={{ width: '50%', height: '70%', borderRadius: '10px', border: '2px solid #B8BFCA' }}

                                                          >
                                                            <option value="">Year</option>
                                                            {years.map(year => (
                                                              <option key={year} value={year}>{year}</option>
                                                            ))}
                                                          </select>

                                                        </div>
                                                      </div>
                                                      <div className="row mb-4">
                                                        <div className="col-12 col-md-6">&nbsp;</div>
                                                        <div className="col-12 col-md-6 d-flex align-items-center">
                                                          <span className="mr-2">(or)</span>
                                                          <label className="m-0">Present</label>
                                                          <input id={`present-${index}`} name="present"
                                                            style={{ height: '25px', width: '12% !important' }} // Adjusted styles
                                                            type="checkbox"

                                                            onChange={(e) => handlePresentCheckboxChange(index)}

                                                          />


                                                        </div>



                                                      </div>
                                                      <div className="row mb-4">
                                                        <div className="col-12">
                                                          <label>Location</label><br />
                                                          <input type="text" name="" value={obj["location"] ? obj["location"] : ""} onChange={(e) => onChangeHandelerFunction(index, e.target.value, "location", employeementHistory, setemployeementHistory)} id="input" placeholder="San Francisco, California" />
                                                          <div style={{ color: 'red' }}>{formErrors.employeementHistory[index]["location"] ? "Location is required" : ""}</div>
                                                        </div>
                                                      </div>
                                                      <div className="box1">
                                                        <label>Job Description</label><br />
                                                        <ReactQuill value={obj["summary"] ? obj["summary"] : ""} onChange={(e: any) => onChangeHandelerFunction(index, e, "summary", employeementHistory, setemployeementHistory)} modules={{ toolbar: [['bold', 'italic', 'underline'], [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'link']] }} formats={["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link"]} />
                                                      </div>
                                                    </div>
                                                  ))
                                                }

                                                <button className="ipt text-start" placeholder="+  Add more job history" onClick={() => addExtra(setemployeementHistory, employeementHistory, { user_id: localStorage.getItem("user_id"), job_title: "", company: "", start_date: "", end_date: "", location: "", summary: "" }, "employeementHistory")}> + Add more job history </button>

                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <button onClick={handleBackClick} className="btn2" id="backButton">
                                                Back
                                              </button>
                                              <button onClick={() => handleNextClick("EmployeementHistory")} className="btn1 ms-auto" id="nextButton" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="spinner-border" style={{ display: "none" }} id="EMloading" role="status">
                                                  <span className="sr-only">Loading...</span>
                                                </div>
                                                <span style={{ display: "block" }} id="EMnext" >Next</span>
                                              </button>

                                            </div>
                                          </div>
                                          <div id="headingFive"></div>
                                          <div id="step5" className="collapse step-hide" aria-labelledby="headingFive"
                                            data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                              <div className="row education">
                                                <div className="col-12">
                                                  <h2 className='my-3'>Education</h2>
                                                  <p className="para mb-4">
                                                    Add details about your school or schools and what you studied.
                                                  </p>
                                                </div>
                                                {
                                                  education.map((item: any, index: number) => {
                                                    return (
                                                      <div key={index}>
                                                        <div className='d-flex justify-content-end'>
                                                          <button className='btn btn-danger' style={{ textAlign: "right", fontSize: "12px", padding: "5px 10px" }} onClick={() => {
                                                            if (education.length === 1) {
                                                              alert("Minimum One Record Needed")
                                                            } else {
                                                              // Display confirmation dialog
                                                              const confirmDelete = window.confirm("Are you sure you want to delete this record?");
                                                              // If user confirms deletion, execute seteducation
                                                              if (confirmDelete) {
                                                                seteducation([...education.filter((val, ind) => ind !== index)])
                                                              }
                                                            }
                                                          }}>&#10006; </button>
                                                        </div>
                                                        <div className="row mb-4">
                                                          <div className="col-12 col-md-6">
                                                            <label>School</label><br />
                                                            <input type="text" name="" value={education[index].institution} onChange={(e) => onChangeHandelerFunction(index, e.target.value, "institution", education, seteducation)} placeholder="Tyler Community College" />
                                                            <div style={{ color: 'red' }}>{formErrors.educationHistory[index].institution ? "Institute name is required" : ""}</div>
                                                          </div>
                                                          <div className="col-12 col-md-6">
                                                            <label>Degree or Diploma</label><br />
                                                            <input type="text" name="" value={education[index].degree} onChange={(e) => onChangeHandelerFunction(index, e.target.value, "degree", education, seteducation)} id="input" placeholder="Associate of Arts in History" />
                                                            <div style={{ color: 'red' }}>{formErrors.educationHistory[index].degree ? "Degree is required" : ""}</div>
                                                          </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                          {/* <div className="col-12 col-md-6">
                                                            <label>Start Date</label><br />
                                                            <input type="month" max={education[index].end_date ? education[index].end_date : ""} name="" value={education[index].start_date} onChange={(e) => onChangeHandelerFunction(index, e.target.value, "start_date", education, seteducation)} id="input" placeholder="Jan, 2020" />
                                                            
                                                          </div> */}
                                                          <div className="col-12 col-md-6">
                                                            <label>Graduation</label><br />
                                                            <select id={`gradmonth-${index}`} name={`gradmonth-${index}`} value={education[index].end_date.split("-")[1]} onChange={(e) => handleGraduationEndMonthChange(e.target.value, index, "gradmonth", "gradyear")} style={{ width: '50%', height: '70%', borderRadius: '10px', border: '2px solid #B8BFCA' }}>
                                                              <option value="">Month</option>
                                                              <option value="01">January</option>
                                                              <option value="02">February</option>
                                                              <option value="03">March</option>
                                                              <option value="04">April</option>
                                                              <option value="05">May</option>
                                                              <option value="06">June</option>
                                                              <option value="07">July</option>
                                                              <option value="08">August</option>
                                                              <option value="09">September</option>
                                                              <option value="10">October</option>
                                                              <option value="11">November</option>
                                                              <option value="12">December</option>
                                                            </select>
                                                            <select id={`gradyear-${index}`} name={`gradyear-${index}`} value={education[index].end_date.split("-")[0]} onChange={(e) => handleGraduationEndYearChange(e.target.value, index, "gradyear", "gradmonth")} style={{ width: '50%', height: '70%', borderRadius: '10px', border: '2px solid #B8BFCA' }}>
                                                              <option value="">Year</option>
                                                              {years.map(year => (
                                                                <option key={year} value={year}>{year}</option>
                                                              ))}
                                                            </select>
                                                            {/* <input type="month" min={education[index].start_date ? education[index].start_date : ""} name="" value={education[index].end_date} onChange={(e) => onChangeHandelerFunction(index, e.target.value, "end_date", education, seteducation)} id="input" placeholder="Jan, 2023" /> */}
                                                            <div style={{ color: 'red' }}>{formErrors.educationHistory[index].end_date ? "Graduation completion date is required" : ""}</div>
                                                          </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                          <div className="col-12">
                                                            <label>Location</label><br />
                                                            <input type="text" name="" value={education[index].location} onChange={(e) => onChangeHandelerFunction(index, e.target.value, "location", education, seteducation)} id="input" placeholder="San Francisco, California" />
                                                            <div style={{ color: 'red' }}>{formErrors.educationHistory[index].location ? "Location is required" : ""}</div>
                                                          </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                          <div className="col-12">
                                                            <div className="box1">
                                                              <label>Description of School Activities (optional)</label><br />

                                                              <ReactQuill value={education[index]["summary"] ? education[index]["summary"] : ""} onChange={(e: any) => onChangeHandelerFunction(index, e, "summary", education, seteducation)} modules={{ toolbar: [['bold', 'italic', 'underline'], [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'link']] }} formats={["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link"]} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  })
                                                }

                                                <button className="ipt text-start" placeholder="+  Add more education history" onClick={() => addExtra(seteducation, education, { user_id: localStorage.getItem("user_id"), institution: "", degree: "", start_date: "", end_date: "", location: "", summary: "" }, "educationHistory")}> + Add more education history </button>
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <button onClick={handleBackClick} className="btn2" id="backButton">
                                                Back
                                              </button>
                                              <button onClick={() => handleNextClick("Education")} className="btn1 ms-auto" id="nextButton" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="spinner-border" style={{ display: "none" }} id="EDloading" role="status">
                                                  <span className="sr-only">Loading...</span>
                                                </div>
                                                <span style={{ display: "block" }} id="EDnext" >Next</span>
                                              </button>

                                            </div>
                                          </div>


                                          <div id="headingSix"></div>
                                          <div id="step6" className="collapse step-hide" aria-labelledby="headingSix"
                                            data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                              <div className="row">
                                                <div className="col-12 px-0">
                                                  <h2 className='text-left my-3'>Skills</h2>
                                                  <p className="para mb-4">
                                                    Choose your most important skills that relate to the job you want.
                                                  </p>
                                                </div>
                                                <div className="row" style={{ display: "inline", alignItems: 'center' }}>

                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Time management")} type="button" style={{ color: Skill.includes("Time management") ? "green" : "", backgroundColor: Skill.includes("Time management") ? "#b7ffb7" : "", border: Skill.includes("Time management") ? "1px solid green" : "" }}>
                                                    Time management
                                                  </button>

                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Attention to detail")} type="button" style={{ color: Skill.includes("Attention to detail") ? "green" : "", backgroundColor: Skill.includes("Attention to detail") ? "#b7ffb7" : "", border: Skill.includes("Attention to detail") ? "1px solid green" : "" }}>
                                                    Attention to detail
                                                  </button>

                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Decision-making")} type="button" style={{ color: Skill.includes("Decision-making") ? "green" : "", backgroundColor: Skill.includes("Decision-making") ? "#b7ffb7" : "", border: Skill.includes("Decision-making") ? "1px solid green" : "" }}>
                                                    Decision-making
                                                  </button>

                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Managing people")} style={{ color: Skill.includes("Managing people") ? "green" : "", backgroundColor: Skill.includes("Managing people") ? "#b7ffb7" : "", border: Skill.includes("Managing people") ? "1px solid green" : "" }} type="button">
                                                    Managing people
                                                  </button>

                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Teamwork and collaboration")} style={{ color: Skill.includes("Teamwork and collaboration") ? "green" : "", backgroundColor: Skill.includes("Teamwork and collaboration") ? "#b7ffb7" : "", border: Skill.includes("Teamwork and collaboration") ? "1px solid green" : "" }} type="button">
                                                    Teamwork and collaboration
                                                  </button>

                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Active listening")} style={{ color: Skill.includes("Active listening") ? "green" : "", backgroundColor: Skill.includes("Active listening") ? "#b7ffb7" : "", border: Skill.includes("Active listening") ? "1px solid green" : "" }} type="button">
                                                    Active listening
                                                  </button>

                                                  {/*  <button className="ms-1  chip-css" onClick={() => addDefault("Active listening")} style={{ color: Skill.includes("Active listening") ? "green" : "", backgroundColor: Skill.includes("Active listening") ? "#b7ffb7" : "", border: Skill.includes("Active listening") ? "1px solid green" : "" }} type="button">
                                                    Active listening
                                                  </button> */}

                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Project management")} type="button" style={{ color: Skill.includes("Project management") ? "green" : "", backgroundColor: Skill.includes("Project management") ? "#b7ffb7" : "", border: Skill.includes("Project management") ? "1px solid green" : "" }}>
                                                    Project management
                                                  </button>

                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Critical thinking")} type="button" style={{ color: Skill.includes("Critical thinking") ? "green" : "", backgroundColor: Skill.includes("Critical thinking") ? "#b7ffb7" : "", border: Skill.includes("Critical thinking") ? "1px solid green" : "" }}>
                                                    Critical thinking
                                                  </button>

                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Relationship Building")} type="button" style={{ color: Skill.includes("Relationship Building") ? "green" : "", backgroundColor: Skill.includes("Relationship Building") ? "#b7ffb7" : "", border: Skill.includes("Relationship Building") ? "1px solid green" : "" }}>
                                                    Relationship Building
                                                  </button>

                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Motivating others")} type="button" style={{ color: Skill.includes("Motivating others") ? "green" : "", backgroundColor: Skill.includes("Motivating others") ? "#b7ffb7" : "", border: Skill.includes("Motivating others") ? "1px solid green" : "" }}>
                                                    Motivating others
                                                  </button>




                                                </div>
                                                {/* <div className="row" style={{ display: "inline", alignItems: 'center' }}>
                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Selling products or services")} type="button" style={{ color: Skill.includes("Selling products or services") ? "green" : "", backgroundColor: Skill.includes("Selling products or services") ? "#b7ffb7" : "", border: Skill.includes("Selling products or services") ? "1px solid green" : "" }}>

                                                    Selling products or services
                                                  </button>
                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Organizing")} type="button" style={{ color: Skill.includes("Organizing") ? "green" : "", backgroundColor: Skill.includes("Organizing") ? "#b7ffb7" : "", border: Skill.includes("Organizing") ? "1px solid green" : "" }}>

                                                    Organizing
                                                  </button>
                                                </div>
                                                <div className="row" style={{ display: "inline", alignItems: 'center' }}>

                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Designing")} type="button" style={{ color: Skill.includes("Designing") ? "green" : "", backgroundColor: Skill.includes("Designing") ? "#b7ffb7" : "", border: Skill.includes("Designing") ? "1px solid green" : "" }}>
                                                    Designing
                                                  </button>
                                                  <button className="ms-1  chip-css" onClick={() => addDefault("Writing")} type="button" style={{ color: Skill.includes("Writing") ? "green" : "", backgroundColor: Skill.includes("Writing") ? "#b7ffb7" : "", border: Skill.includes("Writing") ? "1px solid green" : "" }}>

                                                    Writing
                                                  </button></div> */}

                                              </div>
                                              <hr />
                                              <div className="row" style={{ display: "inline", alignItems: 'center' }}>
                                                {
                                                  Skill && Skill.map((val: string, index: number) => {
                                                    return (<button key={index} onMouseEnter={() => setShowCrossIcon(val)}
                                                      onMouseLeave={() => setShowCrossIcon("")} style={{ position: 'relative', }} className="ms-1  chip-css col-12 col-sm-6 com-lg-3" type="button">
                                                      {val}
                                                      {showCrossIcon === val && (
                                                        <span
                                                          onClick={() => handleCrossIconClick(val)}
                                                          style={{
                                                            color: "red",
                                                            position: 'absolute',
                                                            top: 1,
                                                            right: 8,
                                                            cursor: 'pointer',
                                                          }}
                                                        >
                                                          {/* Add your cross icon here */}
                                                          &#10006;
                                                        </span>)}
                                                    </button>)
                                                  })
                                                }

                                              </div>
                                              <div className='skills'>
                                                <input type="text" name="skills" id="input" value={addAditionalSkills} placeholder='ReactJS' onChange={(e) => setaddAditionalSkills(e.target.value)} />
                                                <div style={{ color: 'red' }}>{formErrors.Skill ? "Skill Field is Empty" : ""}</div>
                                              </div>
                                              <div className='text-right mt-2'>
                                                <button className='btn btn-danger addSkill' onClick={addSkills}>Add</button>
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <button onClick={handleBackClick} className="btn2" id="backButton">
                                                Back
                                              </button>
                                              <button onClick={() => handleNextClick("Skills")} className="btn1 ms-auto" id="nextButton" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="spinner-border" style={{ display: "none" }} id="Sloading" role="status">
                                                  <span className="sr-only">Loading...</span>
                                                </div>
                                                <span style={{ display: "block" }} id="Snext" >Next</span>
                                              </button>
                                            </div>
                                          </div>


                                          <div id="headingSix"></div>
                                          <div id="step7" className="collapse step-hide" aria-labelledby="headingSix"
                                            data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                              <div className="row add-extra">
                                                <div className="col-12 px-0">
                                                  <h1 className='text-left'>
                                                    Optional:
                                                  </h1>
                                                  <p className="para">
                                                    Do you have additional skills, talents, or things you want to share? Write them here.
                                                  </p>
                                                </div>
                                                {/* <button className="ipt text-start"
                                                  placeholder="+  Add Additional Details" onClick={() => handleShowEditor("additional")}> + Add Additional Details </button> */}
                                                {/* {additionalDetails.additional && ( */}
                                                <div className="box1">
                                                  <ReactQuill value={additionalSummary} onChange={(content: any) => setadditionalSummary(content)} modules={{ toolbar: [['bold', 'italic', 'underline'], [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'link']] }} formats={["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link"]} />
                                                </div>
                                                {/* )} */}
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <button onClick={handleBackClick} style={{ height: "50px" }} className="btn2" id="backButton">
                                                Back
                                              </button>
                                              <button onClick={() => handleNextClick("AdditionalDetails")} className="btn1 ms-auto" id="nextButton" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="spinner-border" style={{ display: "none" }} id="Aloading" role="status">
                                                  <span className="sr-only">Loading...</span>
                                                </div>
                                                <span style={{ display: "block" }} id="Anext" >Save</span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div id="pane-job-desc" className="card tab-pane fade show active" role="tabpanel"
                                          aria-labelledby="tab-A" style={{ textAlign: "end" }}>
                                          {/* {currentIndex == 6 ? (
                                            <LoadingButton loading={loadingCreateResume} className='btn1 ms-auto border-none' onClick={() => CreateResume()}>
                                              {loadingCreateResume ? "" : "Create Resume"}
                                            </LoadingButton>
                                          ) : (
                                            <p></p>
                                          )
                                          } */}


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="pane-B" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-B">
                    <div className="card-header" role="tab" id="heading-B">
                      <h5 className="mb-0">
                        <a className="collapsed" data-toggle="collapse" href="#collapse-B" aria-expanded="false"
                          aria-controls="collapse-B">
                          Accordion 02
                        </a>
                      </h5>
                    </div>

                    <div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
                      <div className="card-body p-0">
                        <div className="row p-0">
                          <div className="col-md-12 p-0">
                            <h6 style={{ fontWeight: 'lighter' }}>Add Resume/Copy and Paste Job Description</h6>
                            <h4><b>Copy/Paste Job Description</b></h4>

                            <div id="content" className="tab-content" role="tablist">
                              <div id="pane-job-desc" className="card tab-pane fade show active" role="tabpanel"
                                aria-labelledby="tab-A">
                                <div className="card-header" role="tab" id="heading-A">
                                  <h5 className="mb-0">
                                    {/* <!-- Note: `data-parent` removed from here --> */}
                                    <a data-toggle="collapse" href="#collapse-A" aria-expanded="true"
                                      aria-controls="collapse-A">
                                      Accordion 01
                                    </a>
                                  </h5>
                                </div>

                                {/* <!-- Note: New place of `data-parent` --> */}
                                <div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel"
                                  aria-labelledby="heading-A">
                                  <div className="card-body new-card-body mt-2">
                                    <div className="row">
                                      <div className="col-md-12 px-0">
                                        {/* <!-- <h2>Text</h2> --> */}
                                        <div className="row text-tab">
                                          <div className="col-12">
                                            <Box
                                            >
                                              <textarea style={{ width: "100%", border: "none", overflowY: "scroll" }} onFocus={(e) => {
                                                const target = e.target as HTMLTextAreaElement;
                                                target.style.border = 'none';
                                                // target.style.overflow = 'hidden';
                                                target.style.outline = 'none';
                                              }} rows={20} value={JobDescData} onChange={(e: any) => handleJobContentChange(e)} />
                                            </Box>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>

                              </div>
                              {/* <div className="d-flex justify-content-between">
                                <LoadingButton loading={loadingCreateResume} className='btn1 ms-auto border-none' onClick={() => CreateResume()}>
                                  {loadingCreateResume ? "" : "Create Resume"}
                                </LoadingButton>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div >


              {/* <!-- Column 2 --> */}
              <div className="col-md-7 col-xxl-7 right" style={{ paddingBottom: "20px" }} >
                {/* <!-- Tabs --> */}
                {/* < ul id="tabs" className="row nav mb-2 nav-tabs left-tabs" role="tablist">
                  <li className="nav-item col-md-6 bg">
                    <a id="tab-A" href="#pane-AAA" className="nav-link active" data-toggle="tab" role="tab" onClick={() => setIsType('resume')}>RESUME
                    </a>
                  </li>
                  <li className="nav-item col-md bg">
                    <a id="tab-B" href="#pane-BBB" className="nav-link" data-toggle="tab" role="tab" onClick={() => setIsType('cover')}>COVER LETTER</a>
                  </li>
                </ul>
                <ul id="tabs" className="row nav nav-tabs right-tabs" role="tablist">
                  <li className="nav-item col-md-6 bg" style={{ cursor: "pointer" }} onClick={() => setShowTemplateModal(true)}>
                    <a id="tab-A" className="nav-link" >My Resume</a>
                  </li>
                  <li className="nav-item col-md bg m" style={{ cursor: "pointer" }} onClick={() => setShowViewTemplateModal(true)}>
                    <a id="tab-B" className="nav-link" >My Cover Letter</a>
                  </li>
                </ul> */}

                {/* <div className="row nav nav-tabs left-tabs" style={{ textAlign: 'center', alignItems: 'center' }}> */}
                {/* <div className='col-md-3 col-xxl-3' style={{ textAlign: 'center', alignItems: 'center' }}>
                    <a id="tab-B" href={`#${activePane}`} className="nav-link bg" data-toggle="tab" role="tab" style={{display: 'flow-root', color: 'blue', textDecoration: 'underLine' }}>
                      <Button variant="outlined" onClick={!coverData ? undefined : () => handleCoverletterTab()} style={{ cursor: !coverData ? 'not-allowed' : 'pointer', minWidth: '60px', margin: '1% 0%', borderRadius: '4px', padding: '17px 10px', boxShadow: 'none', lineHeight: 'inherit', color: tabBbuttonColor, background: tabBbuttongbColor, border: "none" }}>{activePane === "pane-BBB" ? <>Step 5 <br></br> Show Resume</> : <>Step 5 <br></br> Show Cover Letter</>}</Button>
                    </a>
                  </div> */}

                {/* <div className='col-md-3 col-xxl-3' style={{ textAlign: 'center', alignItems: 'center', marginRight: '25%' }}>
                    <a id="tab-A" className="nav-link bg" style={{ textAlign: 'center', alignContent: 'center', cursor: 'pointer', display: 'inline-grid', color: 'blue', textDecoration: 'underLine', background: "#fff" }}>
                      
                      <Dropdown drop="up" className="custom-dropdown-menu1" onBlur={() => { toggleColor() }} style={{ justifyContent: "center", alignItems: "center", textDecoration: 'none', color: tabBbuttonColor, border: 'none', background: tabBbuttongbColor, fontWeight: 500, fontSize: '14px', height: '100%', cursor: 'not-allowed' }}>

                        <Dropdown.Toggle onMouseOut={() => { toggleColor() }} onBlur={() => { toggleColor() }} style={{ width: '100%', height: '100%' }}>
                          <span>Step 6<br></br></span>Download
                          <Dropdown.Menu >
                            <Dropdown.Item onClick={() => { handleOptionSelect('pdf'); }} style={{ fontSize: "14px", fontWeight: "400" }}>PDF</Dropdown.Item>
                            <Dropdown.Item onClick={() => { handleOptionSelect('word'); }} style={{ fontSize: "14px", fontWeight: "400" }}>MS Word</Dropdown.Item>
                            <Dropdown.Item onClick={() => { handleOptionSelect('googledocs'); }} style={{ fontSize: "14px", fontWeight: "400" }}>Google Docs</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown.Toggle>

                      </Dropdown>
                    </a>
                  </div> */}

                {/* <div className='col-md-3 col-xxl-3' style={{ textAlign: 'center', alignItems: 'center' }}>
                    <a id="tab-A" className="nav-link bg " style={{ background: "#fff", margin: '-1% 0%', textAlign: 'center', alignContent: 'center', cursor: 'pointer', display: 'inline-block', textDecoration: 'underLine', }} onClick={() => setShowTemplateModal(true)}>
                      <Button variant="outlined" style={{ minWidth: '60px', margin: '1% 0%', borderRadius: '4px', padding: '0px 10px', boxShadow: 'none', paddingTop: '10%', paddingBottom: '10%', border: 'none' }}>View Saved Resumes</Button>
                    </a>
                  </div> */}
                {/* <a id="tab-B" className="nav-link bg" style={{ cursor: 'pointer', display: 'inline-block', width: '33%', color: 'blue', textDecoration: 'underLine' }} onClick={() => setShowViewTemplateModal(true)}>
                    View Saved CoverLetters
                  </a> */}
                {/* <a id="tab-B" href={`#${activePane}`}"#pane-BBB" className="nav-link" data-toggle="tab" role="tab" onClick={handleCoverletterTab() => setIsType('cover')} style={{ cursor: 'pointer', display: 'inline-block', width: '33%', color: 'blue', textDecoration: 'underLine' }}>
                    {activePane === "pane-BBB" ? "View Generated Resume" : "View Generated Cover Letter"}
                    </a> */}
                {/* </div> */}
                <Dialog maxWidth="lg" open={showViewTemplateModal} onClose={handleCloseViewTemplateModal} style={{ width: "100%" }}>
                  <Box sx={{ backgroundColor: "white", padding: "20px" }}>
                    <div >
                      <div className="row justify-content-center">
                        <h3 style={{ color: "#222", fontWeight: "bold", marginBottom: "20px", position: 'absolute', top: 0, left: 0, width: '100%', padding: '10px' }}>
                          My Cover Letter</h3>
                      </div>
                      <div className="row" style={{ marginTop: '50px', maxHeight: '80%' }}>
                        {savedCoverLettersData && savedCoverLettersData.map((data, index) => {
                          const personalDetails = data?.content?.["PERSONAL DETAILS"];

                          const firstName: string | undefined = savedResumesFirstName[index]/* (personalDetails?.["Name"]) ? personalDetails?.["Name"] : personalDetails?.["First Name"] */;
                          const rawDate: string | undefined | null = data?.created_on;
                          const newDate: string | undefined | null = `${rawDate?.substring(0, 10)}   ${rawDate?.substring(11, 19)}`;
                          const cleanedDate: string | undefined | null = newDate?.replace(/[^\w]|_/g, "").slice(0, -2);
                          return (
                            <div key={index} className="card" style={{ marginBottom: '10px', border: selectedCoverLetter?._id === data._id ? "2px solid #1B75D0" : "" }} >
                              <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => setSelectedCoverLetter(data)}>
                                <p>{firstName ? firstName + '_CoverLetter_' + cleanedDate : `New CoverLetter${index + 1}`}</p>
                                <p>
                                  {newDate}
                                  <span onClick={() => handleDeleteCoverLetter(data?._id)}>
                                    <HighlightOffRoundedIcon color='warning' style={{ marginLeft: '30px', marginRight: '10px' }} />
                                  </span>
                                </p>
                              </div>
                            </div>
                          )
                        })
                        }
                      </div>

                      {/* <div className="col-md-4">
                      <label>
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                        />
                        <img src={resumeTemplateTwo} alt="Image 2" style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template2' ? "2px solid #1B75D0" : "" }} />
                      </label>
                    </div> */}

                    </div>
                    <div style={{ textAlign: "end", margin: "10px 10px" }}>
                      <Button variant="outlined" onClick={
                        () => {
                          handleCloseViewTemplateModal();
                          setCoverData(selectedCoverLetter?.formatted_data);
                          setJobDescData(selectedCoverLetter?.original_data);
                          downloadmode = "cover";
                          localStorage.setItem("cover_doc_id", selectedCoverLetter?.document_id);
                          setActivePane("pane-AAA");
                          handleCoverletterTab();
                        }} disableElevation>
                        Select
                      </Button>
                    </div>
                  </Box>
                </Dialog>
                <Dialog maxWidth="lg" open={showTemplateModal} onClose={handleCloseTemplateModal} style={{ width: "100%" }}>
                  <Box sx={{ backgroundColor: "white", padding: "20px" }}>
                    <div >
                      <div className="row justify-content-center">
                        <h3 style={{ color: "#222", fontWeight: "bold", marginBottom: "20px", position: 'absolute', top: 0, left: 0, width: '100%', padding: '10px' }}>
                          My Resume</h3>
                      </div>
                      <div style={{ textAlign: "end", margin: "10px 10px" }}>
                        <Button variant="outlined" onClick={() => {
                          if (!selectedResume) {
                            toast.error('Please select one resume');
                          }
                          else {
                            handleCloseTemplateModal();
                            addDataSaved(selectedResume?.raw_content);
                            setdisplayResumeData(selectedResume?.formatted_data);
                            sethtmlResumeContent(selectedResume?.formatted_data);
                            handleFormData(selectedResume);
                            localStorage_msg = selectedResume.content;
                            downloadmode = "resume";
                            handleResumePreview("saved", localStorage_msg);
                            localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg));
                            localStorage.setItem('doc_id', selectedResume?.document_id);
                            //console.log(localStorage.getItem("doc_id"));
                            setActivePane("pane-BBB");
                            handleCoverletterTab();
                          }
                        }
                        } disableElevation>
                          Select
                        </Button>
                        &nbsp; &nbsp;
                        <Button variant="outlined" onClick={() => {

                          handleCloseTemplateModal();
                        }}
                        >
                          Close
                        </Button>
                      </div>
                      <div className="row" style={{ marginTop: '50px', maxHeight: '80%', padding: '10' }}>
                        {savedResumesData && savedResumesData.map((data, index) => {
                          const personalDetails = data?.content?.["PERSONAL DETAILS"];

                          const firstName: string | undefined = (personalDetails?.["Name"]) ? personalDetails?.["Name"] : personalDetails?.["First Name"];
                          const rawDate: string | undefined | null = data?.created_on;
                          const newDate: string | undefined | null = `${rawDate?.substring(0, 10)}  |  ${rawDate?.substring(11, 19)}`;
                          const cleanedDate: string | undefined | null = newDate?.replace(/[^\w]|_/g, "").slice(0, -2);
                          return (
                            <div key={index} className="card" style={{ marginBottom: '10px', border: selectedResume?._id === data._id ? "2px solid #1B75D0" : "" }} >
                              <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => setSelectedResume(data)}>
                                <p>{firstName ? firstName + '_Resume_' + cleanedDate : `New Resume${index + 1}_` + cleanedDate}</p>
                                <p>
                                  {newDate}
                                  <span onClick={() => handleDeleteResume(data?._id)}>
                                    <HighlightOffRoundedIcon color='warning' style={{ marginLeft: '30px', marginRight: '10px' }} />
                                  </span>
                                </p>
                              </div>
                            </div>
                          )
                        })
                        }
                      </div>
                    </div>
                    <div style={{ textAlign: "end", margin: "10px 10px" }}>
                      <Button variant="outlined" onClick={() => {
                        if (!selectedResume) {
                          toast.error('Please select one resume');
                        }
                        else {
                          handleCloseTemplateModal();
                          addDataSaved(selectedResume?.raw_content);
                          setdisplayResumeData(selectedResume?.formatted_data);
                          sethtmlResumeContent(selectedResume?.formatted_data);
                          handleFormData(selectedResume);
                          localStorage_msg = selectedResume.content;
                          downloadmode = "resume";
                          handleResumePreview("saved", localStorage_msg);
                          localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg));
                          localStorage.setItem('doc_id', selectedResume?.document_id);
                          //console.log(localStorage.getItem("doc_id"));
                          setActivePane("pane-BBB");
                          handleCoverletterTab();
                        }
                      }
                      } disableElevation>
                        Select
                      </Button>
                      &nbsp; &nbsp;
                      <Button variant="outlined" onClick={() => {

                        handleCloseTemplateModal();
                      }}
                      >
                        Close
                      </Button>
                    </div>
                  </Box>
                </Dialog>
                {/* <Dialog maxWidth="lg" open={showTemplateModal} onClose={handleCloseTemplateModal} style={{ width: "100%" }}>
                <Box sx={{ backgroundColor: "white", padding: "20px" }}>
                  <div className="row">
                    <h3 style={{ color: "#222", fontWeight: "bold", marginBottom: "20px" }}>Choose one template</h3>
                    <div className="col-md-4">
                      <label >
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                        />
                        <img src={resumeTemplateOne} alt="Image 1" onClick={()=> setSelectedImage("template1")} style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template1' ? "2px solid #1B75D0" : "" }} />
                      </label>
                    </div>

                    <div className="col-md-4">
                      <label>
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                        />
                        <img src={resumeTemplateTwo} alt="Image 2" onClick={()=> setSelectedImage("template2")} style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template2' ? "2px solid #1B75D0" : "" }} />
                      </label>
                    </div>

                    <div className="col-md-4">
                      <label>
                        <input
                          type="checkbox"
                          style={{ display: "none" }}
                        />
                        <img src={resumeTemplateThree} alt="Image 3" onClick={()=> setSelectedImage("template3")} style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template3' ? "2px solid #1B75D0" : "" }} />
                      </label>

                    </div>
                  </div>
                  <div style={{ textAlign: "end", margin: "10px 10px" }}>
                    <Button variant="outlined" onClick={handleCloseTemplateModal} disableElevation>
                      Select
                    </Button>
                  </div>
                </Box>
              </Dialog> */}
                {/* <div className='mt-2 d-flex align-items-center'>
              <button className='btn btn-outline-primary' style={{ borderRight: "none", borderRadius: "0px" }}>Summary</button>
              <button className='btn btn-outline-primary' style={{ borderRadius: "0px" }}>details</button>
              <div className="form-check form-switch ms-2">
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                <label className="form-check-label text-black" >With Profile Picture</label>
              </div>
            </div> */}
                <div id="content" className="tab-content mt-2" role="tablist">
                  <div id="pane-AAA" className={`card tab-pane fade ${activePane === "pane-AAA" ? "show active" : ""}`} /* className="card tab-pane fade show active" */ role="tabpanel" aria-labelledby="tab-A">
                    <div className="card-header" role="tab" id="heading-A">
                      <h5 className="mb-0">
                        {/* <!-- Note: `data-parent` removed from here --> */}
                        <a data-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
                          Accordion 01
                        </a>
                      </h5>
                    </div>

                    {/* <!-- Note: New place of `data-parent` --> */}
                    <div className="demo-resume p-2">
                      <div className="d-flex justify-content-between">
                        <Box sx={{ display: 'flex', alignContent: 'center' }}>
                          {loadingCreateResume &&
                            <span style={{ display: 'flex', alignContent: 'center' }}>
                              {/* <CircularProgress size={22}  style={{marginTop:'12px', marginLeft:'7px'}}/> */}
                              <div className='loader-spiner' style={{ marginTop: '20px', marginLeft: '15px' }}></div>
                              <span style={{ marginLeft: '22px', marginTop: '12px', color: '#7CB9E8' }}>Processing...</span>
                            </span>
                          }
                        </Box>
                        {/* <button className="btn" style={{ color: "#0076ad" }} onClick={EditResumeData}>Edit</button> */}
                        <div className="d-flex justify-content-end">
                          {/* <button className="btn btn-outline-info" onClick={() => handleSave()} style={{ marginRight: '16px' }}>Save</button>
                          <Dropdown className="btn btn-outline-info" style={{ padding: "10px 5px", color: "inherit" }} onClick={() => setShowModal(true)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.5 14H3.5C3.36739 14 3.24021 13.9473 3.14645 13.8536C3.05268 13.7598 3 13.6326 3 13.5V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H9.5L13 5.5V13.5C13 13.6326 12.9473 13.7598 12.8536 13.8536C12.7598 13.9473 12.6326 14 12.5 14Z"
                                stroke="#292C33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M9.5 2V5.5H13" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                                strokeLinejoin="round" />
                              <path d="M6.25 9.25L8 7.5L9.75 9.25" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                                strokeLinejoin="round" />
                              <path d="M8 11.5V7.5" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                                strokeLinejoin="round" />
                            </svg>
                            &nbsp; Change Design
                          </Dropdown> */}
                          {/* </Dropdown.Toggle> */}

                          {/* <Dropdown.Menu>
                              <Dropdown.Item onClick={() => handleOptionSelect('pdf')} style={{ fontSize: "14px", fontWeight: "400" }}>PDF</Dropdown.Item>
                              <Dropdown.Item onClick={() => handleOptionSelect('word')} style={{ fontSize: "14px", fontWeight: "400" }}>MS Word</Dropdown.Item>
                              <Dropdown.Item onClick={() => handleOptionSelect('googledocs')} style={{ fontSize: "14px", fontWeight: "400" }}>Google Docs</Dropdown.Item>
                            </Dropdown.Menu> */}

                          {/* {selectedOption && <p>Selected Option: {selectedOption}</p>} */}

                          <Dialog maxWidth="lg" open={showModal} onClose={handleCloseModal} style={{ width: "100%" }}>
                            <Box sx={{ backgroundColor: "white", padding: "20px" }}>
                              <div className="row">
                                <h3 style={{ color: "#222", fontWeight: "bold", marginBottom: "20px" }}>Choose one template</h3>

                                <div className="col-md-4">
                                  <label >
                                    <input
                                      type="checkbox"
                                      style={{ display: "none" }}
                                      checked={selectedImage === 'template1'}
                                      onChange={() => handleCheckboxChange('template1')}
                                    />
                                    <img src={resumeTemplateOne} alt="Image 1" style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template1' ? "2px solid #1B75D0" : "2px solid #F2F2F2" }} />
                                  </label>
                                </div>

                                <div className="col-md-4">
                                  <label>
                                    <input
                                      type="checkbox"
                                      style={{ display: "none" }}
                                      checked={selectedImage === 'template2'}
                                      onChange={() => handleCheckboxChange('template2')}
                                    />
                                    <img src={resumeTemplateTwo} alt="Image 2" style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template2' ? "2px solid #1B75D0" : "2px solid #F2F2F2" }} />
                                  </label>
                                </div>

                                <div className="col-md-4">
                                  <label>
                                    <input
                                      type="checkbox"
                                      style={{ display: "none" }}
                                      checked={selectedImage === 'template3'}
                                      onChange={() => handleCheckboxChange('template3')}
                                    />
                                    <img src={resumeTemplateThree} alt="Image 3" style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template3' ? "2px solid #1B75D0" : "2px solid #F2F2F2" }} />
                                  </label>

                                </div>
                                {/* {changeDesignModalhandle && (<>
                                  <div className="col-md-4">
                                    <label>
                                      <input
                                        type="radio"
                                        name="exportOption"
                                        value="pdf"
                                        onChange={() => handleOptionSelect('pdf')}
                                      />
                                      Export as PDF
                                    </label>
                                  </div>
                                  <div className="col-md-4">
                                    <label>
                                      <input
                                        type="radio"
                                        name="exportOption"
                                        value="word"
                                        onChange={() => handleOptionSelect('word')}
                                      />
                                      Export as Word
                                    </label>
                                  </div>
                                  <div className="col-md-4">
                                    <label>
                                      <input
                                        type="radio"
                                        name="exportOption"
                                        value="googledocs"
                                        onChange={() => handleOptionSelect('googledocs')}
                                      />
                                      Export to Google DocsbuttonRefprevi
                                    </label>
                                  </div>
                                </>)} */}
                              </div>
                              <div style={{ textAlign: "end", margin: "10px 10px" }}>
                                {/* {downloadBtnhandle && (<> */}
                                {/* <LoadingButton loading={DownloadLoading} style={{ backgroundColor: "#0076AD", color: "#FFF", padding: DownloadLoading ? "18px 50px" : "" }} variant="contained" sx={{ mr: "20px" }} onClick={handleDownload} disableElevation> */}
                                {/* {DownloadLoading ? "" : "Close"} */}
                                {/* </LoadingButton> */}
                                {/* </>)} */}
                                <Button variant="outlined" onClick={handleCloseModal} disableElevation>
                                  Close
                                </Button>
                                &nbsp;
                                {/* <Button  variant="outlined" onClick={handleDownload} disableElevation>
                                  Download
                                </Button> */}
                              </div>
                            </Box>
                          </Dialog>

                          <Dialog maxWidth="lg" open={showModal2} onClose={handleCloseModal2} style={{ width: "100%" }}>
                            <Box sx={{ backgroundColor: "white", padding: "20px" }}>
                              <div className="row">
                                <h3 style={{ color: "#222", fontWeight: "bold", marginBottom: "20px" }}>Choose one template</h3>

                                <div className="col-md-4">
                                  <label >
                                    <input
                                      type="checkbox"
                                      style={{ display: "none" }}
                                      checked={selectedImage === 'template1'}
                                      onChange={() => handleCheckboxChange('template1')}
                                    />
                                    <img src={resumeTemplateOne} alt="Image 1" style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template1' ? "2px solid #1B75D0" : "" }} />
                                  </label>
                                </div>

                                <div className="col-md-4">
                                  <label>
                                    <input
                                      type="checkbox"
                                      style={{ display: "none" }}
                                      checked={selectedImage === 'template2'}
                                      onChange={() => handleCheckboxChange('template2')}
                                    />
                                    <img src={resumeTemplateTwo} alt="Image 2" style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template2' ? "2px solid #1B75D0" : "" }} />
                                  </label>
                                </div>

                                <div className="col-md-4">
                                  <label>
                                    <input
                                      type="checkbox"
                                      style={{ display: "none" }}
                                      checked={selectedImage === 'template3'}
                                      onChange={() => handleCheckboxChange('template3')}
                                    />
                                    <img src={resumeTemplateThree} alt="Image 3" style={{ maxWidth: '100%', maxHeight: '100%', border: selectedImage === 'template3' ? "2px solid #1B75D0" : "" }} />
                                  </label>

                                </div>
                                {changeDesignModalhandle && (<>
                                  {/* <div className="col-md-4">
                                    <label>
                                      <input
                                        type="radio"
                                        name="exportOption"
                                        value="pdf"
                                        onChange={() => handleOptionSelect('pdf')}
                                      />
                                      Export as PDF
                                    </label>
                                  </div>
                                  <div className="col-md-4">
                                    <label>
                                      <input
                                        type="radio"
                                        name="exportOption"
                                        value="word"
                                        onChange={() => handleOptionSelect('word')}
                                      />
                                      Export as Word
                                    </label>
                                  </div>
                                  <div className="col-md-4">
                                    <label>
                                      <input
                                        type="radio"
                                        name="exportOption"
                                        value="googledocs"
                                        onChange={() => handleOptionSelect('googledocs')}
                                      />
                                      Export to Google Docs
                                    </label>
                                  </div> */}
                                </>)}
                              </div>
                              <div style={{ textAlign: "end", margin: "10px 10px" }}>

                                <Button variant="outlined" onClick={handleCloseModal2} disableElevation>
                                  Close
                                </Button>
                              </div>
                            </Box>
                          </Dialog>
                        </div>
                      </div>
                      {/* <hr /> */}

                      <section className="container-fluid frame">
                        <div className="main-new container-fluid">
                          <div className="row">
                            <div className="col-md-12" style={{ marginTop: '5px' }}>
                              <Box
                                component="pre"
                                sx={(theme) => ({
                                  whiteSpace: "pre-wrap",
                                  overflow: "auto",
                                  height: "600px",
                                  "&.MuiBox-root::-webkit-scrollbar": {
                                    width: 4,
                                  },
                                  "&.MuiBox-root::-webkit-scrollbar-thumb": {
                                    backgroundColor: theme.palette.divider,
                                    borderRadius: 4,
                                  },
                                  "&.MuiBox-root::-webkit-scrollbar-track": {
                                    backgroundColor:
                                      theme.palette.mode === "dark" ? "#2D2D2D" : "#fff",
                                  },
                                })}
                                ref={contentRef}
                                contentEditable={isEditable}
                                onInput={(e: any) => handleContentChange(e)}
                                dangerouslySetInnerHTML={{ __html: htmlResumeContent }}
                              >

                              </Box>
                            </div>
                          </div>
                        </div>

                      </section>
                    </div>
                  </div>

                  <div id="pane-BBB" className={`card tab-pane fade ${activePane === "pane-BBB" ? "show active" : ""}`} /* className="card tab-pane fade" */ role="tabpanel" aria-labelledby="tab-B">
                    <div className="card-header" role="tab" id="heading-B">
                      <h5 className="mb-0">
                        <a className="collapsed" data-toggle="collapse" href="#collapse-B" aria-expanded="false"
                          aria-controls="collapse-B">
                          Accordion 02
                        </a>
                      </h5>
                    </div>

                    <div className="demo-resume p-2">
                      <div className="d-flex justify-content-end">
                        {/* <button className="btn" style={{ color: "#0076ad" }} onClick={EditCoverData} >Edit</button> */}
                        <div className="d-flex justify-content-around">
                          {/* <button className="btn btn-outline-info" style={{ marginRight: '11px' }} onClick={handleSaveCover}>
                            Save
                          </button> */}
                          {/* <button className="btn btn-outline-info mx-2" onClick={copyCoverDataToClipboard}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.5 11.5V2.5H4.5" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round" />
                            <path d="M11.5 4.5H2.5V13.5H11.5V4.5Z" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round" />
                          </svg>
                          &nbsp; Copy
                        </button> */}
                          {/*  <Dropdown>
                            <Dropdown.Toggle variant="outline-info" className="btn btn-outline-info" style={{ padding: "10px 5px", color: "inherit" }}>
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M12.5 14H3.5C3.36739 14 3.24021 13.9473 3.14645 13.8536C3.05268 13.7598 3 13.6326 3 13.5V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H9.5L13 5.5V13.5C13 13.6326 12.9473 13.7598 12.8536 13.8536C12.7598 13.9473 12.6326 14 12.5 14Z"
                                  stroke="#292C33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.5 2V5.5H13" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round" />
                                <path d="M6.25 9.25L8 7.5L9.75 9.25" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round" />
                                <path d="M8 11.5V7.5" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round" />
                              </svg>
                              &nbsp; Export
                            </Dropdown.Toggle> */}

                          {/* <Dropdown.Menu> */}
                          {/* <Dropdown.Item onClick={() => handleCoverOptionSelect('pdf')} style={{ fontSize: "14px", fontWeight: "400" }}>PDF</Dropdown.Item> */}
                          {/* <Dropdown.Item onClick={() => handleCoverOptionSelect('word')} style={{ fontSize: "14px", fontWeight: "400" }}>MS Word</Dropdown.Item> */}
                          {/* <Dropdown.Item onClick={() => handleCoverOptionSelect('googledocs')} style={{ fontSize: "14px", fontWeight: "400" }}>Google Docs</Dropdown.Item> */}
                          {/* </Dropdown.Menu> */}
                          {/* </Dropdown> */}

                          {/* {selectedOption && <p>Selected Option: {selectedOption}</p>} */}

                        </div>
                      </div>
                      {/* <hr /> */}


                      <div id="collapse-B" className="collapse" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <Box
                                component="pre"
                                sx={(theme) => ({
                                  whiteSpace: "pre-wrap",
                                  overflow: "auto",
                                  height: "600px",
                                  "&.MuiBox-root::-webkit-scrollbar": {
                                    width: 4,
                                  },
                                  "&.MuiBox-root::-webkit-scrollbar-thumb": {
                                    backgroundColor: theme.palette.divider,
                                    borderRadius: 4,
                                  },
                                  "&.MuiBox-root::-webkit-scrollbar-track": {
                                    backgroundColor:
                                      theme.palette.mode === "dark" ? "#2D2D2D" : "#fff",
                                  },
                                })}
                                ref={jobDescRef}
                                contentEditable={isEditableJobDesc}
                                onInput={(e: any) => handleCoverContentChange(e)}
                              >
                                {CoverData}
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-right" style={{ justifyContent: 'right' }}>
                  {/*  <LoadingButton
                    startIcon={
                      <SvgIcon>
                        <FeedBackIcon />
                      </SvgIcon>
                    }
                    sx={{
                      background:
                        "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                      marginLeft: 5,
                      color: "white",
                      paddingX: "20px",
                      paddingY: "12px",
                      borderRadius: "4px",
                      width: "fit-content",
                      float: 'right',
                      margin: '2% 0%'
                    }}
                    onClick={() => {
                      setIsPopupOpen(true);
                    }}>
                    Submit FeedBack
                  </LoadingButton> */}
                  {/* <Dropdown className="btn btn-outline-info" style={{
                    background:
                      "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 5,
                    color: "white",
                    // paddingX: "20px",
                    // paddingY: "12px",
                    padding: '10px 5px',
                    borderRadius: "4px",
                    width: "fit-content",
                    float: 'right',
                    margin: '2% 0%'
                  }} onClick={() => setShowModal2(true)}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.5 14H3.5C3.36739 14 3.24021 13.9473 3.14645 13.8536C3.05268 13.7598 3 13.6326 3 13.5V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H9.5L13 5.5V13.5C13 13.6326 12.9473 13.7598 12.8536 13.8536C12.7598 13.9473 12.6326 14 12.5 14Z"
                      stroke="#292C33" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.5 2V5.5H13" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round" />
                    <path d="M6.25 9.25L8 7.5L9.75 9.25" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round" />
                    <path d="M8 11.5V7.5" stroke="#292C33" strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round" />
                  </svg>
                  &nbsp; Change Design
                </Dropdown> */}
                  <Modal open={isPopupOpen} >
                    <Box sx={{
                      position: "absolute" as "absolute",
                      top: "50%",
                      left: "50%",
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      transform: "translate(-50%, -50%)",
                      width: isMobile ? "95%" : 600,
                      bgcolor: "background.paper",
                      border: "2px solid #000",
                      boxShadow: 24,
                      pt: 2,
                      px: 4,
                      pb: 3,
                    }}>
                      <div style={{ padding: '3%' }}>
                        {"How satisfied are you with our Resume builder?"}
                      </div>
                      <div style={{ padding: '3%' }}>
                        {
                          ratings.map((rating, index) => (
                            <Button key={index} style={{ backgroundColor: rating <= feedbackForm.rating ? "#187BC6" : '#FFC107', color: '#FFFF', margin: '5px', minWidth: '50px' }} onClick={() => handleRating(rating)}>{rating}</Button>
                          ))
                        }
                      </div>
                      <TextareaAutosize
                        aria-label="Comments"
                        minRows={3}
                        placeholder="Enter your comments(Optional)"
                        onChange={handleTextAreaChange}
                        style={{ width: '100%', marginBottom: '10px' }}
                      />
                      <div style={{ paddingTop: '3%', width: '100%' }}>
                        <Button
                          onClick={() => {
                            setIsPopupOpen(false);
                          }}
                          variant="contained"
                          sx={{ mr: 2, /* backgroundColor: '#DC3545', */ float: 'right' }}
                        >
                          Close
                        </Button>
                        <Button variant="contained" sx={{ mr: 2,/*  backgroundColor: '#198754', */ float: 'right' }}
                          onClick={handleFormSubmit}
                        >
                          Submit
                        </Button>
                      </div>
                    </Box>
                  </Modal>

                  {/* <button className="btn2 ms-auto border-none" id="backButton" style={{ padding: "8px 10px", marginRight: "10px" }}>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.4125 16.8781L16.1375 19.8781C16.7469 20.2625 17.4969 19.6906 17.3187 18.9875L15.95 13.6063C15.913 13.4571 15.9189 13.3005 15.967 13.1546C16.0151 13.0086 16.1034 12.8792 16.2219 12.7813L20.4594 9.2469C21.0125 8.78752 20.7312 7.8594 20.0094 7.81252L14.4781 7.45627C14.3272 7.44749 14.1821 7.39494 14.0606 7.30503C13.939 7.21513 13.8463 7.09177 13.7937 6.95002L11.7312 1.75627C11.6767 1.60618 11.5772 1.47653 11.4464 1.38491C11.3156 1.29329 11.1597 1.24414 11 1.24414C10.8403 1.24414 10.6844 1.29329 10.5536 1.38491C10.4228 1.47653 10.3233 1.60618 10.2687 1.75627L8.20625 6.95002C8.15368 7.09177 8.06096 7.21513 7.93943 7.30503C7.81789 7.39494 7.67279 7.44749 7.52187 7.45627L1.99062 7.81252C1.26875 7.8594 0.987496 8.78752 1.54062 9.2469L5.77812 12.7813C5.89656 12.8792 5.98492 13.0086 6.03302 13.1546C6.08112 13.3005 6.08701 13.4571 6.05 13.6063L4.78437 18.5938C4.56875 19.4375 5.46875 20.1219 6.19062 19.6625L10.5875 16.8781C10.7108 16.7997 10.8539 16.7581 11 16.7581C11.1461 16.7581 11.2892 16.7997 11.4125 16.8781V16.8781Z" stroke="#0076AD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span style={{ color: '#0076AD', fontWeight: 'bold' }}>&nbsp; &nbsp; &nbsp; Submit Feedback</span>
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </section >
      </div >

    </>

  )
}

export default InnerPageResumeBuilderSection 